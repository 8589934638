import React, { Component, Fragment } from 'react';
import { Form, Modal, Button, Dropdown, Pagination, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Info, Warnings, Errors } from '../utils/Notices';
import Navigation from '../shared/NavigationV2';
import { InnerSidebar, InnerSidebarToggle } from '../shared/InnerSidebar';
import { isMobile } from "react-device-detect";
import User from '../stores/User';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/documents',
      sharedDocuments: [],
      myDocuments: [],
      activeDocument: null,
      // activeDocument: {
      //   documentId: '',
      //   name: 'Publisher Ad Tech',
      //   publisherDocumentId: '1CoQcwwbL7Zwxleuf5Z6tKQhcydzV_CW_CvYhNZFviAw',
      //   publisher: 'google',
      //   type: 'spreadsheet'
      // },
      newDocument: {
        link: ''
      },
      newDisabled: true,
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await Promise.all([
      this.updateSharedDocuments(),
      this.updateMyDocuments()
    ]);
  }
  
  updateSharedDocuments = async () => {
    try {
      let [response] = await Promise.all([
        (await fetch('/api/documents/shared', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            protocol: window.location.protocol
          })
        })).json()
      ]);
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        sharedDocuments: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  updateMyDocuments = async () => {
    try {
      let [response] = await Promise.all([
        (await fetch('/api/documents/my', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            protocol: window.location.protocol
          })
        })).json()
      ]);
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        myDocuments: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  openDocument = (type) => {
    switch (this.state.activeDocument.type) {
      case 'spreadsheet':
        if (type) {
          window.open(`https://docs.google.com/spreadsheets/d/${this.state.activeDocument.publisherDocumentId}/pub?output=${type}`);
        } else {
          window.open(`https://docs.google.com/spreadsheets/d/${this.state.activeDocument.publisherDocumentId}/edit?usp=sharing`);
        }
      break;
      case 'document':
        if (type) {
          window.open(`https://docs.google.com/document/u/0/export?format=${type}&id=${this.state.activeDocument.publisherDocumentId}`);
        } else {
          window.open(`https://docs.google.com/document/d/${this.state.activeDocument.publisherDocumentId}/edit?usp=sharing`);
        }
      break;
      case 'pdf':
        
      break;
    }
  }
  
  activateDocument = (item) => {
    this.setState({
      activeDocument: item
    });
  }
  
  removeDocument = async (item) => {
    try {
      if (!window.confirm(`Remove document ${item.name}?`)) {
        return;
      }
      let [response] = await Promise.all([
        (await fetch('/api/documents/my/remove', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            documentId: item.documentId
          })
        })).json()
      ]);
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        myDocuments: this.state.myDocuments.filter(doc => doc.documentId !== item.documentId)
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  shareDocument = async (item) => {
    try {
      if (!window.confirm(`Share document ${item.name}?`)) {
        return;
      }
      let shared = item.shared ? 0 : 1;
      let [response] = await Promise.all([
        (await fetch('/api/documents/my/share', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            documentId: item.documentId,
            shared: shared
          })
        })).json()
      ]);
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        myDocuments: this.state.myDocuments.map(doc => {
          if (doc.documentId === item.documentId) {
            doc.shared = shared;
          }
          return doc;
        })
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  addDocument = async () => {
    try {
      if (!this.validLink(this.state.newDocument.link)) {
        throw Error('Unsupported link.');
      }
      let name = window.prompt('Document Title');
      if (!name.trim()) {
        throw Error('Missing name.');
      }
      let [response] = await Promise.all([
        (await fetch('/api/documents/my/new', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            link: this.state.newDocument.link,
            name: name
          })
        })).json()
      ]);
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.updateMyDocuments();
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }

  }
  
  validLink = (link) => {
    if (!link.includes('docs.google.com')) {
      return false;
    }
    if (!link.includes('/spreadsheets/') && !link.includes('/document/')) {
      return false;
    }
    return true;
  }
  
  handleChange = (e, field) => {
    let newDisabled = true;
    let doc = this.state.newDocument;
    doc[field] = e.target.value;
    if (this.validLink(doc[field])) {
      newDisabled = false;
    }
    this.setState({
      newDocument: doc,
      newDisabled: newDisabled,
    });
  }
  
  render () {
    
    let link = '';
    if (this.state.activeDocument) {
      if (this.state.activeDocument.type === 'spreadsheet') {
        link = `https://docs.google.com/spreadsheets/d/${this.state.activeDocument.publisherDocumentId}/pub?widget=true&chrome=true&headers=true`;
      } else if (this.state.activeDocument.type === 'document') {
        link = `https://docs.google.com/document/d/${this.state.activeDocument.publisherDocumentId}/pub?widget=true&chrome=false&headers=true`;
      }
    }
    
    return (
      <Fragment>
        <Navigation routes={{
          'Documents': '/documents'
        }}>
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="btn btn-outline-primary mr-1" disabled={!this.state.activeDocument}>{!isMobile ? 'Download' : <i className="fa fa-download"></i>}</Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {this.state.activeDocument && this.state.activeDocument.type === 'spreadsheet' &&
                <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={e => this.openDocument('csv')}>CSV File {this.state.activeDocument.type === 'spreadsheet' ? '(First Sheet)' : ''}</Dropdown.Item>
              }
              <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={e => this.openDocument('pdf')}>PDF</Dropdown.Item>
              {this.state.activeDocument && this.state.activeDocument.type === 'spreadsheet' &&
                <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={e => this.openDocument('xlsx')}>Excel Spreadsheet</Dropdown.Item>
              }
              {this.state.activeDocument && this.state.activeDocument.type === 'document' &&
                <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={e => this.openDocument('docx')}>Document</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
          <button type="button" className="btn btn-outline-primary" onClick={e => this.openDocument('')} disabled={!this.state.activeDocument}>{!isMobile ? <span>Open<i className="fa fa-external-link ml-2"></i></span> : <i className="fa fa-external-link"></i>}</button>
        </Navigation>
        <InnerSidebar sidebarId="documents-list" icon="book">
          <div className="pl-2 font-weight-bold">Shared Documents</div>
          <hr className="m-2"/>
          <Form.Group className="position-relative mb-0">
            {this.state.sharedDocuments.map(item => {
              return (
                <Form.Label className="mt-2 mb-2 position-relative pl-2 pr-2 w-100" style={{wordBreak: "break-all", lineHeight: "1.4"}} key={item.id}>
                  <div type="button" onClick={e => this.activateDocument(item)}>{item.name} ({item.type})</div>
                </Form.Label>
              );
            })}
          </Form.Group>
          <div className="pl-2 font-weight-bold mt-5">My Documents</div>
          <hr className="m-2"/>
          <Form.Group className="position-relative mb-0">
            {this.state.myDocuments.map(item => {
              return (
                <Form.Label className="mt-2 mb-2 position-relative pl-2 pr-5 w-100" style={{wordBreak: "break-all", lineHeight: "1.4"}} key={item.id}>
                  <div type="button" onClick={e => this.activateDocument(item)}>{item.name} ({item.type})</div>
                  <i className={item.shared ? 'fa fa-share-alt position-absolute text-primary' : 'fa fa-share-alt position-absolute text-muted'} style={{right: "14%", top: "50%", transform: "translateY(-50%)"}} type="button" onClick={e => this.shareDocument(item)}></i>
                  <i className="fa fa-trash-o position-absolute text-muted" style={{right: "5%", top: "50%", transform: "translateY(-50%)"}} type="button" onClick={e => this.removeDocument(item)}></i>
                </Form.Label>
              );
            })}
          </Form.Group>
          <Form.Group className="position-relative mb-1 mt-2">
            <Form.Control className="pr-4 mt-2" size="md" onChange={e => this.handleChange(e, 'link')} placeholder="Document Link" value={this.state.newDocument.link}/>
            <i className={this.state.newDisabled ? "fa fa-plus position-absolute text-muted" : "fa fa-plus position-absolute text-primary"} style={this.state.newDisabled ? {right: "5%", top: "50%", transform: "translateY(-50%)", pointerEvents: "none", cursor: "default"} : {right: "5%", top: "50%", transform: "translateY(-50%)", cursor: "pointer", pointerEvents: "all"}} type="button" onClick={this.addDocument}></i>
          </Form.Group>
          <br />
          <div className="pl-2 pr-2 pt-2 pb-1 text-muted">Google document and spreadsheet are supported.</div>
          <div className="pl-2 pr-2 pt-1 pb-1 text-muted">Paste the direct link to document from browser address bar.</div>
          <div className="pl-2 pr-2 pb-2 pt-1 text-muted">Documents need to be <a href="https://support.google.com/docs/answer/2494822?hl=en#zippy=%2Cchange-who-your-link-is-shared-with" target="_blank">Shared</a> (Anyone with the link. See section "Change who your link is shared with") and <a href="https://support.google.com/a/users/answer/9308870?hl=en" target="_blank">Published</a> (Entire Document).</div>
        </InnerSidebar>
        <div className="inner-container has-inner-sidebar">
          <div className="row">
            <div className="col-12">
              <Errors messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {this.state.activeDocument &&
                <iframe src={link} style={{
                  border: '1px solid #c9c9c9',
                  boxShadow: '0px 0px 4px 2px #e1e1e1',
                  width: '100%',
                  height: isMobile ? 'calc(100vh - 300px)' : `calc(100vh - (${160 + (this.state.errors.length * 50) + (this.state.warnings.length * 50) + (this.state.info.length * 50)}px)`
                }}></iframe>
              }
            </div>
          </div>
        </div>
      </Fragment>
    );
  }  
  
}

export default Documents;