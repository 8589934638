import { extendObservable } from 'mobx';

class Notices {
  constructor() {
    let notices = {
      'notices.fetched': false
    };
    extendObservable(this, notices);
  }
}

Notices.prototype.close = async function (page, noticeId) {
  let notice = this[page].filter(notice => notice.noticeId === noticeId)[0];
  if (notice.once) {
    await (await fetch('/api/notices/close', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        noticeId: noticeId
      })
    })).json();
  }
  this[page] = this[page].filter(notice => notice.noticeId !== noticeId);
};

Notices.prototype.fetch = async function (key, value) {
  try {
    if (!this['notices.fetched']) {
      this['notices.fetched'] = true;
      let response = await (await fetch('/api/notices', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status === 'success') {
        for (let i = 0, l = response.data.items.length; i < l; i++) {
          if (!this[response.data.items[i].page]) {
            // this[response.data.items[i].page] = [];
            let notices = {};
            notices[response.data.items[i].page] = []
            extendObservable(this, notices);
          }
          this[response.data.items[i].page].push(response.data.items[i]);
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const notices = new Notices();

export default notices;