import React, { Component } from 'react';
import { Form, Dropdown, Pagination, FormControl  } from 'react-bootstrap';
import '../../assets/styles/core/utils/GalleryPicker.scss';

export class GalleryPicker extends Component {
  constructor(props) {
    super(props);
    let selected = null;
    let index = -1;
    React.Children.forEach(props.children, (element, idx) => {
      if (element.props.selected) {
        selected = element;
        index = idx;
      }
    });
    this.state = {
      selectedIndex: selected ? index : -1,
      selectedValue: selected ? selected.props.value : ''
    };

  }
  
  componentDidMount () {
    if (this.state.selectedElement) {
      
    }
  }
  
  onClick = (e, index, value) => {
    this.setState({
      selectedIndex: index,
      selectedValue: value,
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.selectedValue);
      }
    });
  }
  
  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value) {
      if (nextProps.value !== this.state.selectedValue) {
        let selected = null;
        let index = -1;
        React.Children.forEach(this.props.children, (element, idx) => {
          if (element.props.selected) {
            selected = element;
            index = idx;
          }
        });
        this.setState({
          selectedIndex: selected ? index : -1,
          selectedValue: selected ? selected.props.value: ''
        }, () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.selectedValue);
          }
        });
      }
    }
  }
  
  render () {
    return (
      <div style={{
        
      }} className={this.props.className ? `${this.props.className} item-picker` : 'item-picker'}>
        {React.Children.map(this.props.children, (element, idx) => {
          return (
            <div style={{
              
            }} key={element.key} onClick={(e) => { this.onClick(e, idx, element.props.value); }} className={this.state.selectedIndex > -1 && this.state.selectedIndex === idx ? 'active' : ''}>
              {element.props.children}
              <div className="check">
                <i className="fa fa-check-circle-o"></i>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default GalleryPicker;