import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import User from '../stores/User';
import { Info, Warnings, Errors } from '../utils/Notices';
import Loader from '../stores/Loader';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../stores/Translator.js';

class NewUserNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/notifications/new',
      list: [],
      topics: [],
      selected: [],
      search: '',
      organizations: [],
      note: '',
      message: '',
      action: '',
      topic: '',
      errors: [],
      warnings: [],
      info: [],
      templates: {},
      template: ''
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await Promise.all([
      this.organizationsList(),
      this.topicsList(),
      this.emailTemplates()
    ]);
    await this.updateList();
  }
  
  updateList = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/admin/users', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items.map(item => {
          if (item.organizationId) {
            let organization = this.state.organizations.filter(org => { return org.organizationId === item.organizationId})[0];
            item.organization = organization
          }
          return item;
        })
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  emailTemplates = async () => {
    try {
      let response = await (await fetch('/api/notifications/email/templates', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        templates: response.data.templates
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  organizationsList = async () => {
    try {
      let response = await (await fetch('/api/admin/organizations', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        organizations: response.data.items.map(organization => {
          if (!organization.admins) {
            organization.admins = [];
          }
          return organization;
        })
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  topicsList = async () => {
    try {
      let response = await (await fetch('/api/subscriptions/topics', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        topics: response.data.items,
        topic: response.data.items[0] ? response.data.items[0].topic : ''
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  onSelectChange = (e, userId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(userId)) {
        this.setState({
          selected: this.state.selected.concat([userId])
        });
      }
    } else {
      if (this.state.selected.includes(userId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== userId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.userId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = () => {
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.userId)).map(item => item.userId);
    this.setState({
      selected: selected
    });
  };
  
  applyFilters = (list) => {
    return list.filter(item => {
      return item.username.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.userId.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
    });
  }
  
  sendNotification = async () => {
    try {
      if (!this.state.note.trim()) {
        throw Error('Message is required!');
      }
      if (window.confirm(_t('Send notifications?'))) {
        let selected = this.state.selected;
        for (let i in selected) {
          let response = await (await fetch('/api/admin/notifications/new', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: selected[i],
              note: this.state.note,
              message: this.state.message,
              action: this.state.action,
              topic: this.state.topic,
              template: this.state.template
            })
          })).json();
          if (response.status !== 'success') {
            throw Error(response.message);
          }
        }
        this.setState({
          info: this.state.info.concat('Notifications sent successfully!'),
          // note: '',
          // message: '',
          // action: '',
          selected: []
        });
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Notifications': ''
        }}>
          <button test-id="button_send" type="button" className="btn btn-outline-primary" disabled={this.state.selected.length == 0} onClick={this.sendNotification}>{_t('Send')}</button>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Label>{_t('Topic:')}</Form.Label>
                    <select test-id="select_topic" className="form-control form-control-sm" id="type" onChange={(e => {this.setState({ topic: e.target.value })})}>
                      {this.state.topics.map((topic, i) => {
                        return (
                          <option test-id="option_topic" key={topic.topic} value={topic.topic} selected={this.state.topic === topic.topic}>{topic.description}</option>
                        );
                      })}
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{_t('Notification / Email Subject:')}</Form.Label>
                    <Form.Control test-id="input_subject" type="text" size="sm" value={this.state.note} onChange={(e) => {this.setState({ note: e.target.value })}} autoFocus={isMobile ? false : true}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{_t('Full Notification Message / Email Body:')}</Form.Label>
                    <Form.Control test-id="input_message" as="textarea" rows="9" value={this.state.message} onChange={(e) => {this.setState({ message: e.target.value })}}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{_t('Notification Action:')}</Form.Label>
                    <Form.Control test-id="input_action" type="text" size="sm" value={this.state.action} onChange={(e) => {this.setState({ action: e.target.value })}}/>
                    <Form.Text className="text-muted">{_t('Click on notification will lead user to this link.')}</Form.Text>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Label>{_t('Email Template:')}</Form.Label>
                    <select test-id="select_email-template" className="form-control form-control-sm" id="type" onChange={(e => {this.setState({ template: e.target.value })})}>
                      <option value={''} selected={true}>{_t('No Template')}</option>
                      {Object.entries(this.state.templates).map(([key, value], i) => {
                        return (
                          <option test-id="option_email-template" key={key} value={key} selected={this.state.template === key}>{_t(key)}</option>
                        );
                      })}
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{_t('Email Preview:')}</Form.Label>
                    <div>
                      {(() => {
                        let template = this.state.templates[this.state.template];
                        if (!template) {
                          return (
                            <div style={{
                              padding: '16px',
                              fontSize: '13px',
                              fontFamily: 'sans-serif',
                              whiteSpace: 'pre-wrap',
                              border: '1px solid #c8c8c8'
                            }}>{this.state.message}</div>
                          );
                        } else {
                          return (
                            <iframe style={{
                              width: '100%',
                              minHeight: '640px',
                              border: '1px solid #c8c8c8'
                            }} src={`data:text/html;charset=utf-8,${encodeURIComponent(template.replace('[HTML]', this.state.message.replace(/(?:\r\n|\r|\n)/g, '<br>')))}`}></iframe>
                          );
                        }
                      })()}
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Control type="text" size="sm" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} />
                  </Form.Group>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input test-id="checkbox_list-all" type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          <th>{_t('Username')}</th>
                          <th>{_t('Email')}</th>
                          <th>{_t('System Admin')}</th>
                          <th>{_t('Active')}</th>
                          <th>{_t('Organization')}</th>
                          <th>{_t('Org. Admin')}</th>
                          <th>{_t('Org. Owner')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => { 
                          return (
                            <tr key={item.userId} className={this.state.selected.includes(item.userId) ? 'table-primary' : ''}>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input test-id="checkbox_list-single" type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.userId)} onChange={e => this.onSelectChange(e, item.userId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              <td><Link to={`/admin/users/edit/${item.userId}`}>{item.username}</Link></td>
                              <td>{item.email}</td>
                              <td>{item.admin ? _t('Yes') : _t('No')}</td>
                              <td>{item.active ? _t('Yes') : _t('No')}</td>
                              <td>{item.organization ? item.organization.organizationName : '-'}</td>
                              <td>{item.organization && item.organization.adminId === item.userId || item.organization && item.organization.admins.includes(item.userId) ? _t('Yes'): _t('No')}</td>
                              <td>{item.organization && item.organization.adminId === item.userId ? _t('Yes'): _t('No')}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
          </div>
        </div>
      </Fragment>
    );
  } 
}

export {
  NewUserNotification
};