import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import User from '../stores/User';
import Loader from '../stores/Loader';
import Navigation from '../../core/shared/NavigationV2';
import Settings from '../stores/Settings';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import { SearchableMultiSelect } from '../../core/utils/SearchableMultiSelect';
import { _t } from '../stores/Translator.js';

class NewOrganization extends Component {
  constructor(props){
    super(props);
    this.state = {
      path: '/organization/new',
      organization: {
        organizationName: ''
      },
      additionalOrganizationInfo: {},
      organizationInfo: {},
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  async componentDidMount() {
    this._ismounted = true;
    let state = {
      additionalOrganizationInfo: {}
    };
    for (let i in Settings.organization.info) {
      let info = Settings.organization.info[i];
      state.additionalOrganizationInfo[info.id] = info.type === 'L' ? [] : '';
    }
    this.setState(state);
     await Promise.all([
      this.organizationInfo(),
      this.listsOrganizationInfoEndpoints()
    ]);
  }
  
  handleChange = (e, field) => {
    let organization = {...this.state.organization};
    organization[field] = e.target.value;
    this.setState({
      organization: organization
    });
  }
  
  // saveOrganizationState = () => {
  //   let body = {
  //     organization: this.state.organization,
  //     organizationInfo: this.state.additionalOrganizationInfo,
  //     showModal: false 
  //   };
  //   this.props.updateState(body);
  // }
  
  createOrganization = async () => {
    try {
      let body = {
        organization: this.state.organization,
        organizationInfo: this.state.additionalOrganizationInfo
      };

      let response = await (await fetch('/api/organization/new', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      if (this.props.location && this.props.location.pathname) {
        this.props.history.push(`/organization`);
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  organizationInfo = async () => {
    try {
      let response = await (await fetch('/api/admin/organization/info', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        organizationInfo: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  listsOrganizationInfoEndpoints = async () => {
    while (!Settings.organization.info) { /* Sometimes info will remain empty */
      await this.sleep(0.2);
    };
    try {
      for (let i in Settings.organization.info) {
        let info = Settings.organization.info[i];
        if (!info.listEndpoint) { 
          this.setState({
            listOrganizationInfoReady: true
          });
        } else {
          let response = await (await fetch(info.listEndpoint, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })).json();
          if (response.status !== 'success') {
            throw Error(response.message);
          }
          // let state = {};
          // state[info.listName] = response.data.items;
          // this.setState(state);
          this.setState({
            [info.listName]: response.data.items
          }, () => {
            if (info.id === 'networkId') {
              this.setState({
                duplicateNetworksList: this.state.networks
              });
            }
            this.setState({
              listOrganizationInfoReady: true
            });
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render() {
    return (
      <Fragment>
        <Navigation routes={{
          'Create Organization': '/organization/new'
        }}>
          <Link to='/organization' className="mr-2">
            <input type="button" className="btn btn-outline-primary" size="sm" value={_t('Back')}/>
          </Link>
          <input type="button" className="btn btn-outline-primary" size="sm" value={_t('Create')} onClick={this.createOrganization}/>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <Form.Group>  
                    <Form.Label>{_t('Organization Name')}</Form.Label>
                    <Form.Control type="text" size="sm" value={this.state.organization.organizationName} onChange={e => this.handleChange(e, 'organizationName')}/>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          {Settings.organization.info.length > 0 && 
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-header">
                    {_t('Additional Organization Info')}
                  </div>
                  <div className="card-body">
                  {Settings.organization.info && this.state.listOrganizationInfoReady && Settings.organization.info.map(info => {
                    switch (info.fieldType) {
                      case 'text':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            <Form.Control type="text" value={this.state.additionalOrganizationInfo[info.id] ? this.state.additionalOrganizationInfo[info.id] : ''} size="sm" onChange={(e) => { 
                              let additionalOrganizationInfo = {...this.state.additionalOrganizationInfo};
                              additionalOrganizationInfo[info.id] = e.target.value;
                              this.setState({
                                additionalOrganizationInfo: additionalOrganizationInfo
                              })
                            }}/>
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'select':
                        return (
                          <Form.Group>  
                           <Form.Label>{info.title}</Form.Label>
                            <SearchableSelect className="borderless form-control form-control-sm" placeholder={`-- ${_t('Select')} --`} value={this.state.additionalOrganizationInfo[info.id] ? this.state.additionalOrganizationInfo[info.id] : ''} onChange={(e) => { 
                              let additionalOrganizationInfo = {...this.state.additionalOrganizationInfo};
                              additionalOrganizationInfo[info.id] = e.target.value;
                              this.setState({
                                additionalOrganizationInfo: additionalOrganizationInfo
                              })
                            }}>
                                <option key={0} value={''} selected={this.state.additionalOrganizationInfo[info.id] === ''}>-- {_t('Select')} --</option>
                                {this.state[info.listName].map(item => {
                                  return (
                                    <option key={item[info.id]} value={item[info.id]} selected={this.state.additionalOrganizationInfo[info.id] === item[info.id]}>{item[info.value]}</option>
                                  );
                              })}
                            </SearchableSelect>
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'multi-select':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            {this.state[info.listName] && <SearchableMultiSelect className="borderless form-control form-control-sm" placeholder={`-- ${_t('Select')} --`}  value={this.state[info.id]} onChange={(e) => { 
                              let additionalOrganizationInfo = {...this.state.additionalOrganizationInfo};
                              additionalOrganizationInfo[info.id] = e.target.value;
                              this.setState({
                                additionalOrganizationInfo: additionalOrganizationInfo
                              })
                            }} disabled={info.id === 'organizationIds' && this.state.selectedOrganization !== '' ? true : false}>
                                {this.state[info.listName].map(item => {
                                  return (
                                    <option key={item[info.key]} value={item[info.key]} selected={this.state.additionalOrganizationInfo[info.id].includes(item[info.key])}>{item[info.name]}</option>
                                  );
                              })}
                            </SearchableMultiSelect>}
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                    }
                  })}
                  </div>
                </div>
              </div>
            </div>
            }
        </div>
      </Fragment>
    );
  }
}

class EditOrganization extends Component {
  constructor(props){
    super(props);
    this.state = {
      path: '/organization',
      organization: null,
      list: [],
      selected: [],
      search: '',
      invitation: {
        email: ''
      },
      invitationList: [],
      usersInvitations: [],
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateOrganizations();
    await this.usersList();
    await this.updateInvitations();
    await this.getInvitations();
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  updateOrganizations = async () => {
    try {
      let response = await (await fetch('/api/organization', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        organization: response.data
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  usersList = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/organization/members', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          organization: this.state.organization
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  updateInvitations = async () => {
    try {
      let response = await (await fetch('/api/organization/invitations', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        invitationList: response.data
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  onSelectChange = (e, organizationId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(organizationId)) {
        this.setState({
          selected: this.state.selected.concat([organizationId])
        });
      }
    } else {
      if (this.state.selected.includes(organizationId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== organizationId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.userId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = () => {
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.organizationId)).map(item => item.organizationId);
    this.setState({
      selected: selected
    });
  };
  
  applyFilters = (list) => {
    return list.filter(item => {
      return (
        item.username.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
        item.email.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
        item.userId.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
      );
    });
  }
  
  handleChange = (e, field) => {
    let invitation = {...this.state.invitation};
    invitation[field] = e.target.value;
    this.setState({
      invitation: invitation
    });
  }
  
  removeSelected = async (e) => { 
    try {
      if (window.confirm(this.state.selected.length == 1 ? `Remove selected user from organization?` : `Disable ${this.state.selected.length} selected user?`)) {
        let selected = this.state.selected;
        for (let i in selected) {
          let response = await (await fetch('api/organization/member/remove', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: selected[i]
            })
          })).json();
          if (response.status !== 'success') {
            throw Error('Something went wrong');
          }
          this.setState({
            list: this.state.list.filter(item => {
              return item.userId !== selected[i];
            }),
            selected: this.state.selected.filter(item => {
              return item !== selected[i];
            })
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  sendInvitation = async (e) => {
    try {
      let response = await (await fetch('/api/organization/member/invite', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          invitation: this.state.invitation,
          organization: this.state.organization
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        invitation: {
          email: ''
        },
        info: this.state.info.concat(response.message)
      });
      await this.updateInvitations();
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  removeInvitation = async (e, invitationId) => {
    try {
      let response = await (await fetch('/api/organization/invitation/remove', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          invitationId: invitationId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      await this.updateInvitations();
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getInvitations = async () => {
    try {
      let response = await (await fetch('/api/organization/member/invitations', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        usersInvitations: response.data
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  acceptInvitation = async (e, invitationId, organizationId) => {
    try {
      let response = await (await fetch('/api/organization/member/invitation/accept', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          invitationId: invitationId,
          organizationId: organizationId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        usersInvitations: []
      });
      await this.updateOrganizations();
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  declineInvitation = async (e, invitationId, organizationId) => {
    try {
      let response = await (await fetch('/api/organization/member/invitation/decline', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          invitationId: invitationId,
          organizationId: organizationId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      await this.getInvitations();
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  viewAccount = async () => {
    try {
      let response = await (await fetch('/api/organization/member/view', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: this.state.selected[0]
        })
      })).json();
      if (response.status !== 'success') {
        throw Error('Something went wrong');
      }
      User.isAdmin = response.data.isAdmin;
      User.userId = response.data.userId;
      User.ownerId = response.data.organizationId || response.data.userId;
      User.username = response.data.username;
      User.email = response.data.email;
      User.name = response.data.name;
      User.access = response.data.access;
      User.organization = response.data.organization;
      User.userInfo = response.data.userInfo;
      User.organizationInfo = response.data.organizationInfo;
      User.impersonator = response.data.impersonator;
      User.viewer = response.data.viewer;
      window.location.href = '/';
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render() {
    
    let routes = [];
    routes[`${this.state.organization ? `${_t('Organization:')} ${this.state.organization.organizationName}` : _t('Organization')}`] = '';
    
    return (
      <Fragment>
        <Navigation routes={routes}>
          {!this.state.organization &&
            <Link to='/organization/new'>
              <input type="button" className="btn btn-outline-primary mr-2" size="sm" value={_t('Create Organization')}/>
            </Link>
          }
          {this.state.organization && (this.state.organization.adminId === User.userId || (this.state.organization.admins && this.state.organization.admins.includes(User.userId))) &&
            <Dropdown>
              <Dropdown.Toggle variant="btn btn-outline-primary" disabled={this.state.selected.length == 0}>{_t('Actions')}</Dropdown.Toggle>
              <Dropdown.Menu className="p-0">
                {!User.impersonator && !User.viewer &&
                  <Dropdown.Item className="pt-3 pb-3" onClick={this.viewAccount} disabled={this.state.selected.length !== 1}>{_t('View Account')}</Dropdown.Item>
                }
                {this.state.organization.adminId === User.userId && 
                  <Dropdown.Item className="pt-3 pb-3" onClick={this.removeSelected}>{_t('Remove')}</Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          }
        </Navigation>
        <div className="inner-container">
          {!this.state.organization &&
            <div>
              <div className="row">
                {this.state.usersInvitations.length !== 0 &&
                <div className="col-lg-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <div className="form-group">
                        <table>
                          <tbody>
                            {this.state.usersInvitations.map(item => {
                              return (
                                <tr key={item.invitationId}>
                                  <td>{item.organizationName}</td>
                                  <td><Moment fromNow>{item.createdAt * 1000}</Moment></td>
                                  <td><input className="dropdown-toggle btn btn-btn btn-outline-primary mx-0 mt-0" type="button" value={_t('Accept')} onClick={e => this.acceptInvitation(e, item.invitationId, item.organizationId)} /></td>
                                  <td><input className="dropdown-toggle btn btn-btn btn-outline-primary mx-0 mt-0" type="button" value={_t('Decline')} onClick={e => this.declineInvitation(e, item.invitationId, item.organizationId)} /></td>
                                </tr>
                              );
                             })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          }
          {this.state.organization && (this.state.organization.adminId === User.userId || (this.state.organization.admins && this.state.organization.admins.includes(User.userId))) &&
            <div>
              <div className="row">
                <div className="col-12">
                  <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-header">
                      {_t('Members')}
                    </div>
                    <div className="card-body">
                      <Form.Group>
                        <Form.Control type="text" size="sm" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
                      </Form.Group>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </th>
                              <th>{_t('Username')}</th>
                              <th>{_t('Email')}</th>
                              <th>{_t('Org. Admin')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.applyFilters(this.state.list).map(item => { 
                              return (
                                <tr key={item.userId} className={this.state.selected.includes(item.userId) ? 'table-primary' : ''}>
                                  <td>
                                    <Form.Group>
                                      <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.userId)} onChange={e => this.onSelectChange(e, item.userId)}/>
                                          <i className="input-helper"></i>
                                        </label>
                                      </div>
                                    </Form.Group>
                                  </td>
                                  <td>
                                    {(() => {
                                      if (this.state.organization.adminId === User.userId) {
                                        return (
                                          <Link to={`/organization/members/edit/${item.userId}`}>{item.username}</Link>
                                        );
                                      } else if (this.state.organization.admins && this.state.organization.admins.includes(User.userId) && !this.state.organization.admins.includes(item.userId) && this.state.organization.adminId !== item.userId) {
                                        return (
                                          <Link to={`/organization/members/edit/${item.userId}`}>{item.username}</Link>
                                        );
                                      } else {
                                        return (
                                          <span>{item.username}</span>
                                        );
                                      }
                                    })()}
                                  </td> 
                                  <td>{item.email}</td>
                                  <td>{(this.state.organization.adminId === item.userId || (this.state.organization.admins && this.state.organization.admins.includes(item.userId))) ? _t('Yes') : _t('No')}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.organization.adminId === User.userId &&
                <div className="row">
                  <div className="col-lg-12 grid-margin">
                    <div className="card">
                      <div className="card-header">
                        {_t('Invitations')}
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <input className="form-control form-control-sm" type="text" placeholder={_t('Enter Email...')} value={this.state.invitation.email} onChange={e => this.handleChange(e, 'email')} />
                          <input className="dropdown-toggle btn btn-btn btn-outline-primary mx-0 mt-3 float-right" type="button" value={_t('Invite')} onClick={this.sendInvitation} />
                        </div>
                      </div>
                      {this.state.invitationList.length > 0 &&
                        <div className="card-body">
                          <div className="form-group">
                            <table>
                              <tbody>
                                {this.state.invitationList.map(item => {
                                  return (
                                    <tr key={item.invitationId}>
                                      <td>{item.email}</td>
                                      <td><Moment fromNow>{item.createdAt * 1000}</Moment></td>
                                      <td><input className="dropdown-toggle btn btn-btn btn-outline-primary mx-0 mt-0" type="button" value={_t('Cancel Invitation')} onClick={e => this.removeInvitation(e, item.invitationId)} /></td>
                                    </tr>
                                  );
                                 })
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
              <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

export {
  NewOrganization,
  EditOrganization
};