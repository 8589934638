import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { _t } from '../stores/Translator.js';
import HeaderV2 from '../../core/shared/HeaderV2';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      buttonValue: 'Send'
    };
    this.emailRef = React.createRef();
  }
  
  onSubmit = async () => {
    this.setState({
      error: '',
      notice: ''
    });
    let result = await (await fetch('/api/user/recover', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.emailRef.current.value
      })
    })).json();
    if (result.status == 'success') {
      this.setState({
        notice: result.message,
        buttonValue: 'Resend'
      });
    } else {
      this.setState({
        error: result.message,
        buttonValue: 'Send'
      });
    }
  }
  
  render() {
    return(
        <div className="wy-login">
        <HeaderV2 />
        <div className="bg-image d-flex vertical-center align-items-center">
          <div className="d-flex align-items-center auth forgot-pass px-2 py-5 mx-auto mt-7">
            <div className="row w-100 mx-0">
              <div className="col-lg-8 mx-auto flex-reset">
                <div className="auth-form-light text-left my-10 py-5 px-4 px-sm-5">
                  <h1 className="text-center">{_t('Reset Password')}</h1>
                  <p className="text-center mt-3 small-text text-muted">{_t('Enter Your email address and we\'ll send you a link so you can reset your password.')}</p>
                  <p className="error">{this.state.error}</p>
                  <Form>
                    <Form.Group className="d-flex search-field border-bottom position-relative">
                      <Form.Control test-id="input_email" type="email" placeholder={_t('Email')} size="lg" className="h-auto border-0" ref={this.emailRef} />
                    </Form.Group>
                    <Form.Group className="mt-3 d-flex justify-content-center align-items-center">
                      <Form.Control test-id="button_send" type="button" value={_t(this.state.buttonValue)} className="btn btn-primary btn-lg font-weight-medium auth-form-bt blue-bg" onClick={this.onSubmit} />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>   
    );
  }
  
}


export {
  ResetPassword
};