import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { _t } from '../../core/stores/Translator.js';
import { LivePostsHeadless } from '../live/LivePosts';

import HeaderV2 from '../../core/shared/HeaderV2';

import Platform from '../../core/stores/Platform';

class Start extends Component {
  constructor(props) {
    super(props);
    let params = new URLSearchParams(props.location.search)
    this.state = {
      userId: params.get('u'),
      searchId: params.get('s'),
      error: ''
    };
  }
  
  componentDidMount = async () => {
    await (await fetch('/api/public/searches/stop/v2', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: this.state.userId,
        searchId: this.state.searchId,
      })
    })).json();
  }
  
  render() {
    return (
      <div className="ba-start" tabIndex="0">
        {!Platform.embed &&
          <HeaderV2 />
        }
        <div className="bg-image d-flex vertical-center align-items-center">
          <div className="bg-image-overlay"></div>
          <div className="d-flex align-items-center start px-2 py-5 mx-auto mt-7">
            <div className="row w-100 mx-0">
              <div className="col-lg-8 mx-auto flex-reset">
                <div className="start-form text-left my-10 py-5 px-4 px-sm-5">
                  <h3>{_t('You will no longer receive notification for your search.')}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Start;
