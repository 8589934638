import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, Modal, Dropdown, Pagination } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import Navigation from '../shared/NavigationV2';
import Loader from '../stores/Loader';
import { _t } from '../stores/Translator.js';
import AceEditor from "react-ace-builds";
import "react-ace-builds/webpack-resolver-min";

class Translations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/translations',
      data: '',
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await Promise.all([
      this.list()
    ]);
  }
  
  handleChange = (value) => {
    this.setState({
      data: value
    });
  }
  
  list = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/admin/translations/json', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search: this.state.search
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        data: JSON.stringify(response.data, null, 2)
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  save = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/admin/translations/json/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: JSON.parse(this.state.data)
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        info: this.state.info.concat('Successfully Updated!')
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  render () {
    let routes = {};
    routes[_t('Translations')] = '/admin/translations';
    return (
      <Fragment>
        <Navigation routes={routes}>
          
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-6" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{this.props.title}</div>
            <div className="col-6 grid-margin stretch-card text-right wy-db-controls">
              <button type="button" className="btn btn-outline-primary mr-0 ml-2" onClick={this.save}>{_t('Update')}</button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
                <AceEditor
                  width="100%"
                  height={isMobile ? 'calc(100vh - 300px)' : `calc(100vh - (${210 + (this.state.errors.length * 50) + (this.state.warnings.length * 50) + (this.state.info.length * 50)}px)`}
                  mode="json"
                  tabSize="2"
                  theme="github"
                  onChange={this.handleChange}
                  value={this.state.data}
                  name="translations"
                  editorProps={{
                    $blockScrolling: true 
                  }}
                />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export {
  Translations
};