import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Observer } from 'mobx-react';
import Notices from '../stores/Notices';

class Errors extends Component {
  constructor(props) {
    super(props);
  }
  
  render () {
    return (
      <React.Fragment>
        <Observer>
          {() => (
            (Notices[this.props.path] || Notices['*'] || []).map((notice, i) => {
              if (notice.type === 'error') {
                return (
                  <div className="row" key={i}>
                    <div className="col-12 grid-margin stretch-card error-banner show-error-banner">
                      <div className="card">
                        <div className="card-body">
                          <i className="fa fa-exclamation-triangle"></i>
                          <span>{notice.message}</span>
                          <i className="mdi mdi-close wy-db-close" onClick={e => Notices.close(this.props.path, notice.noticeId) }></i>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          )}
        </Observer>
        {(this.props.messages || []).map((message, i) => {
          return (
            <div className="row" key={i}>
              <div className="col-12 grid-margin stretch-card error-banner show-error-banner">
                <div className="card">
                  <div className="card-body">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>{message ? message.replace(/Error\: /g, '') : '-- message --'}</span>
                    <i className="mdi mdi-close wy-db-close" onClick={e => this.props.onClose(message) }></i>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

class Warnings extends Component {
  constructor(props) {
    super(props);
  }
  
  render () {
    return (
      <React.Fragment>
        <Observer>
          {() => (
            (Notices[this.props.path] || Notices['*'] || []).map((notice, i) => {
              if (notice.type == 'warning') {
                return (
                  <div className="row" key={i}>
                    <div className="col-12 grid-margin stretch-card warning-banner show-warning-banner">
                      <div className="card">
                        <div className="card-body">
                          <i className="fa fa-info-circle"></i>
                          <span>{notice.message}</span>
                          <i className="mdi mdi-close wy-db-close" onClick={e => Notices.close(this.props.path, notice.noticeId) }></i>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          )}
        </Observer>
        {(this.props.messages || []).map((message, i) => {
          return (
            <div className="row" key={i}>
              <div className="col-12 grid-margin stretch-card warning-banner show-warning-banner">
                <div className="card">
                  <div className="card-body">
                    <i className="fa fa-info-circle"></i>
                    <span>{message ? message : '-- message --'}</span>
                    <i className="mdi mdi-close wy-db-close" onClick={e => this.props.onClose(message) }></i>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

class Info extends Component {
  constructor(props) {
    super(props);
  }
  
  render () {
    return (
      <React.Fragment>
        <Observer>
          {() => (
            (Notices[this.props.path] || Notices['*'] || []).map((notice, i) => {
              if (notice.type == 'info') {
                return (
                  <div className="row" key={i}>
                    <div className="col-12 grid-margin stretch-card success-banner show-success-banner">
                      <div className="card">
                        <div className="card-body">
                          <i className="fa fa-check-circle"></i>
                          <span>{notice.message}</span>
                          <i className="mdi mdi-close wy-db-close" onClick={e => Notices.close(this.props.path, notice.noticeId) }></i>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          )}
        </Observer>
        {(this.props.messages || []).map((message, i) => {
          return (
            <div className="row" key={i}>
              <div className="col-12 grid-margin stretch-card success-banner show-success-banner">
                <div className="card">
                  <div className="card-body">
                    <i className="fa fa-check-circle"></i>
                    <span>{message ? message : '-- message --'}</span>
                    <i className="mdi mdi-close wy-db-close" onClick={e => this.props.onClose(message) }></i>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}


export {
  Errors,
  Warnings,
  Info
};