import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, Modal, Button, Dropdown, Pagination, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import Navigation from '../../core/shared/NavigationV2';
import { ListPreferences } from '../utils/ListPreferences';
import Preferences from '../../core/stores/Preferences';
import { Info, Warnings, Errors } from '../../core/utils/Notices';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import Loader from '../../core/stores/Loader';
import moment from "moment";
import Env from '../../core/stores/Env';
import { InnerSidebar } from '../../core/shared/InnerSidebar';
import { SearchableMultiSelect } from '../../core/utils/SearchableMultiSelect';
import { Range } from 'react-range';
import { _t } from '../../core/stores/Translator.js';

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/wishlist',
      selectedSearch: '',
      list: [],
      total: 0,
      rows: 80,
      page: 1,
      offset: 3,
      selected: [],
      search: '',
      locations: [],
      selectedLocations: [],
      minPrice: 0,
      maxPrice: 1200000,
      agreement: 1,
      searches: [],
      selectedSearch: '',
      types: {
        'buy': _t('Selling'),
        'rent': _t('Renting')
      },
      platforms: {
        'og': 'oglasi.rs',
        'kp': 'kupujemprodajem.com',
        'kn': 'kupindo.com',
        'sm': 'sasomange.rs',
        'ho': 'halooglasi.com',
        'nk': 'nekretnine.rs',
        '4z': '4zida.rs',
        'pa': 'polovniautomobili.com'
      },
      selectedPlatform: '',
      errors: [],
      warnings: [],
      info: [],
      showColumnSettings: false,
      sort: {
        column: 'createdAt',
        direction: 'DESC'
      },
    };
  }
  
  updateList = async () => {
    Loader.show();
    this.setState({
      errors: []
    }, async () => {
      try {
        let [response] = await Promise.all([
          (await fetch(`/api/wishlist/v2`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              rows: this.state.rows,
              offset: this.state.rows * (this.state.page - 1),
              keywords: this.state.search,
              sort: this.state.sort,
              searchId: this.state.selectedSearch,
              search: this.state.search,
              locations: this.state.selectedLocations,
              minPrice: this.state.minPrice,
              maxPrice: this.state.maxPrice,
              agreement: this.state.agreement,
              platform: this.state.selectedPlatform
            })
          })).json()
        ]);
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.setState({
          total: response.data.total,
          list: response.data.items.sort((a, b) => b.createdAt - a.createdAt)
        });
      } catch (err) {
        this.setState({
          errors: this.state.errors.concat(err.toString())
        });
      }
      Loader.hide();
    });
  }
  
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props !== nextProps) {
      this.updateList();
    }
  }
  
  geLocations = async () => {
    try {
      let response = await (await fetch('/api/countries', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        countries: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getCategories = async () => {
    try {
      let response = await (await fetch('/api/networks', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rows: this.state.rows,
          offset: this.state.rows * (this.state.page - 1),
          keywords: ''
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        networks: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await Promise.all([
      this.getSearches(),
      this.getLocations(),
      // this.getCategories(),
      this.updateList()
    ]);
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  getLocations = async () => {
    try {
      let response = await (await fetch(`/locations.json`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      this.setState({
        locations: response
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getSearches = async () => {
    try {
      let response = await (await fetch(`/api/searches/v2`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        searches: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  onSelectChange = (e, wishId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(wishId)) {
        this.setState({
          selected: this.state.selected.concat([wishId])
        });
      }
    } else {
      if (this.state.selected.includes(wishId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== wishId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.wishId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = async () => {
    /*await this.updateList();*/
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.wishId)).map(item => item.wishId);
    this.setState({
      selected: selected,
    });
  }
  
  applyFilters = (list) => {
    return list.filter(item => {
      return (
        (!this.state.selectedPlatform || item.postPlatform === this.state.selectedPlatform) &&
        (this.state.selectedLocations.length === 0 || this.state.selectedLocations.includes(item.postLocation)) &&
        (
          item.postName.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
          item.postDescription.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
        )
      );
    });
  }
  
  onPaginationClick = async (e, page) => {
    if (page === this.state.page) {
      return;
    }
    this.setState({
      page: page
    }, () => {
      this.updateList();
    });
  }
  
  searchKeyDown = async (e) => {
    switch (e.keyCode) {
      case 13:
        clearTimeout(this.state.typeTimeout);
        this.setState({
          page: 1
        }, () => {
          this.updateList();
        });
      break;
      default:
        if (
          (e.keyCode === 8 && this.state.search.length > 0) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          (e.keyCode === 32 || e.keyCode === 13) ||
          (e.keyCode > 64 && e.keyCode < 91) ||
          (e.keyCode > 95 && e.keyCode < 112) ||
          (e.keyCode > 185 && e.keyCode < 193) ||
          (e.keyCode > 218 && e.keyCode < 223)
        ) {
          if (this.state.typeTimeout) {
            clearTimeout(this.state.typeTimeout);
          }
          this.setState({
            typeTimeout: setTimeout(() => {
              this.updateList();
            }, 1000)
          });
        }
      break;
    }
  }
  
  remove = async (e) => {
    Loader.show();
    e.preventDefault();
    try {
      let selected = this.state.selected;
      let response = await (await fetch('/api/wishlist/remove/v2', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          wishIds: selected
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: this.state.list.filter(item => !selected.includes(item.wishId)),
        selected: []
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  readable = (input) => {
    if (Number.isInteger(input)) {
      return input.toLocaleString();
    } else {
      return null;
    }
  }
  
  sort = (column, direction) => {
    let sort = {...this.state.sort};
    sort.column = column;
    sort.direction = direction;
    this.setState({
      sort: sort
    }, this.updateList);
  }
  
  render () {
    let routes = {};
    routes[_t('Wishlist')] = '/wishlist'
    return (
      <Fragment>
        <Navigation routes={routes}>
          <Dropdown>
            <Dropdown.Toggle variant="btn btn-outline-primary mr-2" disabled={this.state.selected.length === 0}>{_t('Actions')}</Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={this.remove}>{_t('Remove')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navigation>
        {/*
        <InnerSidebar icon="filter">
          <Form.Group>
            <Form.Label>Searches</Form.Label>
            <SearchableSelect className="form-control" placeholder="All Searches" value={this.state.selectedSearch} onChange={e => { this.setState({ selectedSearch: e.target.value }, this.updateList) }}>
              <option key={0} value={''} selected={this.state.selectedSearch === ''}>All Searches</option>
              {this.state.searches.map(search => {
                return (
                  <option key={`${search.searchId}`} value={`${search.searchId}`} selected={this.state.selectedSearch === search.searchId}>{search.matchKeywords ? search.keywords : '-- No Keywords --'}</option>
                );
              })}
            </SearchableSelect>
          </Form.Group>
          <Form.Group>
            <Form.Label>Platforms</Form.Label>
            <SearchableSelect className="form-control" placeholder="Platforms..." value={this.state.selectedPlatform} onChange={e => { this.setState({ selectedPlatform: e.target.value }, this.updateList) }}>
              <option key={0} value={''} selected={this.state.selectedPlatform === ''}>All Platforms</option>
              {Object.entries(this.state.platforms).map(([platformId, platformName]) => {
                return (
                  <option key={`${platformId}`} value={`${platformId}`} selected={this.state.selectedPlatform === platformId}>{platformName}</option>
                );
              })}
            </SearchableSelect>
          </Form.Group>
          <Form.Group>
            <Form.Label>Locations</Form.Label>
            <SearchableMultiSelect className="form-control" placeholder="Search Locations..." value={this.state.selectedLocations} onChange={e => { this.setState({ selectedLocations: e.target.value }, this.updateList) }}>
              {this.state.locations.map(location => {
                return (
                  <option key={`${location.name}`} value={`${location.name}`} selected={this.state.selectedLocations.includes(`${location.name}`)}>{location.name}</option>
                );
              })}
            </SearchableMultiSelect>
          </Form.Group>
          <Form.Group>
            <Form.Label>Price Range</Form.Label>
            <div className="">
              <Range
                step={10}
                min={0}
                max={2048}
                values={[this.state.minPrice, this.state.maxPrice]}
                onChange={([min, max]) => this.setState({
                  minPrice: min,
                  maxPrice: max
                }, this.updateList)}
                renderTrack={({props, children}) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '4px',
                      width: '92%',
                      margin: '2%',
                      marginLeft: '4%',
                      marginRight: '4%',
                      backgroundColor: '#1093C9'
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({props}) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '12px',
                      width: '12px',
                      backgroundColor: '#1093C9',
                      borderRadius: '50%'
                    }}
                  />
                )}
              />
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto'
              }}>
                <div style={{ fontSize: '11px' }} className="m-2">Min Price: {this.state.minPrice}</div>
                <div style={{ fontSize: '11px', textAlign: 'right' }} className="m-2">Max Price: {this.state.maxPrice}</div>
              </div>
            </div>
          </Form.Group>
        </InnerSidebar>
        */}
        {/*<div className="inner-container has-inner-sidebar">*/}
        <div className="inner-container">
          <div className="row">
            <Form.Group className="col-lg-12 grid-margin stretch-card">
              <Form.Control type="text" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
            </Form.Group>
          </div>
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-10 grid-margin stretch-card text-right wy-db-pagination">
                      <Pagination>
                        {(() => {
                          let rows = [];
                          if (this.state.page > this.state.offset + 1) {
                            rows.push(<Pagination.First onClick={e => this.onPaginationClick(e, 1)} />);
                          }
                          if (this.state.page > 1) {
                            rows.push(<Pagination.Prev onClick={e => this.onPaginationClick(e, this.state.page - 1)} />);
                          }
                          for (let i = this.state.page - this.state.offset, l = this.state.page; i < l; i++) {
                            if (i > 0) {
                              rows.push(<Pagination.Item onClick={e => this.onPaginationClick(e, i)} >{i}</Pagination.Item>);
                            }
                          }
                          return rows;
                        })()}
                        <Pagination.Item active>{this.state.page}</Pagination.Item>
                        {(() => {
                          let rows = [];
                          for (let i = this.state.page + 1, l = this.state.page + this.state.offset + 2; i < l; i++) {
                            if (i <= Math.ceil(this.state.total / this.state.rows)) {
                              if (i > this.state.page + this.state.offset) {
                                rows.push(<Pagination.Next onClick={e => this.onPaginationClick(e, this.state.page + 1)} />);
                                rows.push(<Pagination.Last onClick={e => this.onPaginationClick(e, Math.ceil(this.state.total / this.state.rows))} />);
                                break;
                              }
                              rows.push(<Pagination.Item onClick={e => this.onPaginationClick(e, i)} >{i}</Pagination.Item>);
                            }
                          }
                          return rows;
                        })()}
                      </Pagination>
                    </div>
                    <div className="col-2 stretch-card text-right wy-db-list-settings">
                      <i className="fa fa-sync" onClick={this.updateList}></i>
                      {/*<i className="fa fa-pencil" onClick={() => { this.setState({ showColumnSettings: true }) }}></i>*/}
                    </div>
                  </div>
                  <div className="table-responsive table-fix-head" style={{ maxHeight: isMobile ? 'calc(100vh - 300px)' : `calc(100vh - (${300 + (this.state.errors.length * 50) + (this.state.warnings.length * 50) + (this.state.info.length * 50)}px)` }}>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th style={{zIndex: "1"}}>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          {Preferences['wishes.columns']['Name'].enabled &&
                            <th>{_t('Name')}</th>
                          }
                          {Preferences['wishes.columns']['Preview'].enabled &&
                            <th>{_t('Preview')}</th>
                          }
                          {Preferences['wishes.columns']['Type'].enabled &&
                            <th>{_t('Type')}</th>
                          }
                          {Preferences['wishes.columns']['Price'].enabled &&
                            <th>{_t('Price')}</th>
                          }
                          {Preferences['wishes.columns']['Platform'].enabled &&
                            <th>{_t('Platform')}</th>
                          }
                          {Preferences['wishes.columns']['Location'].enabled &&
                            <th>{_t('Location')}</th>
                          }
                          {Preferences['wishes.columns']['Categories'].enabled &&
                            <th>{_t('Categories')}</th>
                          }
                          {Preferences['wishes.columns']['Description'].enabled &&
                            <th>{_t('Description')}</th>
                          }
                          {Preferences['wishes.columns']['Date Posted'].enabled &&
                            <th>{_t('Date Posted')}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => {
                          return (
                            <tr key={item.wishId} className={this.state.selected.includes(item.wishId) ? 'table-primary' : ''}>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.wishId)} onChange={e => this.onSelectChange(e, item.wishId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              {Preferences['wishes.columns']['Name'].enabled &&
                                <td>
                                  {!Boolean(item.postRemoved) &&
                                    <a href={item.postUrl} target="_blank">{item.postName}</a>
                                  }
                                  {Boolean(item.postRemoved) &&
                                    <span><s>{item.postName}</s> {_t('(removed)')}</span>
                                  }
                                </td>
                              }
                              {Preferences['wishes.columns']['Preview'].enabled &&
                                <td><div style={{
                                  width: '36px',
                                  height: '36px',
                                  backgroundImage:  `url("${Env.STAGE === 'prod' ? '' : `https://ba.cloud9ws.com`}${item.postImage}")`,
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat'
                                }}></div></td>
                              }
                              {Preferences['wishes.columns']['Type'].enabled &&
                                <td>{this.state.types[item.postType]}</td>
                              }
                              {Preferences['wishes.columns']['Price'].enabled &&
                                <td>{item.postAgreement ? _t('Agreement') : `${item.postPrice.toFixed(2)} EUR`}</td>
                              }
                              {Preferences['wishes.columns']['Platform'].enabled &&
                                <td>{this.state.platforms[item.postPlatform]}</td>
                              }
                              {Preferences['wishes.columns']['Location'].enabled &&
                                <td>{item.postLocation ? item.postLocation : '-'}</td>
                              }
                              {Preferences['wishes.columns']['Categories'].enabled &&
                                <td>{item.postCategories.length > 0 ? item.postCategories.join(', ') : '-'}</td>
                              }
                              {Preferences['wishes.columns']['Description'].enabled &&
                                <td>{item.postDescription.length > 60 ? `${item.postDescription.substr(0, 60)}...` : item.postDescription}</td>
                              }
                              {Preferences['wishes.columns']['Date Posted'].enabled &&
                                <td>{(() => {
                                  if (!item.createdAt) {
                                    return '-';
                                  }
                                  let seconds = item.createdAt;
                                  let date = new Date(0);
                                  date.setUTCSeconds(seconds);
                                  let locale = date.toLocaleDateString();
                                  return (
                                    <span>{locale}</span>
                                  );
                                })()}</td>
                              }
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ListPreferences show={this.state.showColumnSettings} onClose={() => { this.setState({ showColumnSettings: false }); Preferences.save('campaigns.columns') }} columns={Preferences['wishes.columns']} />
        </div>
      </Fragment>
    );
  }
}

export {
  WishList
};