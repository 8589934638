import React, { Component } from 'react';
import packageJSON from '../../../package.json';

export class Version extends Component {
  render() {
    return (
      <div className="version">
        <a href="/releases">
          <span className="project-name">{packageJSON.name} </span>
          <span className="semantic-version">v{packageJSON.version}</span>
        </a>
      </div>
    );
  }
}

export default Version;