import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, Modal, Dropdown, Pagination } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import User from '../../core/stores/User';
import { ListPreferences } from '../utils/ListPreferences';
import Preferences from '../../core/stores/Preferences';
import { Info, Warnings, Errors } from '../../core/utils/Notices';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import { SearchableMultiSelect } from '../../core/utils/SearchableMultiSelect';
import Navigation from '../../core/shared/NavigationV2';
import Loader from '../../core/stores/Loader';
import { _t } from '../../core/stores/Translator.js';

class EditSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/searches/edit/:searchId',
      search: props.search,
      step: props.search.category ? 1 : 0,
      errors: [],
      warnings: [],
      info: [],
      selectedItems: []
    };
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  async componentDidMount() {
    this._ismounted = true;
  }
  
  handleChange = (e, field) => {
    let search = {...this.state.search};
    search[field] = e.target.value;
    this.setState({
      search: search
    });
  }
  
  handleCheckboxChange = (e, field) => {
    let search = {...this.state.search};
    search[field] = Number(e.target.checked);
    this.setState({
      search: search
    }); 
  }
  
  handleCheckboxArrayChange = (e, field) => {
    let search = {...this.state.search};
    if (e.target.checked) {
      search[field].push(e.target.value);
    } else {
      search[field].splice(search[field].indexOf(e.target.value), 1);
    }
    this.setState({
      search: search
    }); 
  }
  
  save = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/searches/update/v2', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search: this.state.search
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      if (this.props.onUpdate) {
        this.props.onUpdate();
      }
      if (this.props.onHide) {
        this.props.onHide();
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  setCategory = (categoryId) => {
    let search = {...this.state.search};
    categoryId = Number(categoryId);
    search.category = categoryId;
    search.title = '';
    switch (categoryId) {
      case 1:
        search.realestate_country = 0;
        search.realestate_matchRegions = 0;
        search.realestate_regions = [];
        search.realestate_matchDistricts = 0;
        search.realestate_districts = [];
        search.realestate_matchCities = 0;
        search.realestate_cities = [];
        search.realestate_matchQuarters = 0;
        search.realestate_quarters = [];
        search.realestate_spaces = [];
        search.realestate_types = [];
        search.realestate_newConstruction = 0;
        search.realestate_useMinSquares = 0;
        search.realestate_minSquares = 0;
        search.realestate_useMaxSquares = 0;
        search.realestate_maxSquares = 0;
      break;
      case 2:
        search.automotive_year = 0;
        search.automotive_type = '';
        search.automotive_testDrive = 0;
        search.automotive_useMinkW = 0;
        search.automotive_minkW = 0;
        search.automotive_useMaxkW = 0;
        search.automotive_maxkW = 0;
        search.automotive_euroEmission = '';
        search.automotive_transmissionType = '';
        search.automotive_seats = 0;
        search.automotive_airConditioning = 0;
        search.automotive_color = '';
        search.automotive_registration = '';
        search.automotive_interiorMaterial = '';
        search.automotive_interiorColor = '';
        search.automotive_safetyEquipment = [];
        search.automotive_additionalEquipment = [];
      break;
      case 3:
        search.matchKeywords = 1;
      break;
    }
    this.setState({
      search: search,
      step: categoryId ? 1 : 0
    }); 
  }
  
  render () {
    let routes = {};
    routes[_t('Searches')] = '/searches';
    routes[_t('Edit')] = '/searches/edit/:searchId';
    return (
      <Fragment>
        <Navigation routes={routes}>
          
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-6" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{this.props.title}</div>
            <div className="col-6 grid-margin stretch-card text-right wy-db-controls">
              <button type="button" className="btn btn-outline-primary mr-0 ml-2" onClick={this.props.onHide}>{_t('Discard')}</button>
              {this.state.step > 0 &&
                <button type="button" className="btn btn-outline-primary mr-0 ml-2" onClick={this.save}>{_t('Save')}</button>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className={this.state.step === 0 ? 'step step-0' : 'step step-0 hidden'}>
                <div className="row">
                  {this.props.categories.map(category => {
                    return (
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className={`${category.categoryId === 2 ? `box ribbon ${User.isAdmin ? 'new' : 'new'}` : ''} ${category.categoryId === 1 ? `box ribbon ${User.isAdmin ? 'new' : 'new'}` : ''} category m-2 mb-3`} onClick={e => {
                          this.setCategory(category.categoryId);
                        }} style={{
                          backgroundImage: `url("/cat-${category.name.toLowerCase()}.png")`
                        }}>
                          <span>{_t(category.name)}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={this.state.step === 1 ? 'step step-1' : 'step step-1 hidden'}>
                  <Form.Group>
                    <Form.Label>{_t('Search Title')}</Form.Label>
                    <Form.Control type="text" value={this.state.search.title} onChange={e => this.handleChange(e, 'title')} autofocus={true}/>
                  </Form.Group>
                {this.state.search.category === 3 &&
                  <Form.Group>
                    <div className="form-check form-check-primary ml-1">
                      <label className="form-check-label">
                        <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.matchKeywords)} onChange={e => this.handleCheckboxChange(e, 'matchKeywords')}/>
                        <i className="input-helper"></i>
                        {_t('Match Keywords')}
                      </label>
                    </div>
                    {Boolean(this.state.search.matchKeywords) &&
                      <Form.Control type="text" value={this.state.search.keywords} onChange={e => this.handleChange(e, 'keywords')} autofocus={true}/>
                    }
                    {!Boolean(this.state.search.matchKeywords) &&
                      <div className="ml-2" style={{
                        color: '#a0a0a0',
                        fontSize: '11px',
                        fontStyle: 'italic'
                      }}>{_t('Not using keywords may result in large amounts of notifications.')}</div>
                    }
                  </Form.Group>
                }
                {this.state.search.category === 3 &&
                  <Form.Group>
                    {Boolean(this.state.search.matchKeywords) &&
                      <div className="form-check form-check-primary ml-1">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.matchDescription)} onChange={e => this.handleCheckboxChange(e, 'matchDescription')}/>
                          <i className="input-helper"></i>
                          {_t('Match Description (Beta)')}
                        </label>
                      </div>
                    }
                  </Form.Group>
                }
                {this.state.search.category === 2 &&
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <Form.Group>
                        <Form.Label>{_t('Manufacturers')}</Form.Label>
                        <SearchableMultiSelect className="form-control" placeholder={_t('Search Manufacturers...')} value={this.state.search.manufacturers} onChange={e => this.handleChange(e, 'manufacturers')}>
                          {this.props.manufacturers.filter(manufacturer => manufacturer.categoryId === this.state.search.category).map(manufacturer => {
                            return (
                              <option key={manufacturer.manufacturerId} value={`${manufacturer.manufacturerId}`} selected={this.state.search.manufacturers.includes(`${manufacturer.manufacturerId}`)}>{manufacturer.name}</option>
                            );
                          })}
                        </SearchableMultiSelect>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <Form.Group>
                        <Form.Label>{_t('Models')}</Form.Label>
                        <SearchableMultiSelect className="form-control" placeholder={_t('Search Models...')} value={this.state.search.models} onChange={e => this.handleChange(e, 'models')}>
                          {this.props.models.filter(model => model.categoryId === this.state.search.category).filter(model => this.state.search.manufacturers.includes(`${model.manufacturerId}`)).map(model => {
                            return (
                              <option key={model.modelId} value={`${model.modelId}`} selected={this.state.search.models.includes(`${model.modelId}`)}>{model.name}</option>
                            );
                          })}
                        </SearchableMultiSelect>
                      </Form.Group>
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col-lg-4 col-sm-6">
                    <Form.Group>
                      <div className="form-check form-check-primary ml-1">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.buy)} onChange={e => this.handleCheckboxChange(e, 'buy')}/>
                          <i className="input-helper"></i>
                          {_t('Buying')}
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <Form.Group>
                      <div className="form-check form-check-primary ml-1">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.rent)} onChange={e => this.handleCheckboxChange(e, 'rent')}/>
                          <i className="input-helper"></i>
                          {_t('Renting')}
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {this.state.search.category === 1 &&
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <Form.Group>
                        <SearchableMultiSelect className="form-control" placeholder={_t('Search Spaces...')} value={this.state.search.realestate_spaces} onChange={e => this.handleChange(e, 'realestate_spaces')}>
                          {['Apartment', 'House', 'Commercial Space', 'Office Space', 'Plot', 'Garage/Parking', 'Room', 'Cottage', 'Land', 'Retail Space', 'Other'].map(space => {
                            return (
                              <option key={`${space}`} value={`${space}`} selected={this.state.search.realestate_spaces.includes(`${space}`)}>{_t(space)}</option>
                            );
                          })}
                        </SearchableMultiSelect>
                      </Form.Group>
                    </div>
                    {this.state.search.realestate_spaces.find(space => ['Apartment', 'House', 'Cottage'].includes(space)) &&
                      <div className="col-lg-4 col-sm-6">
                        <Form.Group>
                          <SearchableMultiSelect className="form-control" placeholder={_t('Search Types...')} value={this.state.search.realestate_types} onChange={e => this.handleChange(e, 'realestate_types')}>
                            {['Studio', 'One-Bedroom', 'Two-Bedroom', 'Three-Bedroom', 'Four-Bedroom or more'].map(space => {
                              return (
                                <option key={`${space}`} value={`${space}`} selected={this.state.search.realestate_types.includes(`${space}`)}>{_t(space)}</option>
                              );
                            })}
                          </SearchableMultiSelect>
                        </Form.Group>
                      </div>
                    }
                  </div>
                }
                <div className="row">
                  <div className="col-lg-4 col-sm-6">
                    <Form.Group>
                      <div className="form-check form-check-primary ml-1">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.useMinPrice)} onChange={e => this.handleCheckboxChange(e, 'useMinPrice')}/>
                          <i className="input-helper"></i>
                          {_t('Set Min. Price (EUR)')}
                        </label>
                      </div>
                      {Boolean(this.state.search.useMinPrice) &&
                        <Form.Control type="number" value={this.state.search.minPrice} onChange={e => this.handleChange(e, 'minPrice')}/>
                      }
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <Form.Group>
                      <div className="form-check form-check-primary ml-1">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.useMaxPrice)} onChange={e => this.handleCheckboxChange(e, 'useMaxPrice')}/>
                          <i className="input-helper"></i>
                          {_t('Set Max. Price (EUR)')}
                        </label>
                      </div>
                      {Boolean(this.state.search.useMaxPrice) &&
                        <Form.Control type="number" value={this.state.search.maxPrice} onChange={e => this.handleChange(e, 'maxPrice')}/>
                      }
                    </Form.Group>
                  </div>
                </div>
                {this.state.search.category === 2 || this.state.search.category === 3 &&
                  <Form.Group>  
                    <div className="form-check form-check-primary ml-1">
                      <label className="form-check-label">
                        <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.matchLocation)} onChange={e => this.handleCheckboxChange(e, 'matchLocation')}/>
                        <i className="input-helper"></i>
                        {_t('Match Locations')}
                      </label>
                    </div>
                    {Boolean(this.state.search.matchLocation) &&
                      <SearchableMultiSelect className="form-control" placeholder={_t('Search Locations...')} value={this.state.search.locations} onChange={e => this.handleChange(e, 'locations')}>
                        {this.props.locations.map(location => {
                          return (
                            <option key={`${location.location_id}`} value={`${location.location_id}`} selected={this.state.search.locations.includes(`${location.location_id}`)}>{location.name}</option>
                          );
                        })}
                      </SearchableMultiSelect>
                    }
                  </Form.Group>
                }
                <div className="row">
                  {this.state.search.category === 1 &&
                    <div className="col-lg-6 col-md-6 col-sm-8 col-xs-12">
                      <Form.Group>
                        <Form.Label>{_t('Country')}</Form.Label>
                        <SearchableSelect className="form-control" placeholder={_t('Search Countries...')} value={this.state.search.realestate_country} onChange={e => this.handleChange(e, 'realestate_country')}>
                          {this.props.countries.map(country => {
                            return (
                              <option key={country.countryId} value={country.countryId} selected={this.state.search.realestate_country === country.countryId}>{country.name}</option>
                            );
                          })}
                        </SearchableSelect>
                      </Form.Group>
                    </div>
                  }
                  <div className="col-lg-6 col-md-6 col-sm-4 col-xs-12"></div>
                  {this.state.search.category === 1 &&
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                      <Form.Group>  
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.realestate_matchRegions)} onChange={e => this.handleCheckboxChange(e, 'realestate_matchRegions')}/>
                            <i className="input-helper"></i>
                            {_t('Match Regions')}
                          </label>
                        </div>
                        {Boolean(this.state.search.realestate_matchRegions) &&
                          <SearchableMultiSelect className="form-control" placeholder={_t('Search Regions...')} value={this.state.search.realestate_regions} onChange={e => this.handleChange(e, 'realestate_regions')}>
                            {this.props.regions.filter(region => this.state.search.realestate_country === region.countryId).map(region => {
                              return (
                                <option key={region.regionId} value={region.regionId} selected={this.state.search.realestate_regions.includes(region.regionId)}>{region.name}</option>
                              );
                            })}
                          </SearchableMultiSelect>
                        }
                      </Form.Group>
                    </div>
                  }
                  {this.state.search.category === 1 &&
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                      <Form.Group>  
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.realestate_matchDistricts)} onChange={e => this.handleCheckboxChange(e, 'realestate_matchDistricts')}/>
                            <i className="input-helper"></i>
                            {_t('Match Districts')}
                          </label>
                        </div>
                        {Boolean(this.state.search.realestate_matchDistricts) &&
                          <SearchableMultiSelect className="form-control" placeholder={_t('Search Districts...')} value={this.state.search.realestate_districts} onChange={e => this.handleChange(e, 'realestate_districts')}>
                            {this.props.districts.filter(district => this.state.search.realestate_regions.includes(district.regionId)).map(district => {
                              return (
                                <option key={district.districtId} value={district.districtId} selected={this.state.search.realestate_districts.includes(district.districtId)}>{district.name}</option>
                              );
                            })}
                          </SearchableMultiSelect>
                        }
                      </Form.Group>
                    </div>
                  }
                  {this.state.search.category === 1 &&
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                      <Form.Group>  
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.realestate_matchCities)} onChange={e => this.handleCheckboxChange(e, 'realestate_matchCities')}/>
                            <i className="input-helper"></i>
                            {_t('Match Cities')}
                          </label>
                        </div>
                        {Boolean(this.state.search.realestate_matchCities) &&
                          <SearchableMultiSelect className="form-control" placeholder={_t('Search Cities...')} value={this.state.search.realestate_cities} onChange={e => this.handleChange(e, 'realestate_cities')}>
                            {this.props.cities.filter(city => this.state.search.realestate_districts.includes(city.districtId)).map(city => {
                              return (
                                <option key={city.cityId} value={city.cityId} selected={this.state.search.realestate_cities.includes(city.cityId)}>{city.name}</option>
                              );
                            })}
                          </SearchableMultiSelect>
                        }
                      </Form.Group>
                    </div>
                  }
                  {this.state.search.category === 1 &&
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                      <Form.Group>  
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.realestate_matchQuarters)} onChange={e => this.handleCheckboxChange(e, 'realestate_matchQuarters')}/>
                            <i className="input-helper"></i>
                            {_t('Match Quarters')}
                          </label>
                        </div>
                        {Boolean(this.state.search.realestate_matchQuarters) &&
                          <SearchableMultiSelect className="form-control" placeholder={_t('Search Quarters...')} value={this.state.search.realestate_quarters} onChange={e => this.handleChange(e, 'realestate_quarters')}>
                            {this.props.quarters.filter(quarter => this.state.search.realestate_cities.includes(quarter.cityId)).map(quarter => {
                              return (
                                <option key={quarter.quarterId} value={quarter.quarterId} selected={this.state.search.realestate_quarters.includes(quarter.quarterId)}>{quarter.name}</option>
                              );
                            })}
                          </SearchableMultiSelect>
                        }
                      </Form.Group>
                    </div>
                  }
                </div>
                <div className="row">
                  {this.state.search.category === 2 || this.state.search.category === 3 &&
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                      <Form.Group>
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.new)} onChange={e => this.handleCheckboxChange(e, 'new')}/>
                            <i className="input-helper"></i>
                            {_t('New')}
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  }
                  {this.state.search.category === 2 || this.state.search.category === 3 &&
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                      <Form.Group>
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.used)} onChange={e => this.handleCheckboxChange(e, 'used')}/>
                            <i className="input-helper"></i>
                            {_t('Used')}
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  }
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <Form.Group>
                      <div className="form-check form-check-primary ml-1">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.agreement)} onChange={e => this.handleCheckboxChange(e, 'agreement')}/>
                          <i className="input-helper"></i>
                          {_t('Accept Agreement')}
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <Form.Group>
                      <div className="form-check form-check-primary ml-1">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.withImage)} onChange={e => this.handleCheckboxChange(e, 'withImage')}/>
                          <i className="input-helper"></i>
                          {_t('Only with Image')}
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  {this.state.search.category === 1 &&
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                      <Form.Group>
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.realestate_newConstruction)} onChange={e => this.handleCheckboxChange(e, 'realestate_newConstruction')}/>
                            <i className="input-helper"></i>
                            {_t('New Construction')}
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  }
                </div>
                {this.state.search.category === 1 &&
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <Form.Group>
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.realestate_useMinSquares)} onChange={e => this.handleCheckboxChange(e, 'realestate_useMinSquares')}/>
                            <i className="input-helper"></i>
                            {_t('Set Min. Squares (m2)')}
                          </label>
                        </div>
                        {Boolean(this.state.search.realestate_useMinSquares) &&
                          <Form.Control type="number" value={this.state.search.realestate_minSquares} onChange={e => this.handleChange(e, 'realestate_minSquares')}/>
                        }
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <Form.Group>
                        <div className="form-check form-check-primary ml-1">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.realestate_useMaxSquares)} onChange={e => this.handleCheckboxChange(e, 'realestate_useMaxSquares')}/>
                            <i className="input-helper"></i>
                            {_t('Set Max. Squares (m2)')}
                          </label>
                        </div>
                        {Boolean(this.state.search.realestate_useMaxSquares) &&
                          <Form.Control type="number" value={this.state.search.realestate_maxSquares} onChange={e => this.handleChange(e, 'realestate_maxSquares')}/>
                        }
                      </Form.Group>
                    </div>
                  </div>
                }
                {this.state.search.category === 2 &&
                  <div className="details">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Year')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_year} onChange={e => this.handleChange(e, 'automotive_year')}>
                            <option value={''} selected={`${this.state.search.automotive_year}` === '0'}>{_t('Any')}</option>
                            {Array.from({length: new Date().getFullYear() - 1900 + 1}, (x, i) => `${new Date().getFullYear() - i + 1}`).map(year => {
                              return (
                                <option key={year} value={year} selected={`${this.state.search.automotive_year}` === year}>{year}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Type')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_type} onChange={e => this.handleChange(e, 'automotive_type')}>
                            <option value={''} selected={this.state.search.automotive_type === ''}>{_t('Any')}</option>
                            {['Sedan', 'Coupe', 'Sports Car', 'Station Wagon', 'Hatchback', 'Convertable', 'SUV', 'Minivan', 'Pickup Truck'].map(type => {
                              return (
                                <option key={type} value={type} selected={this.state.search.automotive_type === type}>{_t(type)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Euro Emission')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_euroEmission} onChange={e => this.handleChange(e, 'automotive_euroEmission')}>
                            <option value={''} selected={this.state.search.automotive_euroEmission === ''}>{_t('Any')}</option>
                            {['Euro 1', 'Euro 2', 'Euro 3', 'Euro 4', 'Euro 5', 'Euro 6'].map(euroEmission => {
                              return (
                                <option key={euroEmission} value={euroEmission} selected={this.state.search.automotive_euroEmission === euroEmission}>{_t(euroEmission)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Transmission Type')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_transmissionType} onChange={e => this.handleChange(e, 'automotive_transmissionType')}>
                            <option value={''} selected={this.state.search.automotive_transmissionType === ''}>{_t('Any')}</option>
                            {['4-Speed Manual', '5-Speed Manual', '6-Speed Manual', 'Automatic'].map(transmissionType => {
                              return (
                                <option key={transmissionType} value={transmissionType} selected={this.state.search.automotive_transmissionType === transmissionType}>{_t(transmissionType)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Seats')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_seats} onChange={e => this.handleChange(e, 'automotive_seats')}>
                            <option value={''} selected={`${this.state.search.automotive_seats}` === '0'}>{_t('Any')}</option>
                            {Array.from({length: 7}, (x, i) => `${i + 2}`).map(seats => {
                              return (
                                <option key={seats} value={seats} selected={`${this.state.search.automotive_seats}` === seats}>{_t(`${seats} Seats`)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Registration')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_registration} onChange={e => this.handleChange(e, 'automotive_registration')}>
                            <option value={''} selected={this.state.search.automotive_registration === ''}>{_t('Any')}</option>
                            {['Unregistered', 'Registered'].map(registration => {
                              return (
                                <option key={registration} value={registration} selected={this.state.search.automotive_registration === registration}>{_t(registration)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Color')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_color} onChange={e => this.handleChange(e, 'automotive_color')}>
                            <option value={''} selected={this.state.search.automotive_color === ''}>{_t('Any')}</option>
                            {['White', 'Black', 'Gray', 'Silver', 'Red', 'Blue', 'Brown', 'Green', 'Beige', 'Orange', 'Gold', 'Yellow', 'Purple'].map(color => {
                              return (
                                <option key={color} value={color} selected={this.state.search.automotive_color === color}>{_t(color)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Interior Material')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_interiorMaterial} onChange={e => this.handleChange(e, 'automotive_interiorMaterial')}>
                            <option value={''} selected={this.state.search.automotive_interiorMaterial === ''}>{_t('Any')}</option>
                            {['Fabric', 'Leather', 'Synthetic Leather', 'Composite', 'Velour'].map(material => {
                              return (
                                <option key={material} value={material} selected={this.state.search.automotive_interiorMaterial === material}>{_t(material)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <Form.Label>{_t('Interior Color')}</Form.Label>
                          <SearchableSelect className="form-control" value={this.state.search.automotive_interiorColor} onChange={e => this.handleChange(e, 'automotive_interiorColor')}>
                            <option value={''} selected={this.state.search.automotive_interiorColor === ''}>{_t('Any')}</option>
                            {['White', 'Black', 'Gray', 'Brown', 'Beige'].map(color => {
                              return (
                                <option key={color} value={color} selected={this.state.search.automotive_interiorColor === color}>{_t(color)}</option>
                              );
                            })}
                          </SearchableSelect>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <div className="form-check form-check-primary ml-1">
                            <label className="form-check-label">
                              <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.automotive_useMinkW)} onChange={e => this.handleCheckboxChange(e, 'automotive_useMinkW')}/>
                              <i className="input-helper"></i>
                              {_t('Set Min. kW')}
                            </label>
                          </div>
                          {Boolean(this.state.search.automotive_useMinkW) &&
                            <Form.Control type="number" value={this.state.search.automotive_minkW} onChange={e => this.handleChange(e, 'automotive_minkW')}/>
                          }
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <div className="form-check form-check-primary ml-1">
                            <label className="form-check-label">
                              <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.automotive_useMaxkW)} onChange={e => this.handleCheckboxChange(e, 'automotive_useMaxkW')}/>
                              <i className="input-helper"></i>
                              {_t('Set Max. kW')}
                            </label>
                          </div>
                          {Boolean(this.state.search.automotive_useMaxkW) &&
                            <Form.Control type="number" value={this.state.search.automotive_maxkW} onChange={e => this.handleChange(e, 'automotive_maxkW')}/>
                          }
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <div className="form-check form-check-primary ml-1">
                            <label className="form-check-label">
                              <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.automotive_testDrive)} onChange={e => this.handleCheckboxChange(e, 'automotive_testDrive')}/>
                              <i className="input-helper"></i>
                              {_t('Test Drive')}
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                        <Form.Group>
                          <div className="form-check form-check-primary ml-1">
                            <label className="form-check-label">
                              <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.automotive_airConditioning)} onChange={e => this.handleCheckboxChange(e, 'automotive_airConditioning')}/>
                              <i className="input-helper"></i>
                              {_t('Air Conditioning')}
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                      {[
                        'Driver Airbag', 
                        'Passenger Airbag', 
                        'Side Airbag', 
                        'Knee Airbag',
                        'Child Lock', 
                        'ABS',
                        'ESP', 
                        'ASR', 
                        'Alarm',
                        'Koded Key',
                        'Engine Block',
                        'Central Lock',
                        'Mehanical Protection',
                        'Blind Spot Sensor',
                        'OBD Protection',
                        'Keyless Door',
                        'Lane Assist',
                        'Automatic Emergency Braking'
                      ].map(safetyEquipment => {
                        return (
                          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                            <Form.Group>
                              <div className="form-check form-check-primary ml-1">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input wy-db-item-checkbox" value={safetyEquipment} checked={this.state.search.automotive_safetyEquipment.includes(safetyEquipment)} onChange={e => this.handleCheckboxArrayChange(e, 'automotive_safetyEquipment')}/>
                                  <i className="input-helper"></i>
                                  {_t(safetyEquipment)}
                                </label>
                              </div>
                            </Form.Group>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
                {/*
                <Form.Group>  
                  <div className="form-check form-check-primary ml-1">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={Boolean(this.state.search.matchCategory)} onChange={e => this.handleCheckboxChange(e, 'matchCategory')}/>
                      <i className="input-helper"></i>
                      {_t('Match Categories')}
                    </label>
                  </div>
                  {Boolean(this.state.search.matchCategory) &&
                    <SearchableMultiSelect className="form-control" placeholder={_t('Search Categories...')} value={this.state.search.categories} onChange={e => this.handleChange(e, 'categories')}>
                      {this.props.categories.map(category => {
                        return (
                          <option key={`${category.categoryId}`} value={`${category.categoryId}`} selected={this.state.search.categories.includes(`${category.categoryId}`)}>{_t(category.name)}</option>
                        );
                      })}
                    </SearchableMultiSelect>
                  }
                </Form.Group>
                */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

class EditSearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  onHide = () => {
    if (this.props.show) {
      if (this.props.onHide) {
        this.props.onHide();
      }
    }
  }
  
  render () {
    return (
      <Modal dialogClassName="modal-60w" show={this.props.show} onHide={this.onHide} style={{ paddingTop: '46px' }}>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 46px - 20px - 20px - 20px)', overflowY: 'auto', padding: '24px' }}>
          <EditSearch 
            title={this.props.title} 
            search={this.props.search}
            onHide={this.onHide}
            locations={this.props.locations}
            countries={this.props.countries}
            regions={this.props.regions}
            districts={this.props.districts}
            cities={this.props.cities}
            quarters={this.props.quarters}
            categories={this.props.categories} 
            manufacturers={this.props.manufacturers}
            models={this.props.models}
            districts={this.props.districts}
            onUpdate={this.props.onUpdate} 
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export {
  EditSearch,
  EditSearchModal
};