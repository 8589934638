export default [{
  name: 'Demos', /* Display name */
  identity: 'demos', /* For User access */
  path: '/demo',
  icon: 'code', /* fa fa-icon */
  title: 'Demos',
  testId: 'link_demos'
}, {
  name: 'Catalog',
  identity: 'catalog',
  path: '/admin/catalog',
  icon: 'code',
  title: 'Catalog',
  testId: 'link_catalog'
}, {
  name: 'Locations',
  identity: 'locations',
  path: '/admin/locations',
  icon: 'code',
  title: 'Locations',
  testId: 'link_locations'
}];