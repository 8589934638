import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';

class ListPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  onChange = (e) => {
    this.props.columns[e.target.value].enabled = e.target.checked;
    this.setState({
      state: this.state
    });
  }
  
  render () {
    return (
      <div>
        <Modal dialogClassName="modal-10w" show={this.props.show} onHide={this.props.onClose} style={{ paddingTop: '75px' }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <label>Preferences</label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 'calc(100vh - 30px - 30px - 75px - 57px - 60px - 2px)', overflowY: 'auto' }}>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    {Object.entries(this.props.columns).map(([key, column]) => {
                      return (
                        <div key={key}>
                          <label className="form-check-label mb-1">
                            <input type="checkbox" className="form-check-input" value={key} checked={column.enabled} onChange={this.onChange}/>
                            <i className="input-helper"></i>
                            <span className="pl-4">{column.title}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.props.onClose}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  
}

export {
  ListPreferences
};