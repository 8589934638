import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import TopLoader from "react-top-loader";

const Loading = observer(({store}) => {
  return (
    <div className={`page-loading ${store.active ? 'active' : ''}`}>
      <TopLoader className="loader-bar" show={store.active} fixed={false} color="rgb(178, 178, 178)" />
    </div>
  );
});

export default Loading;