import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, Modal, Button, Dropdown, Pagination, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import Navigation from '../../core/shared/NavigationV2';
import { ListPreferences } from '../utils/ListPreferences';
import Preferences from '../../core/stores/Preferences';
import { Info, Warnings, Errors } from '../../core/utils/Notices';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import Loader from '../../core/stores/Loader';
import moment from "moment";
import Env from '../../core/stores/Env';
import { InnerSidebar, InnerSidebarToggle, InnerSidebarExpanded } from '../../core/shared/InnerSidebar';
import { SearchableMultiSelect } from '../../core/utils/SearchableMultiSelect';
import { Range } from 'react-range';
import { _t } from '../../core/stores/Translator.js';

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));

const PLATFORMS = {
  'kp': 'kupujemprodajem.com',
  'sm': 'sasomange.rs',
  'og': 'oglasi.rs',
  'ho': 'halooglasi.com'
};

class LivePostsHeadless extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/live',
      list: [],
      search: '',
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  addItem = async (item) => {
    let list = [item].concat(this.state.list);
    if (list.length > 64) {
      list.pop();
    }
    this.setState({
      list: list
    });
    list[0].active = true;
    await sleep(0.6);
    this.setState({
      list: list
    });
  }
  
  updateList = async () => {
    if (typeof this.props.active == 'undefined' || this.props.active) {
      try {
        let [response] = await Promise.all([
          (await fetch(`/api/live/posts/v1`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              search: '',
              locations: [],
              minPrice: 0,
              maxPrice: 6000000
            })
          })).json()
        ]);
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        if (!this._ismounted) {
          return;
        }
        for (let i in response.data.items) {
          let item = response.data.items[i];
          if (!this.state.list.filter(existingItem => existingItem.postId === item.postId)[0]) {
            await this.addItem(item);
            await sleep(0.8);
          }
        }
      } catch (err) {
        this.setState({
          errors: this.state.errors.concat(err.toString())
        });
      }
    }
    await sleep(1);
    if (!this._ismounted) {
      return;
    }
    this.updateList();
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateList();
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  searchKeyDown = async (e) => {
    switch (e.keyCode) {
      case 13:
        clearTimeout(this.state.typeTimeout);
        this.setState({
          page: 1
        }, () => {
          this.updateList();
        });
      break;
      default:
        if (
          (e.keyCode === 8 && this.state.search.length > 0) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          (e.keyCode === 32 || e.keyCode === 13) ||
          (e.keyCode > 64 && e.keyCode < 91) ||
          (e.keyCode > 95 && e.keyCode < 112) ||
          (e.keyCode > 185 && e.keyCode < 193) ||
          (e.keyCode > 218 && e.keyCode < 223)
        ) {
          if (this.state.typeTimeout) {
            clearTimeout(this.state.typeTimeout);
          }
          this.setState({
            typeTimeout: setTimeout(() => {
              this.updateList();
            }, 1000)
          });
        }
      break;
    }
  }
  
  applyFilters = (list) => {
    return list.filter(item => {
      return (
        item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
        item.description.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
      );
    });
  }
  
  readable = (input) => {
    if (Number.isInteger(input)) {
      return input.toLocaleString();
    } else {
      return null;
    }
  }
  
  render () {
    return (
      <Fragment>
        <table className="table table-hover table-posts">
          <tbody>
            {this.applyFilters(this.state.list).map((item, i) => {
              return (
                <tr key={item.postId}>
                  <td style={{
                    padding: '0px'
                  }}>
                    <div className={item.active ? 'post active' : 'post'}>
                      <div>
                        {this.props.image &&
                          <div className="image" style={{
                            backgroundImage: `url("${Env.STAGE === 'prod' ? '' : `https://ba.cloud9ws.com`}${item.image}")`
                          }}></div>
                        }
                        <div className="info">
                          <a className="title" href={item.url} target="_blank">{item.name.length > 32 ? `${item.name.substr(0, 32)}...` : item.name}</a>
                          {this.props.location &&
                            <div className="location mt-2">{item.location ? item.location : ''}</div>
                          }
                          {this.props.description &&
                            <div className="description mt-2">
                              <div dangerouslySetInnerHTML={{
                                __html: item.description.length > 128 ? `${item.description.substr(0, 128)}...` : item.description
                              }}/>
                            </div>
                          }
                          {this.props.price &&
                            <div className="price">{Number(item.price).toFixed(2)} EUR</div>
                          }
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

class LivePosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/live',
      list: [],
      search: '',
      locations: [],
      selectedLocations: [],
      minPrice: 0,
      maxPrice: 1200000,
      filtersUpdated: false,
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  addItem = async (item) => {
    let list = [item].concat(this.state.list);
    if (list.length > 64) {
      list.pop();
    }
    this.setState({
      list: list
    });
    list[0].active = true;
    await sleep(0.6);
    this.setState({
      list: list
    });
  }
  
  updateList = async () => {
    if (typeof this.props.active == 'undefined' || this.props.active) {
      try {
        let [response] = await Promise.all([
          (await fetch(`/api/live/posts/v1`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              search: this.state.search,
              locations: this.state.selectedLocations,
              minPrice: this.state.minPrice,
              maxPrice: this.state.maxPrice
            })
          })).json()
        ]);
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        for (let i in response.data.items) {
          let item = response.data.items[i];
          if (!this.state.list.filter(existingItem => existingItem.postId === item.postId)[0]) {
            if (!this.state.filtersUpdated) {
              await this.addItem(item);
              await sleep(0.8);
            }
          }
        }
      } catch (err) {
        this.setState({
          errors: this.state.errors.concat(err.toString())
        });
      }
    }
    if (!this.state.filtersUpdated) {
      await sleep(1);
    } else {
      this.setState({
        filtersUpdated: false,
      });
    }
    this.updateList();
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.getLocations();
    await this.updateList();
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  searchKeyDown = async (e) => {
    switch (e.keyCode) {
      case 13:
        clearTimeout(this.state.typeTimeout);
        this.setState({
          page: 1
        }, () => {
          this.updateList();
        });
      break;
      default:
        if (
          (e.keyCode === 8 && this.state.search.length > 0) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          (e.keyCode === 32 || e.keyCode === 13) ||
          (e.keyCode > 64 && e.keyCode < 91) ||
          (e.keyCode > 95 && e.keyCode < 112) ||
          (e.keyCode > 185 && e.keyCode < 193) ||
          (e.keyCode > 218 && e.keyCode < 223)
        ) {
          if (this.state.typeTimeout) {
            clearTimeout(this.state.typeTimeout);
          }
          this.setState({
            typeTimeout: setTimeout(() => {
              this.updateList();
            }, 1000)
          });
        }
      break;
    }
  }
  
  applyFilters = (list) => {
    return list.filter(item => {
      return (
        item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
        item.description.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
      );
    });
  }
  
  readable = (input) => {
    if (Number.isInteger(input)) {
      return input.toLocaleString();
    } else {
      return null;
    }
  }
  
  getLocations = async () => {
    try {
      let response = await (await fetch(`/locations.json`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      this.setState({
        locations: response
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  addToWishlist = async (e, postId) => {
    Loader.show();
    e.preventDefault();
    try {
      this.setState({
        list: this.state.list.map(item => {
          if (item.postId === postId) {
            item.wishlisted = true;
          }
          return item;
        })
      });
      let response = await (await fetch('/api/wishlist/new/v2', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          postId: postId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  };
  
  removeFromWishlist = async (e, postId) => {
    Loader.show();
    e.preventDefault();
    try {
      this.setState({
        list: this.state.list.map(item => {
          if (item.postId === postId) {
            item.wishlisted = false;
          }
          return item;
        })
      });
      let response = await (await fetch('/api/wishlist/remove/v2', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          postId: postId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  };
  
  render () {
    let routes = {};
    routes[_t('Live Posts')] = '/live/posts';
    return (
      <Fragment>
        <Navigation routes={routes}>
          
        </Navigation>
        <InnerSidebar sidebarId="live-posts" icon="filter">
          <Form.Group>
            <Form.Label>{_t('Locations')}</Form.Label>
            <SearchableMultiSelect className="form-control" placeholder={_t('Search Locations...')} value={this.state.selectedLocations} onChange={e => { this.setState({ selectedLocations: e.target.value, filtersUpdated: true }) }}>
              {this.state.locations.map(location => {
                return (
                  <option key={`${location.name}`} value={`${location.name}`} selected={this.state.selectedLocations.includes(`${location.name}`)}>{location.name}</option>
                );
              })}
            </SearchableMultiSelect>
          </Form.Group>
          <Form.Group>
            <Form.Label>{_t('Price Range')}</Form.Label>
            <div className="">
              <Range
                step={10}
                min={0}
                max={1200000}
                values={[this.state.minPrice, this.state.maxPrice]}
                onChange={([min, max]) => this.setState({
                  filtersUpdated: true,
                  minPrice: min,
                  maxPrice: max
                })}
                renderTrack={({props, children}) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '4px',
                      width: '92%',
                      margin: '2%',
                      marginLeft: '4%',
                      marginRight: '4%',
                      backgroundColor: '#4f5d75'
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({props}) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '12px',
                      width: '12px',
                      backgroundColor: '#4f5d75',
                      borderRadius: '50%'
                    }}
                  />
                )}
              />
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto'
              }}>
                <div style={{ fontSize: '11px' }} className="m-2">{_t('Min Price:')} {this.state.minPrice}</div>
                <div style={{ fontSize: '11px' }} className="m-2 text-right">{_t('Max Price:')} {this.state.maxPrice}</div>
              </div>
            </div>
          </Form.Group>
        </InnerSidebar>
        <div className={`inner-container has-inner-sidebar ${InnerSidebarExpanded('live-posts') ? 'expanded' : ''}`}>
          <div className="row">
            <Form.Group className="col-lg-12 grid-margin stretch-card">
              <Form.Control type="text" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value, filtersUpdated: true })}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
            </Form.Group>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body" style={{ height: 'calc(100vh - 220px)', overflowY: 'auto' }}>
                  <table className="table table-hover table-posts">
                    <tbody>
                      {this.applyFilters(this.state.list).map((item, i) => {
                        return (
                          <tr key={item.postId}>
                            <td style={{
                              padding: '0px'
                            }}>
                              <div className={item.active ? 'post active' : 'post'}>
                                <div>
                                  <div className="image" style={{
                                    backgroundImage: `url("${Env.STAGE === 'prod' ? '' : `https://ba.cloud9ws.com`}${item.image}")`
                                  }}></div>
                                  <div className="info">
                                    <a className="title" href={item.url} target="_blank">{item.name.length > 32 ? `${item.name.substr(0, 32)}...` : item.name}</a>
                                    <div className="platform mt-1">{PLATFORMS[item.platform]}</div>
                                    <div className="location mt-2">{item.location ? item.location : ''}</div>
                                    <div className="description mt-2">
                                      <div dangerouslySetInnerHTML={{
                                        __html: item.description.length > 128 ? `${item.description.substr(0, 128)}...` : item.description
                                      }}/>
                                    </div>
                                    <div className="price">{Number(item.price).toFixed(2)} EUR</div>
                                    <span className="wishlist mr-2 text-danger" style={{
                                      position: 'absolute',
                                      right: '0px',
                                      bottom: '2px',
                                      cursor: 'pointer',
                                      fontSize: '16px'
                                    }} onClick={e => { e.preventDefault(); e.stopPropagation(); item.wishlisted ? this.removeFromWishlist(e, item.postId) : this.addToWishlist(e, item.postId); }}>
                                      <i className={item.wishlisted ? 'fa fa-heart' : 'fa far-heart'}></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export {
  LivePosts,
  LivePostsHeadless
};