import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import User from '../../core/stores/User';
import { Info, Warnings, Errors } from '../utils/Notices';
import SidebarItems from '../../app/shared/Sidebar';
import Loader from '../stores/Loader';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../stores/Translator.js';

class ListKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/organization/keys',
      list: [],
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateList();
  }
    
  updateList = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/organization/keys', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'API Keys': ''
        }}>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>{_t('API Access Key')}</th>
                          <th>{_t('Scope')}</th>
                          <th>{_t('Date Created')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.list.map(item => { 
                          return (
                            <tr key={item.key}>
                              <td>{item.key}</td>
                              <td>{item.scope.join(', ')}</td>
                              <td>{(() => {
                                let seconds = item.createdAt
                                let date = new Date(0);
                                date.setUTCSeconds(seconds);
                                let locale = date.toLocaleDateString();
                                return (
                                  <span>{locale}</span>
                                );
                              })()}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
        </div>
      </Fragment>
    );
  } 
}

export {
  ListKeys
};