/**
 * !important - every time this file is updated, we must also update /src/server/shared/permissions.js to reflect permission updates
 */
export default [{
  type: 'page',
  name: 'Searches',
  identity: 'searches',
  path: '/searches',
  icon: 'search',
  title: 'Searches'
}, {
  type: 'page',
  name: 'Search Results',
  identity: 'results',
  path: '/results',
  icon: 'bell',
  title: 'Search Results'
}, {
  type: 'page',
  name: 'Wishlist',
  identity: 'wishlist',
  path: '/wishlist',
  icon: 'heart',
  title: 'Wishlist'
}, {
  type: 'page',
  name: 'Live Posts',
  identity: 'live/posts',
  path: '/live/posts',
  icon: 'play',
  title: 'Live Posts'
}, {
  name: 'Apps',
  platforms: ['web'],
  icon: 'qrcode',
  path: '/apps',
  title: 'Apps'
}];