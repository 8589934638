import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import Loader from '../stores/Loader';
import User from '../stores/User';
import Navigation from '../../core/shared/NavigationV2';
import Settings from '../stores/Settings';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import { SearchableMultiSelect } from '../../core/utils/SearchableMultiSelect';
import { _t } from '../stores/Translator.js';

class ListOrganizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/organizations',
      list: [],
      selected: [],
      search: '',
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  updateList = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/admin/organizations', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateList();
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  onSelectChange = async (e, organizationId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(organizationId)) {
        this.setState({
          selected: this.state.selected.concat([organizationId])
        });
      }
    } else {
      if (this.state.selected.includes(organizationId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== organizationId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = async (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.organizationId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = async () => {
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.organizationId)).map(item => item.organizationId);
    this.setState({
      selected: selected
    });
  };
  
  applyFilters = (list) => {
    return list.filter(item => {
      return item.organizationName.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.admin.email.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.organizationId.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
    });
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Organizations': ''
        }}>
          {/*
          <Dropdown>
            <Dropdown.Toggle variant="btn btn-outline-primary" disabled={this.state.selected.length == 0}>Actions</Dropdown.Toggle>
            <Dropdown.Menu>
              
            </Dropdown.Menu>
          </Dropdown>
          */}
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Control type="text" size="sm" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
                  </Form.Group>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          <th>{_t('Organization Name')}</th>
                          <th>{_t('Owner Username')}</th>
                          <th>{_t('Owner Email')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => { 
                          return (
                            <tr key={item.organizationId} className={this.state.selected.includes(item.organizationId) ? 'table-primary' : ''}>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.organizationId)} onChange={e => this.onSelectChange(e, item.organizationId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              {/*<td><Link to={`/admin/organizations/edit/${item.organizationId}`}>{item.organizationName}</Link></td>*/}
                              <td><Link to={`/admin/organizations/edit/${item.organizationId}`}>{item.organizationName}</Link></td>
                              <td><Link to={`/admin/users/edit/${item.admin.userId}`}>{item.admin.username}</Link></td>
                              <td>{item.admin.email}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
        </div>
      </Fragment>
    );
  } 
}

class EditOrganizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/organizations/edit/:organizationId',
      organization: {
        organizationId: props.match.params.organizationId,
        organizationName: ''
      },
      list: [],
      selected: [],
      search: '',
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  async componentDidMount() {
    this._ismounted = true;
    Loader.show();
    let state = {
      additionalOrganizationInfo: {}
    };
    for (let i in Settings.organization.info) {
      let info = Settings.organization.info[i];
      state.additionalOrganizationInfo[info.id] = info.type === 'L' ? [] : '';
    }
    this.setState(state);
     await Promise.all([
      this.organization(),
      this.organizationInfo(),
      this.listsOrganizationInfoEndpoints()
    ]);
    Loader.hide();
  }
  
  handleChange = (e, field) => {
    let organization = {...this.state.organization};
    organization[field] = e.target.value;
    this.setState({
      organization: organization
    });
  }
  
  organization = async () => {
    try {
      let response = await (await fetch('/api/admin/organization', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          organizationId: this.state.organization.organizationId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        organization: response.data
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }

  }
  
  organizationInfo = async () => {
    try {
      let response = await (await fetch('/api/admin/organization-info', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          organizationId: this.state.organization.organizationId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      let state = {
        additionalOrganizationInfo: {}
      };
      for (let i in Settings.organization.info) {
        let info = Settings.organization.info[i];
        if (info.type === 'S') {
          state.additionalOrganizationInfo[info.id] = response.data[info.id] ?  response.data[info.id] : '';
        } else if (info.type === 'L') {
          state.additionalOrganizationInfo[info.id] = response.data[info.id] ?  response.data[info.id] : [];
        }
      }
      this.setState(state);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  listsOrganizationInfoEndpoints = async () => {
    while (!Settings.organization.info) { /* Sometimes info will remain empty */
      await this.sleep(0.2);
    };
    try {
      for (let i in Settings.organization.info) {
        let info = Settings.organization.info[i];
        if (!info.listEndpoint) { 
          this.setState({
            listOrganizationInfoReady: true
          });
        } else {
          let response = await (await fetch(info.listEndpoint, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })).json();
          if (response.status !== 'success') {
            throw Error(response.message);
          }
          // let state = {};
          // state[info.listName] = response.data.items;
          // this.setState(state);
          this.setState({
            [info.listName]: response.data.items
          }, () => {
            if (info.id === 'networkId') {
              this.setState({
                duplicateNetworksList: this.state.networks
              });
            }
            this.setState({
              listOrganizationInfoReady: true
            });
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  updateOrganization = async () => {
    try {
      let response = await (await fetch('/api/admin/organization/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          organization: this.state.organization,
          organizationInfo: this.state.additionalOrganizationInfo
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        info: this.state.info.concat(response.message)
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Update Organization': 'admin/organizations/:organizationId'
        }}>
          <Link to='/admin/organizations' className="mr-2">
            <input type="button" className="btn btn-outline-primary" size="sm" value={_t('Back')}/>
          </Link>
          <button type="button" className="btn btn-outline-primary mr-2" onClick={this.updateOrganization}>{_t('Update')}</button>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <Form.Group>  
                    <Form.Label>{_t('Organization Name')}</Form.Label>
                    <Form.Control type="text" size="sm" value={this.state.organization.organizationName} onChange={e => this.handleChange(e, 'organizationName')}/>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          {Settings.organization.info.length > 0 && 
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-header">
                    {_t('Additional Organization Info')}
                  </div>
                  <div className="card-body">
                  {Settings.organization.info && this.state.listOrganizationInfoReady && Settings.organization.info.map(info => {
                    switch (info.fieldType) {
                      case 'text':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            <Form.Control type="text" value={this.state.additionalOrganizationInfo[info.id] ? this.state.additionalOrganizationInfo[info.id] : ''} size="sm" onChange={(e) => { 
                              let additionalOrganizationInfo = {...this.state.additionalOrganizationInfo};
                              additionalOrganizationInfo[info.id] = e.target.value;
                              this.setState({
                                additionalOrganizationInfo: additionalOrganizationInfo
                              })
                            }}/>
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'select':
                        return (
                          <Form.Group>  
                           <Form.Label>{info.title}</Form.Label>
                            <SearchableSelect className="borderless form-control form-control-sm" placeholder="-- Select --" value={this.state.additionalOrganizationInfo[info.id] ? this.state.additionalOrganizationInfo[info.id] : ''} onChange={(e) => { 
                              let additionalOrganizationInfo = {...this.state.additionalOrganizationInfo};
                              additionalOrganizationInfo[info.id] = e.target.value;
                              this.setState({
                                additionalOrganizationInfo: additionalOrganizationInfo
                              })
                            }}>
                                <option key={0} value={''} selected={this.state.additionalOrganizationInfo[info.id] === ''}>-- Select --</option>
                                {this.state[info.listName].map(item => {
                                  return (
                                    <option key={item[info.id]} value={item[info.id]} selected={this.state.additionalOrganizationInfo[info.id] === item[info.id]}>{item[info.value]}</option>
                                  );
                              })}
                            </SearchableSelect>
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'multi-select':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            {this.state[info.listName] && <SearchableMultiSelect className="borderless form-control form-control-sm" placeholder="-- Select --"  value={this.state[info.id]} onChange={(e) => { 
                              let additionalOrganizationInfo = {...this.state.additionalOrganizationInfo};
                              additionalOrganizationInfo[info.id] = e.target.value;
                              this.setState({
                                additionalOrganizationInfo: additionalOrganizationInfo
                              })
                            }} disabled={info.id === 'organizationIds' && this.state.selectedOrganization !== '' ? true : false}>
                                {this.state[info.listName].map(item => {
                                  return (
                                    <option key={item[info.key]} value={item[info.key]} selected={this.state.additionalOrganizationInfo[info.id].includes(item[info.key])}>{item[info.name]}</option>
                                  );
                              })}
                            </SearchableMultiSelect>}
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                    }
                  })}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}
export {
  ListOrganizations,
  EditOrganizations
};