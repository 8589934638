export default {
  title: 'Get notified when an item becomes available!',
  nav: {
    links: [{
      name: 'THE TECH',
      href: 'http://wowyow.com/tech'
    }, {
      name: 'PUBLISHERS',
      href: 'http://wowyow.com/publishers'
    }, {
      name: 'ADVERTISERS',
      href: 'http://wowyow.com/advertiser'
    }, {
      name: 'SUPPORT',
      href: 'http://support.wowyow.com'
    }, {
      name: 'ABOUT',
      href: 'http://wowyow.com/about'
    }]
  }
};