import React, { Component, Fragment } from 'react';
import { Line, Doughnut, Bar, Pie, Scatter } from 'react-chartjs-2';
import { Link, withRouter } from 'react-router-dom';
import { Sparklines, SparklinesBars } from 'react-sparklines';
import { ProgressBar, Dropdown } from 'react-bootstrap';
import { ButtonGroup, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
// import DatePicker from 'react-datepicker';
// import { Dropdown } from 'react-bootstrap';
import SearchableSelect from '../utils/SearchableSelect';
import Navigation from '../shared/NavigationV2';
import DateSelect from '../../core/utils/DateSelect';
import SearchableMultiSelect from '../../core/utils/SearchableMultiSelect';
import GalleryPicker from '../../core/utils/GalleryPicker';
import { CheckboxTree } from '../../core/utils/CheckboxTree';

export class DemoUIElements extends Component {
  constructor(props) {
    super(props);
    let startDate= new Date();
    startDate.setDate(startDate.getDate() - 730);
    let endDate = new Date();
    this.state = {
      startDate : new Date(5),
      minDate: startDate,
      maxDate: endDate,
      todos: [
        {
            id: 1,
            task: 'Pick up kids from school',
            isCompleted: false
        },
        {
            id: 2,
            task: 'Prepare for presentation',
            isCompleted: false
        },
        {
            id: 3,
            task: 'Print Statements',
            isCompleted: false
        },
        {
            id: 4,
            task: 'Create invoice',
            isCompleted: false
        },
        {
            id: 5,
            task: 'Call John',
            isCompleted: false
        }
    ],
    inputValue: '',
    }
    
    this.statusChangedHandler = this.statusChangedHandler.bind(this);
    this.addTodo = this.addTodo.bind(this);
    this.removeTodo = this.removeTodo.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
  } 
  
  
  /*chart js*/
  data = {
      labels: ["2013", "2014", "2014", "2015", "2016", "2017"],
      datasets: [{
        label: '# of Votes',
        data: [10, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255,99,132,1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1,
        fill: false
      }]
  };

  options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      legend: {
        display: false
      },
      elements: {
        point: {
          radius: 0
        }
      }
  
  };

  areaData = {
      labels: ["2013", "2014", "2015", "2016", "2017"],
      datasets: [{
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255,99,132,1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1,
        fill: true, // 3: no fill
      }]
  };

  areaOptions = {
      plugins: {
        filler: {
          propagate: true
        }
      }
  }

  doughnutPieData = {
      datasets: [{
        data: [30, 40, 30],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)'
        ],
        borderColor: [
          'rgba(255,99,132,1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
      }],
  
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        'Pink',
        'Blue',
        'Yellow',
      ]
  };

  doughnutPieOptions = {
      responsive: true,
      animation: {
        animateScale: true,
        animateRotate: true
      }
  };

  scatterChartData = {
      datasets: [{
        label: 'First Dataset',
        data: [{
          x: -10,
          y: 0
        },
        {
          x: 0,
          y: 3
        },
        {
          x: -25,
          y: 5
        },
        {
          x: 40,
          y: 5
        }
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)'
        ],
        borderColor: [
          'rgba(255,99,132,1)'
        ],
        borderWidth: 1
      },
      {
        label: 'Second Dataset',
        data: [{
          x: 10,
          y: 5
        },
        {
          x: 20,
          y: -30
        },
        {
          x: -25,
          y: 15
        },
        {
          x: -10,
          y: 5
        }
        ],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1
      }
      ]
  }

  scatterChartOptions = {
      scales: {
        xAxes: [{
          type: 'linear',
          position: 'bottom'
        }]
      }
  }
  
  
  
  /*Basic Elements*/
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  
  /*DashboardJS*/
  statusChangedHandler(event, id) {
    const todo = {...this.state.todos[id]};
    todo.isCompleted = event.target.checked;

    const todos = [...this.state.todos];
    todos[id] = todo;

    this.setState({
        todos: todos
    })
  }

  addTodo (event) {
    event.preventDefault();

    const todos = [...this.state.todos];
    todos.unshift({
        id: todos.length ? todos[todos.length - 1].id + 1 : 1,
        task: this.state.inputValue,
        isCompleted: false
        
    })

    this.setState({
        todos: todos,
        inputValue: ''
    })
}

  removeTodo (index) {
    const todos = [...this.state.todos];
    todos.splice(index, 1);

    this.setState({
        todos: todos
    })
}

  inputChangeHandler(event) {
    this.setState({
        inputValue: event.target.value
    });
}

  areaData = {
    labels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    datasets: [{
        label: 'Product-1',
        data: [3, 3, 8, 5, 7, 4, 6, 4, 6, 3],
        backgroundColor: '#2196f3',
        borderColor: '#0c83e2',
        borderWidth: 1,
        fill: true,
        datasetKeyProvider: "key1"
      },
      {
        label: 'Product-2',
        data: [7, 5, 14, 7, 12, 6, 10, 6, 11, 5],
        backgroundColor: '#19d895',
        borderColor: '#15b67d',
        borderWidth: 1,
        fill: true,
        datasetKeyProvider: "key2"
      }
    ]
  };

  areaOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        gridLines: {
          color: "#F2F6F9"
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 20,
          stepSize: 5,
        }
      }],
      xAxes: [{
        gridLines: {
          color: "#F2F6F9"
        },
        ticks: {
          beginAtZero: true
        }
      }]
    },
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 2
      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    stepsize: 1
  };
  
  usersDoughnutChartData = {
    datasets: [{
      data: [80, 34, 100],
      backgroundColor: [
        "#19d895",
        "#2196f3",
        "#dde4eb"
      ],
      borderColor: [
        "#19d895",
        "#2196f3",
        "#dde4eb"
      ],
    }],
    labels: [
      'Request',
      'Email',
    ]
};

  usersDoughnutChartOptions = {
  cutoutPercentage: 70,
  animationEasing: "easeOutBounce",
  animateRotate: true,
  animateScale: false,
  responsive: true,
  maintainAspectRatio: true,
  showScale: true,
  legend: {
    display: false
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  }
};

  amountDueBarData = {
  labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7", "Day 8", "Day 9", "Day 10"],
  datasets: [{
    label: 'Profit',
    data: [39, 19, 25, 16, 31, 39, 12, 18, 33, 24],
    backgroundColor: [
      '#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3',
    ],
    borderColor: [
      '#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3','#2196f3',
    ],
    borderWidth: 2,
    fill: true
  }]
};

  amountDueBarOptions = {
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },

  scales: {
    responsive: true,
    maintainAspectRatio: true,
    yAxes: [{
      display: false,
      gridLines: {
        color: 'rgba(0, 0, 0, 0.03)',
      }
    }],
    xAxes: [{
      display: false,
      barPercentage: 0.4,
      gridLines: {
        display: false,
      }
    }]
  },
  legend: {
    display: false
  }
};

  toggleProBanner() {
  document.querySelector('.proBanner').classList.toggle("hide");
}
  
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Dashboard': '/',
          'Demo': '/demo'
        }}>
          
        </Navigation>
        <div className="inner-container">
          <div className="m-2 w-25">
            <h3 className="page-title mb-2">
              Date Select
            </h3>
            <p>
              <h6>Selected min and max date</h6>
              <DateSelect onChange={({from, to, utc}) => {}} showTimezone={false} showTime={false} minDate={this.state.minDate} maxDate={this.state.maxDate} />
            </p>
            <p>
              <h6>Selected min date</h6>
              <DateSelect onChange={({from, to, utc}) => {}} showTimezone={false} showTime={false} minDate={this.state.minDate}/>
            </p>
            <p>
              <h6>Selected max date</h6>
              <DateSelect onChange={({from, to, utc}) => {}} showTimezone={false} showTime={false} maxDate={this.state.maxDate} />
            </p>  
          </div>
          <div className="m-2 w-50">
            <h3 className="page-title mt-2">
              Multiselect
            </h3>
            <SearchableMultiSelect className="borderless form-control form-control-sm" placeholder="-- Select --" >
              <option key="option1" value="option1" >option1</option>
              <option key="option2" value="option2" >option2</option>
              <option key="option3" value="option3" >option3</option>
            </SearchableMultiSelect>
          </div>
          <div className="m-2 w-50">
            <h3 className="page-title mt-2">
              Searchable Select
            </h3>
            <SearchableSelect className="borderless form-control form-control-sm" placeholder="-- Select --" >
              <option key="option0" value="" >---</option>
              <option key="option1" value="option1" >option1</option>
              <option key="option2" value="option2" >option2</option>
              <option key="option3" value="option3" >option3</option>
            </SearchableSelect>
          </div>
          <div className="m-2 w-50">
            <h3 className="page-title mt-2">
              Galery Picker
            </h3>
            <GalleryPicker >
            {[1, 2, 3, 4].map(item => {
              return (
                <div style={{
                    width: '100px',
                    height: '100px'
                  }} value={item}>
                <img src="https://via.placeholder.com/120x100?text=Test" width="128px" height="72px" />
                </div>
              )
            })}
            </GalleryPicker>
          </div>
            <h3 className="page-title mt-2">
              CheckBox Tree
            </h3>
          <div className="m-2 w-50">
            <CheckboxTree nodes={[{
              value: 'wowyow-test-uuid',
              label: 'WowYow Test',
              selected: true,
              children: [{
                value: 'website-uuid',
                label: 'Website',
                selected: true,
                children: [{
                  value: 'website-tag-1',
                  label: 'Tag #1',
                  selected: false
                }, {
                  value: 'website-tag-2',
                  label: 'Tag #2',
                  selected: true
                }]
              }, {
                value: 'ott-uuid',
                label: 'OTT',
                selected: false,
                children: [{
                  value: 'ott-tag-3',
                  label: 'Tag #3',
                  selected: false
                }]
              }]
            }, {
              value: 'wowyow-test-2-uuid',
              label: 'WowYow Test #2',
              selected: true,
              children: [{
                value: 'website-2-uuid',
                label: 'Website #2',
                selected: false
              }]
            }, {
              value: 'wowyow-test-3-uuid',
              label: 'WowYow Test #3',
              selected: false
            }]} onChange={(nodes) => {
              console.log(nodes);
            }} />
          </div>
          <div>
            <div>
              <div>
                <h3 className="page-title">
                  Buttons
                </h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>UI Elements</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Buttons</li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Normal buttons</h4>
                      <p className="card-description">Use any of the available button classes to quickly create a styled button.</p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-primary">Primary</button>
                        <button type="button" className="btn btn-secondary">Secondary</button>
                        <button type="button" className="btn btn-success">Success</button>
                        <button type="button" className="btn btn-danger">Danger</button>
                        <button type="button" className="btn btn-warning">Warning</button>
                        <button type="button" className="btn btn-info">Info</button>
                        <button type="button" className="btn btn-light">Light</button>
                        <button type="button" className="btn btn-dark">Dark</button>
                        <button type="button" className="btn btn-link">Link</button>
                      </div>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">Rounded buttons</h4>
                      <p className="card-description">Add className <code>.btn-rounded</code></p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-primary btn-rounded">Primary</button>
                        <button type="button" className="btn btn-secondary btn-rounded">Secondary</button>
                        <button type="button" className="btn btn-success btn-rounded">Success</button>
                        <button type="button" className="btn btn-danger btn-rounded">Danger</button>
                        <button type="button" className="btn btn-warning btn-rounded">Warning</button>
                        <button type="button" className="btn btn-info btn-rounded">Info</button>
                        <button type="button" className="btn btn-light btn-rounded">Light</button>
                        <button type="button" className="btn btn-dark btn-rounded">Dark</button>
                        <button type="button" className="btn btn-link btn-rounded">Link</button>
                      </div>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">Outlined buttons</h4>
                      <p className="card-description">Add className <code>.btn-outline-&#123;color&#125;</code> for outline buttons</p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-outline-primary">Primary</button>
                        <button type="button" className="btn btn-outline-secondary">Secondary</button>
                        <button type="button" className="btn btn-outline-success">Success</button>
                        <button type="button" className="btn btn-outline-danger">Danger</button>
                        <button type="button" className="btn btn-outline-warning">Warning</button>
                        <button type="button" className="btn btn-outline-info">Info</button>
                        <button type="button" className="btn btn-outline-light">Light</button>
                        <button type="button" className="btn btn-outline-dark">Dark</button>
                        <button type="button" className="btn btn-link">Link</button>
                      </div>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">Single color buttons</h4>
                      <p className="card-description">Add className <code>.btn-&#123;color&#125;</code> for buttons in theme colors</p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-primary">Primary</button>
                        <button type="button" className="btn btn-secondary">Secondary</button>
                        <button type="button" className="btn btn-success">Success</button>
                        <button type="button" className="btn btn-danger">Danger</button>
                        <button type="button" className="btn btn-warning">Warning</button>
                        <button type="button" className="btn btn-info">Info</button>
                        <button type="button" className="btn btn-light">Light</button>
                        <button type="button" className="btn btn-dark">Dark</button>
                        <button type="button" className="btn btn-link">Link</button>
                      </div>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">Inverse buttons</h4>
                      <p className="card-description">Add className <code>.btn-inverse-&#123;color&#125; for inverse buttons</code></p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-inverse-primary">Primary</button>
                        <button type="button" className="btn btn-inverse-secondary">Secondary</button>
                        <button type="button" className="btn btn-inverse-success">Success</button>
                        <button type="button" className="btn btn-inverse-danger">Danger</button>
                        <button type="button" className="btn btn-inverse-warning">Warning</button>
                        <button type="button" className="btn btn-inverse-info">Info</button>
                        <button type="button" className="btn btn-inverse-light">Light</button>
                        <button type="button" className="btn btn-inverse-dark">Dark</button>
                        <button type="button" className="btn btn-link">Link</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-7 col-lg-9">
                          <h4 className="card-title">Icon Buttons</h4>
                          <p className="card-description">Add className <code>.btn-icon</code> for buttons with only icons</p>
                          <div className="template-demo d-sm-flex justify-content-between flex-nowrap">
                            <button type="button" className="btn btn-primary btn-rounded btn-icon">
                              <i className="mdi mdi-home-outline"></i>
                            </button>
                            <button type="button" className="btn btn-dark btn-rounded btn-icon">
                              <i className="mdi mdi-internet-explorer"></i>
                            </button>
                            <button type="button" className="btn btn-danger btn-rounded btn-icon">
                              <i className="mdi mdi-email-open"></i>
                            </button>
                            <button type="button" className="btn btn-info btn-rounded btn-icon">
                              <i className="mdi mdi-star"></i>
                            </button>
                            <button type="button" className="btn btn-success btn-rounded btn-icon">
                              <i className="mdi mdi-map-marker"></i>
                            </button>
                          </div>
                          <div className="template-demo d-sm-flex justify-content-between flex-nowrap">
                            <button type="button" className="btn btn-inverse-primary btn-rounded btn-icon">
                              <i className="mdi mdi-home-outline"></i>
                            </button>
                            <button type="button" className="btn btn-inverse-dark btn-icon">
                              <i className="mdi mdi-internet-explorer"></i>
                            </button>
                            <button type="button" className="btn btn-inverse-danger btn-icon">
                              <i className="mdi mdi-email-open"></i>
                            </button>
                            <button type="button" className="btn btn-inverse-info btn-icon">
                              <i className="mdi mdi-star"></i>
                            </button>
                            <button type="button" className="btn btn-inverse-success btn-icon">
                              <i className="mdi mdi-map-marker"></i>
                            </button>
                          </div>
                          <div className="template-demo d-sm-flex justify-content-between flex-nowrap mt-4">
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-heart-outline text-danger"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-music text-dark"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-star text-primary"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-signal text-info"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-trending-up text-success"></i>
                            </button>
                          </div>
                          <div className="template-demo d-sm-flex justify-content-between flex-nowrap">
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-heart-outline"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-music"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-star"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-signal"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon">
                              <i className="mdi mdi-trending-up"></i>
                            </button>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-3">
                          <h4 className="card-title">Button Size</h4>
                          <p className="card-description">Use className <code>.btn-&#123;size&#125;</code></p>
                          <div className="template-demo">
                            <button type="button" className="btn btn-outline-secondary btn-lg">btn-lg</button>
                            <button type="button" className="btn btn-outline-secondary btn-md">btn-md</button>
                            <button type="button" className="btn btn-outline-secondary btn-sm">btn-sm</button>
                          </div>
                          <div className="template-demo mt-4">
                            <button type="button" className="btn btn-danger btn-lg">btn-lg</button>
                            <button type="button" className="btn btn-success btn-md">btn-md</button>
                            <button type="button" className="btn btn-primary btn-sm">btn-sm</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Block buttons</h4>
                      <p className="card-description">Add className <code>.btn-block</code></p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-info btn-lg btn-block">Block buttons <i className="mdi mdi-menu float-right"></i>
                        </button>
                        <button type="button" className="btn btn-dark btn-lg btn-block">Block buttons</button>
                        <button type="button" className="btn btn-primary btn-lg btn-block">
                          <i className="mdi mdi-account"></i> Block buttons </button>
                        <button type="button" className="btn btn-outline-secondary btn-lg btn-block">Block buttons</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="card-title">Button groups</h4>
                          <p className="card-description">Wrap a series of buttons with <code>.btn</code> in <code>.btn-group</code></p>
                          <div className="template-demo">
                            <div className="btn-group" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-outline-secondary">1</button>
                              <button type="button" className="btn btn-outline-secondary">2</button>
                              <button type="button" className="btn btn-outline-secondary">3</button>
                            </div>
                            <div className="btn-group" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-heart-outline"></i>
                              </button>
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-calendar"></i>
                              </button>
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-clock"></i>
                              </button>
                            </div>
                          </div>
                          <div className="template-demo">
                            <div className="btn-group" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-primary">1</button>
                              <button type="button" className="btn btn-primary">2</button>
                              <button type="button" className="btn btn-primary">3</button>
                            </div>
                            <div className="btn-group" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-primary">
                                <i className="mdi mdi-heart-outline"></i>
                              </button>
                              <button type="button" className="btn btn-primary">
                                <i className="mdi mdi-calendar"></i>
                              </button>
                              <button type="button" className="btn btn-primary">
                                <i className="mdi mdi-clock"></i>
                              </button>
                            </div>
                          </div>
                          <div className="template-demo mt-4">
                            <div className="btn-group-vertical" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-format-vertical-align-top"></i>
                              </button>
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-format-vertical-align-center"></i>
                              </button>
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-format-vertical-align-bottom"></i>
                              </button>
                            </div>
                            <div className="btn-group-vertical" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-outline-secondary">Default</button>
                              <div className="btn-group">
                                <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">Dropdown</button>
                                <div className="dropdown-menu">
                                  <a href="!#" onClick={event => event.preventDefault()} className="dropdown-item">Go back</a>
                                  <a href="!#" onClick={event => event.preventDefault()} className="dropdown-item">Delete</a>
                                  <a href="!#" onClick={event => event.preventDefault()} className="dropdown-item">Swap</a>
                                </div>
                              </div>
                              <button type="button" className="btn btn-outline-secondary">Default</button>
                            </div>
                            <div className="btn-group-vertical" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-outline-secondary">Top</button>
                              <button type="button" className="btn btn-outline-secondary">Middle</button>
                              <button type="button" className="btn btn-outline-secondary">Bottom</button>
                            </div>
                          </div>
                          <div className="template-demo mt-4">
                            <div className="btn-group" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-star d-block mb-1"></i> Favourites </button>
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-reload d-block mb-1"></i> Reload </button>
                              <button type="button" className="btn btn-outline-secondary">
                                <i className="mdi mdi-account d-block mb-1"></i> Users </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4 className="card-title">Button with text and icon</h4>
                          <p className="card-description">Wrap icon and text inside <code>.btn-icon-text</code> and use <code>.btn-icon-prepend</code> or <code>.btn-icon-append</code> for icon tags</p>
                          <div className="template-demo">
                            <button type="button" className="btn btn-primary btn-icon-text">
                              <i className="mdi mdi-file-check btn-icon-prepend"></i> Submit </button>
                            <button type="button" className="btn btn-dark btn-icon-text"> Edit <i className="mdi mdi-file-check btn-icon-append"></i>
                            </button>
                            <button type="button" className="btn btn-success btn-icon-text">
                              <i className="mdi mdi-alert btn-icon-prepend"></i> Warning </button>
                          </div>
                          <div className="template-demo">
                            <button type="button" className="btn btn-danger btn-icon-text">
                              <i className="mdi mdi-upload btn-icon-prepend"></i> Upload </button>
                            <button type="button" className="btn btn-info btn-icon-text"> Print <i className="mdi mdi-printer btn-icon-append"></i>
                            </button>
                            <button type="button" className="btn btn-warning btn-icon-text">
                              <i className="mdi mdi-reload btn-icon-prepend"></i> Reset </button>
                          </div>
                          <div className="template-demo mt-2">
                            <button type="button" className="btn btn-outline-primary btn-icon-text">
                              <i className="mdi mdi-file-check btn-icon-prepend"></i> Submit </button>
                            <button type="button" className="btn btn-outline-secondary btn-icon-text"> Edit <i className="mdi mdi-file-check btn-icon-append"></i>
                            </button>
                            <button type="button" className="btn btn-outline-success btn-icon-text">
                              <i className="mdi mdi-alert btn-icon-prepend"></i> Warning </button>
                          </div>
                          <div className="template-demo">
                            <button type="button" className="btn btn-outline-danger btn-icon-text">
                              <i className="mdi mdi-upload btn-icon-prepend"></i> Upload </button>
                            <button type="button" className="btn btn-outline-info btn-icon-text"> Print <i className="mdi mdi-printer btn-icon-append"></i>
                            </button>
                            <button type="button" className="btn btn-outline-warning btn-icon-text">
                              <i className="mdi mdi-reload btn-icon-prepend"></i> Reset </button>
                          </div>
                          <div className="template-demo mt-2">
                            <button className="btn btn-dark btn-icon-text">
                              <i className="mdi mdi-apple btn-icon-prepend mdi-36px"></i>
                              <span className="d-inline-block text-left">
                                <small className="font-weight-light d-block">Available on the</small> App Store </span>
                            </button>
                            <button className="btn btn-dark btn-icon-text">
                              <i className="mdi mdi-android-debug-bridge btn-icon-prepend mdi-36px"></i>
                              <span className="d-inline-block text-left">
                                <small className="font-weight-light d-block">Get it on the</small> Google Play </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Social Icon Buttons</h4>
                      <p className="card-description">Add className <code>.btn-social-icon</code></p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-social-icon btn-outline-facebook"><i className="mdi mdi-facebook"></i></button>
                        <button type="button" className="btn btn-social-icon btn-outline-youtube"><i className="mdi mdi-youtube"></i></button>
                        <button type="button" className="btn btn-social-icon btn-outline-twitter"><i className="mdi mdi-twitter"></i></button>
                        <button type="button" className="btn btn-social-icon btn-outline-dribbble"><i className="mdi mdi-dribbble"></i></button>
                        <button type="button" className="btn btn-social-icon btn-outline-linkedin"><i className="mdi mdi-linkedin"></i></button>
                        <button type="button" className="btn btn-social-icon btn-outline-google"><i className="mdi mdi-google-plus"></i></button>
                      </div>
                      <div className="template-demo">
                        <button type="button" className="btn btn-social-icon btn-facebook"><i className="mdi mdi-facebook"></i></button>
                        <button type="button" className="btn btn-social-icon btn-youtube"><i className="mdi mdi-youtube"></i></button>
                        <button type="button" className="btn btn-social-icon btn-twitter"><i className="mdi mdi-twitter"></i></button>
                        <button type="button" className="btn btn-social-icon btn-dribbble"><i className="mdi mdi-dribbble"></i></button>
                        <button type="button" className="btn btn-social-icon btn-linkedin"><i className="mdi mdi-linkedin"></i></button>
                        <button type="button" className="btn btn-social-icon btn-google"><i className="mdi mdi-google-plus"></i></button>
                      </div>
                      <div className="template-demo">
                        <button type="button" className="btn btn-social-icon btn-facebook btn-rounded"><i className="mdi mdi-facebook"></i></button>
                        <button type="button" className="btn btn-social-icon btn-youtube btn-rounded"><i className="mdi mdi-youtube"></i></button>
                        <button type="button" className="btn btn-social-icon btn-twitter btn-rounded"><i className="mdi mdi-twitter"></i></button>
                        <button type="button" className="btn btn-social-icon btn-dribbble btn-rounded"><i className="mdi mdi-dribbble"></i></button>
                        <button type="button" className="btn btn-social-icon btn-linkedin btn-rounded"><i className="mdi mdi-linkedin"></i></button>
                        <button type="button" className="btn btn-social-icon btn-google btn-rounded"><i className="mdi mdi-google-plus"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Social button with text</h4>
                      <p className="card-description">Add className <code>.btn-social-icon-text</code></p>
                      <div className="template-demo">
                        <button type="button" className="btn btn-social-icon-text btn-facebook"><i className="mdi mdi-facebook"></i>Facebook</button>
                        <button type="button" className="btn btn-social-icon-text btn-youtube"><i className="mdi mdi-youtube"></i>Youtube</button>
                        <button type="button" className="btn btn-social-icon-text btn-twitter"><i className="mdi mdi-twitter"></i>Twitter</button>
                        <button type="button" className="btn btn-social-icon-text btn-dribbble"><i className="mdi mdi-dribbble"></i>Dribbble</button>
                        <button type="button" className="btn btn-social-icon-text btn-linkedin"><i className="mdi mdi-linkedin"></i>Linkedin</button>
                        <button type="button" className="btn btn-social-icon-text btn-google"><i className="mdi mdi-google-plus"></i>Google</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2>Dropdowns</h2>
            <div>
              <div>
                <h3 className="page-title">
                  Dropdowns
                </h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>UI Elements</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Dropdowns</li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-lg-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Basic dropdown</h4>
                      <p className="card-description">
                        Wrap the dropdown’s toggle (your button or link) and the dropdown menu within <code>.dropdown</code>
                      </p>
                      <div className="template-demo">
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-primary" id="dropdownMenuButton1">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-secondary" id="dropdownMenuButton2">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-danger" id="dropdownMenuButton3">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-warning" id="dropdownMenuButton4">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-success" id="dropdownMenuButton5">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-info" id="dropdownMenuButton6">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">Dropdown outline</h4>
                      <p className="card-description">
                        Add class <code>.btn-outline-&#123;color&#125;</code> to the button inside <code>.dropdown</code>
                      </p>
                      <div className="template-demo">
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-outline-primary" id="dropdownMenuOutlineButton1">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-outline-secondary" id="dropdownMenuOutlineButton2">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-outline-danger" id="dropdownMenuOutlineButton3">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-outline-warning" id="dropdownMenuOutlineButton4">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-outline-success" id="dropdownMenuOutlineButton5">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-outline-info" id="dropdownMenuOutlineButton6">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Split button dropdowns</h4>
                      <p className="card-description">
                        Wrap two buttons in <code>.btn-group</code> and add <code>.dropdown-toggle-split</code> to the toggling button
                      </p>
                      <div className="template-demo">
                        <Dropdown as={ButtonGroup}>
                          <button type="button" className="btn btn-primary">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-primary dropdown-toggle-split" id="dropdownMenuSplitButton1">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as={ButtonGroup}>
                          <button type="button" className="btn btn-danger">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-danger dropdown-toggle dropdown-toggle-split" id="dropdownMenuSplitButton2">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as={ButtonGroup}>
                          <button type="button" className="btn btn-success">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-success dropdown-toggle dropdown-toggle-split" id="dropdownMenuSplitButton3">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as={ButtonGroup}>
                          <button type="button" className="btn btn-secondary">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-secondary dropdown-toggle dropdown-toggle-split" id="dropdownMenuSplitButton4">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as={ButtonGroup}>
                          <button type="button" className="btn btn-info">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-info dropdown-toggle dropdown-toggle-split" id="dropdownMenuSplitButton5">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as={ButtonGroup}>
                          <button type="button" className="btn btn-warning">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-warning dropdown-toggle dropdown-toggle-split" id="dropdownMenuSplitButton6">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Icon dropdowns</h4>
                      <p className="card-description">
                        Add icon tags instead of text
                      </p>
                      <div className="row">
                        <div className="col-12">
                          <div className="template-demo d-flex justify-content-between">
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-warning" id="dropdownMenuIconButton1">
                                <i className="mdi mdi-earth"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-danger" id="dropdownMenuIconButton2">
                                <i className="mdi mdi-trophy-outline"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-outline-info" id="dropdownMenuIconButton3">
                                <i className="mdi mdi-clock"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="template-demo d-flex justify-content-between">
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-success" id="dropdownMenuIconButton4">
                                <i className="mdi mdi-heart"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-warning" id="dropdownMenuIconButton5">
                                <i className="mdi mdi-logout"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-primary" id="dropdownMenuIconButton6">
                                <i className="mdi mdi-security"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="template-demo d-flex justify-content-between">
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-dark" id="dropdownMenuIconButton7">
                                <i className="mdi mdi-account"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-info" id="dropdownMenuIconButton8">
                                <i className="mdi mdi-bell"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-success" id="dropdownMenuIconButton7">
                                <i className="mdi mdi-account"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Settings</Dropdown.Header>
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider></Dropdown.Divider>
                                <Dropdown.Item>Separated link</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Dropup variation</h4>
                      <p className="card-description">
                        Add class <code>.dropup</code>
                      </p>
                      <div className="template-demo">
                        <Dropdown drop="up" as={ButtonGroup}>
                          <button type="button" className="btn btn-primary">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-primary" id="dropupMenuSplitButton1">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown drop="up" as={ButtonGroup}>
                          <button type="button" className="btn btn-danger">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-danger" id="dropupMenuSplitButton2">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown drop="up" as={ButtonGroup}>
                          <button type="button" className="btn btn-success">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-success" id="dropupMenuSplitButton3">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown drop="up" as={ButtonGroup}>
                          <button type="button" className="btn btn-secondary">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-secondary" id="dropupMenuSplitButton4">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown drop="up" as={ButtonGroup}>
                          <button type="button" className="btn btn-info">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-info" id="dropupMenuSplitButton5">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown drop="up" as={ButtonGroup}>
                          <button type="button" className="btn btn-warning">Dropdown</button>
                          <Dropdown.Toggle variant="btn btn-warning" id="dropupMenuSplitButton6">
                            <span className="sr-only">Toggle Dropdown</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Dropdown sizes</h4>
                      <p className="card-description">
                        Add class <code>.btn-&#123;size&#125;</code> to dropdown buttons
                      </p>
                      <div className="template-demo mt-5">
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-danger btn-lg" id="dropdownMenuSizeButton1">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-danger" id="dropdownMenuSizeButton2">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="btn btn-danger btn-sm" id="dropdownMenuSizeButton3">
                            Dropdown
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Header>Settings</Dropdown.Header>
                            <Dropdown.Item>Action</Dropdown.Item>
                            <Dropdown.Item>Another action</Dropdown.Item>
                            <Dropdown.Item>Something else here</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item>Separated link</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2>Typography</h2>
            <div>
            <div>
              <h3 className="page-title"> Typography </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>UI Elements</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Typography</li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Headings</h4>
                    <p className="card-description"> Add tags <code>&lt;h1&gt;</code> to <code>&lt;h6&gt;</code> or class <code>.h1</code> to <code>.h6</code>
                    </p>
                    <div className="template-demo">
                      <h1>h1. Heading</h1>
                      <h2>h2. Heading</h2>
                      <h3>h3. Heading</h3>
                      <h4>h4. Heading</h4>
                      <h5>h5. Heading</h5>
                      <h6>h6. Heading</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Headings with secondary text</h4>
                    <p className="card-description"> Add faded secondary text to headings </p>
                    <div className="template-demo">
                      <h1> h1. Heading <small className="text-muted"> Secondary text </small>
                      </h1>
                      <h2> h2. Heading <small className="text-muted"> Secondary text </small>
                      </h2>
                      <h3> h3. Heading <small className="text-muted"> Secondary text </small>
                      </h3>
                      <h4> h4. Heading <small className="text-muted"> Secondary text </small>
                      </h4>
                      <h5> h5. Heading <small className="text-muted"> Secondary text </small>
                      </h5>
                      <h6> h6. Heading <small className="text-muted"> Secondary text </small>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Display headings</h4>
                    <p className="card-description"> Add class <code>.display1</code> to <code>.display-4</code>
                    </p>
                    <div className="template-demo">
                      <h1 className="display-1">Display 1</h1>
                      <h1 className="display-2">Display 2</h1>
                      <h1 className="display-3">Display 3</h1>
                      <h1 className="display-4">Display 4</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Paragraph</h4>
                        <p className="card-description"> Write text in <code>&lt;p&gt;</code> tag </p>
                        <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley not only five centuries, </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Icon size</h4>
                        <p className="card-description"> Add class <code>.icon-lg</code>, <code>.icon-md</code>, <code>.icon-sm</code>
                        </p>
                        <div className="row">
                          <div className="col-md-4 d-flex align-items-center">
                            <div className="d-flex flex-row align-items-center">
                              <i className="mdi mdi-compass icon-lg text-warning"></i>
                              <p className="mb-0 ml-1"> Icon-lg </p>
                            </div>
                          </div>
                          <div className="col-md-4 d-flex align-items-center">
                            <div className="d-flex flex-row align-items-center">
                              <i className="mdi mdi-compass icon-md text-success"></i>
                              <p className="mb-0 ml-1"> Icon-md </p>
                            </div>
                          </div>
                          <div className="col-md-4 d-flex align-items-center">
                            <div className="d-flex flex-row align-items-center">
                              <i className="mdi mdi-compass icon-sm text-danger"></i>
                              <p className="mb-0 ml-1"> Icon-sm </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Blockquotes</h4>
                    <p className="card-description"> Wrap content inside<code>&lt;blockquote className="blockquote"&gt;</code>
                    </p>
                    <blockquote className="blockquote">
                      <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                    </blockquote>
                  </div>
                  <div className="card-body">
                    <blockquote className="blockquote blockquote-primary">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                      <footer className="blockquote-footer text-muted">Someone famous in <cite title="Source Title">Source Title</cite></footer>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Address</h4>
                    <p className="card-description"> Use <code>&lt;address&gt;</code> tag </p>
                    <div className="row">
                      <div className="col-md-6">
                        <address>
                          <p className="font-weight-bold">Purple imc</p>
                          <p> 695 lsom Ave, </p>
                          <p> Suite 00 </p>
                          <p> San Francisco, CA 94107 </p>
                        </address>
                      </div>
                      <div className="col-md-6">
                        <address className="text-primary">
                          <p className="font-weight-bold"> E-mail </p>
                          <p className="mb-2"> johndoe@examplemeail.com </p>
                          <p className="font-weight-bold"> Web Address </p>
                          <p> www.Purple.com </p>
                        </address>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title">Lead</h4>
                    <p className="card-description"> Use class <code>.lead</code>
                    </p>
                    <p className="lead"> Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Text colors</h4>
                    <p className="card-description"> Use class <code>.text-primary</code>, <code>.text-secondary</code> etc. for text in theme colors </p>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="text-primary">.text-primary</p>
                        <p className="text-success">.text-success</p>
                        <p className="text-danger">.text-danger</p>
                        <p className="text-warning">.text-warning</p>
                        <p className="text-info">.text-info</p>
                      </div>
                      <div className="col-md-6">
                        <p className="text-light bg-dark pl-1">.text-light</p>
                        <p className="text-secondary">.text-secondary</p>
                        <p className="text-dark">.text-dark</p>
                        <p className="text-muted">.text-muted</p>
                        <p className="text-white bg-dark pl-1">.text-white</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Top aligned media</h4>
                    <div className="media">
                      <i className="mdi mdi-earth icon-md text-info d-flex align-self-start mr-3"></i>
                      <div className="media-body">
                        <p className="card-text">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Center aligned media</h4>
                    <div className="media">
                      <i className="mdi mdi-earth icon-md text-info d-flex align-self-center mr-3"></i>
                      <div className="media-body">
                        <p className="card-text">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Bottom aligned media</h4>
                    <div className="media">
                      <i className="mdi mdi-earth icon-md text-info d-flex align-self-end mr-3"></i>
                      <div className="media-body">
                        <p className="card-text">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Highlighted Text</h4>
                    <p className="card-description"> Wrap the text in <code>&lt;mark&gt;</code> to highlight text </p>
                    <p> It is a long <mark className="bg-warning text-white">established</mark> fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">List Unordered</h4>
                    <ul>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Consectetur adipiscing elit</li>
                      <li>Integer molestie lorem at massa</li>
                      <li>Facilisis in pretium nisl aliquet</li>
                      <li>Nulla volutpat aliquam velit</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Bold text</h4>
                    <p className="card-description"> Use class<code>.font-weight-bold</code>
                    </p>
                    <p> It is a long <span className="font-weight-bold">established fact</span> that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">List Ordered</h4>
                    <ol>
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Consectetur adipiscing elit</li>
                      <li>Integer molestie lorem at massa</li>
                      <li>Facilisis in pretium nisl aliquet</li>
                      <li>Nulla volutpat aliquam velit></li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title text-primary">Underline</h4>
                    <p className="card-description"> Wrap in <code>&lt;u&gt;</code> tag for underline </p>
                    <p>
                      <u>lorem ipsum dolor sit amet, consectetur mod tempor incididunt ut labore et dolore magna aliqua.</u>
                    </p>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title text-danger">Lowercase</h4>
                    <p className="card-description"> Use class <code>.text-lowercase</code>
                    </p>
                    <p className="text-lowercase"> lorem ipsum dolor sit amet, consectetur mod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title text-warning">Uppercase</h4>
                    <p className="card-description"> Use class <code>.text-uppercase</code>
                    </p>
                    <p className="text-uppercase"> lorem ipsum dolor sit amet, consectetur mod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Mute</h4>
                    <p className="card-description"> Use class <code>.text-muted</code>
                    </p>
                    <p className="text-muted"> lorem ipsum dolor sit amet, consectetur mod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title text-success">Strike</h4>
                    <p className="card-description"> Wrap content in <code>&lt;del&gt;</code> tag </p>
                    <p>
                      <del> lorem ipsum dolor sit amet, consectetur mod tempor incididunt ut labore et dolore magna aliqua. </del>
                    </p>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title text-info">Capitalized</h4>
                    <p className="card-description"> Use class <code>.text-capitalize</code>
                    </p>
                    <p className="text-capitalize"> lorem ipsum dolor sit amet, consectetur mod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">List with icon</h4>
                    <p className="card-description">Add class <code>.list-ticked</code> to <code>&lt;ul&gt;</code></p>
                    <ul className="list-ticked">
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Consectetur adipiscing elit</li>
                      <li>Integer molestie lorem at massa</li>
                      <li>Facilisis in pretium nisl aliquet</li>
                      <li>Nulla volutpat aliquam velit></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">List with icon</h4>
                    <p className="card-description">Add class <code>.list-arrow</code> to <code>&lt;ul&gt;</code></p>
                    <ul className="list-arrow">
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Consectetur adipiscing elit</li>
                      <li>Integer molestie lorem at massa</li>
                      <li>Facilisis in pretium nisl aliquet</li>
                      <li>Nulla volutpat aliquam velit></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">List with icon</h4>
                    <p className="card-description">Add class <code>.list-star</code> to <code>&lt;ul&gt;</code></p>
                    <ul className="list-star">
                      <li>Lorem ipsum dolor sit amet</li>
                      <li>Consectetur adipiscing elit</li>
                      <li>Integer molestie lorem at massa</li>
                      <li>Facilisis in pretium nisl aliquet</li>
                      <li>Nulla volutpat aliquam velit></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>ChartJs</h2>  
          <div>
            <div>
              <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Charts</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Chart-js</li>
                  </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Line Chart</h4>
                    <Line data={this.data} options={this.options} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Bar Chart</h4>
                        <Bar data={this.data} options={this.options} />    
                    </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Area Chart</h4>
                    <Line data={this.areaData} options={this.areaOptions} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Doughnut Chart</h4>
                    <Doughnut data={this.doughnutPieData} options={this.doughnutPieOptions} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Pie Chart</h4>
                    <Pie data={this.doughnutPieData} options={this.doughnutPieOptions} />                                
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Scatter Chart</h4>
                    <Scatter data={this.scatterChartData} options={this.scatterChartOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>Dashboard</h2>  
          <div>
            <div className="row proBanner">
              <div className="col-12">
                <span className="d-flex align-items-center purchase-popup">
                  <p>Like what you see? Check out our premium version for more.</p>
                  <a href="https://github.com/BootstrapDash/StarAdmin-Free-React-Admin-Template" target="_blank" rel="noopener noreferrer" className="btn ml-auto download-button">Download Free Version</a>
                  <a href="http://www.bootstrapdash.com/demo/star-admin-pro-react/template/demo_1/preview/dashboard" rel="noopener noreferrer" target="_blank" className="btn purchase-button">Upgrade To Pro</a>
                  <i className="mdi mdi-close bannerClose" onClick={this.toggleProBanner}></i>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-left">
                        <i className="mdi mdi-cube text-danger icon-lg"></i>
                      </div>
                      <div className="float-right">
                        <p className="mb-0 text-right text-dark">Total Revenue</p>
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">$65,650</h3>
                        </div>
                      </div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <i className="mdi mdi-alert-octagon mr-1" aria-hidden="true"></i> 65% lower growth </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-left">
                        <i className="mdi mdi-receipt text-warning icon-lg"></i>
                      </div>
                      <div className="float-right">
                        <p className="mb-0 text-right text-dark">Orders</p>
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">3455</h3>
                        </div>
                      </div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <i className="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> Product-wise sales </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-left">
                        <i className="mdi mdi-poll-box text-success icon-lg"></i>
                      </div>
                      <div className="float-right">
                        <p className="mb-0 text-right text-dark">Sales</p>
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">5693</h3>
                        </div>
                      </div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <i className="mdi mdi-calendar mr-1" aria-hidden="true"></i> Weekly Sales </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-left">
                        <i className="mdi mdi-account-box-multiple text-info icon-lg"></i>
                      </div>
                      <div className="float-right">
                        <p className="mb-0 text-right text-dark">Employees</p>
                        <div className="fluid-container">
                          <h3 className="font-weight-medium text-right mb-0 text-dark">246</h3>
                        </div>
                      </div>
                    </div>
                    <p className="text-muted mt-3 mb-0">
                      <i className="mdi mdi-reload mr-1" aria-hidden="true"></i> Product-wise sales </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h2 className="card-title mb-0">Product Analysis</h2>
                      <div className="wrapper d-flex">
                        <div className="d-flex align-items-center mr-3">
                          <span className="dot-indicator bg-success"></span>
                          <p className="mb-0 ml-2 text-muted">Product</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="dot-indicator bg-primary"></span>
                          <p className="mb-0 ml-2 text-muted">Resources</p>
                        </div>
                      </div>
                    </div>
                    <div className="chart-container">
                    <Line data={this.areaData} options={this.areaOptions}  datasetKeyProvider={this.datasetKeyProvider} height={80} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-sm-6  grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Todo</h4>
                    <form  className="add-items d-lg-flex" onSubmit={this.addTodo}>
                      <input 
                          type="text" 
                          className="form-control h-auto" 
                          placeholder="What do you need to do today?" 
                          value={this.state.inputValue} 
                          onChange={this.inputChangeHandler}
                          required />
                      <button type="submit" className="btn btn-primary font-weight-bold ml-0 mt-2 mt-lg-0">Add</button>
                    </form>
                    <div className="list-wrapper">
                      <ul className="d-flex flex-column todo-list todo-padding-lg">
                        {this.state.todos.map((todo, index) =>{
                            return <ListItem 
                            isCompleted={todo.isCompleted}
                            changed={(event) => this.statusChangedHandler(event, index)}
                            key={todo.id}
                            remove={() => this.removeTodo(index) }
                            >{todo.task}</ListItem>
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-sm-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Schedules</h4>
                    <div className="shedule-list d-xl-flex align-items-center justify-content-between mb-3">
                      <h3>27 Sep 2018</h3>
                      <small>21 Events</small>
                    </div>
                    <div className="event border-bottom py-3">
                      <p className="mb-2 font-weight-medium">Skype call with alex</p>
                      <div className="d-flex align-items-center">
                        <div className="badge badge-success">3:45 AM</div>
                        <small className="text-muted ml-2">London, UK</small>
                        <div className="image-grouped ml-auto mt-2 mt-xl-0">
                          <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile" />
                          <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile" /> </div>
                      </div>
                    </div>
                    <div className="event py-3 border-bottom">
                      <p className="mb-2 font-weight-medium">Data Analysing with team</p>
                      <div className="d-flex  align-items-center">
                        <div className="badge badge-warning">12.30 AM</div>
                        <small className="text-muted ml-2">San Francisco, CA</small>
                        <div className="image-grouped ml-auto mt-2 mt-xl-0">
                          <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile "/>
                          <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile "/>
                          <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile " /> </div>
                      </div>
                    </div>
                    <div className="event py-3">
                      <p className="mb-2 font-weight-medium">Meeting with client</p>
                      <div className="d-flex  align-items-center">
                        <div className="badge badge-danger">4.15 AM</div>
                        <small className="text-muted ml-2">San Diego, CA</small>
                        <div className="image-grouped ml-auto mt-2 mt-xl-0">
                          <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile"/>
                          <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile" /> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 col-sm-12 grid-margin stretch-card">
                <div className="row flex-grow">
                  <div className="col-xl-12 col-lg-6 col-sm-6 grid-margin-0 grid-margin-xl stretch-card">
                    <div className="card card-revenue">
                      <div className="card-body">
                        <div className="d-flex w-100 h-100 justify-content-between align-items-center">
                          <div className="mr-auto">
                            <p className="highlight-text text-white"> $168.90 </p>
                            <p className="text-white"> This Month </p>
                            <div className="badge badge-pill"> 18% </div>
                          </div>
                          <div className="ml-auto mt-2 mt-xl-0">
                            <Sparklines data={[4,3,10,9,4,3,8,6,7,8]} style={{ width: "110px", height:"70px" }}>
                              <SparklinesBars barWidth = {4} style={{ fill: "#fff" }} />
                            </Sparklines>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-6 col-sm-6 stretch-card">
                    <div className="card card-revenue-table mt-4 mt-sm-0 mt-xl-4">
                      <div className="card-body">
                        <div className="revenue-item d-flex">
                          <div className="revenue-desc">
                            <h6>Member Profit</h6>
                            <p className="font-weight-light"> Average Weekly Profit </p>
                          </div>
                          <div className="revenue-amount">
                            <p className="text-secondary"> +168.900 </p>
                          </div>
                        </div>
                        <div className="revenue-item d-flex">
                          <div className="revenue-desc">
                            <h6>Total Profit</h6>
                            <p className="font-weight-light"> Weekly Customer Orders </p>
                          </div>
                          <div className="revenue-amount">
                            <p className="text-primary"> +6890.00 </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5 d-flex align-items-center">
                        <Doughnut data={this.usersDoughnutChartData} options={this.usersDoughnutChartOptions} width= {180} />
                      </div>
                      <div className="col-md-7">
                        <h4 className="card-title font-weight-medium mb-0 d-none d-md-block">Active Users</h4>
                        <div className="wrapper mt-4">
                          <div className="d-flex justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <p className="mb-0 font-weight-medium">67,550</p>
                              <small className="text-muted ml-2">Email account</small>
                            </div>
                            <p className="mb-0 font-weight-medium">80%</p>
                          </div>
                            <ProgressBar variant="primary" now={80}/>
                        </div>
                        <div className="wrapper mt-4">
                          <div className="d-flex justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <p className="mb-0 font-weight-medium">21,435</p>
                              <small className="text-muted ml-2">Requests</small>
                            </div>
                            <p className="mb-0 font-weight-medium">34%</p>
                          </div>
                            <ProgressBar variant="success" now={34}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-7">
                        <h4 className="card-title font-weight-medium mb-3">Amount Due</h4>
                        <h1 className="font-weight-medium mb-0 text-dark">$5998</h1>
                        <p className="text-muted">Milestone Completed</p>
                        <p className="mb-0">Payment for next week</p>
                      </div>
                      <div className="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                        <Bar data={this.amountDueBarData} options={this.amountDueBarOptions} />    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body py-5">
                    <div className="d-flex flex-row justify-content-center align-items">
                      <i className="mdi mdi-facebook text-facebook icon-lg"></i>
                      <div className="ml-3">
                        <h6 className="text-facebook font-weight-semibold mb-0">2.62 Subscribers</h6>
                        <p className="text-muted card-text">You main list growing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body py-5">
                    <div className="d-flex flex-row justify-content-center align-items">
                      <i className="mdi mdi-google-plus text-google icon-lg"></i>
                      <div className="ml-3">
                        <h6 className="text-google font-weight-semibold mb-0">3.4k Followers</h6>
                        <p className="text-muted card-text">You main list growing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body py-5">
                    <div className="d-flex flex-row justify-content-center align-items">
                      <i className="mdi mdi-twitter text-twitter icon-lg"></i>
                      <div className="ml-3">
                        <h6 className="text-twitter font-weight-semibold mb-0">3k followers</h6>
                        <p className="text-muted card-text">You main list growing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Orders</h4>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th> # </th>
                            <th> First name </th>
                            <th> Progress </th>
                            <th> Amount </th>
                            <th> Sales </th>
                            <th> Deadline </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-medium"> 1 </td>
                            <td> Herman Beck </td>
                            <td>
                              <ProgressBar variant="success" striped now={25}/>
                            </td>
                            <td> $ 77.99 </td>
                            <td className="text-danger"> 53.64% <i className="mdi mdi-arrow-down"></i>
                            </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td className="font-weight-medium"> 2 </td>
                            <td> Messsy Adam </td>
                            <td>
                              <ProgressBar variant="danger" striped now={75}/>
                            </td>
                            <td> $245.30 </td>
                            <td className="text-success"> 24.56% <i className="mdi mdi-arrow-up"></i>
                            </td>
                            <td> July 1, 2015 </td>
                          </tr>
                          <tr>
                            <td className="font-weight-medium"> 3 </td>
                            <td> John Doe </td>
                            <td>
                              <ProgressBar variant="warning" striped now={90}/>
                            </td>
                            <td> $138.00 </td>
                            <td className="text-danger"> 28.76% <i className="mdi mdi-arrow-down"></i>
                            </td>
                            <td> Apr 12, 2015 </td>
                          </tr>
                          <tr>
                            <td className="font-weight-medium"> 4 </td>
                            <td> Peter Meggik </td>
                            <td>
                              <ProgressBar variant="primary" striped now={50}/>
                            </td>
                            <td> $ 77.99 </td>
                            <td className="text-danger"> 53.45% <i className="mdi mdi-arrow-down"></i>
                            </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td className="font-weight-medium"> 5 </td>
                            <td> Edward </td>
                            <td>
                              <ProgressBar variant="danger" striped now={35}/>
                            </td>
                            <td> $ 160.25 </td>
                            <td className="text-success"> 18.32% <i className="mdi mdi-arrow-up"></i>
                            </td>
                            <td> May 03, 2015 </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-4">Manage Tickets</h5>
                    <div className="fluid-container">
                      <div className="row ticket-card mt-3 pb-2 border-bottom pb-3 mb-3">
                        <div className="col-md-1">
                          <img className="img-sm rounded-circle mb-4 mb-md-0 d-block mx-md-auto" src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile" /> </div>
                        <div className="ticket-details col-md-9">
                          <div className="d-lg-flex">
                            <p className="text-dark font-weight-semibold mr-2 mb-0 no-wrap">James :</p>
                            <p className="text-primary mr-1 mb-0">[#23047]</p>
                            <p className="mb-0 ellipsis">Donec rutrum congue leo eget malesuada.</p>
                          </div>
                          <p className="text-gray ellipsis mb-2">Donec rutrum congue leo eget malesuada. Quisque velit nisi, pretium ut lacinia in, elementum id enim vivamus. </p>
                          <div className="row text-gray d-md-flex d-none">
                            <div className="col-4 d-flex">
                              <small className="mb-0 mr-2 text-muted text-muted">Last responded :</small>
                              <small className="Last-responded mr-2 mb-0 text-muted text-muted">3 hours ago</small>
                            </div>
                            <div className="col-4 d-flex">
                              <small className="mb-0 mr-2 text-muted text-muted">Due in :</small>
                              <small className="Last-responded mr-2 mb-0 text-muted text-muted">2 Days</small>
                            </div>
                          </div>
                        </div>
                        <div className="ticket-actions col-md-2">
                          <div className="btn-group dropdown">
                            <Dropdown>
                              <Dropdown.Toggle className="btn btn-success btn-sm">
                                Manage
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="navbar-dropdown preview-list">
                                <Dropdown.Item>
                                  Quick reply
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Another action
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Resolve Issue
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Close Issue
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="row ticket-card mt-3 pb-2 border-bottom pb-3 mb-3">
                        <div className="col-md-1">
                          <img className="img-sm rounded-circle mb-4 mb-md-0 d-block mx-md-auto" src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile" /> </div>
                        <div className="ticket-details col-md-9">
                          <div className="d-lg-flex">
                            <p className="text-dark font-weight-semibold mr-2 mb-0 no-wrap">Stella :</p>
                            <p className="text-primary mr-1 mb-0">[#23135]</p>
                            <p className="mb-0 ellipsis">Curabitur aliquet quam id dui posuere blandit.</p>
                          </div>
                          <p className="text-gray ellipsis mb-2">Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Curabitur non nulla sit amet nisl. </p>
                          <div className="row text-gray d-md-flex d-none">
                            <div className="col-4 d-flex">
                              <small className="mb-0 mr-2 text-muted">Last responded :</small>
                              <small className="Last-responded mr-2 mb-0 text-muted">3 hours ago</small>
                            </div>
                            <div className="col-4 d-flex">
                              <small className="mb-0 mr-2 text-muted">Due in :</small>
                              <small className="Last-responded mr-2 mb-0 text-muted">2 Days</small>
                            </div>
                          </div>
                        </div>
                        <div className="ticket-actions col-md-2">
                          <div className="btn-group dropdown">
                            <Dropdown>
                              <Dropdown.Toggle className="btn btn-success btn-sm">
                                Manage
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="navbar-dropdown preview-list">
                                <Dropdown.Item>
                                  Quick reply
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Another action
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Resolve Issue
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Close Issue
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="row ticket-card mt-3">
                        <div className="col-md-1">
                          <img className="img-sm rounded-circle mb-4 mb-md-0 d-block mx-md-auto" src={require("../../assets/images/core/faces/placeholder.jpg")} alt="profile" /> </div>
                        <div className="ticket-details col-md-9">
                          <div className="d-lg-flex">
                            <p className="text-dark font-weight-semibold mr-2 mb-0 no-wrap">John Doe :</p>
                            <p className="text-primary mr-1 mb-0">[#23246]</p>
                            <p className="mb-0 ellipsis">Mauris blandit aliquet elit, eget tincidunt nibh pulvinar.</p>
                          </div>
                          <p className="text-gray ellipsis mb-2">Nulla quis lorem ut libero malesuada feugiat. Proin eget tortor risus. Lorem ipsum dolor sit amet.</p>
                          <div className="row text-gray d-md-flex d-none">
                            <div className="col-4 d-flex">
                              <small className="mb-0 mr-2 text-muted">Last responded :</small>
                              <small className="Last-responded mr-2 mb-0 text-muted">3 hours ago</small>
                            </div>
                            <div className="col-4 d-flex">
                              <small className="mb-0 mr-2 text-muted">Due in :</small>
                              <small className="Last-responded mr-2 mb-0 text-muted">2 Days</small>
                            </div>
                          </div>
                        </div>
                        <div className="ticket-actions col-md-2">
                          <div className="btn-group dropdown">
                            <Dropdown>
                              <Dropdown.Toggle className="btn btn-success dropdown-toggle btn-sm">
                                Manage
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="navbar-dropdown preview-list">
                                <Dropdown.Item>
                                  Quick reply
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Another action
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Resolve Issue
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  Close Issue
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>   
          <h2>Form elements</h2>
          <div>
            <div>
              <h3 className="page-title"> Form elements </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Forms</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Form elements</li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Default form</h4>
                    <p className="card-description"> Basic form layout </p>
                    <form className="forms-sample">
                      <Form.Group>
                        <label htmlFor="exampleInputUsername1">Username</label>
                        <Form.Control type="text" id="exampleInputUsername1" placeholder="Username" size="lg" />
                      </Form.Group>
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <Form.Control type="email" className="form-control" id="exampleInputEmail1" placeholder="Email" />
                      </Form.Group>
                      <Form.Group>
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <Form.Control type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                      </Form.Group>
                      <Form.Group>
                        <label htmlFor="exampleInputConfirmPassword1">Confirm Password</label>
                        <Form.Control type="password" className="form-control" id="exampleInputConfirmPassword1" placeholder="Password" />
                      </Form.Group>
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input"/>
                          <i className="input-helper"></i>
                          Remember me
                        </label>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Submit</button>
                      <button className="btn btn-light">Cancel</button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Horizontal Form</h4>
                    <p className="card-description"> Horizontal form layout </p>
                    <form className="forms-sample">
                      <Form.Group className="row">
                        <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Email</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="exampleInputUsername2" placeholder="Username" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="exampleInputEmail2" className="col-sm-3 col-form-label">Email</label>
                        <div className="col-sm-9">
                        <Form.Control type="email" className="form-control" id="exampleInputEmail2" placeholder="Email" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="exampleInputMobile" className="col-sm-3 col-form-label">Mobile</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" className="form-control" id="exampleInputMobile" placeholder="Mobile number" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Password</label>
                        <div className="col-sm-9">
                        <Form.Control type="password" className="form-control" id="exampleInputPassword2" placeholder="Password" />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <label htmlFor="exampleInputConfirmPassword2" className="col-sm-3 col-form-label">Re Password</label>
                        <div className="col-sm-9">
                        <Form.Control type="password" className="form-control" id="exampleInputConfirmPassword2" placeholder="Password" />
                        </div>
                      </Form.Group>
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input"/>
                          <i className="input-helper"></i>
                          Remember me
                        </label>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Submit</button>
                      <button className="btn btn-light">Cancel</button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Basic form elements</h4>
                    <p className="card-description"> Basic form elements </p>
                    <form className="forms-sample">
                      <Form.Group>
                        <label htmlFor="exampleInputName1">Name</label>
                        <Form.Control type="text" className="form-control" id="exampleInputName1" placeholder="Name" />
                      </Form.Group>
                      <Form.Group>
                        <label htmlFor="exampleInputEmail3">Email address</label>
                        <Form.Control type="email" className="form-control" id="exampleInputEmail3" placeholder="Email" />
                      </Form.Group>
                      <Form.Group>
                        <label htmlFor="exampleInputPassword4">Password</label>
                        <Form.Control type="password" className="form-control" id="exampleInputPassword4" placeholder="Password" />
                        </Form.Group>
                        <Form.Group>
                        <label htmlFor="exampleSelectGender">Gender</label>
                        <select className="form-control" id="exampleSelectGender">
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                      </Form.Group>
                      <Form.Group>
                        <label>File upload</label>
                        <Form.Control type="file" name="img[]" className="file-upload-default" />
                        <div className="input-group col-xs-12">
                          <Form.Control type="text" className="form-control file-upload-info" disabled placeholder="Upload Image" /> 
                          <span className="input-group-append">
                            <button className="file-upload-browse btn btn-primary" type="button">Upload</button>
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <label htmlFor="exampleInputCity1">City</label>
                        <Form.Control type="text" className="form-control" id="exampleInputCity1" placeholder="Location" />
                      </Form.Group>
                      <Form.Group>
                        <label htmlFor="exampleTextarea1">Textarea</label>
                        <textarea className="form-control" id="exampleTextarea1" rows="4"></textarea>
                      </Form.Group>
                      <button type="submit" className="btn btn-primary mr-2">Submit</button>
                      <button className="btn btn-light">Cancel</button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Searchable select example</h4>
                    <form className="forms-sample">
                      <Form.Group>
                        <label htmlFor="exampleSelectGender">Gender</label>
                        <SearchableSelect className="form-control" id="exampleSelectGender">
                          <option value="">All Ganders</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </SearchableSelect>
                      </Form.Group>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Input size</h4>
                    <p className="card-description"> Add classNames like <code>.form-control-lg</code> and <code>.form-control-sm</code>. </p>
                    <Form.Group>
                      <label>Large input</label>
                      <Form.Control type="text" className="form-control-lg" placeholder="Username" aria-label="Username" />
                    </Form.Group>
                    <Form.Group>
                      <label>Default input</label>
                      <Form.Control type="text" className="form-control" placeholder="Username" aria-label="Username" />
                    </Form.Group>
                    <Form.Group>
                      <label>Small input</label>
                      <Form.Control type="text" className="form-control-sm" placeholder="Username" aria-label="Username" />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Select size</h4>
                    <p className="card-description"> Add classNamees like <code>.form-control-lg</code> and <code>.form-control-sm</code>. </p>
                    <Form.Group>
                      <label htmlFor="exampleFormControlSelect1">Large select</label>
                      <select className="form-control form-control-lg" id="exampleFormControlSelect1">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleFormControlSelect2">Default select</label>
                      <select className="form-control" id="exampleFormControlSelect2">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleFormControlSelect3">Small select</label>
                      <select className="form-control form-control-sm" id="exampleFormControlSelect3">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Basic input groups</h4>
                    <p className="card-description"> Basic bootstrap input groups </p>
                    <Form.Group>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">@</span>
                        </div>
                        <Form.Control type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-primary text-white">$</span>
                        </div>
                        <Form.Control type="text" className="form-control" aria-label="Amount (to the nearest dollar)" />
                        <div className="input-group-append">
                          <span className="input-group-text">.00</span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text">0.00</span>
                        </div>
                        <Form.Control type="text" className="form-control" aria-label="Amount (to the nearest dollar)" />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="input-group">
                      <Form.Control type="text" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                          <button className="btn btn-sm btn-primary" type="button">Search</button>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button className="btn btn-sm btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</button>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Action</a>
                            <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Another action</a>
                            <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Something else here</a>
                            <div role="separator" className="dropdown-divider"></div>
                            <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Separated link</a>
                          </div>
                        </div>
                        <Form.Control type="text" className="form-control" aria-label="Text input with dropdown button" />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="input-group">
                        <Form.Control type="text" className="form-control" placeholder="Find in facebook" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                          <div className="input-group-append">
                            <button className="btn btn-sm btn-facebook" type="button">
                              <i className="mdi mdi-facebook"></i>
                            </button>
                          </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Checkbox Controls</h4>
                    <p className="card-description">Checkbox and radio controls (default appearance is in primary color)</p>
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="checkbox" className="form-check-input"/>
                                <i className="input-helper"></i>
                                Default
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="checkbox" defaultChecked className="form-check-input"/>
                                <i className="input-helper"></i>
                                Checked
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="checkbox" disabled className="form-check-input"/>
                                <i className="input-helper"></i>
                                Disabled
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="checkbox" disabled defaultChecked className="form-check-input"/>
                                <i className="input-helper"></i>
                                Disabled checked
                              </label>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value=""/>
                                <i className="input-helper"></i>
                                Default
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2" defaultChecked/>
                                <i className="input-helper"></i>
                                Selected
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="optionsRadios2" id="optionsRadios3" value="option3" disabled/>
                                <i className="input-helper"></i>
                                Disabled
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="optionsRadios2" id="optionsRadios4" value="option4" disabled defaultChecked/>
                                <i className="input-helper"></i>
                                Selected and disabled 
                              </label>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-body">
                    <p className="card-description">Add className <code>.form-check-&#123;color&#123;</code> for checkbox and radio controls in theme colors</p>
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group>
                            <div className="form-check form-check-primary">
                              <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" defaultChecked /> Primary 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-success">
                              <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" defaultChecked /> Success 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-info">
                              <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" defaultChecked /> Info 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-danger">
                              <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" defaultChecked /> Danger 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-warning">
                              <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" defaultChecked /> Warning 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group>
                            <div className="form-check form-check-primary">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="ExampleRadio1" id="ExampleRadio1" defaultChecked /> Primary 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-success">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="ExampleRadio2" id="ExampleRadio2" defaultChecked /> Success 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-info">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="ExampleRadio3" id="ExampleRadio3" defaultChecked /> Info 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-danger">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="ExampleRadio4" id="ExampleRadio4" defaultChecked /> Danger 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            <div className="form-check form-check-warning">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="ExampleRadio5" id="ExampleRadio5" defaultChecked /> Warning 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Inline forms</h4>
                    <p className="card-description"> Use the <code>.form-inline</code> className to display a series of labels, form controls, and buttons on a single horizontal row </p>
                    <form className="form-inline">
                      <label className="sr-only" htmlFor="inlineFormInputName2">Name</label>
                      <Form.Control  type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Jane Doe" />
                      <label className="sr-only" htmlFor="inlineFormInputGroupUsername2">Username</label>
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">@</div>
                        </div>
                        <Form.Control  type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Username" />
                      </div>
                      <div className="form-check mx-sm-2">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input" defaultChecked/> Remember me 
                          <i className="input-helper"></i>
                        </label>
                      </div>
                      <button type="submit" className="btn btn-primary mb-2">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Horizontal Two column</h4>
                    <form className="form-sample">
                      <p className="card-description"> Personal info </p>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">First Name</label>
                            <div className="col-sm-9">
                            <Form.Control  type="text" />
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Last Name</label>
                            <div className="col-sm-9">
                            <Form.Control type="text" />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Gender</label>
                            <div className="col-sm-9">
                              <select className="form-control">
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Date of Birth</label>
                            <div className="col-sm-9">
                            <DatePicker className="form-control w-100"
                              selected={this.state.startDate}
                              onChange={this.handleChange}
                            />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Category</label>
                            <div className="col-sm-9">
                              <select className="form-control">
                                <option>Category1</option>
                                <option>Category2</option>
                                <option>Category3</option>
                                <option>Category4</option>
                              </select>
                            </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Membership</label>
                            <div className="col-sm-4">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="ExampleRadio4" id="membershipRadios1" defaultChecked /> Free 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            </div>
                            <div className="col-sm-5">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="ExampleRadio4" id="membershipRadios2" /> Proffessional 
                                <i className="input-helper"></i>
                              </label>
                            </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <p className="card-description"> Address </p>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Address 1</label>
                            <div className="col-sm-9">
                            <Form.Control type="text"/>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">State 1</label>
                            <div className="col-sm-9">
                            <Form.Control type="text"/>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Address 2</label>
                            <div className="col-sm-9">
                            <Form.Control type="text"/>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Postcode</label>
                            <div className="col-sm-9">
                            <Form.Control type="text"/>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="row">
                            <label className="col-sm-3 col-form-label">Cirt</label>
                            <div className="col-sm-9">
                            <Form.Control type="text"/>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="row">  
                            <label className="col-sm-3 col-form-label">Country</label>
                            <div className="col-sm-9">
                              <select className="form-control">
                                <option>America</option>
                                <option>Italy</option>
                                <option>Russia</option>
                                <option>Britain</option>
                              </select>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>Font Awesome</h2>
          <div>
            <div>
              <h3 className="page-title">
                Font awesome
              </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Icons</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Font awesome</li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">New Icons</h4>
                    <div className="icons-list row">
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-address-book"></i>fa fa-address-book </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-address-book-o"></i>fa fa-address-book-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-address-card"></i>fa fa-address-card </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-address-card-o"></i>fa fa-address-card-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-bandcamp"></i>fa fa-bandcamp </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-bath"></i>fa fa-bath </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-bathtub"></i>fa fa-bathtub </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-drivers-license"></i>fa fa-drivers-license </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-drivers-license-o"></i>fa fa-drivers-license-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-eercast"></i>fa fa-eercast </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-envelope-open"></i>fa fa-envelope-open </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-envelope-open-o"></i>fa fa-envelope-open-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-etsy"></i>fa fa-etsy </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-free-code-camp"></i>fa fa-free-code-camp </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-grav"></i>fa fa-grav </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-handshake-o"></i>fa fa-handshake-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-id-badge"></i>fa fa-id-badge </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-id-card"></i>fa fa-id-card </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-id-card-o"></i>fa fa-id-card-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-imdb"></i>fa fa-imdb </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-linode"></i> fa fa-linode </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-meetup"></i>fa fa-meetup </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-microchip"></i>fa fa-microchip </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-podcast"></i>fa fa-podcast </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-quora"></i>fa fa-quora </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-ravelry"></i>fa fa-ravelry </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-s15"></i>fa fa-s15 </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-shower"></i>fa fa-shower </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-snowflake-o"></i>fa fa-snowflake-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-superpowers"></i>fa fa-superpowers </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-telegram"></i>fa fa-telegram </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer"></i>fa fa-thermometer </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-0"></i>fa fa-thermometer-0 </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-1"></i>fa fa-thermometer-1 </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-2"></i>fa fa-thermometer-2 </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-3"></i>fa fa-thermometer-3 </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-4"></i>fa fa-thermometer-4 </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-empty"></i>fa fa-thermometer-empty </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-full"></i>fa fa-thermometer-full </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-half"></i>fa fa-thermometer-half </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-quarter"></i>fa fa-thermometer-quarter </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-thermometer-three-quarters"></i>fa fa-thermometer-three-quarters
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-times-rectangle"></i>fa fa-times-rectangle </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-times-rectangle-o"></i>fa fa-times-rectangle-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-user-circle"></i>fa fa-user-circle </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-user-circle-o"></i>fa fa-user-circle-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-user-o"></i>fa fa-user-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-vcard"></i>fa fa-vcard </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-vcard-o"></i>fa fa-vcard-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-window-close"></i>fa fa-window-close </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-window-close-o"></i>fa fa-window-close-o </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-window-maximize"></i>fa fa-window-maximize </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-window-minimize"></i>fa fa-window-minimize </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-window-restore"></i>fa fa-window-restore </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"> <i className="fa fa-wpexplorer"></i>fa fa-wpexplorer </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Web Application Icons</h4>
                    <div className="icons-list row">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-adjust"></i> fa-adjust</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-anchor"></i> fa-anchor</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-archive"></i> fa-archive</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows"></i> fa-arrows</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-h"></i> fa-arrows-h</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-v"></i> fa-arrows-v</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-asterisk"></i> fa-asterisk</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-automobile"></i> fa-automobile <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ban"></i> fa-ban</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bank"></i> fa-bank <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bar-chart-o"></i> fa-bar-chart-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-barcode"></i> fa-barcode</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bars"></i> fa-bars</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-beer"></i> fa-beer</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bell"></i> fa-bell</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bell-o"></i> fa-bell-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bolt"></i> fa-bolt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bomb"></i> fa-bomb</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-book"></i> fa-book</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bookmark"></i> fa-bookmark</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bookmark-o"></i> fa-bookmark-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-briefcase"></i> fa-briefcase</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bug"></i> fa-bug</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-building"></i> fa-building</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-building-o"></i> fa-building-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bullhorn"></i> fa-bullhorn</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bullseye"></i> fa-bullseye</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cab"></i> fa-cab <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-calendar"></i> fa-calendar</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-calendar-o"></i> fa-calendar-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-camera"></i> fa-camera</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-camera-retro"></i> fa-camera-retro</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-car"></i> fa-car</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-down"></i> fa-caret-square-o-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-left"></i> fa-caret-square-o-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-right"></i> fa-caret-square-o-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-up"></i> fa-caret-square-o-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-certificate"></i> fa-certificate</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check"></i> fa-check</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-circle"></i> fa-check-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-circle-o"></i> fa-check-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-square"></i> fa-check-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-square-o"></i> fa-check-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-child"></i> fa-child</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle"></i> fa-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle-o"></i> fa-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle-o-notch"></i> fa-circle-o-notch</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle-thin"></i> fa-circle-thin</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-clock-o"></i> fa-clock-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cloud"></i> fa-cloud</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cloud-download"></i> fa-cloud-download</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cloud-upload"></i> fa-cloud-upload</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-code"></i> fa-code</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-code-fork"></i> fa-code-fork</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-coffee"></i> fa-coffee</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cog"></i> fa-cog</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cogs"></i> fa-cogs</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comment"></i> fa-comment</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comment-o"></i> fa-comment-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comments"></i> fa-comments</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comments-o"></i> fa-comments-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-compass"></i> fa-compass</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-credit-card"></i> fa-credit-card</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-crop"></i> fa-crop</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-crosshairs"></i> fa-crosshairs</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cube"></i> fa-cube</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cubes"></i> fa-cubes</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cutlery"></i> fa-cutlery</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dashboard"></i> fa-dashboard <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-database"></i> fa-database</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-desktop"></i> fa-desktop</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dot-circle-o"></i> fa-dot-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-download"></i> fa-download</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-edit"></i> fa-edit <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ellipsis-h"></i> fa-ellipsis-h</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ellipsis-v"></i> fa-ellipsis-v</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-envelope"></i> fa-envelope</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-envelope-o"></i> fa-envelope-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-envelope-square"></i> fa-envelope-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eraser"></i> fa-eraser</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exchange"></i> fa-exchange</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exclamation"></i> fa-exclamation</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exclamation-circle"></i> fa-exclamation-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exclamation-triangle"></i> fa-exclamation-triangle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-external-link"></i> fa-external-link</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-external-link-square"></i> fa-external-link-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eye"></i> fa-eye</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eye-slash"></i> fa-eye-slash</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fax"></i> fa-fax</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-female"></i> fa-female</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fighter-jet"></i> fa-fighter-jet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-archive-o"></i> fa-file-archive-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-audio-o"></i> fa-file-audio-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-code-o"></i> fa-file-code-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-excel-o"></i> fa-file-excel-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-image-o"></i> fa-file-image-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-movie-o"></i> fa-file-movie-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-pdf-o"></i> fa-file-pdf-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-photo-o"></i> fa-file-photo-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-picture-o"></i> fa-file-picture-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-powerpoint-o"></i> fa-file-powerpoint-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-sound-o"></i> fa-file-sound-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-video-o"></i> fa-file-video-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-word-o"></i> fa-file-word-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-zip-o"></i> fa-file-zip-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-film"></i> fa-film</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-filter"></i> fa-filter</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fire"></i> fa-fire</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fire-extinguisher"></i> fa-fire-extinguisher</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flag"></i> fa-flag</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flag-checkered"></i> fa-flag-checkered</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flag-o"></i> fa-flag-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flash"></i> fa-flash <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flask"></i> fa-flask</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder"></i> fa-folder</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder-o"></i> fa-folder-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder-open"></i> fa-folder-open</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder-open-o"></i> fa-folder-open-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-frown-o"></i> fa-frown-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gamepad"></i> fa-gamepad</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gavel"></i> fa-gavel</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gear"></i> fa-gear <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gears"></i> fa-gears <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gift"></i> fa-gift</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-glass"></i> fa-glass</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-globe"></i> fa-globe</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-graduation-cap"></i> fa-graduation-cap</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-group"></i> fa-group <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hdd-o"></i> fa-hdd-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-headphones"></i> fa-headphones</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-heart"></i> fa-heart</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-heart-o"></i> fa-heart-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-history"></i> fa-history</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-home"></i> fa-home</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-image"></i> fa-image <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-inbox"></i> fa-inbox</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-info"></i> fa-info</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-info-circle"></i> fa-info-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-institution"></i> fa-institution <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-key"></i> fa-key</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-keyboard-o"></i> fa-keyboard-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-language"></i> fa-language</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-laptop"></i> fa-laptop</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-leaf"></i> fa-leaf</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-legal"></i> fa-legal <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-lemon-o"></i> fa-lemon-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-level-down"></i> fa-level-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-level-up"></i> fa-level-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-life-bouy"></i> fa-life-bouy <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-life-ring"></i> fa-life-ring</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-life-saver"></i> fa-life-saver <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-lightbulb-o"></i> fa-lightbulb-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-location-arrow"></i> fa-location-arrow</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-lock"></i> fa-lock</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-magic"></i> fa-magic</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-magnet"></i> fa-magnet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mail-forward"></i> fa-mail-forward <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mail-reply"></i> fa-mail-reply <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mail-reply-all"></i> fa-mail-reply-all <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-male"></i> fa-male</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-map-marker"></i> fa-map-marker</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-meh-o"></i> fa-meh-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-microphone"></i> fa-microphone</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-microphone-slash"></i> fa-microphone-slash</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus"></i> fa-minus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-circle"></i> fa-minus-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-square"></i> fa-minus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-square-o"></i> fa-minus-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mobile"></i> fa-mobile</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mobile-phone"></i> fa-mobile-phone <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-money"></i> fa-money</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-moon-o"></i> fa-moon-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mortar-board"></i> fa-mortar-board <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-music"></i> fa-music</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-navicon"></i> fa-navicon <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paper-plane"></i> fa-paper-plane</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paper-plane-o"></i> fa-paper-plane-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paw"></i> fa-paw</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pencil"></i> fa-pencil</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pencil-square"></i> fa-pencil-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pencil-square-o"></i> fa-pencil-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-phone"></i> fa-phone</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-phone-square"></i> fa-phone-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-photo"></i> fa-photo <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-picture-o"></i> fa-picture-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plane"></i> fa-plane</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus"></i> fa-plus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-circle"></i> fa-plus-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-square"></i> fa-plus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-square-o"></i> fa-plus-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-power-off"></i> fa-power-off</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-print"></i> fa-print</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-puzzle-piece"></i> fa-puzzle-piece</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-qrcode"></i> fa-qrcode</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-question"></i> fa-question</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-question-circle"></i> fa-question-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-quote-left"></i> fa-quote-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-quote-right"></i> fa-quote-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-random"></i> fa-random</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-recycle"></i> fa-recycle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sync"></i> fa-sync</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reorder"></i> fa-reorder <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reply"></i> fa-reply</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reply-all"></i> fa-reply-all</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-retweet"></i> fa-retweet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-road"></i> fa-road</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rocket"></i> fa-rocket</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rss"></i> fa-rss</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rss-square"></i> fa-rss-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-search"></i> fa-search</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-search-minus"></i> fa-search-minus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-search-plus"></i> fa-search-plus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-send"></i> fa-send <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-send-o"></i> fa-send-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share"></i> fa-share</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-alt"></i> fa-share-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-alt-square"></i> fa-share-alt-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-square"></i> fa-share-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-square-o"></i> fa-share-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-shield"></i> fa-shield</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-shopping-cart"></i> fa-shopping-cart</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sign-in"></i> fa-sign-in</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sign-out"></i> fa-sign-out</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-signal"></i> fa-signal</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sitemap"></i> fa-sitemap</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sliders"></i> fa-sliders</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-smile-o"></i> fa-smile-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort"></i> fa-sort</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-alpha-asc"></i> fa-sort-alpha-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-alpha-desc"></i> fa-sort-alpha-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-amount-asc"></i> fa-sort-amount-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-amount-desc"></i> fa-sort-amount-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-asc"></i> fa-sort-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-desc"></i> fa-sort-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-down"></i> fa-sort-down <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-numeric-asc"></i> fa-sort-numeric-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-numeric-desc"></i> fa-sort-numeric-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-up"></i> fa-sort-up <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-space-shuttle"></i> fa-space-shuttle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spinner"></i> fa-spinner</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spoon"></i> fa-spoon</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-square"></i> fa-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-square-o"></i> fa-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star"></i> fa-star</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half"></i> fa-star-half</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half-empty"></i> fa-star-half-empty <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half-full"></i> fa-star-half-full <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half-o"></i> fa-star-half-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-o"></i> fa-star-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-suitcase"></i> fa-suitcase</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sun-o"></i> fa-sun-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-support"></i> fa-support <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tablet"></i> fa-tablet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tachometer"></i> fa-tachometer</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tag"></i> fa-tag</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tags"></i> fa-tags</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tasks"></i> fa-tasks</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-taxi"></i> fa-taxi</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-terminal"></i> fa-terminal</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumb-tack"></i> fa-thumb-tack</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-down"></i> fa-thumbs-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-o-down"></i> fa-thumbs-o-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-o-up"></i> fa-thumbs-o-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-up"></i> fa-thumbs-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ticket"></i> fa-ticket</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-times"></i> fa-times</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-times-circle"></i> fa-times-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-times-circle-o"></i> fa-times-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tint"></i> fa-tint</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-down"></i> fa-toggle-down <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-left"></i> fa-toggle-left <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-right"></i> fa-toggle-right <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-up"></i> fa-toggle-up <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-trash-o"></i> fa-trash-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tree"></i> fa-tree</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-trophy"></i> fa-trophy</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-truck"></i> fa-truck</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-umbrella"></i> fa-umbrella</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-university"></i> fa-university</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-unlock"></i> fa-unlock</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-unlock-alt"></i> fa-unlock-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-unsorted"></i> fa-unsorted <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-upload"></i> fa-upload</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-user"></i> fa-user</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-users"></i> fa-users</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-video-camera"></i> fa-video-camera</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-volume-down"></i> fa-volume-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-volume-off"></i> fa-volume-off</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-volume-up"></i> fa-volume-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-warning"></i> fa-warning <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-wheelchair"></i> fa-wheelchair</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-wrench"></i> fa-wrench</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">File Type Icons</h4>
                    <div className="icons-list row">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-adjust"></i> fa-adjust</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-anchor"></i> fa-anchor</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-archive"></i> fa-archive</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows"></i> fa-arrows</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-h"></i> fa-arrows-h</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-v"></i> fa-arrows-v</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-asterisk"></i> fa-asterisk</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-automobile"></i> fa-automobile <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ban"></i> fa-ban</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bank"></i> fa-bank <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bar-chart-o"></i> fa-bar-chart-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-barcode"></i> fa-barcode</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bars"></i> fa-bars</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-beer"></i> fa-beer</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bell"></i> fa-bell</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bell-o"></i> fa-bell-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bolt"></i> fa-bolt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bomb"></i> fa-bomb</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-book"></i> fa-book</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bookmark"></i> fa-bookmark</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bookmark-o"></i> fa-bookmark-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-briefcase"></i> fa-briefcase</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bug"></i> fa-bug</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-building"></i> fa-building</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-building-o"></i> fa-building-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bullhorn"></i> fa-bullhorn</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bullseye"></i> fa-bullseye</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cab"></i> fa-cab <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-calendar"></i> fa-calendar</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-calendar-o"></i> fa-calendar-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-camera"></i> fa-camera</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-camera-retro"></i> fa-camera-retro</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-car"></i> fa-car</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-down"></i> fa-caret-square-o-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-left"></i> fa-caret-square-o-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-right"></i> fa-caret-square-o-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-up"></i> fa-caret-square-o-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-certificate"></i> fa-certificate</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check"></i> fa-check</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-circle"></i> fa-check-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-circle-o"></i> fa-check-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-square"></i> fa-check-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-square-o"></i> fa-check-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-child"></i> fa-child</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle"></i> fa-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle-o"></i> fa-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle-o-notch"></i> fa-circle-o-notch</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle-thin"></i> fa-circle-thin</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-clock-o"></i> fa-clock-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cloud"></i> fa-cloud</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cloud-download"></i> fa-cloud-download</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cloud-upload"></i> fa-cloud-upload</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-code"></i> fa-code</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-code-fork"></i> fa-code-fork</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-coffee"></i> fa-coffee</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cog"></i> fa-cog</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cogs"></i> fa-cogs</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comment"></i> fa-comment</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comment-o"></i> fa-comment-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comments"></i> fa-comments</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-comments-o"></i> fa-comments-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-compass"></i> fa-compass</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-credit-card"></i> fa-credit-card</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-crop"></i> fa-crop</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-crosshairs"></i> fa-crosshairs</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cube"></i> fa-cube</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cubes"></i> fa-cubes</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cutlery"></i> fa-cutlery</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dashboard"></i> fa-dashboard <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-database"></i> fa-database</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-desktop"></i> fa-desktop</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dot-circle-o"></i> fa-dot-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-download"></i> fa-download</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-edit"></i> fa-edit <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ellipsis-h"></i> fa-ellipsis-h</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ellipsis-v"></i> fa-ellipsis-v</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-envelope"></i> fa-envelope</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-envelope-o"></i> fa-envelope-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-envelope-square"></i> fa-envelope-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eraser"></i> fa-eraser</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exchange"></i> fa-exchange</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exclamation"></i> fa-exclamation</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exclamation-circle"></i> fa-exclamation-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-exclamation-triangle"></i> fa-exclamation-triangle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-external-link"></i> fa-external-link</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-external-link-square"></i> fa-external-link-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eye"></i> fa-eye</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eye-slash"></i> fa-eye-slash</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fax"></i> fa-fax</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-female"></i> fa-female</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fighter-jet"></i> fa-fighter-jet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-archive-o"></i> fa-file-archive-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-audio-o"></i> fa-file-audio-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-code-o"></i> fa-file-code-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-excel-o"></i> fa-file-excel-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-image-o"></i> fa-file-image-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-movie-o"></i> fa-file-movie-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-pdf-o"></i> fa-file-pdf-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-photo-o"></i> fa-file-photo-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-picture-o"></i> fa-file-picture-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-powerpoint-o"></i> fa-file-powerpoint-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-sound-o"></i> fa-file-sound-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-video-o"></i> fa-file-video-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-word-o"></i> fa-file-word-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-zip-o"></i> fa-file-zip-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-film"></i> fa-film</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-filter"></i> fa-filter</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fire"></i> fa-fire</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fire-extinguisher"></i> fa-fire-extinguisher</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flag"></i> fa-flag</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flag-checkered"></i> fa-flag-checkered</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flag-o"></i> fa-flag-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flash"></i> fa-flash <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flask"></i> fa-flask</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder"></i> fa-folder</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder-o"></i> fa-folder-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder-open"></i> fa-folder-open</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-folder-open-o"></i> fa-folder-open-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-frown-o"></i> fa-frown-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gamepad"></i> fa-gamepad</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gavel"></i> fa-gavel</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gear"></i> fa-gear <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gears"></i> fa-gears <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gift"></i> fa-gift</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-glass"></i> fa-glass</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-globe"></i> fa-globe</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-graduation-cap"></i> fa-graduation-cap</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-group"></i> fa-group <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hdd-o"></i> fa-hdd-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-headphones"></i> fa-headphones</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-heart"></i> fa-heart</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-heart-o"></i> fa-heart-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-history"></i> fa-history</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-home"></i> fa-home</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-image"></i> fa-image <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-inbox"></i> fa-inbox</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-info"></i> fa-info</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-info-circle"></i> fa-info-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-institution"></i> fa-institution <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-key"></i> fa-key</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-keyboard-o"></i> fa-keyboard-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-language"></i> fa-language</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-laptop"></i> fa-laptop</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-leaf"></i> fa-leaf</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-legal"></i> fa-legal <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-lemon-o"></i> fa-lemon-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-level-down"></i> fa-level-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-level-up"></i> fa-level-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-life-bouy"></i> fa-life-bouy <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-life-ring"></i> fa-life-ring</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-life-saver"></i> fa-life-saver <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-lightbulb-o"></i> fa-lightbulb-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-location-arrow"></i> fa-location-arrow</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-lock"></i> fa-lock</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-magic"></i> fa-magic</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-magnet"></i> fa-magnet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mail-forward"></i> fa-mail-forward <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mail-reply"></i> fa-mail-reply <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mail-reply-all"></i> fa-mail-reply-all <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-male"></i> fa-male</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-map-marker"></i> fa-map-marker</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-meh-o"></i> fa-meh-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-microphone"></i> fa-microphone</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-microphone-slash"></i> fa-microphone-slash</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus"></i> fa-minus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-circle"></i> fa-minus-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-square"></i> fa-minus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-square-o"></i> fa-minus-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mobile"></i> fa-mobile</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mobile-phone"></i> fa-mobile-phone <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-money"></i> fa-money</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-moon-o"></i> fa-moon-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-mortar-board"></i> fa-mortar-board <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-music"></i> fa-music</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-navicon"></i> fa-navicon <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paper-plane"></i> fa-paper-plane</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paper-plane-o"></i> fa-paper-plane-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paw"></i> fa-paw</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pencil"></i> fa-pencil</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pencil-square"></i> fa-pencil-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pencil-square-o"></i> fa-pencil-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-phone"></i> fa-phone</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-phone-square"></i> fa-phone-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-photo"></i> fa-photo <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-picture-o"></i> fa-picture-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plane"></i> fa-plane</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus"></i> fa-plus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-circle"></i> fa-plus-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-square"></i> fa-plus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-square-o"></i> fa-plus-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-power-off"></i> fa-power-off</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-print"></i> fa-print</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-puzzle-piece"></i> fa-puzzle-piece</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-qrcode"></i> fa-qrcode</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-question"></i> fa-question</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-question-circle"></i> fa-question-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-quote-left"></i> fa-quote-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-quote-right"></i> fa-quote-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-random"></i> fa-random</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-recycle"></i> fa-recycle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sync"></i> fa-sync</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reorder"></i> fa-reorder <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reply"></i> fa-reply</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reply-all"></i> fa-reply-all</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-retweet"></i> fa-retweet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-road"></i> fa-road</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rocket"></i> fa-rocket</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rss"></i> fa-rss</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rss-square"></i> fa-rss-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-search"></i> fa-search</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-search-minus"></i> fa-search-minus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-search-plus"></i> fa-search-plus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-send"></i> fa-send <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-send-o"></i> fa-send-o <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share"></i> fa-share</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-alt"></i> fa-share-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-alt-square"></i> fa-share-alt-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-square"></i> fa-share-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-square-o"></i> fa-share-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-shield"></i> fa-shield</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-shopping-cart"></i> fa-shopping-cart</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sign-in"></i> fa-sign-in</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sign-out"></i> fa-sign-out</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-signal"></i> fa-signal</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sitemap"></i> fa-sitemap</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sliders"></i> fa-sliders</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-smile-o"></i> fa-smile-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort"></i> fa-sort</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-alpha-asc"></i> fa-sort-alpha-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-alpha-desc"></i> fa-sort-alpha-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-amount-asc"></i> fa-sort-amount-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-amount-desc"></i> fa-sort-amount-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-asc"></i> fa-sort-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-desc"></i> fa-sort-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-down"></i> fa-sort-down <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-numeric-asc"></i> fa-sort-numeric-asc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-numeric-desc"></i> fa-sort-numeric-desc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sort-up"></i> fa-sort-up <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-space-shuttle"></i> fa-space-shuttle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spinner"></i> fa-spinner</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spoon"></i> fa-spoon</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-square"></i> fa-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-square-o"></i> fa-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star"></i> fa-star</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half"></i> fa-star-half</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half-empty"></i> fa-star-half-empty <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half-full"></i> fa-star-half-full <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-half-o"></i> fa-star-half-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-star-o"></i> fa-star-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-suitcase"></i> fa-suitcase</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-sun-o"></i> fa-sun-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-support"></i> fa-support <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tablet"></i> fa-tablet</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tachometer"></i> fa-tachometer</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tag"></i> fa-tag</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tags"></i> fa-tags</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tasks"></i> fa-tasks</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-taxi"></i> fa-taxi</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-terminal"></i> fa-terminal</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumb-tack"></i> fa-thumb-tack</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-down"></i> fa-thumbs-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-o-down"></i> fa-thumbs-o-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-o-up"></i> fa-thumbs-o-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-thumbs-up"></i> fa-thumbs-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ticket"></i> fa-ticket</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-times"></i> fa-times</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-times-circle"></i> fa-times-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-times-circle-o"></i> fa-times-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tint"></i> fa-tint</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-down"></i> fa-toggle-down <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-left"></i> fa-toggle-left <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-right"></i> fa-toggle-right <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-up"></i> fa-toggle-up <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-trash-o"></i> fa-trash-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tree"></i> fa-tree</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-trophy"></i> fa-trophy</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-truck"></i> fa-truck</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-umbrella"></i> fa-umbrella</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-university"></i> fa-university</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-unlock"></i> fa-unlock</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-unlock-alt"></i> fa-unlock-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-unsorted"></i> fa-unsorted <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-upload"></i> fa-upload</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-user"></i> fa-user</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-users"></i> fa-users</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-video-camera"></i> fa-video-camera</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-volume-down"></i> fa-volume-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-volume-off"></i> fa-volume-off</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-volume-up"></i> fa-volume-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-warning"></i> fa-warning <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-wheelchair"></i> fa-wheelchair</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-wrench"></i> fa-wrench</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Spinner icons</h4>
                    <div className="alert alert-success mb-4">
                      These icons work great with the <code>fa-spin</code> class. Check out the <a href="http://fontawesome.io/examples/#animated" className="alert-link">spinning icons example</a>.
                    </div>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spin fa-circle-o-notch"></i> fa-circle-o-notch</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spin fa-cog"></i> fa-cog</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spin fa-gear"></i> fa-gear <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spin fa-sync"></i> fa-sync</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spin fa-spinner"></i> fa-spinner</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Form Control Icons</h4>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-square"></i> fa-check-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-check-square-o"></i> fa-check-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle"></i> fa-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-circle-o"></i> fa-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dot-circle-o"></i> fa-dot-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-square"></i> fa-minus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-minus-square-o"></i> fa-minus-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-square"></i> fa-plus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-square-o"></i> fa-plus-square-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-square"></i> fa-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-square-o"></i> fa-square-o</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Currency Icons</h4>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bitcoin"></i> fa-bitcoin <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-btc"></i> fa-btc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cny"></i> fa-cny <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dollar"></i> fa-dollar <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eur"></i> fa-eur</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-euro"></i> fa-euro <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gbp"></i> fa-gbp</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-inr"></i> fa-inr</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-jpy"></i> fa-jpy</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-krw"></i> fa-krw</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-money"></i> fa-money</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rmb"></i> fa-rmb <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rouble"></i> fa-rouble <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rub"></i> fa-rub</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ruble"></i> fa-ruble <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rupee"></i> fa-rupee <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-try"></i> fa-try</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-turkish-lira"></i> fa-turkish-lira <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-usd"></i> fa-usd</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-won"></i> fa-won <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-yen"></i> fa-yen <span className="text-muted">(alias)</span> </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Text Editor Icons</h4>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-align-center"></i> fa-align-center</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-align-justify"></i> fa-align-justify</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-align-left"></i> fa-align-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-align-right"></i> fa-align-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bold"></i> fa-bold</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chain"></i> fa-chain <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chain-broken"></i> fa-chain-broken</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-clipboard"></i> fa-clipboard</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-columns"></i> fa-columns</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-copy"></i> fa-copy <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-cut"></i> fa-cut <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dedent"></i> fa-dedent <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eraser"></i> fa-eraser</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file"></i> fa-file</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-o"></i> fa-file-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-text"></i> fa-file-text</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-file-text-o"></i> fa-file-text-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-files-o"></i> fa-files-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-floppy-o"></i> fa-floppy-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-font"></i> fa-font</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-header"></i> fa-header</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-indent"></i> fa-indent</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-italic"></i> fa-italic</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-link"></i> fa-link</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-list"></i> fa-list</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-list-alt"></i> fa-list-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-list-ol"></i> fa-list-ol</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-list-ul"></i> fa-list-ul</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-outdent"></i> fa-outdent</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paperclip"></i> fa-paperclip</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paragraph"></i> fa-paragraph</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-paste"></i> fa-paste <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-repeat"></i> fa-repeat</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rotate-left"></i> fa-rotate-left <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rotate-right"></i> fa-rotate-right <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-save"></i> fa-save <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-scissors"></i> fa-scissors</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-strikethrough"></i> fa-strikethrough</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-subscript"></i> fa-subscript</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-superscript"></i> fa-superscript</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-table"></i> fa-table</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-text-height"></i> fa-text-height</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-text-width"></i> fa-text-width</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-th"></i> fa-th</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-th-large"></i> fa-th-large</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-th-list"></i> fa-th-list</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-underline"></i> fa-underline</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-undo"></i> fa-undo</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-unlink"></i> fa-unlink <span className="text-muted">(alias)</span> </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Directional Icons</h4>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-double-down"></i> fa-angle-double-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-double-left"></i> fa-angle-double-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-double-right"></i> fa-angle-double-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-double-up"></i> fa-angle-double-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-down"></i> fa-angle-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-left"></i> fa-angle-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-right"></i> fa-angle-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-angle-up"></i> fa-angle-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-down"></i> fa-arrow-circle-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-left"></i> fa-arrow-circle-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-o-down"></i> fa-arrow-circle-o-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-o-left"></i> fa-arrow-circle-o-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-o-right"></i> fa-arrow-circle-o-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-o-up"></i> fa-arrow-circle-o-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-right"></i> fa-arrow-circle-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-circle-up"></i> fa-arrow-circle-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-down"></i> fa-arrow-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-left"></i> fa-arrow-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-right"></i> fa-arrow-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrow-up"></i> fa-arrow-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows"></i> fa-arrows</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-alt"></i> fa-arrows-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-h"></i> fa-arrows-h</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-v"></i> fa-arrows-v</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-down"></i> fa-caret-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-left"></i> fa-caret-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-right"></i> fa-caret-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-up"></i> fa-caret-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-left"></i> fa-caret-square-o-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-right"></i> fa-caret-square-o-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-up"></i> fa-caret-square-o-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-caret-square-o-down"></i> fa-caret-square-o-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-circle-down"></i> fa-chevron-circle-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-circle-left"></i> fa-chevron-circle-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-circle-right"></i> fa-chevron-circle-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-circle-up"></i> fa-chevron-circle-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-down"></i> fa-chevron-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-left"></i> fa-chevron-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-right"></i> fa-chevron-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-chevron-up"></i> fa-chevron-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hand-o-down"></i> fa-hand-o-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hand-o-left"></i> fa-hand-o-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hand-o-right"></i> fa-hand-o-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hand-o-up"></i> fa-hand-o-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-long-arrow-down"></i> fa-long-arrow-down</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-long-arrow-left"></i> fa-long-arrow-left</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-long-arrow-right"></i> fa-long-arrow-right</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-long-arrow-up"></i> fa-long-arrow-up</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-down"></i> fa-toggle-down <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-left"></i> fa-toggle-left <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-right"></i> fa-toggle-right <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-toggle-up"></i> fa-toggle-up <span className="text-muted">(alias)</span> </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Video Player Icons</h4>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-arrows-alt"></i> fa-arrows-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-backward"></i> fa-backward</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-compress"></i> fa-compress</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-eject"></i> fa-eject</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-expand"></i> fa-expand</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fast-backward"></i> fa-fast-backward</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-fast-forward"></i> fa-fast-forward</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-forward"></i> fa-forward</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pause"></i> fa-pause</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-play"></i> fa-play</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-play-circle"></i> fa-play-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-play-circle-o"></i> fa-play-circle-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-step-backward"></i> fa-step-backward</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-step-forward"></i> fa-step-forward</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-stop"></i> fa-stop</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-youtube-play"></i> fa-youtube-play</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Brand Icons</h4>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-adn"></i> fa-adn</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-android"></i> fa-android</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-apple"></i> fa-apple</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-behance"></i> fa-behance</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-behance-square"></i> fa-behance-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bitbucket"></i> fa-bitbucket</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bitbucket-square"></i> fa-bitbucket-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-bitcoin"></i> fa-bitcoin <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-btc"></i> fa-btc</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-codepen"></i> fa-codepen</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-css3"></i> fa-css3</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-delicious"></i> fa-delicious</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-deviantart"></i> fa-deviantart</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-digg"></i> fa-digg</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dribbble"></i> fa-dribbble</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-dropbox"></i> fa-dropbox</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-drupal"></i> fa-drupal</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-empire"></i> fa-empire</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-facebook"></i> fa-facebook</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-facebook-square"></i> fa-facebook-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-flickr"></i> fa-flickr</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-foursquare"></i> fa-foursquare</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ge"></i> fa-ge <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-git"></i> fa-git</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-git-square"></i> fa-git-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-github"></i> fa-github</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-github-alt"></i> fa-github-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-github-square"></i> fa-github-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-gittip"></i> fa-gittip</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-google"></i> fa-google</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-google-plus"></i> fa-google-plus</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-google-plus-square"></i> fa-google-plus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hacker-news"></i> fa-hacker-news</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-html5"></i> fa-html5</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-instagram"></i> fa-instagram</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-joomla"></i> fa-joomla</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-jsfiddle"></i> fa-jsfiddle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-linkedin"></i> fa-linkedin</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-linkedin-square"></i> fa-linkedin-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-linux"></i> fa-linux</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-maxcdn"></i> fa-maxcdn</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-openid"></i> fa-openid</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pagelines"></i> fa-pagelines</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pied-piper"></i> fa-pied-piper</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pied-piper-alt"></i> fa-pied-piper-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pinterest"></i> fa-pinterest</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-pinterest-square"></i> fa-pinterest-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-qq"></i> fa-qq</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ra"></i> fa-ra <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-rebel"></i> fa-rebel</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reddit"></i> fa-reddit</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-reddit-square"></i> fa-reddit-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-renren"></i> fa-renren</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-alt"></i> fa-share-alt</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-share-alt-square"></i> fa-share-alt-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-skype"></i> fa-skype</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-slack"></i> fa-slack</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-soundcloud"></i> fa-soundcloud</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-spotify"></i> fa-spotify</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-stack-exchange"></i> fa-stack-exchange</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-stack-overflow"></i> fa-stack-overflow</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-steam"></i> fa-steam</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-steam-square"></i> fa-steam-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-stumbleupon"></i> fa-stumbleupon</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-stumbleupon-circle"></i> fa-stumbleupon-circle</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tencent-weibo"></i> fa-tencent-weibo</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-trello"></i> fa-trello</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tumblr"></i> fa-tumblr</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-tumblr-square"></i> fa-tumblr-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-twitter"></i> fa-twitter</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-twitter-square"></i> fa-twitter-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-vimeo-square"></i> fa-vimeo-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-vine"></i> fa-vine</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-vk"></i> fa-vk</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-wechat"></i> fa-wechat <span className="text-muted">(alias)</span> </div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-weibo"></i> fa-weibo</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-weixin"></i> fa-weixin</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-windows"></i> fa-windows</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-wordpress"></i> fa-wordpress</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-xing"></i> fa-xing</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-xing-square"></i> fa-xing-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-yahoo"></i> fa-yahoo</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-youtube"></i> fa-youtube</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-youtube-play"></i> fa-youtube-play</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-youtube-square"></i> fa-youtube-square</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Medical Icons</h4>
                    <div className="row icons-list">
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-ambulance"></i> fa-ambulance</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-h-square"></i> fa-h-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-hospital-o"></i> fa-hospital-o</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-medkit"></i> fa-medkit</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-plus-square"></i> fa-plus-square</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-stethoscope"></i> fa-stethoscope</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-user-md"></i> fa-user-md</div>
                      <div className="col-sm-6 col-md-4 col-lg-3"><i className="fa fa-wheelchair"></i> fa-wheelchair</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>Basic table</h2>
          <div>
            <div>
              <h3 className="page-title"> Basic Tables </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Tables</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Basic tables</li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Basic Table</h4>
                    <p className="card-description"> Add className <code>.table</code>
                    </p>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Profile</th>
                            <th>VatNo.</th>
                            <th>Created</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Jacob</td>
                            <td>53275531</td>
                            <td>12 May 2017</td>
                            <td><label className="badge badge-danger">Pending</label></td>
                          </tr>
                          <tr>
                            <td>Messsy</td>
                            <td>53275532</td>
                            <td>15 May 2017</td>
                            <td><label className="badge badge-warning">In progress</label></td>
                          </tr>
                          <tr>
                            <td>John</td>
                            <td>53275533</td>
                            <td>14 May 2017</td>
                            <td><label className="badge badge-info">Fixed</label></td>
                          </tr>
                          <tr>
                            <td>Peter</td>
                            <td>53275534</td>
                            <td>16 May 2017</td>
                            <td><label className="badge badge-success">Completed</label></td>
                          </tr>
                          <tr>
                            <td>Dave</td>
                            <td>53275535</td>
                            <td>20 May 2017</td>
                            <td><label className="badge badge-warning">In progress</label></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Hoverable Table</h4>
                    <p className="card-description"> Add className <code>.table-hover</code>
                    </p>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Product</th>
                            <th>Sale</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Jacob</td>
                            <td>Photoshop</td>
                            <td className="text-danger"> 28.76% <i className="mdi mdi-arrow-down"></i></td>
                            <td><label className="badge badge-danger">Pending</label></td>
                          </tr>
                          <tr>
                            <td>Messsy</td>
                            <td>Flash</td>
                            <td className="text-danger"> 21.06% <i className="mdi mdi-arrow-down"></i></td>
                            <td><label className="badge badge-warning">In progress</label></td>
                          </tr>
                          <tr>
                            <td>John</td>
                            <td>Premier</td>
                            <td className="text-danger"> 35.00% <i className="mdi mdi-arrow-down"></i></td>
                            <td><label className="badge badge-info">Fixed</label></td>
                          </tr>
                          <tr>
                            <td>Peter</td>
                            <td>After effects</td>
                            <td className="text-success"> 82.00% <i className="mdi mdi-arrow-up"></i></td>
                            <td><label className="badge badge-success">Completed</label></td>
                          </tr>
                          <tr>
                            <td>Dave</td>
                            <td>53275535</td>
                            <td className="text-success"> 98.05% <i className="mdi mdi-arrow-up"></i></td>
                            <td><label className="badge badge-warning">In progress</label></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Striped Table</h4>
                    <p className="card-description"> Add className <code>.table-striped</code>
                    </p>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th> User </th>
                            <th> First name </th>
                            <th> Progress </th>
                            <th> Amount </th>
                            <th> Deadline </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-1">
                              <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="user icon" />
                            </td>
                            <td> Herman Beck </td>
                            <td>
                              <ProgressBar variant="success" now={25} />
                            </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td className="py-1">
                              <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="user icon" />
                            </td>
                            <td> Messsy Adam </td>
                            <td>
                              <ProgressBar variant="danger" now={75} />
                            </td>
                            <td> $245.30 </td>
                            <td> July 1, 2015 </td>
                          </tr>
                          <tr>
                            <td className="py-1">
                              <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="user icon" />
                            </td>
                            <td> John Richards </td>
                            <td>
                              <ProgressBar variant="warning" now={90} />
                            </td>
                            <td> $138.00 </td>
                            <td> Apr 12, 2015 </td>
                          </tr>
                          <tr>
                            <td className="py-1">
                              <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="user icon" />
                            </td>
                            <td> Peter Meggik </td>
                            <td>
                              <ProgressBar variant="primary" now={50} />
                            </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td className="py-1">
                              <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="user icon" />
                            </td>
                            <td> Edward </td>
                            <td>
                              <ProgressBar variant="danger" now={60} />
                            </td>
                            <td> $ 160.25 </td>
                            <td> May 03, 2015 </td>
                          </tr>
                          <tr>
                            <td className="py-1">
                              <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="user icon" />
                            </td>
                            <td> John Doe </td>
                            <td>
                              <ProgressBar variant="info" now={65} />
                            </td>
                            <td> $ 123.21 </td>
                            <td> April 05, 2015 </td>
                          </tr>
                          <tr>
                            <td className="py-1">
                              <img src={require("../../assets/images/core/faces/placeholder.jpg")} alt="user icon" />
                            </td>
                            <td> Henry Tom </td>
                            <td>
                              <ProgressBar variant="warning" now={20} />
                            </td>
                            <td> $ 150.00 </td>
                            <td> June 16, 2015 </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Bordered table</h4>
                    <p className="card-description"> Add className <code>.table-bordered</code>
                    </p>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th> # </th>
                            <th> First name </th>
                            <th> Progress </th>
                            <th> Amount </th>
                            <th> Deadline </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> 1 </td>
                            <td> Herman Beck </td>
                            <td>
                              <ProgressBar variant="success" now={25} />
                            </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td> 2 </td>
                            <td> Messsy Adam </td>
                            <td>
                              <ProgressBar variant="danger" now={75} />
                            </td>
                            <td> $245.30 </td>
                            <td> July 1, 2015 </td>
                          </tr>
                          <tr>
                            <td> 3 </td>
                            <td> John Richards </td>
                            <td>
                              <ProgressBar variant="warning" now={90} />
                            </td>
                            <td> $138.00 </td>
                            <td> Apr 12, 2015 </td>
                          </tr>
                          <tr>
                            <td> 4 </td>
                            <td> Peter Meggik </td>
                            <td>
                              <ProgressBar variant="primary" now={50} />
                            </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td> 5 </td>
                            <td> Edward </td>
                            <td>
                              <ProgressBar variant="danger" now={35} />
                            </td>
                            <td> $ 160.25 </td>
                            <td> May 03, 2015 </td>
                          </tr>
                          <tr>
                            <td> 6 </td>
                            <td> John Doe </td>
                            <td>
                              <ProgressBar variant="info" now={65} />
                            </td>
                            <td> $ 123.21 </td>
                            <td> April 05, 2015 </td>
                          </tr>
                          <tr>
                            <td> 7 </td>
                            <td> Henry Tom </td>
                            <td>
                            <ProgressBar now={60} />
                              <ProgressBar variant="warning" now={20} />
                            </td>
                            <td> $ 150.00 </td>
                            <td> June 16, 2015 </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Inverse table</h4>
                    <p className="card-description"> Add className <code>.table-dark</code>
                    </p>
                    <div className="table-responsive">
                      <table className="table table-dark">
                        <thead>
                          <tr>
                            <th> # </th>
                            <th> First name </th>
                            <th> Amount </th>
                            <th> Deadline </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> 1 </td>
                            <td> Herman Beck </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td> 2 </td>
                            <td> Messsy Adam </td>
                            <td> $245.30 </td>
                            <td> July 1, 2015 </td>
                          </tr>
                          <tr>
                            <td> 3 </td>
                            <td> John Richards </td>
                            <td> $138.00 </td>
                            <td> Apr 12, 2015 </td>
                          </tr>
                          <tr>
                            <td> 4 </td>
                            <td> Peter Meggik </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr>
                            <td> 5 </td>
                            <td> Edward </td>
                            <td> $ 160.25 </td>
                            <td> May 03, 2015 </td>
                          </tr>
                          <tr>
                            <td> 6 </td>
                            <td> John Doe </td>
                            <td> $ 123.21 </td>
                            <td> April 05, 2015 </td>
                          </tr>
                          <tr>
                            <td> 7 </td>
                            <td> Henry Tom </td>
                            <td> $ 150.00 </td>
                            <td> June 16, 2015 </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Table with contextual classNames</h4>
                    <p className="card-description"> Add className <code>.table-&#123;color&#125;</code>
                    </p>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th> # </th>
                            <th> First name </th>
                            <th> Product </th>
                            <th> Amount </th>
                            <th> Deadline </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="table-info">
                            <td> 1 </td>
                            <td> Herman Beck </td>
                            <td> Photoshop </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr className="table-warning">
                            <td> 2 </td>
                            <td> Messsy Adam </td>
                            <td> Flash </td>
                            <td> $245.30 </td>
                            <td> July 1, 2015 </td>
                          </tr>
                          <tr className="table-danger">
                            <td> 3 </td>
                            <td> John Richards </td>
                            <td> Premeire </td>
                            <td> $138.00 </td>
                            <td> Apr 12, 2015 </td>
                          </tr>
                          <tr className="table-success">
                            <td> 4 </td>
                            <td> Peter Meggik </td>
                            <td> After effects </td>
                            <td> $ 77.99 </td>
                            <td> May 15, 2015 </td>
                          </tr>
                          <tr className="table-primary">
                            <td> 5 </td>
                            <td> Edward </td>
                            <td> Illustrator </td>
                            <td> $ 160.25 </td>
                            <td> May 03, 2015 </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div> 
        </div>
      </Fragment>
    );
  }
}

const ListItem = (props) => {
  return (
    <li className={(props.isCompleted ? 'completed' : null)}>
      <div className="form-check form-check-success m-0 align-items-start">
        <label htmlFor="" className="form-check-label font-weight-medium"> 
          <input className="checkbox" type="checkbox" checked={props.isCompleted} onChange={props.changed}/>
          {props.children}
          <i className="input-helper"></i>
        </label>
      </div>
      <i className="remove mdi mdi-close-circle-outline" onClick={props.remove}></i>
    </li>
  )
};

export default DemoUIElements;