// import { extendObservable } from 'mobx';

// class Settings {
//   constructor() {
//     extendObservable(this, {
//       type: '',
//       title: '',
//       platformId: '',
//       icon: '',
//       info: []
//     });
//   }
// }

// export default new Settings();

import { observable, extendObservable, makeObservable } from 'mobx';

class Settings {
  // active = false;
  type = '';
  title = '';
  platformId = '';
  icon = '';
  info = [];
  
  constructor() {
    makeObservable(this, {
      type: observable,
      title: observable,
      platformId: observable,
      icon: observable,
      info: observable
    });
  }
  
  update (prop, value) {
    this[prop] = value;
  }
  
  
  // hide () {
  //   this.active = false;
  // }
  
}

export default new Settings();