import React, { Component } from 'react';

import FooterExtension from '../../app/shared/Footer';

class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <FooterExtension />
        </div>
      </footer>
    );
  }
}

export default Footer;