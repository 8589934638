import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import User from '../stores/User';
import HeaderV2 from '../../core/shared/HeaderV2';
import { _t } from '../stores/Translator.js';

class Recover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.match.params.userId,
      token: props.match.params.token,
      password: '',
      rpassword: '',
      error: '',
    };
  }
  
  async componentDidMount() {
    
  }
  
  submit = async () => {
    try {
      if (this.state.password !== this.state.rpassword) {
        this.setState({
          error: _t('Passwords do not match.')
        });
        return;
      }
      this.setState({
        error: ''
      });
      let result = await (await fetch('/api/user/change-password', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: this.state.userId,
          token: this.state.token,
          password: this.state.password,
          rpassword: this.state.rpassword
        })
      })).json();
      if (result.status == 'success') {
        this.props.history.push('/login');
      } else {
        this.setState({
          error: result.message
        });
      }
    } catch (err) {
      this.setState({
        error: err.toString()
      });
    }
  }
  
  handleChange = (e, field) => {
    let state = this.state;
    state[field] = e.target.value;
    this.setState(state);
  }
  
  render() {
    return (
      <div className="wy-login">
        <HeaderV2 />
        <div className="bg-image d-flex vertical-center align-items-center">
          <div className="d-flex align-items-center auth px-2 py-5 mx-auto mt-7">
            <div className="row w-100 mx-0">
              <div className="col-lg-8 mx-auto flex-reset">
                <div className="auth-form-light text-left my-10 py-5 px-4 px-sm-5">
                  <h1 className="text-center">{_t('Change Password')}</h1>
                  <p className="error">{this.state.error}</p>
                  <Form>
                    <Form.Group className="d-flex search-field border-bottom position-relative">
                      <Form.Control type="password" placeholder={_t('Password')} size="lg" className="h-auto border-0" value={this.state.password} onChange={e => this.handleChange(e, 'password')} />
                    </Form.Group>
                    <Form.Group className="d-flex search-field border-bottom">
                      <Form.Control type="password" placeholder={_t('Repeat Password')} size="lg" className="h-auto border-0" value={this.state.rpassword} onChange={e => this.handleChange(e, 'rpassword')} />
                    </Form.Group>
                    <Form.Group className="mt-3 d-flex justify-content-center align-items-center">
                      <Form.Control type="button" value={_t('Submit')} className="btn btn-primary btn-lg font-weight-medium auth-form-bt blue-bg" onClick={this.submit} />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    );
  }
}

export default Recover;
