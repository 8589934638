import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, Modal, Button, Dropdown, Pagination, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import Navigation from '../../core/shared/NavigationV2';
import { ListPreferences } from '../utils/ListPreferences';
import Preferences from '../../core/stores/Preferences';
import { Info, Warnings, Errors } from '../../core/utils/Notices';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import Loader from '../../core/stores/Loader';
import moment from "moment";
import Env from '../../core/stores/Env';
import { EditSearchModal } from './EditSearch';
import { _t } from '../../core/stores/Translator.js';

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/searches',
      list: [],
      total: 0,
      rows: 80,
      page: 1,
      offset: 3,
      selected: [],
      search: '',
      errors: [],
      warnings: [],
      info: [],
      locations: [],
      categories: [],
      manufacturers: [],
      models: [],
      countries: [],
      regions: [],
      districts: [],
      cities: [],
      quarters: [],
      showColumnSettings: false,
      showEditSearchModal: false,
      editTitle: '',
      sort: {
        column: 'createdAt',
        direction: 'DESC'
      },
    };
  }
  
  updateList = async () => {
    Loader.show();
    this.setState({
      errors: []
    }, async () => {
      try {
        let [response] = await Promise.all([
          (await fetch(`/api/searches/v2`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              rows: this.state.rows,
              offset: this.state.rows * (this.state.page - 1),
              keywords: this.state.search,
              sort: this.state.sort
            })
          })).json()
        ]);
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.setState({
          total: response.data.total,
          list: response.data.items
        });
      } catch (err) {
        this.setState({
          errors: this.state.errors.concat(err.toString())
        });
      }
      Loader.hide();
    });
  }
  
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props !== nextProps) {
      this.updateList();
    }
  }
  
  getLocations = async () => {
    try {
      let response = await (await fetch(`/locations.json`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        locations: response
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getCategories = async () => {
    try {
      let response = await (await fetch(`/api/categories`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        categories: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getManufacturers = async () => {
    try {
      let response = await (await fetch(`/api/manufacturers`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        manufacturers: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getModels = async () => {
    try {
      let response = await (await fetch(`/api/models`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        models: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getCountries = async () => {
    try {
      let response = await (await fetch(`/api/countries`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        countries: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getRegions = async () => {
    try {
      let response = await (await fetch(`/api/regions`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        regions: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getDistricts = async () => {
    try {
      let response = await (await fetch(`/api/districts`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        districts: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }

  getCities = async () => {
    try {
      let response = await (await fetch(`/api/cities`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        cities: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  getQuarters = async () => {
    try {
      let response = await (await fetch(`/api/quarters`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'wy-api-key': 'Oni8kpYaiBzvtFEzBjvDWWzDYQ2b1NB5RaVMWHJE'
        }
      })).json();
      this.setState({
        quarters: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await Promise.all([
      this.getLocations(),
      this.getCountries(),
      this.getRegions(),
      this.getDistricts(),
      this.getCities(),
      this.getQuarters(),
      this.getCategories(),
      this.getManufacturers(),
      this.getModels()
    ]);
    await this.updateList();
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  onSelectChange = (e, searchId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(searchId)) {
        this.setState({
          selected: this.state.selected.concat([searchId])
        });
      }
    } else {
      if (this.state.selected.includes(searchId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== searchId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.searchId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = async () => {
    /*await this.updateList();*/
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.searchId)).map(item => item.searchId);
    this.setState({
      selected: selected,
    });
  }
  
  applyFilters = (list) => {
    return list.filter(item => {
        return (
          item.keywords.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
        );
      });
  }
  
  onPaginationClick = async (e, page) => {
    if (page === this.state.page) {
      return;
    }
    this.setState({
      page: page
    }, () => {
      this.updateList();
    });
  }
  
  remove = async (e) => {
    Loader.show();
    e.preventDefault();
    try {
      let selected = this.state.selected;
      let response = await (await fetch('/api/searches/remove/v2', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          searchIds: selected
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: this.state.list.filter(item => !selected.includes(item.searchId)),
        selected: []
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  updateState = async (e, state) => {
    e.preventDefault();
    try {
      let selected = this.state.selected;
      let response = await (await fetch('/api/searches/update/state/v2', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          searchIds: selected,
          state: state
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        selected: []
      }, this.updateList);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  searchKeyDown = async (e) => {
    switch (e.keyCode) {
      case 13:
        clearTimeout(this.state.typeTimeout);
        this.setState({
          page: 1
        }, () => {
          this.updateList();
        });
      break;
      default:
        if (
          (e.keyCode === 8 && this.state.search.length > 0) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          (e.keyCode === 32 || e.keyCode === 13) ||
          (e.keyCode > 64 && e.keyCode < 91) ||
          (e.keyCode > 95 && e.keyCode < 112) ||
          (e.keyCode > 185 && e.keyCode < 193) ||
          (e.keyCode > 218 && e.keyCode < 223)
        ) {
          if (this.state.typeTimeout) {
            clearTimeout(this.state.typeTimeout);
          }
          this.setState({
            typeTimeout: setTimeout(() => {
              this.updateList();
            }, 1000)
          });
        }
      break;
    }
  }
  
  readable = (input) => {
    if (Number.isInteger(input)) {
      return input.toLocaleString();
    } else {
      return null;
    }
  }
  
  sort = (column, direction) => {
    let sort = {...this.state.sort};
    sort.column = column;
    sort.direction = direction;
    this.setState({
      sort: sort
    }, this.updateList);
  }
  
  render () {
    let routes = {};
    routes[_t('Searches')] = '/searches';
    return (
      <Fragment>
        <Navigation routes={routes}>
          <Dropdown>
            <Dropdown.Toggle variant="btn btn-outline-primary mr-2" disabled={this.state.selected.length === 0}>{_t('Actions')}</Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={e => this.updateState(e, 'inactive')}>{_t('Pause')}</Dropdown.Item>
              <Dropdown.Divider className="m-0"></Dropdown.Divider>
              <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={e => this.updateState(e, 'active')}>{_t('Resume')}</Dropdown.Item>
              <Dropdown.Divider className="m-0"></Dropdown.Divider>
              <Dropdown.Item className="pt-3 pb-3 text-decoration-none text-reset" onClick={this.remove}>{_t('Remove')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button type="button" className="btn btn-success" onClick={e => {
            this.setState({
              showEditSearchModal: true,
              editTitle: _t('New Search'),
              editSearch: {
                agreement: 0,
                buy: 1,
                categories: [],
                manufacturers: [],
                models: [],
                districts: [],
                keywords: '',
                locations: [],
                categoryId: 0,
                matchCategory: 0,
                matchDescription: 1,
                matchKeywords: 0,
                matchLocation: 0,
                minPrice: 0,
                maxPrice: 10,
                rent: 0,
                new: 1,
                used: 1,
                category: 0,
                withImage: 0,
                useMaxPrice: 0,
                useMinPrice: 0,
                state: 'active'
              }
            });
          }}>
            <i className="fa fa-plus mr-2"></i>
            {_t('New Search')}
          </button>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <Form.Group className="col-lg-12 grid-margin stretch-card">
              <Form.Control type="text" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
            </Form.Group>
          </div>
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-10 grid-margin stretch-card text-right wy-db-pagination">
                      <Pagination>
                        {(() => {
                          let rows = [];
                          if (this.state.page > this.state.offset + 1) {
                            rows.push(<Pagination.First onClick={e => this.onPaginationClick(e, 1)} />);
                          }
                          if (this.state.page > 1) {
                            rows.push(<Pagination.Prev onClick={e => this.onPaginationClick(e, this.state.page - 1)} />);
                          }
                          for (let i = this.state.page - this.state.offset, l = this.state.page; i < l; i++) {
                            if (i > 0) {
                              rows.push(<Pagination.Item onClick={e => this.onPaginationClick(e, i)} >{i}</Pagination.Item>);
                            }
                          }
                          return rows;
                        })()}
                        <Pagination.Item active>{this.state.page}</Pagination.Item>
                        {(() => {
                          let rows = [];
                          for (let i = this.state.page + 1, l = this.state.page + this.state.offset + 2; i < l; i++) {
                            if (i <= Math.ceil(this.state.total / this.state.rows)) {
                              if (i > this.state.page + this.state.offset) {
                                rows.push(<Pagination.Next onClick={e => this.onPaginationClick(e, this.state.page + 1)} />);
                                rows.push(<Pagination.Last onClick={e => this.onPaginationClick(e, Math.ceil(this.state.total / this.state.rows))} />);
                                break;
                              }
                              rows.push(<Pagination.Item onClick={e => this.onPaginationClick(e, i)} >{i}</Pagination.Item>);
                            }
                          }
                          return rows;
                        })()}
                      </Pagination>
                    </div>
                    <div className="col-2 stretch-card text-right wy-db-list-settings">
                      <i className="fa fa-play mr-2 text-success"onClick={e => this.updateState(e, 'active')} style={{
                        pointerEvents: this.state.selected.length === 0 ? 'none' : 'all',
                        opacity: this.state.selected.length === 0 ? '0.2' : '0.5'
                      }}></i>
                      <i className="fa fa-pause mr-3 text-warning" onClick={e => this.updateState(e, 'inactive')} style={{
                        pointerEvents: this.state.selected.length === 0 ? 'none' : 'all',
                        opacity: this.state.selected.length === 0 ? '0.2' : '0.5',
                      }}></i>
                      <i className="fa fa-sync" onClick={this.updateList}></i>
                      {/*<i className="fa fa-pencil" onClick={() => { this.setState({ showColumnSettings: true }) }}></i>*/}
                    </div>
                  </div>
                  <div className="table-responsive table-fix-head" style={{ maxHeight: isMobile ? 'calc(100vh - 300px)' : `calc(100vh - (${300 + (this.state.errors.length * 50) + (this.state.warnings.length * 50) + (this.state.info.length * 50)}px)` }}>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th style={{zIndex: "1"}}>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          {Preferences['searches.columns']['Search'].enabled &&
                            <th>{_t('Search')}</th>
                          }
                          {Preferences['searches.columns']['Category'].enabled &&
                          <th>{_t('Category')}</th>
                          }
                          {Preferences['searches.columns']['Keywords'].enabled &&
                            <th>{_t('Keywords')}</th>
                          }
                          {Preferences['searches.columns']['State'].enabled &&
                            <th>{_t('State')}</th>
                          }
                          {Preferences['searches.columns']['Match Description'].enabled &&
                            <th>{_t('Match Description')}</th>
                          }
                          {Preferences['searches.columns']['Exchange Type'].enabled &&
                            <th>{_t('Exchange Type')}</th>
                          }
                          {Preferences['searches.columns']['Min. Price'].enabled &&
                            <th>{_t('Min. Price')}</th>
                          }
                          {Preferences['searches.columns']['Max. Price'].enabled &&
                            <th>{_t('Max. Price')}</th>
                          }
                          {Preferences['searches.columns']['Categories'].enabled &&
                            <th>{_t('Categories')}</th>
                          }
                          {Preferences['searches.columns']['Locations'].enabled &&
                            <th>{_t('Locations')}</th>
                          }
                          {Preferences['searches.columns']['Date Created'].enabled &&
                            <th>{_t('Date Created')}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => {
                          return (
                            <tr key={item.searchId} className={this.state.selected.includes(item.searchId) ? 'table-primary' : ''}>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.searchId)} onChange={e => this.onSelectChange(e, item.searchId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              {Preferences['searches.columns']['Search'].enabled &&
                                <td>
                                  <button className="btn btn-link" onClick={e => { this.setState({ showEditSearchModal: true, editSearch: item, editTitle: `${_t('Editing Search:')} ${item.searchId}` }) }}>
                                    {item.category === 1 &&
                                      <span>
                                        {item.title &&
                                          <span>{item.title}</span>
                                        }
                                        {!item.title &&
                                          <span>{_t('Realestate')}</span>
                                        }
                                      </span>
                                    }
                                    {item.category === 2 &&
                                      <span>
                                        {item.title &&
                                          <span>{item.title}</span>
                                        }
                                        {!item.title &&
                                          <span>{item.manufacturers.slice(0, 3).map(manufacturerId => {
                                            let manufacturer = this.state.manufacturers.find(manufacturer => manufacturer.manufacturerId === Number(manufacturerId));
                                            return manufacturer ? manufacturer.name : '';
                                          }).join(', ')} </span>
                                        }
                                      </span>
                                    }
                                    {item.category === 3 &&
                                      <span>
                                        {item.title &&
                                          <span>{item.title}</span>
                                        }
                                        {!item.title &&
                                          <span>{item.matchKeywords ? item.keywords : _t('No Keywords')} </span>
                                        }
                                      </span>
                                    }
                                  </button>
                                </td>
                              }
                              {Preferences['searches.columns']['Category'].enabled &&
                                <td>
                                  {item.category === 1 &&
                                    <span>{_t('Realestate')} </span>
                                  }
                                  {item.category === 2 &&
                                    <span>{_t('Automotive')} </span>
                                  }
                                  {item.category === 3 &&
                                    <span>{_t('Keywords')} </span>
                                  }
                                </td>
                              }
                              {Preferences['searches.columns']['Keywords'].enabled &&
                                <td>
                                  {item.category === 3 &&
                                    <span>{item.matchKeywords ? item.keywords : _t('No Keywords')} </span>
                                  }
                                </td>
                              }
                              {Preferences['searches.columns']['State'].enabled &&
                                <td className={item.state === 'active' ? 'text-success' : 'text-warning'}>{_t(`${item.state.charAt(0).toUpperCase()}${item.state.slice(1)}`)}</td>
                              }
                              {Preferences['searches.columns']['Match Description'].enabled &&
                                <td>{item.matchDescription ? _t('Yes') : _t('No')}</td>
                              }
                              {Preferences['searches.columns']['Exchange Type'].enabled &&
                                <td>{[item.buy ? _t('Buy') : '', item.rent ? _t('Rent') : ''].filter(item => item).join(', ')}</td>
                              }
                              {Preferences['searches.columns']['Min. Price'].enabled &&
                                <td>{item.useMinPrice ? item.minPrice : '-'}</td>
                              }
                              {Preferences['searches.columns']['Max. Price'].enabled &&
                                <td>{item.useMaxPrice ? item.maxPrice : '-'}</td>
                              }
                              {Preferences['searches.columns']['Categories'].enabled &&
                                <td>{item.matchCategory ? item.categories.map(categoryId => this.state.categories.find(category => category.categoryId === Number(categoryId)).name).map(name => _t(name)).join(', ') : _t('All')}</td>
                              }
                              {Preferences['searches.columns']['Locations'].enabled &&
                                <td>{item.matchLocation ? item.locations.map(locationId => this.state.locations.find(location => location.location_id === Number(locationId)).name).map(name => _t(name)).join(', ') : _t('Any')}</td>
                              }
                              {Preferences['searches.columns']['Date Created'].enabled &&
                                <td>{(() => {
                                  if (!item.createdAt) {
                                    return '-';
                                  }
                                  let seconds = item.createdAt;
                                  let date = new Date(0);
                                  date.setUTCSeconds(seconds);
                                  let locale = date.toLocaleDateString();
                                  return (
                                    <span>{locale}</span>
                                  );
                                })()}</td>
                              }
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EditSearchModal 
            show={this.state.showEditSearchModal} 
            title={this.state.editTitle} 
            search={this.state.editSearch} 
            onHide={e => { this.setState({ showEditSearchModal: false }) }} 
            locations={this.state.locations} 
            categories={this.state.categories} 
            manufacturers={this.state.manufacturers}
            models={this.state.models}
            countries={this.state.countries}
            regions={this.state.regions}
            districts={this.state.districts}
            cities={this.state.cities}
            quarters={this.state.quarters}
            onUpdate={this.updateList} 
          />
          <ListPreferences
            show={this.state.showColumnSettings} 
            onClose={() => { this.setState({ showColumnSettings: false }); 
            Preferences.save('campaigns.columns') }} 
            columns={Preferences['searches.columns']} 
          />
        </div>
      </Fragment>
    );
  }
}

export {
  SearchList
};