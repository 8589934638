import React, { Component, Fragment } from 'react';
import { Info, Warnings, Errors } from '../../core/utils/Notices';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../../core/stores/Translator.js';
const QRCode = require('qrcode.react');

class Apps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/apps',
      android: '',
      ios: '',
      windows: '',
      osx: '',
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    try {
      let [apps] = await Promise.all([
        (await fetch('/api/apps', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            protocol: window.location.protocol
          })
        })).json()
      ]);
      if (apps.status !== 'success') {
        throw Error(apps.message);
      }
      this.setState({
        android: apps.data.android,
        ios: apps.data.ios,
        windows: apps.data.windows,
        osx: apps.data.osx
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Apps': '/apps'
        }}>
          
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 grid-margin">
              {this.state.android &&
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                        <i className="fab fa-android" style={{ fontSize: '48px', color: '#a4c639' }}></i>
                      </div>
                      <div className="col-5">
                        {_t('Android Device Installation Package')}
                      </div>
                      <div className="col-4">
                        <QRCode value={this.state.android} style={{ width: '100%', height: 'auto'}} />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <form method="get" action={this.state.android}>
                      <button type="submit" className="btn btn-outline-primary">{_t('Download')}</button>
                    </form>
                  </div>
                </div>
              }
            </div>
            {/*
            <div className="col-xl-3 col-sm-6 col-xs-12 grid-margin">
              {this.state.ios &&
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                        <i className="fab fa-apple" style={{ fontSize: '48px', color: 'rgb(102 102 102)' }}></i>
                      </div>
                      <div className="col-5">
                        {_t('App for iOS Simulator')}
                      </div>
                      <div className="col-4">
                        <QRCode value={this.state.ios} style={{ width: '100%', height: 'auto'}} />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <form method="get" action={this.state.ios}>
                      <button type="submit" className="btn btn-outline-primary">{_t('Download')}</button>
                    </form>
                  </div>
                </div>
              }
            </div>
            */}
            <div className="col-xl-3 col-sm-6 col-xs-12 grid-margin">
              {this.state.windows &&
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                        <i className="fab fa-windows" style={{ fontSize: '48px', color: '#00A4EF' }}></i>
                      </div>
                      <div className="col-9">
                        {_t('Windows Desktop Application')}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <form method="get" action={this.state.windows}>
                      <button type="submit" className="btn btn-outline-primary">{_t('Download')}</button>
                    </form>
                  </div>
                </div>
              }
            </div>
            {/*
            <div className="col-xl-3 col-sm-6 col-xs-12 grid-margin">
              {this.state.osx &&
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                        <i className="fab fa-apple" style={{ fontSize: '48px', color: 'rgb(102 102 102)' }}></i>
                      </div>
                      <div className="col-9">
                        {_t('Apple OSX Desktop Application')}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <form method="get" action={this.state.osx}>
                      <button type="submit" className="btn btn-outline-primary">{_t('Download')}</button>
                    </form>
                  </div>
                </div>
              }
            </div>
            */}
          </div>
        </div>
      </Fragment>
    );
  }  
  
}

export default Apps;