import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import User from '../../core/stores/User';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../stores/Translator.js';

class ListSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/subscriptions',
      list: [],
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateList();
  }
    
  updateList = async () => {
    try {
      let response = await (await fetch('/api/subscriptions', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  updateSubscription = async (e, type, subscriptionId) => {
    let subscribed = this.state.list.filter(subscription => subscription.subscriptionId === subscriptionId)[0][type];
    this.setState({
      list: this.state.list.map(subscription => {
        if (subscription.subscriptionId === subscriptionId) {
          subscription[type] = !subscription[type];
        }
        return subscription;
      })
    });
    try {
      if (subscribed) {
        let response = await (await fetch(`/api/subscriptions/${type}/unsubscribe`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId
          })
        })).json();
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.updateList();
      } else {
        let response = await (await fetch(`/api/subscriptions/${type}/subscribe`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId
          })
        })).json();
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.updateList();
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Subscriptions': ''
        }}>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-header">
                  <h5 className="mt-2">{_t('Available Topics')}</h5>
                </div>
                <div className="card-body">
                  <div className="mt-3 mb-5 font-italic">{_t('Select topics you would like to receive.')}</div>
                  {this.state.list.map(item => {
                    return (
                      <div key={item.subscriptionId}>
                        <div>- {item.description}</div>
                        <Form.Group className="mr-3 d-inline-block">
                          <div className="form-check form-check-primary d-inline-block">
                            <label className="form-check-label">
                              <input type="checkbox" className="form-check-input" checked={item.notification ? true : false} onChange={e => this.updateSubscription(e, 'notification', item.subscriptionId)}/>
                              {_t('Notification')}
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        </Form.Group>
                        <Form.Group className="mr-3 d-inline-block">
                          <div className="form-check form-check-primary d-inline-block">
                            <label className="form-check-label">
                              <input type="checkbox" className="form-check-input" checked={item.email ? true : false} onChange={e => this.updateSubscription(e, 'email', item.subscriptionId)}/>
                              {_t('Email')}
                              <i className="input-helper"></i>
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } 
}

export {
  ListSubscriptions
};