import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import DatePicker from 'react-datepicker';
// import { Dropdown } from 'react-bootstrap';
import Navigation from '../../core/shared/NavigationV2';

export class DomainSettings extends Component {
   constructor(props) {
    super(props);
    this.state = {
      path: '/admin/domain_settings',
    };
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Domain Settings': ''
        }}>
        </Navigation>
        <div className="inner-container">
          
        </div>
      </Fragment>
    );
  }
}

export default DomainSettings;