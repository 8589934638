import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { _t } from '../stores/Translator.js';
import HeaderV2 from '../../core/shared/HeaderV2';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      success: '',
      submitting: false
    };
    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
    this.usernameRef = React.createRef();
  }

  onCreate = async () => {
    this.setState({
      error: '',
      submitting: true
    }, async () => {
      let result = await (await fetch('/api/user/create', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.emailRef.current.value,
          name: this.nameRef.current.value,
          username: this.usernameRef.current.value
        })
      })).json();
      if (result.status == 'success') {
        this.setState({
          success: result.message,
          submitting: false
        });
      } else {
        this.setState({
          error: result.message,
          submitting: false
        });
      }
    });
  }
  
  componentDidMount () {
    if (this.props.location && this.props.location.state && this.props.location.state.email) {
      this.emailRef.current.value = this.props.location.state.email;
      this.nameRef.current.focus();
    }
  }
  
  render() {
    return(
      <div className="wy-login">
        <HeaderV2 />
        <div className="bg-image d-flex vertical-center align-items-center">
          <div className="d-flex align-items-center auth forgot-pass px-2 py-5 mx-auto">
            <div className="row w-100 mx-0">
              <div className="col-lg-8 mx-auto flex-reset">
              {(this.state.success) ? 
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <p className="success">{this.state.success}</p>
                </div>
                :
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <h1 className="text-center">{_t('Create Your Account')}</h1>
                  <p className="mt-3 small-text text-muted text-center">{_t('Please enter your account information below. Once completed, we will confirm your account via email')}</p>
                  <p className="error">{this.state.error}</p>
                  <Form>
                    <Form.Group className="d-flex search-field border-bottom position-relative">
                      <Form.Control test-id="input_username" type="text" placeholder={_t('Username')} size="lg" className="h-auto border-0" ref={this.usernameRef} />
                    </Form.Group>
                    <Form.Group className="d-flex search-field border-bottom position-relative">
                      <Form.Control test-id="input_email" type="email" placeholder={_t('Email')} size="lg" className="h-auto border-0" ref={this.emailRef} />
                    </Form.Group>
                    <Form.Group className="d-flex search-field border-bottom position-relative">
                      <Form.Control test-id="input_full-name" type="text" placeholder={_t('Full Name')} size="lg" className="h-auto border-0" ref={this.nameRef} />
                    </Form.Group>
                    <Form.Group className="mt-3 d-flex justify-content-center align-items-center">
                      <Form.Control test-id="button_sign-up" type="button" value={_t('Create')} className="btn btn-primary btn-lg font-weight-medium auth-form-bt blue-bg" onClick={this.onCreate} disabled={this.state.submitting}/>
                    </Form.Group>
                    <div>
                      <div className="dividerline"></div>
                      <div className="text-center mt-4 font-weight-light">
                        <p className="mt-3 small-text text-muted">{_t('By signin up your agree to our')}
                          <a href="/tos" target="_blank" className="text-muted" rel="noopener noreferrer"> {_t('Terms of Service.')}</a>
                        </p>
                      </div>
                    </div>
                  </Form>
                </div>
                }
              </div>
            </div>
          </div>  
        </div>
      </div>  
    );
  }
  
}


export default Signup;