import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination, Col, Row, InputGroup, Modal} from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import User from '../../core/stores/User';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import Loader from '../stores/Loader';
import Settings from '../stores/Settings';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import Navigation from '../../core/shared/NavigationV2';
import { SearchableMultiSelect } from '../../core/utils/SearchableMultiSelect';

import SidebarItems from '../../app/shared/Sidebar';
import NavbarItems from '../../app/shared/Navbar';
import { _t } from '../stores/Translator.js';

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/users/new',
      user: {
        email: '',
        name: '',
        password: '',
        username: '',
        access: [],
        requestId: ''
      },
      showPassword: false,
      typePasswordField: 'password',
      listReady: false,
      disableUserCredentials: false,
      organizations: [],
      organizationName: '',
      selectedOrganization: '',
      duplicatePublishersList: [],
      usersInfo: [],
      organizationInfo: [],
      additionalUserInfo: {},
      errors: [],
      warnings: [], 
      info: [],
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    let organizationId = '';
    if (this.props.location.state && this.props.location.state.user) {
      let user = {...this.state.user};
      user.email = this.props.location.state.user.email ? this.props.location.state.user.email : '';
      user.name = this.props.location.state.user.name ? this.props.location.state.user.name : '';
      user.password = this.props.location.state.user.password ? this.props.location.state.user.password : '';
      user.username = this.props.location.state.user.username ? this.props.location.state.user.username : '';
      user.requestId = this.props.location.state.user.requestId ? this.props.location.state.user.requestId : '';
      organizationId = this.props.location.state.user.invitations && this.props.location.state.user.invitations.organizationId ? this.props.location.state.user.invitations.organizationId : '';
      this.setState({
        user: user,
        selectedOrganization: organizationId,
        disableUserCredentials: true
      })
    }
    let additionalUserInfo = {};
    for (let i in Settings.user.info) {
      let info = Settings.user.info[i];
      additionalUserInfo[info.id] = info.type === 'L' ? [] : '';
    }
    // for (let i in Settings.organization.info) {
    //   let info = Settings.organization.info[i];
    //   state.additionalOrganizationInfo[info.id] = info.type === 'L' ? [] : '';
    // }
    this.setState({
      additionalUserInfo: additionalUserInfo
    });
    await Promise.all([
      this.usersInfo(),
      this.organizations(),
      this.lists()
    ]);
    if (organizationId) {
      this.organizationUpdate(organizationId);
    }
    // let state = {};
    // for (let i in Settings.info) {
    //   let info = Settings.info[i];
    //   state[info.id] = info.type === 'L' ? [] : '';
    //   if (info.id === 'publisherId') {
    //     state.duplicatePublishersList = this.state.publishers
    //   }
    // }
    // this.setState(state)
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  handleChange = (e, field) => {
    let user = {...this.state.user};
    user[field] = e.target.value;
    this.setState({
      user: user,
    });
  }
  
  organizations = async () => {
    try {
      let response = await (await fetch('/api/organizations', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        organizations: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  usersInfo = async () => {
    try {
      let response = await (await fetch('/api/admin/users/info', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        usersInfo: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  organizationUpdate = (organizationId) => {
    for (let i in Settings.info) {
      let info = Settings.info[i];
      let userInfo = this.state.usersInfo.filter(user => user.userId === organizationId);
      if (info.id === 'publisherId') {
        if (!userInfo[0]) {
          this.setState({
            publishers: this.state.duplicatePublishersList.filter(publisher => {
              let usersInfo = this.state.usersInfo.filter(user => user.publisherId === publisher.publisherId);
              if (!usersInfo[0]) {
                return publisher;
              }
            }),
            publisherId: ''
          });
        } else {
          this.setState({
            publishers: this.state.duplicatePublishersList.filter(publisher => publisher.publisherId === userInfo[0].publisherId),
            publisherId: ''
          });
        }
      }
      if (info.id === 'organizationIds') {
        /*filter selected organization*/
        if (organizationId) {
          if (!userInfo[0]) {
            this.setState({
              organizationLists: this.state.organizations.filter(organization => organization.organizationId !== organizationId),
              organizationIds: []
            });
          } else {
            if (userInfo[0].organizationIds) {
              this.setState({
                organizationLists: this.state.organizations.filter(organization => organization.organizationId !== organizationId),
                organizationIds: userInfo[0].organizationIds ? userInfo[0].organizationIds : []
              });
            }
          }
        } else {
          this.setState({
            organizationLists: this.state.organizations,
            organizationIds: []
          });
        }
      }
    }
  }
  
  onOrganizationChange = (e) => {
    let organizationId = e.target.value;
    this.setState({
      selectedOrganization: organizationId,
      organizationName: '',
    }, this.organizationUpdate(organizationId));
  }
  
  sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000))
  
  lists = async () => {
    while (!Settings.user.info) { /* Sometimes info will remain empty */
      await this.sleep(0.2);
    };
    try {
      for (let i in Settings.user.info) {
        let info = Settings.user.info[i];
        if (!info.listEndpoint) { 
          this.setState({
            listReady: true
          });
        } else {
          let response = await (await fetch(info.listEndpoint, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })).json();
          if (response.status !== 'success') {
            throw Error(response.message);
          }
          // let state = {};
          // state[info.listName] = response.data.items;
          // this.setState(state);
          this.setState({
            [info.listName]: response.data.items
          }, () => {
            if (info.id === 'publisherId') {
              this.setState({
                duplicatePublishersList: this.state.publishers
              });
            }
            this.setState({
              listReady: true
            });
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  save = async () => {
    Loader.show();
    try {
      let body = {
        user: this.state.user,
        organizationId: this.state.selectedOrganization,
        organizationName: this.state.organizationName,
        userInfo: this.state.additionalUserInfo
      };
      // for (let i in Settings.info) {
      //   let info = Settings.info[i];
      //   body[info.id] = this.state[info.id];
      // }
      let response = await (await fetch('/api/admin/user/new', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, 
        body: JSON.stringify(body) 
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      if (response.status === 'success') {
        let state = {
          info: this.state.info.concat(response.message),
          user: {
            email: '',
            name: '',
            password: '',
            username: '',
            access: []
          },
          selectedOrganization: '',
          disableUserCredentials: false,
          additionalUserInfo: {},
          additionalOrganizationInfo: {}
        };
        for (let i in Settings.user.info) {
          let info = Settings.user.info[i];
          state.additionalUserInfo[info.id] = '';
        }
        for (let i in Settings.organization.info) {
          let info = Settings.organization.info[i];
          state.additionalOrganizationInfo[info.id] = '';
        }
        this.setState(state);
      }
      this.organizations();
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  generatePassword = () => {
    let user = {...this.state.user};
    user.password = Math.random().toString(36).substr(2, 8);
    this.setState({
      user: user
    });
  }
  
  addAccess = (identity, type, item) => {
    let user = {...this.state.user};
    if (type === 'top') {
      if (!user.access.includes(identity)) {
        user.access.push(identity);
        this.setState({
          user: user
        });
      }
    } else if (type === 'sub') {
      if (!user.access.includes(identity)) {
        user.access.push(identity);
        if (!user.access.includes(item.identity)) {
          user.access.push(item.identity);
        }
        this.setState({
          user: user
        });
      }
    }
  }
  
  removeAccess = (identity, type, item) => {
    let user = {...this.state.user};
    if (type === 'top') {
      if (user.access.includes(identity)) {
        user.access.splice(user.access.indexOf(identity), 1);
        this.setState({
          user: user
        });
      }
    } else if (type === 'sub') {
      if (user.access.includes(identity)) {
        user.access.splice(user.access.indexOf(identity), 1);
        if (user.access.filter(access => access.includes(`${item.identity}/`)).length === 0) {
          if (user.access.includes(item.identity)) {
            user.access.splice(user.access.indexOf(item.identity), 1);
          }
        }
        this.setState({
          user: user
        });
      }
    }
  }
  
  update = async (endpoint, listName, id) => {
    try {
      Loader.show();
      if (!endpoint) {
        return
      }
      let response = await (await fetch(endpoint, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      // let state = {};
      // state[info.listName] = response.data.items;
      // this.setState(state);
      this.setState({
        [listName]: response.data.items
      }, () => {
        if (id === 'publisherId') {
          this.setState({
            duplicatePublishersList: this.state.publishers
          });
        }
        this.setState({
          listReady: true
        });
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Users': '/admin/users',
          'New': ''
        }}>
          <Link to="/admin/users" className="mr-2">
            <button type="button" className="btn btn-outline-primary">{_t('Back')}</button>
          </Link>
          <button test-id="button_save" type="button" className="btn btn-outline-primary" onClick={this.save}>{_t('Save')}</button>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-header">
                  {_t('User Info')}
                </div>
                <div className="card-body">
                  <Form.Group>  
                    <Form.Label>{_t('Username')}</Form.Label>
                    <Form.Control test-id="input_username" type="text" size="sm" value={this.state.user.username} onChange={e => this.handleChange(e, 'username')} disabled={this.state.disableUserCredentials}/>
                  </Form.Group>
                  <Form.Group>  
                    <Form.Label>{_t('Full Name')}</Form.Label>
                    <Form.Control test-id="input_full-name" type="text" size="sm" value={this.state.user.name} onChange={e => this.handleChange(e, 'name')} disabled={this.state.disableUserCredentials}/>
                  </Form.Group>
                  <Form.Group>  
                    <Form.Label>{_t('Email')}</Form.Label>
                    <Form.Control test-id="input_email" type="text" size="sm" value={this.state.user.email} onChange={e => this.handleChange(e, 'email')} disabled={this.state.disableUserCredentials}/>
                  </Form.Group>
                  <Form.Group>  
                    <Form.Label>{_t('Password')}</Form.Label>
                    <InputGroup>
                      <Form.Control test-id="input_password" type={this.state.showPassword ? 'text' : 'password'} size="sm"  value={this.state.user.password} onChange={e => this.handleChange(e, 'password')}/>
                      <InputGroup.Append onClick={e => {this.setState({ showPassword: this.state.showPassword ? false : true})}} >
                        <InputGroup.Text style={{"fontSize": '0.675rem'}} className="pl-2 pr-2 text-secondary bg-transparent border-1">
                          <span className={this.state.showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></span>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>  
                    <button test-id="button_generate-password" type="button" className="btn btn-outline-primary" onClick={this.generatePassword}>{_t('Auto Generate password')}</button>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 grid-margin stretch-card">
                      <Form.Group className="permissions-list">
                        <Form.Label>Available Permissions</Form.Label>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              {SidebarItems.map(item => {
                                if (!item.sub) {
                                  if (item.identity && !this.state.user.access.includes(item.identity)) {
                                    return (
                                      <tr key={item.identity}>
                                        <td test-id="text_permissions-enable" className="font-weight-medium">{item.name}</td>
                                        <td>
                                          <button test-id="button_permissions-enable" type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(item.identity, 'top', item) }}>
                                            <i className="fa fa-caret-right"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return item.sub.filter(subitem => {
                                    return subitem.identity && !this.state.user.access.includes(subitem.identity);
                                  }).map(subitem => {
                                    return (
                                      <tr key={subitem.identity}>
                                        <td test-id="text_permissions-disable" className="font-weight-medium">{item.name} / {subitem.name}</td>
                                        <td>
                                          <button test-id="button_permissions-disable" type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(subitem.identity, 'sub', item) }}>
                                            <i className="fa fa-caret-right"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  });
                                }
                              })}
                              {NavbarItems.map(item => {
                                if (item.identity && !this.state.user.access.includes(item.identity)) {
                                  return (
                                    <tr key={item.identity}>
                                      <td className="font-weight-medium">{item.name}</td>
                                      <td>
                                        <button type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(item.identity, 'top', item) }}>
                                          <i className="fa fa-caret-right"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 grid-margin stretch-card">
                      <Form.Group className="permissions-list">
                        <Form.Label>User Permissions</Form.Label>
                        <div className="table-responsive permissions-list">
                          <table className="table">
                            <tbody>
                              {SidebarItems.map(item => {
                                if (!item.sub) {
                                  if (item.identity && this.state.user.access.includes(item.identity)) {
                                    return (
                                      <tr key={item.identity}>
                                        <td className="font-weight-medium">{item.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(item.identity, 'top', item) }}>
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return item.sub.filter(subitem => {
                                    return subitem.identity && this.state.user.access.includes(subitem.identity);
                                  }).map(subitem => {
                                    return (
                                      <tr key={subitem.identity}>
                                        <td className="font-weight-medium">{item.name} / {subitem.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(subitem.identity, 'sub', item) }}>
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  });
                                }
                              })}
                              {NavbarItems.map(item => {
                                if (item.identity && this.state.user.access.includes(item.identity)) {
                                  return (
                                    <tr key={item.identity}>
                                      <td className="font-weight-medium">{item.name}</td>
                                      <td>
                                        <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(item.identity, 'top', item) }}>
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-header">
                  {_t('Organization')}  
                </div>
                <div className="card-body">
                  <div className="row">
                    <Form.Group className="col-md-6 mb-0">
                      <Form.Label>{_t('Select Organization')}</Form.Label>
                      <SearchableSelect test-id="select_existing-organization" className="borderless form-control form-control-sm" placeholder="" value={this.state.selectedOrganization} onChange={(e) => {this.onOrganizationChange(e)}}>
                        <option key={0} value={''} selected={this.state.selectedOrganization === ''}></option>
                        {this.state.organizations.map(organization => {
                          return (
                            <option test-id="option_existing-organization" key={organization.organizationId} value={organization.organizationId} selected={this.state.selectedOrganization === organization.organizationId}>{organization.organizationName}</option>
                          );
                        })}
                      </SearchableSelect>
                    </Form.Group>
                    <Form.Group className="col-md-6">  
                      <Form.Label>{_t('Create New Organization')}</Form.Label>
                      <Form.Control test-id="input_new-organization" type="text" value={this.state.organizationName} size="sm" onChange={(e) => { this.setState({organizationName: e.target.value })}} disabled={this.state.selectedOrganization !== ''}/>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Settings.user.info.length > 0 && <div className="row" style={{'marginBottom': '600px'}}>
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-header">
                  {_t('Additional User Info')}
                </div>
                <div className="card-body">
                  {Settings.user.info && this.state.listReady && Settings.user.info.map(info => {
                    switch (info.fieldType) {
                      case 'text':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            <div className="row">
                              <div className={info.listEndpoint ? "col-10 col-md-11" : "col-12"}>
                                <Form.Control type="text" value={this.state.additionalUserInfo[info.id] ? this.state.additionalUserInfo[info.id] : ''} size="sm" onChange={(e) => { 
                                  let additionalUserInfo = {...this.state.additionalUserInfo};
                                  additionalUserInfo[info.id] = e.target.value;
                                  this.setState({additionalUserInfo: additionalUserInfo})
                                }}/>
                              </div>
                              {info.listEndpoint && <div className="col-2 col-md-1 align-self-center">
                                <div className="stretch-card text-right  wy-db-list-settings justify-content-start">
                                  <i className="fa fa-sync" onClick={ e => this.update(info.listEndpoint, info.listName, info.id)}></i>
                                </div>
                              </div>}
                            </div>
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'select':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            <div className="row">
                              <div className={info.listEndpoint ? "col-10 col-md-11" : "col-12"}>
                                <SearchableSelect className="borderless form-control form-control-sm" placeholder={`-- ${_t('Select')} --`} value={this.state.additionalUserInfo[info.id] ? this.state.additionalUserInfo[info.id] : ''} onChange={(e) => { 
                                  let additionalUserInfo = {...this.state.additionalUserInfo};
                                  additionalUserInfo[info.id] = e.target.value;
                                  this.setState({additionalUserInfo: additionalUserInfo})
                                }}>
                                  <option key={0} value={''} selected={this.state.additionalUserInfo[info.id] === ''}>-- {_t('Select')} --</option>
                                  {this.state[info.listName].map(item => {
                                    return (
                                      <option key={item[info.id]} value={item[info.id]} selected={this.state.additionalUserInfo[info.id] === item[info.id]}>{item[info.value]}</option>
                                    );
                                  })}
                                </SearchableSelect>
                              </div>
                              {info.listEndpoint && <div className="col-2 col-md-1 align-self-center">
                                <div className="stretch-card text-right wy-db-list-settings justify-content-start">
                                  <i className="fa fa-sync" onClick={ e => this.update(info.listEndpoint, info.listName, info.id)}></i>
                                </div>
                              </div>}
                            </div>
                            {info.note && <Form.Label className="mt-2 font-italic">{_t(info.note)}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'multi-select':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            <div className="row">
                              <div className={info.listEndpoint ? "col-10 col-md-11" : "col-12"}>
                                {this.state[info.listName] && <SearchableMultiSelect className="borderless form-control form-control-sm" placeholder={`-- ${_t('Select')} --`}  value={this.state.additionalUserInfo[info.id] ? this.state.additionalUserInfo[info.id] : []} onChange={(e) => { 
                                  let additionalUserInfo = {...this.state.additionalUserInfo};
                                  additionalUserInfo[info.id] = e.target.value;
                                  this.setState({additionalUserInfo: additionalUserInfo})
                                }} disabled={info.id === 'organizationIds' && this.state.selectedOrganization !== '' ? true : false}>
                                  {this.state[info.listName].map(item => {
                                    return (
                                      <option key={item[info.key]} value={item[info.key]} selected={this.state.additionalUserInfo[info.id] && this.state.additionalUserInfo[info.id].includes(item[info.key]) ? true : false} >{item[info.name]}</option>
                                    );
                                  })}
                                </SearchableMultiSelect>}
                              </div>
                              {info.listEndpoint && <div className="col-2 col-md-1 align-self-center">
                                <div className="stretch-card text-right wy-db-list-settings justify-content-start">
                                  <i className="fa fa-sync" onClick={e => this.update(info.listEndpoint, info.listName, info.id)}></i>
                                </div>
                              </div>}
                            </div>
                            {info.note && <Form.Label className="mt-2 font-italic">{_t(info.note)}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </Fragment>
    );
  }
}

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/users/edit/:userId',
      userId: props.match.params.userId,
      user: {
        username: '',
        email: '',
        admin: 0,
        active: 0,
        access: []
      },
      password: '',
      rpassword: '',
      showChangePassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      organization: {},
      additionalUserInfo: {},
      listReady: false,
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  async componentDidMount() {
    this._ismounted = true;
    Loader.show();
    let additionalUserInfo = {};
    for (let i in Settings.user.info) {
      let info = Settings.user.info[i];
      additionalUserInfo[info.id] = info.type === 'L' ? [] : '';
    }
    this.setState({
      additionalUserInfo: additionalUserInfo
    });
    await Promise.all([
      this.user(),
      this.lists(),
      this.userInfo()
    ]);
    if (this.state.user.organizationId) {
      await this.organization();
    }
    for (let i in Settings.user.info) {
      let info = Settings.user.info[i];
      if (info.id === 'organizationIds' ) {
        this.setState({
          organizationLists: this.state.organizationLists.filter(organization => organization.organizationId !== this.state.organization.organizationId)
        });
      }
    }
    Loader.hide();
  }

  organization = async () => {
    try {
      let response = await (await fetch('/api/user/organization', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          organizationId: this.state.user.organizationId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        organization: response.data
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  lists = async () => {
    while (!Settings.user.info) { /* Sometimes info will remain empty */
      await this.sleep(0.2);
    };
    try {
      for (let i in Settings.user.info) {
        let info = Settings.user.info[i];
        if (!info.listEndpoint) { 
          this.setState({
            listReady: true
          });
        } else {
          let response = await (await fetch(info.listEndpoint, {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })).json();
          if (response.status !== 'success') {
            throw Error(response.message);
          }
          // let state = {};
          // state[info.listName] = response.data.items;
          // this.setState(state);
          this.setState({
            [info.listName]: response.data.items
          }, () => {
            this.setState({
              listReady: true
            });
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  user = async () => {
    try {
      let response = await (await fetch('/api/admin/user', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: this.state.userId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({ 
         user: response.data
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  userInfo = async () => {
    try {
      let response = await (await fetch('/api/admin/user-info', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: this.state.userId
        })
      })).json();

      if (response.status !== 'success') {
        throw Error(response.message);
      }
      let additionalUserInfo = {};

      for (let i in Settings.user.info) {
        let info = Settings.user.info[i];
        if (info.type === 'S') {
          additionalUserInfo[info.id] = response.data[info.id] ?  response.data[info.id] : '';
        } else if (info.type === 'L') {
          additionalUserInfo[info.id] = response.data[info.id] ?  response.data[info.id] : [];
        }
      }
      
      this.setState({
        additionalUserInfo: additionalUserInfo
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  save = async () => {
    Loader.show();
    try {
      if (this.state.password !== this.state.rpassword) {
        this.setState({
          error: 'Passwords do not match.'
        });
        return;
      }
      let body = {
        user: this.state.user,
        password: this.state.password,
        userInfo: {}
      };
      for (let i in Settings.user.info) {
        let info = Settings.user.info[i];
        body.userInfo[info.id] = this.state.additionalUserInfo[info.id];
      }
      let response = await (await fetch('/api/admin/user/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        info: this.state.info.concat(response.message)
      });
      if (User.userId === this.state.user.userId) {
        User.username = this.state.user.username;
        User.email = this.state.user.email;
        User.access = this.state.user.access;
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  disable = async () => {
    try {
      if (window.confirm(`${_t('Remove')} ${this.state.userId}?`)) {
        let response = await (await fetch('/api/admin/user/disable', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: this.state.user.userId
          })
        })).json();
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.setState({
          info: this.state.info.concat(response.message)
        });
        await this.user();
        if (User.userId === this.state.user.userId) {
          this.props.history.push(`/logout`);
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  enable = async () =>  {
    try {
      if (window.confirm(`${_t('Remove')} ${this.state.userId}?`)) {
        let response = await (await fetch('/api/admin/user/enable', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: this.state.user.userId
          })
        })).json();
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.setState({
          info: this.state.info.concat(response.message)
        });
        await this.user();
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  makeAdmin = async () => {
    try {
      if (window.confirm(`${this.state.userId} ${_t('will become admin, are you sure?')}`)) {
        let response = await (await fetch('/api/admin/user/make-admin', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: this.state.user.userId
          })
        })).json();
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.setState({
          info: this.state.info.concat(response.message)
        });
        await this.user();
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  unmakeAdmin = async () => {
    try {
      if (window.confirm(`${_t('Remove admin privileges from')} ${this.state.userId}?`)) {
        let response = await (await fetch('/api/admin/user/unmake-admin', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: this.state.user.userId
          })
        })).json();
        if (response.status !== 'success') {
          throw Error(response.message);
        }
        this.setState({
          info: this.state.info.concat(response.message)
        });
        await this.user();
        if (User.userId === this.state.user.userId) {
          this.props.history.push(`/logout`);
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  handleChange = (e, field) => {
    if (['password', 'rpassword'].includes(field)) {
      let state = this.state;
      state[field] = e.target.value;
      this.setState(state);
    } else {
      let user = {...this.state.user};
      user[field] = e.target.value;
      this.setState({
        user: user,
      });
    }
  }
  
  addAccess = (identity, type, item) => {
    let user = {...this.state.user};
    if (type === 'top') {
      if (!user.access.includes(identity)) {
        user.access.push(identity);
        this.setState({
          user: user
        });
      }
    } else if (type === 'sub') {
      if (!user.access.includes(identity)) {
        user.access.push(identity);
        if (!user.access.includes(item.identity)) {
          user.access.push(item.identity);
        }
        this.setState({
          user: user
        });
      }
    }
  }
  
  removeAccess = (identity, type, item) => {
    let user = {...this.state.user};
    if (type === 'top') {
      if (user.access.includes(identity)) {
        user.access.splice(user.access.indexOf(identity), 1);
        this.setState({
          user: user
        });
      }
    } else if (type === 'sub') {
      if (user.access.includes(identity)) {
        user.access.splice(user.access.indexOf(identity), 1);
        if (user.access.filter(access => access.includes(`${item.identity}/`)).length === 0) {
          if (user.access.includes(item.identity)) {
            user.access.splice(user.access.indexOf(item.identity), 1);
          }
        }
        this.setState({
          user: user
        });
      }
    }
  }
  
  goBack = () => {
    if (this.props.history.length > 2) {
      this.props.history.goBack();
    } else {
      this.props.history.push(`/admin/users`);
    }
  }
  
  impersonateAccount = async () => {
    try {
      let response = await (await fetch('/api/admin/user/impersonate', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: this.state.user.userId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error('Something went wrong');
      }
      User.isAdmin = response.data.isAdmin;
      User.userId = response.data.userId;
      User.ownerId = response.data.organizationId || response.data.userId;
      User.username = response.data.username;
      User.email = response.data.email;
      User.name = response.data.name;
      User.access = response.data.access;
      User.organization = response.data.organization;
      User.userInfo = response.data.userInfo;
      User.organizationInfo = response.data.organizationInfo;
      User.impersonator = response.data.impersonator;
      User.viewer = response.data.viewer;
      window.location.href = '/';
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  generatePassword = () => {
    let password = Math.random().toString(36).substr(2, 8);
    this.setState({
      password: password,
      rpassword: password
    });
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Users': '/admin/users',
          'Edit': ''
        }}>
          <button type="button" className="btn btn-outline-primary mr-2" onClick={this.goBack}>{_t('Back')}</button>
          {this.state.user.active ? 
            <button test-id="button_disable-user" type="button" className="btn btn-outline-primary mr-2" onClick={this.disable}>{_t('Disable')}</button>
          : 
            <button test-id="button_enable-user" type="button" className="btn btn-outline-primary mr-2" onClick={this.enable}>{_t('Enable')}</button>
          }
          {!User.impersonator && !User.viewer &&
            <button test-id="button_impersonate" type="button" className="btn btn-outline-primary mr-2" onClick={this.impersonateAccount}>{_t('Impersonate')}</button>
          }
          <button test-id="button_save" type="button" className="btn btn-outline-primary" onClick={this.save}>{_t('Save')}</button>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          {this.state.organization && this.state.organization.organizationName && <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group className="mb-0">  
                    <Form.Label>{_t('Organization:')} {this.state.organization.organizationName}</Form.Label>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>}
          <div className="row">
            <div className="col-lg-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>  
                    <Form.Label>{_t('Username')}</Form.Label>
                    <Form.Control test-id="input_username" type="text" size="sm" value={this.state.user.username} onChange={e => this.handleChange(e, 'username')}/>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="col-lg-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>  
                    <Form.Label>{_t('Email')}</Form.Label>
                    <Form.Control test-id="input_email" type="text" size="sm" value={this.state.user.email} onChange={e => this.handleChange(e, 'email')}/>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <div className="form-check form-check-primary">
                      <label className="form-check-label">
                        <input test-id="checkbox_change-password" type="checkbox" className="form-check-input" checked={this.state.showChangePassword} onChange={e => this.setState({ showChangePassword: this.state.showChangePassword ? false : true, password: '', rpassword: ''})}/>
                        Change User Password
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </Form.Group>
                  <Form.Group>  
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <Form.Control test-id="input_new-password" type={this.state.showNewPassword ? 'text' : 'password'} size="sm"  value={this.state.password} onChange={e => this.handleChange(e, 'password')} disabled={!this.state.showChangePassword}/>
                      <InputGroup.Append onClick={e => {this.setState({ showNewPassword: this.state.showNewPassword ? false : true})}} >
                        <InputGroup.Text style={{"fontSize": '0.675rem'}} className="pl-2 pr-2 text-secondary bg-transparent border-1">
                          <span className={this.state.showNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></span>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>  
                    <Form.Label>Repeat  Password</Form.Label>
                    <InputGroup>
                      <Form.Control test-id="input_repeat-password" type={this.state.showRepeatPassword ? 'text' : 'password'}  size="sm" value={this.state.rpassword} onChange={e => this.handleChange(e, 'rpassword')} disabled={!this.state.showChangePassword}/>
                      <InputGroup.Append onClick={e => {this.setState({ showRepeatPassword: this.state.showRepeatPassword ? false : true})}} >
                        <InputGroup.Text style={{"fontSize": '0.675rem'}} className="pl-2 pr-2 text-secondary bg-transparent border-1">
                          <span className={this.state.showRepeatPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></span>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>  
                    <button test-id="button_generate-password" type="button" className="btn btn-outline-primary" onClick={this.generatePassword} disabled={!this.state.showChangePassword}>Auto Generate password</button>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 grid-margin stretch-card">
                      <Form.Group className="permissions-list">
                        <Form.Label>{_t('Available Permissions')}</Form.Label>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              {SidebarItems.map(item => {
                                if (!item.sub) {
                                  if (item.identity && !this.state.user.access.includes(item.identity)) {
                                    return (
                                      <tr key={item.identity}>
                                        <td test-id="text_permissions-enable" className="font-weight-medium">{item.name}</td>
                                        <td>
                                          <button test-id="button_permissions-enable" type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(item.identity, 'top', item) }}>
                                            <i className="fa fa-caret-right"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return item.sub.filter(subitem => {
                                    return subitem.identity && !this.state.user.access.includes(subitem.identity);
                                  }).map(subitem => {
                                    return (
                                      <tr key={subitem.identity}>
                                        <td test-id="text_permissions-disable" className="font-weight-medium">{item.name} / {subitem.name}</td>
                                        <td>
                                          <button test-id="button_permissions-disable" type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(subitem.identity, 'sub', item) }}>
                                            <i className="fa fa-caret-right"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  });
                                }
                              })}
                              {NavbarItems.map(item => {
                                if (item.identity && !this.state.user.access.includes(item.identity)) {
                                  return (
                                    <tr key={item.identity}>
                                      <td className="font-weight-medium">{item.name}</td>
                                      <td>
                                        <button type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(item.identity, 'top', item) }}>
                                          <i className="fa fa-caret-right"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 grid-margin stretch-card">
                      <Form.Group className="permissions-list">
                        <Form.Label>{_t('User Permissions')}</Form.Label>
                        <div className="table-responsive permissions-list">
                          <table className="table">
                            <tbody>
                              {SidebarItems.map(item => {
                                if (!item.sub) {
                                  if (item.identity && this.state.user.access.includes(item.identity)) {
                                    return (
                                      <tr key={item.identity}>
                                        <td className="font-weight-medium">{item.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(item.identity, 'top', item) }}>
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return item.sub.filter(subitem => {
                                    return subitem.identity && this.state.user.access.includes(subitem.identity);
                                  }).map(subitem => {
                                    return (
                                      <tr key={subitem.identity}>
                                        <td className="font-weight-medium">{item.name} / {subitem.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(subitem.identity, 'sub', item) }}>
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  });
                                }
                              })}
                              {NavbarItems.map(item => {
                                if (item.identity && this.state.user.access.includes(item.identity)) {
                                  return (
                                    <tr key={item.identity}>
                                      <td className="font-weight-medium">{item.name}</td>
                                      <td>
                                        <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(item.identity, 'top', item) }}>
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Settings.user.info.length > 0 && <div className="row"  style={{'marginBottom': '600px'}}>
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-header">
                  {_t('Additional User Info')}
                </div>
                <div className="card-body">
                  {Settings.user.info && this.state.listReady && Settings.user.info.map(info => {
                    switch (info.fieldType) {
                      case 'text':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            <Form.Control type="text" value={this.state.additionalUserInfo[info.id] ? this.state.additionalUserInfo[info.id] : ''} size="sm" onChange={(e) => { 
                              let additionalUserInfo = {...this.state.additionalUserInfo};
                              additionalUserInfo[info.id] = e.target.value;
                              this.setState({additionalUserInfo: additionalUserInfo})
                            }}/>
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'select':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            <SearchableSelect className="borderless form-control form-control-sm" placeholder={`-- ${_t('Select')} --`} value={this.state.additionalUserInfo[info.id] ? this.state.additionalUserInfo[info.id] : ''} onChange={(e) => { 
                              let additionalUserInfo = {...this.state.additionalUserInfo};
                              additionalUserInfo[info.id] = e.target.value;
                              this.setState({additionalUserInfo: additionalUserInfo})
                            }}>
                              <option key={0} value={''} selected={this.state.additionalUserInfo[info.id] === ''}>-- {_t('Select')} --</option>
                              {this.state[info.listName].map(item => {
                                return (
                                  <option key={item[info.id]} value={item[info.id]} selected={this.state.additionalUserInfo[info.id] === item[info.id]}>{item[info.value]}</option>
                                );
                              })}
                            </SearchableSelect>
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                      case 'multi-select':
                        return (
                          <Form.Group>  
                            <Form.Label>{info.title}</Form.Label>
                            {this.state[info.listName] && <SearchableMultiSelect className="borderless form-control form-control-sm" placeholder={`-- ${_t('Select')} --`}  value={this.state.additionalUserInfo[info.id] ? this.state.additionalUserInfo[info.id] : []} onChange={(e) => { 
                              let additionalUserInfo = {...this.state.additionalUserInfo};
                              additionalUserInfo[info.id] = e.target.value;
                              this.setState({additionalUserInfo: additionalUserInfo})
                            }} disabled={this.state.organization && this.state.organization.organizationId === this.state.user.userId ? false : true}>
                              {this.state[info.listName].map(item => {
                                return (
                                  <option key={item[info.key]} value={item[info.key]} selected={this.state.additionalUserInfo[info.id] && this.state.additionalUserInfo[info.id].includes(item[info.key]) ? true : false} >{item[info.name]}</option>
                                );
                              })}
                            </SearchableMultiSelect>}
                            {info.note && <Form.Label className="mt-2 font-italic">{info.note}</Form.Label>}
                          </Form.Group>
                        );
                      break;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          }
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card text-right wy-db-controls">
              {/*this.state.user.admin ? 
                <button type="button" className="btn btn-outline-primary" onClick={this.unmakeAdmin}>Remove Admin Privileges</button>
              : 
                <button type="button" className="btn btn-outline-primary" onClick={this.makeAdmin}>Make Admin</button>
              */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/users',
      list: [],
      selected: [],
      search: '',
      organizations: [],
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    Loader.show();
    this._ismounted = true;
    await this.organizationsList();
    await this.updateList();
    Loader.hide();
  }
    
  updateList = async() => {
    Loader.show();
    try {
      let response = await (await fetch('/api/admin/users', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items.map(item => {
          if (item.organizationId) {
            let organization = this.state.organizations.filter(org => { return org.organizationId === item.organizationId})[0];
            item.organization = organization
          }
          return item;
        })
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  organizationsList = async () => {
    try {
      let response = await (await fetch('/api/admin/organizations', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        organizations: response.data.items
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  onSelectChange = (e, userId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(userId)) {
        this.setState({
          selected: this.state.selected.concat([userId])
        });
      }
    } else {
      if (this.state.selected.includes(userId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== userId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.userId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = () => {
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.userId)).map(item => item.userId);
    this.setState({
      selected: selected
    });
  };
  
  applyFilters = (list) => {
    return list.filter(item => {
      return item.username.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.userId.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
    });
  }
  
  disableSelected = async (e) => {
    try {
      if (window.confirm(this.state.selected.length == 1 ? _t('Disable selected user?') : _t('Disable selected users?'))) {
        let selected = this.state.selected;
        for (let i in selected) {
          let response = await (await fetch('/api/admin/user/disable', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: selected[i]
            })
          })).json();
          if (response.status !== 'success') {
            throw Error('Something went wrong');
          }
          this.setState({
            list: this.state.list.filter(item => {
              return item.userId !== selected[i];
            }),
            selected: this.state.selected.filter(item => {
              return item !== selected[i];
            })
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    await this.updateList();
    this.setState({
      page: 1
    });
  }
  
  enableSelected = async (e) => {
    try {
      if (window.confirm(this.state.selected.length == 1 ? _t('Enable selected user?') : _t('Enable selected users?'))) {
        let selected = this.state.selected;
        for (let i in selected) {
          let response = await (await fetch('/api/admin/user/enable', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: selected[i]
            })
          })).json();
          if (response.status !== 'success') {
            throw Error('Something went wrong');
          }
          this.setState({
            list: this.state.list.filter(item => {
              return item.userId !== selected[i];
            }),
            selected: this.state.selected.filter(item => {
              return item !== selected[i];
            })
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    await this.updateList();
    this.setState({
      page: 1
    });
  }
  
  impersonateAccount = async () => {
    try {
      let response = await (await fetch('/api/admin/user/impersonate', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: this.state.selected[0]
        })
      })).json();
      if (response.status !== 'success') {
        throw Error('Something went wrong');
      }
      User.isAdmin = response.data.isAdmin;
      User.userId = response.data.userId;
      User.ownerId = response.data.organizationId || response.data.userId;
      User.username = response.data.username;
      User.email = response.data.email;
      User.name = response.data.name;
      User.access = response.data.access;
      User.organization = response.data.organization;
      User.info = response.data.info;
      User.impersonator = response.data.impersonator;
      User.viewer = response.data.viewer;
      window.location.href = '/';
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Users': ''
        }}>
          <Dropdown className="mr-2">
            <Dropdown.Toggle test-id="dropdown_actions" variant="btn btn-outline-primary" disabled={this.state.selected.length == 0}>{_t('Actions')}</Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {/*<Dropdown.Header>Disable</Dropdown.Header>*/}
              <Dropdown.Item test-id="list_enable" className="pt-3 pb-3" onClick={this.enableSelected}>{_t('Enable')}</Dropdown.Item>
              <Dropdown.Divider className="m-0"></Dropdown.Divider>
              <Dropdown.Item test-id="list_disable" className="pt-3 pb-3" onClick={this.disableSelected}>{_t('Disable')}</Dropdown.Item>
              {!User.impersonator && !User.viewer &&
                <Dropdown.Divider className="m-0"></Dropdown.Divider>
              }
              {!User.impersonator && !User.viewer &&
                <Dropdown.Item className="pt-3 pb-3" onClick={this.impersonateAccount} disabled={this.state.selected.length !== 1}>{_t('Impersonate')}</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
          {User.isAdmin && 
            <Link to="/admin/users/new">
              <button test-id="button_new-user" type="button" className="btn btn-outline-primary">{_t('New User')}</button>
            </Link>
          }
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Control type="text" size="sm" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
                  </Form.Group>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input test-id="checkbox_list-all" type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          <th>{_t('Username')}</th>
                          <th>{_t('Email')}</th>
                          <th>{_t('System Admin')}</th>
                          <th>{_t('Active')}</th>
                          <th>{_t('Organization')}</th>
                          <th>{_t('Org. Admin')}</th>
                          <th>{_t('Org. Owner')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => { 
                          return (
                            <tr key={item.userId} className={this.state.selected.includes(item.userId) ? 'table-primary' : ''}>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input test-id="checkbox_list-single" type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.userId)} onChange={e => this.onSelectChange(e, item.userId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              <td><Link test-id="link_edit-user" to={`/admin/users/edit/${item.userId}`}>{item.username}</Link></td>
                              <td>{item.email}</td>
                              <td>{item.admin ? _t('Yes') : _t('No')}</td>
                              <td>{item.active ? _t('Yes') : _t('No')}</td>
                              <td>{item.organization ? item.organization.organizationName : '-'}</td>
                              <td>{item.organization && item.organization.adminId === item.userId || item.organization && item.organization.admins && item.organization.admins.includes(item.userId) ? _t('Yes'): _t('No')}</td>
                              <td>{item.organization && item.organization.adminId === item.userId ? _t('Yes'): _t('No')}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
        </div>
      </Fragment>
    );
  } 
}

export default observer(NewUser);
export {
  EditUser,
  ListUsers
}



