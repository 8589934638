import { observable, extendObservable, makeObservable } from 'mobx';

class Loader {
  active = false;
  
  constructor() {
    makeObservable(this, {
      active: observable
    });
  }
  
  show () {
    this.active = true;
  }
  
  hide () {
    this.active = false;
  }
  
}

export default new Loader();