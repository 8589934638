import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import User from '../../core/stores/User';
import { Info, Warnings, Errors } from '../utils/Notices';
import SidebarItems from '../../app/shared/Sidebar';
import NavbarItems from '../../app/shared/Navbar';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../stores/Translator.js';

class EditMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/organization/members/edit/:memberId',
      memberId: props.match.params.memberId,
      member: {
        userId: '',
        username: '',
        email: '',
        access: []
      },
      organization: {},
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.member();
    await this.organization();
  }
  
  member = async () => {
    try {
      let response = await (await fetch('/api/organization/member', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          memberId: this.state.memberId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      let member = response.data;
      // member.access = member.access.filter(identity => SidebarItems.filter(item => item.identity === identity).length > 0);
      this.setState({ 
        member: member
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  organization = async () => {
    try {
      let response = await (await fetch('/api/organization', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          memberId: User.userId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({ 
        organization: response.data
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  save = async () => {
    try {
      let response = await (await fetch('/api/organization/member/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          member: this.state.member
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        info: this.state.info.concat(response.message)
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  handleChange = (e, field) => {
    let member = {...this.state.member};
    member[field] = e.target.value;
    this.setState({
      member: member
    });
  }
  
  addAccess = (identity, type, item) => {
    let member = {...this.state.member};
    if (type === 'top') {
      if (!member.access.includes(identity)) {
        member.access.push(identity);
        this.setState({
          member: member
        });
      }
    } else if (type === 'sub') {
      if (!member.access.includes(identity)) {
        member.access.push(identity);
        if (!member.access.includes(item.identity)) {
          member.access.push(item.identity);
        }
        this.setState({
          member: member
        });
      }
    }
  }
  
  removeAccess = (identity, type, item) => {
    let member = {...this.state.member};
    if (type === 'top') {
      if (member.access.includes(identity)) {
        member.access.splice(member.access.indexOf(identity), 1);
        this.setState({
          member: member
        });
      }
    } else if (type === 'sub') {
      if (member.access.includes(identity)) {
        member.access.splice(member.access.indexOf(identity), 1);
        if (member.access.filter(access => access.includes(`${item.identity}/`)).length === 0) {
          if (member.access.includes(item.identity)) {
            member.access.splice(member.access.indexOf(item.identity), 1);
          }
        }
        this.setState({
          member: member
        });
      }
    }
  }
  
  makeAdmin = async () => {
    try {
      let response = await (await fetch('/api/organization/make-admin', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          memberId: this.state.memberId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({ 
        organization: response.data,
        info: this.state.info.concat(response.message)
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  unmakeAdmin = async () => {
    try {
      let response = await (await fetch('/api/organization/unmake-admin', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          memberId: this.state.memberId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({ 
        organization: response.data,
        info: this.state.info.concat(response.message)
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  viewAccount = async () => {
    try {
      let response = await (await fetch('/api/organization/member/view', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: this.state.member.userId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error('Something went wrong');
      }
      User.isAdmin = response.data.isAdmin;
      User.userId = response.data.userId;
      User.ownerId = response.data.organizationId || response.data.userId;
      User.username = response.data.username;
      User.email = response.data.email;
      User.name = response.data.name;
      User.access = response.data.access;
      User.organization = response.data.organization;
      User.userInfo = response.data.userInfo;
      User.organizationInfo = response.data.organizationInfo;
      User.impersonator = response.data.impersonator;
      User.viewer = response.data.viewer;
      window.location.href = '/';
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Members': ''
        }}>
          <Link to="/organization" className="mr-2">
            <button type="button" className="btn btn-outline-primary">{_t('Back')}</button>
          </Link>
          {!User.impersonator && !User.viewer &&
            <button type="button" className="btn btn-outline-primary mr-2" onClick={this.viewAccount}>{_t('View Account')}</button>
          }
          <button type="button" className="btn btn-outline-primary" onClick={this.save}>{_t('Save')}</button>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 grid-margin stretch-card">
                      <Form.Group className="permissions-list">
                        <Form.Label>{_t('Available Permissions')}</Form.Label>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              {SidebarItems.map(item => {
                                if (!item.sub) {
                                  if (item.identity && !this.state.member.access.includes(item.identity) && User.access.includes(item.identity)) {
                                    return (
                                      <tr key={item.identity}>
                                        <td className="font-weight-medium">{item.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(item.identity, 'top', item) }}>
                                            <i className="fa fa-caret-right"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return item.sub.filter(subitem => {
                                    return subitem.identity && !this.state.member.access.includes(subitem.identity) && User.access.includes(item.identity);
                                  }).map(subitem => {
                                    return (
                                      <tr key={subitem.identity}>
                                        <td className="font-weight-medium">{item.name} / {subitem.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(subitem.identity, 'sub', item) }}>
                                            <i className="fa fa-caret-right"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  });
                                }
                              })}
                              {NavbarItems.map(item => {
                                if (item.identity && !this.state.member.access.includes(item.identity) && User.access.includes(item.identity)) {
                                  return (
                                    <tr key={item.identity}>
                                      <td className="font-weight-medium">{item.name}</td>
                                      <td>
                                        <button type="button" className="btn btn-outline-light" onClick={e => { this.addAccess(item.identity, 'top', item) }}>
                                          <i className="fa fa-caret-right"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 grid-margin stretch-card">
                      <Form.Group className="permissions-list">
                        <Form.Label>{_t('User Permissions')}</Form.Label>
                        <div className="table-responsive permissions-list">
                          <table className="table">
                            <tbody>
                              {SidebarItems.map(item => {
                                if (!item.sub) {
                                  if (item.identity && this.state.member.access.includes(item.identity)) {
                                    return (
                                      <tr key={item.identity}>
                                        <td className="font-weight-medium">{item.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(item.identity, 'top', item) }}>
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return item.sub.filter(subitem => {
                                    return subitem.identity && this.state.member.access.includes(subitem.identity);
                                  }).map(subitem => {
                                    return (
                                      <tr key={subitem.identity}>
                                        <td className="font-weight-medium">{item.name} / {subitem.name}</td>
                                        <td>
                                          <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(subitem.identity, 'sub', item) }}>
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  });
                                }
                              })}
                              {NavbarItems.map(item => {
                                if (item.identity && this.state.member.access.includes(item.identity)) {
                                  return (
                                    <tr key={item.identity}>
                                      <td className="font-weight-medium">{item.name}</td>
                                      <td>
                                        <button type="button" className="btn btn-outline-light" onClick={e => { this.removeAccess(item.identity, 'top', item) }}>
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.organization.adminId === User.userId &&
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card text-right wy-db-controls">
              {this.state.member.organizationId && this.state.organization.admins && this.state.organization.admins.includes(this.state.memberId) ? 
                <button type="button" className="btn btn-outline-primary" onClick={this.unmakeAdmin}>{_t('Remove Organization Admin Privileges')}</button>
                :
                <button type="button" className="btn btn-outline-primary" onClick={this.makeAdmin}>{_t('Make Organization Admin')}</button>
              }
              </div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

export {
  EditMember
};