import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../../assets/styles/core/shared/Screen.scss';
import User from '../stores/User';
import Preferences from '../stores/Preferences';
import Platform from '../stores/Platform';

let triggers = {};
let toggles = {};

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: 'caret-right',
      visible: props.screenId === '1' || props.show ? true : false
    };
  }
  
  sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));
  
  componentDidMount () {
    if (toggles[this.props.screenId]) {
      triggers[this.props.screenId] = () => {
        this.toggle();
      };
    } else {
      this.setState({
        toggle: true
      });
    }
  }
  
  componentWillUnmount () {
    if (toggles[this.props.screenId]) {
      delete triggers[this.props.screenId];
    }
  }
  
  toggle = (e) => {
    this.setState({
      visible: !this.state.visible
    });
  };
  
  toggleSidebar = (e) => {
    if (!this.state.visible) {
      return;
    }
    this.setState({
      visible: !this.state.visible
    });
  }
  
  containerToggleSidebar = (e) => {
    if (this.state.visible) {
      return;
    }
    this.setState({
      visible: !this.state.visible
    });
  }
  
  render () {
    if (!Platform.embed) {
      return (
        <div className={this.state.visible || this.props.show ? `screen ${this.state.toggle ? 'include-toggle' : ''} visible` : `screen ${this.state.toggle ? 'include-toggle' : ''}`} onClick={this.containerToggleSidebar}>
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div></div>  
      );
    }
  }
}

class ScreenToggle extends Component {
  constructor(props) {
    super(props);
    toggles[this.props.screenId] = true;
  }
  
  componentDidMount () {
    
  }
  
  onClickOpen = (e) => {
    if (!triggers[this.props.screenId]) {
      return;
    }
    triggers[this.props.screenId](e.target);
  }
  
  render () {
    return (
      <div className={this.props.className ? `${this.props.className} screen-toggle` : 'screen-toggle'} onClick={this.onClickOpen} style={{
        color: this.props.color ? this.props.color : '#1093c9'
      }}>
        {!this.props.children &&
          <i className={this.props.icon ? `fa fa-${this.props.icon}` : 'fa fa-info-circle'}></i>
        }
        {this.props.children &&
          <div>{this.props.children}</div>
        }
      </div>
    );
  }
}

export {
  Screen,
  ScreenToggle
};