import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { _t } from '../../core/stores/Translator.js';
import { LivePostsHeadless } from '../live/LivePosts';

import HeaderV2 from '../../core/shared/HeaderV2';

import Platform from '../../core/stores/Platform';

class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: true,
      showPrice: false,
      showEmail: false,
      search: {
        keywords: ''
      },
      email: '',
      error: ''
    };
  }
  
  onSubmit = async () => {
    this.setState({
      error: ''
    });
    let result = await (await fetch('/api/public/searches/start/v2', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        search: this.state.search,
        email: this.state.email,
      })
    })).json();
    if (result.status == 'success') {
      this.props.history.push({
        pathname: '/signup',
        state: { 
          email: this.state.email
        }
      });
    } else {
      if (result.message === 'already registered') {
        this.props.history.push({
          pathname: '/login',
          state: { 
            email: this.state.email
          }
        });
      } else if (result.message === 'already using') {
        this.props.history.push({
          pathname: '/signup',
          state: { 
            email: this.state.email
          }
        });
      } else {
        this.setState({
          error: result.message
        });
      }
    }
  }
  
  handleChange = (e, type) => {
    let search = this.state.search;
    search[type] = e.target.value;
    this.setState({
      search: search
    });
  }
  
  onNext = () => {
    this.setState({
      showSearch: false,
      showPrice: this.state.showSearch ? true : false,
      showEmail: this.state.showPrice ? true : false
    })
  }
  
  emailChange = (e) => {
    let email = e.target.value;
    this.setState({
      email: email
    });
  }
  
  render() {
    return (
      <div className="ba-start" tabIndex="0">
        {!Platform.embed &&
          <HeaderV2 />
        }
        <div className="bg-image d-flex vertical-center align-items-center">
          <div className="bg-image-overlay"></div>
          <div className="d-flex align-items-center start px-2 py-5 mx-auto mt-7">
            <div className="row w-100 mx-0">
              <div className="col-lg-8 mx-auto flex-reset">
                <div className="start-form text-left my-10 py-5 px-4 px-sm-5">
                  <Form>
                    
                    <h3 className="title">{_t('Get notified when new items become available!')}</h3>
                    
                    <p style={{
                      display: this.state.showSearch ? 'block': 'none'
                    }} className="subtitle mt-3 ml-1">{_t('What item are you looking to buy?')}</p>
                    <p style={{
                      display: this.state.showPrice ? 'block': 'none'
                    }} className="subtitle mt-3 ml-1">{_t('How much do you want to spend?')}</p>
                    <p style={{
                      display: this.state.showEmail ? 'block': 'none'
                    }} className="subtitle mt-3 ml-1">{_t('You will get notified on this email when we find your item!')}</p>
                    
                    {this.state.error &&
                      <p className="subtitle text-warning" >{this.state.error}</p>
                    }
                    
                    <Form.Group className="d-flex search-field border-bottom position-relative">
                    
                      <Form.Control style={{
                        display: this.state.showSearch ? 'block': 'none'
                      }} type="text" placeholder={_t('Product')} size="lg" value={this.state.search.keywords} className="search-input h-auto border-0" onChange={e => { this.handleChange(e, 'keywords') }} />
                      <Form.Control style={{
                        display: this.state.showSearch ? 'block': 'none'
                      }}  type="button" value={_t('Start Search')} className="btn btn-success font-weight-medium start-form-bt" onClick={this.onNext} />
                      
                      <Form.Control style={{
                        display: this.state.showPrice ? 'block': 'none'
                      }} type="text" placeholder={`${_t('Min. Price')} (EUR)`} size="lg" value={this.state.search.minPrice} className="search-input h-auto border-0" onChange={e => { this.handleChange(e, 'minPrice') }} />
                      <Form.Control style={{
                        display: this.state.showPrice ? 'block': 'none'
                      }} type="text" placeholder={`${_t('Max. Price')} (EUR)`} size="lg" value={this.state.search.maxPrice} className="search-input h-auto border-0" onChange={e => { this.handleChange(e, 'maxPrice') }} />
                      <Form.Control style={{
                        display: this.state.showPrice ? 'block': 'none'
                      }}  type="button" value={_t('Next')} className="btn btn-success font-weight-medium start-form-bt" onClick={this.onNext} />
                      
                      <Form.Control style={{
                        display: this.state.showEmail ? 'block': 'none'
                      }} type="text" placeholder="korisnik@gmail.com" size="lg" value={this.state.email} className="email-input h-auto border-0" onChange={this.emailChange} />
                      <Form.Control style={{
                        display: this.state.showEmail ? 'block': 'none'
                      }} type="button" value={_t('Complete!')} className="btn btn-success font-weight-medium start-form-bt" onClick={this.onSubmit} />
                    
                    </Form.Group>
                  </Form>
                  <div className="oglasi">
                    <LivePostsHeadless price={true} image={true} location={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className="image-credit">
            Image by <a href="https://pixabay.com/photos/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=984396">Free-Photos</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=984396">Pixabay</a>
          </div>
          */}
        </div>
      </div>
    );
  }
}

export default Start;
