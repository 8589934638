import React, { Component, Fragment } from 'react';
import { Form, Modal, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import User from '../../core/stores/User';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../stores/Translator.js';

class ListNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/user/notifications',
      list: [],
      selected: [],
      search: '',
      organizations: [],
      errors: [],
      warnings: [],
      info: [],
      showMessage: false,
      notification: {}
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateList();
  }
    
  updateList = async () => {
    try {
      let response = await (await fetch('/api/notifications', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items.sort((a, b) => b.createdAt - a.createdAt)
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  onSelectChange = (e, notificationId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(notificationId)) {
        this.setState({
          selected: this.state.selected.concat([notificationId])
        });
      }
    } else {
      if (this.state.selected.includes(notificationId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== notificationId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.notificationId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = () => {
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.notificationId)).map(item => item.notificationId);
    this.setState({
      selected: selected
    });
  }
  
  applyFilters = (list) => {
    return list.filter(item => {
      return (item.note && item.note.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || (item.message && item.message.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
    });
  }
  
  deleteSelected = async (e) => {
    this.setState({
      updating: true
    }, async () => {
      try {
        if (window.confirm('Notifications are unrecoverable after this action, are you sure?')) {
          let selected = this.state.selected;
          let response = await (await fetch('/api/notifications/delete/bulk', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              notificationIds: selected
            })
          })).json();
          if (response.status !== 'success') {
            throw Error('Something went wrong');
          }
          this.setState({
            list: this.state.list.filter(item => !selected.includes(item.notificationId)),
            selected: []
          });
        }
      } catch (err) {
        this.setState({
          errors: this.state.errors.concat(err.toString())
        });
      }
      this.setState({
        updating: true
      });
      await this.updateList();
    });
  }
  
  markAsRead = async (e) => {
    try {
      let selected = this.state.selected;
      for (let i in selected) {
        let response = await (await fetch('/api/notifications/read', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            notificationId: selected[i]
          })
        })).json();
        if (response.status !== 'success') {
          throw Error('Something went wrong');
        }
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== selected[i];
          })
        });
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    await this.updateList();
  }
  
  showMessage = async (e, notificationId) => {
    e.preventDefault();
    this.setState({
      showMessage: true,
      notification: this.state.list.filter(notification => notification.notificationId === notificationId)[0]
    });
    try {
      let response = await (await fetch('/api/notifications/read', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          notificationId: notificationId
        })
      })).json();
      if (response.status !== 'success') {
        throw Error('Something went wrong');
      }
      this.setState({
        selected: this.state.selected.filter(item => {
          return item !== notificationId;
        })
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    await this.updateList();
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Notifications': ''
        }}>
          <Dropdown>
            <Dropdown.Toggle variant="btn btn-outline-primary" disabled={this.state.selected.length == 0}>{_t('Actions')}</Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              <Dropdown.Item className="pt-3 pb-3" onClick={this.markAsRead}>{_t('Mark As Read')}</Dropdown.Item>
              <Dropdown.Divider className="m-0"></Dropdown.Divider>
              {/*<Dropdown.Header>Disable</Dropdown.Header>*/}
              <Dropdown.Item className="pt-3 pb-3" onClick={this.deleteSelected}>{_t('Delete')}</Dropdown.Item>
              {/*<Dropdown.Divider></Dropdown.Divider>*/}
            </Dropdown.Menu>
          </Dropdown>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Control type="text" size="sm" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
                  </Form.Group>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          <th>{_t('Notification')}</th>
                          <th>{_t('Message')}</th>
                          <th>{_t('Date Received')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => { 
                          return (
                            <tr key={item.notificationId} className={this.state.selected.includes(item.notificationId) ? 'table-primary' : '' }>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.notificationId)} onChange={e => this.onSelectChange(e, item.notificationId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              <td><Link to={item.action} className={item.isRead ? '' : 'font-weight-bold'} onClick={e => {this.showMessage(e, item.notificationId)}}>{item.note ? item.note : ''}</Link></td>
                              <td>{item.message.length > 82 ? `${item.message.substr(0, 82)}...` : item.message}</td>
                              <td>{(() => {
                                let seconds = item.createdAt
                                /*if (`${item.createdAt}`.length > 9) {
                                  seconds = Math.round(item.createdAt / 1000);
                                }*/
                                let date = new Date(0);
                                date.setUTCSeconds(seconds);
                                let locale = date.toLocaleDateString();
                                return (
                                  <span>{locale}</span>
                                );
                              })()}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal dialogClassName="modal-60w" show={ this.state.showMessage} onHide={() => { this.setState({ showMessage: false })}} style={{ paddingTop: '75px' }}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.notification.note}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 'calc(100vh - 30px - 30px - 75px - 57px - 60px - 2px)', minHeight: '62vh', overflowY: 'auto' }}>
              <span style={{ whiteSpace: 'pre-line' }}>
                {this.state.notification.message}
              </span>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => { this.setState({ showMessage: false })}}>{_t('Close')}</button>
            </Modal.Footer>
            <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
          </Modal>
        </div>
      </Fragment>
    );
  } 
}

export {
  ListNotifications
};