import React, { Component, Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import Navigation from '../../core/shared/NavigationV2';

class AdminSettings extends Component {
   constructor(props) {
    super(props);
    this.state = {
      path: '/admin/settings'
    };
  }
  
  render () { 
    return (
      <Fragment>
        <Navigation routes={{
          'Admin Settings': ''
        }}>
        </Navigation>
        <div className="inner-container">
        
        </div>
      </Fragment>
    );
  }
}

export default AdminSettings;