import React, { Component } from 'react';
import { Form, Dropdown, Pagination, FormControl  } from 'react-bootstrap';
import '../../assets/styles/core/utils/CheckboxTree.scss';

export class CheckboxTree extends Component {
  constructor(props) {
    super(props);
    let internal = {};
    props.nodes.forEach(node => {
      internal[node.value] = false;
      if (node.children) {
        node.children.map(node => {
          internal[node.value] = false;
          if (node.children) {
            node.children.map(node => {
              internal[node.value] = false;
            });
          }
        });
      }
    });
    this.state = {
      search: '',
      nodes: props.nodes,
      internal: internal
    };
  }
  
  componentDidMount () {
    
  }
  
  componentWillUnmount() {
    
  }
  
  onChange = (e) => {
    let value = e.target.getAttribute('value');
    let nodes = this.state.nodes;
    let internal = this.state.internal;
    let match = false;
    for (let x in nodes) {
      if (nodes[x].value === value) {
        nodes[x].selected = !nodes[x].selected;
        if (nodes[x].selected) {
          internal[nodes[x].value] = true;
        }
        if (nodes[x].children) {
          for (let y in nodes[x].children) {
            nodes[x].children[y].selected = nodes[x].selected;
            if (nodes[x].selected) {
              internal[nodes[x].children[y].value] = true;
            }
            if (nodes[x].children[y].children) {
              for (let z in nodes[x].children[y].children) {
                 nodes[x].children[y].children[z].selected = nodes[x].selected;
              }
            }
          }
        }
        match = true;
        break;
      }
      if (nodes[x].children) {
        for (let y in nodes[x].children) {
          if (nodes[x].children[y].value === value) {
            nodes[x].children[y].selected = !nodes[x].children[y].selected;
            if (nodes[x].children[y].selected) {
              nodes[x].selected = true;
              internal[nodes[x].children[y].value] = true;
            }
            if (nodes[x].children[y].children) {
              for (let z in nodes[x].children[y].children) {
                nodes[x].children[y].children[z].selected = nodes[x].children[y].selected;
              }
            }
            match = true;
            break;
          }
          if (nodes[x].children[y].children) {
            for (let z in nodes[x].children[y].children) {
              if (nodes[x].children[y].children[z].value === value) {
                nodes[x].children[y].children[z].selected = !nodes[x].children[y].children[z].selected;
                if (nodes[x].children[y].children[z].selected) {
                  nodes[x].children[y].selected = true;
                  nodes[x].selected = true;
                }
                match = true;
                break;
              }
            }
          }
          if (match) {
            break;
          }
        }
      }
      if (match) {
        break;
      }
    }
    this.setState({
      nodes: nodes,
      internal: internal
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.nodes);
      }
    });
  }
  
  async componentWillReceiveProps (nextProps) {
    if (JSON.stringify(nextProps.nodes) !== JSON.stringify(this.state.nodes)) {
      let internal = this.state.internal;
      nextProps.nodes.forEach((node, x) => {
        internal[node.value] = internal[node.value] ? internal[node.value] : false;
        if (node.children) {
          node.children.map((node, y) => {
            internal[node.value] = internal[node.value] ? internal[node.value] : false;
            if (node.children) {
              node.children.map((node, z) => {
                internal[node.value] = internal[node.value] ? internal[node.value] : false;
              });
            }
          });
        }
      })
      this.setState({
        nodes: nextProps.nodes,
        internal: internal
      });
    }
  }
  
  render () {
    return (
      <div className={this.props.className ? `${this.props.className} checkbox-tree ${this.props.disabled ? 'disabled' : ''}` : `checkbox-tree ${this.props.disabled ? 'disabled' : ''}`}>
        {this.props.search &&
          <Form.Group>
            <Form.Control type="text" size="md" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder="Search"/>
          </Form.Group>
        }
        <div className="list">
          <ul className="ul-lvl-1">
            {this.state.nodes.map((node, x) => {
              return (
                <li className={!this.state.search || node.label.toLowerCase().includes(this.state.search.toLowerCase()) ? '' : 'hidden'}>
                  <div className={node.children && node.children.length > 0 ? 'expand' : 'expand hidden'} onClick={() => {
                    let internal = this.state.internal;
                    internal[node.value] = !internal[node.value];
                    this.setState({
                      internal: internal
                    });
                  }}>
                    <i className={this.state.internal[node.value] ? 'fa fa-minus' : 'fa fa-plus'}></i>
                  </div>
                  <div className="check">
                    <Form.Group>
                      <div className="form-check form-check-primary">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input wy-db-global-checkbox" checked={node.selected} value={node.value} onChange={this.onChange}/>
                          <i className="input-helper"></i>
                        </label>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="label">{node.label}</div>
                  <div className="children">
                    {node.children && node.children.length > 0 && this.state.internal[node.value] &&
                      <ul className="ul-lvl-2">
                        {node.children.map((node, y) => {
                          return (
                            <li>
                              <div className={node.children && node.children.length > 0 ? 'expand' : 'expand hidden'} onClick={() => {
                                let internal = this.state.internal;
                                internal[node.value] = !internal[node.value];
                                this.setState({
                                  internal: internal
                                });
                              }}>
                                <i className={this.state.internal[node.value] ? 'fa fa-minus' : 'fa fa-plus'}></i>
                              </div>
                              <div className="check">
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input wy-db-global-checkbox" checked={node.selected} value={node.value} onChange={this.onChange}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="label">{node.label}</div>
                              <div className="children">
                                {node.children && node.children.length > 0 && this.state.internal[node.value] &&
                                  <ul className="ul-lvl-3">
                                    {node.children.map((node, z) => {
                                      return (
                                        <li>
                                          <div className="check">
                                            <Form.Group>
                                              <div className="form-check form-check-primary">
                                                <label className="form-check-label">
                                                  <input type="checkbox" className="form-check-input wy-db-global-checkbox" checked={node.selected} value={node.value} onChange={this.onChange}/>
                                                  <i className="input-helper"></i>
                                                </label>
                                              </div>
                                            </Form.Group>
                                          </div>
                                          <div className="label">{node.label}</div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                }
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    }
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default CheckboxTree;