import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import User from '../../core/stores/User';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import Loader from '../stores/Loader';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../stores/Translator.js';

class AccountRequests extends Component {
   constructor(props) {
    super(props);
    this.state = {
      path: '/admin/accounts',
      list: [],
      selected: [],
      search: '',
      errors: [],
      warnings: [], 
      info: [],
    };
  }
  
  updateList = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/admin/accounts', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items
      });
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateList();
  }
  
  componentWillUnmount () {
    this._ismounted = false;
  }
  
  applyFilters = (list) => {
    return list.filter(item => {
      return item.username.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
    });
  }
  
  onSelectChange = (e, requestId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(requestId)) {
        this.setState({
          selected: this.state.selected.concat([requestId])
        });
      }
    } else {
      if (this.state.selected.includes(requestId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== requestId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.requestId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = () => {
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.requestId)).map(item => item.requestId);
    this.setState({
      selected: selected
    });
  };
  
  approveSelected = async () => {
    Loader.show();
    try {
      if (window.confirm(this.state.selected.length === 1 ? _t(`Approve selected request?`) : `${_t('Approve')} ${this.state.selected.length} ${_t('selected requests?')}`)) {
        let selected = this.state.selected;
        for (let i in selected) {
          let response = await (await fetch('/api/admin/accounts/new', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              requestId: selected[i]
            })
          })).json();
          if (response.status !== 'success') {
            this.setState({
              errors: this.state.errors.concat(response.message)
            });
          } else {
            this.setState({
              selected: this.state.selected.filter(item => {
                return item !== selected[i];
              })
            }, this.updateList);
          }
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  removeSelected = async () => {
    Loader.show();
    try {
      if (window.confirm(this.state.selected.length === 1 ? _t(`Remove selected request?`) : `${_t('Remove')} ${this.state.selected.length} ${_t('selected requests?')}`)) {
        let selected = this.state.selected;
        for (let i in selected) {
          let response = await (await fetch('/api/admin/accounts/delete', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              requestId: selected[i]
            })
          })).json();
          if (response.status !== 'success') {
            this.setState({
              errors: this.state.errors.concat(response.message)
            });
          } else {
            this.setState({
              selected: this.state.selected.filter(item => {
                return item !== selected[i];
              })
            }, this.updateList);
          }
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  render() {
    return (
      <Fragment>
        <Navigation routes={{
          'Account Requests': ''
        }}>
          <Dropdown>
            <Dropdown.Toggle variant="btn btn-outline-primary" disabled={this.state.selected.length == 0}>{_t('Actions')}</Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {/*<Dropdown.Header>Disable</Dropdown.Header>*/}
              <Dropdown.Item className="pt-3 pb-3" as={Link} to={ () => {
                let user = this.state.list.filter(item => item.requestId === this.state.selected[0])[0];
                if (!user) {
                  return {};
                }
                return {
                  pathname: "/admin/users/new",
                  state: { 
                    user: user
                  }
                }
              }} disabled={this.state.selected.length === 0 || this.state.selected.length > 1}>{_t('Approve')}</Dropdown.Item>
              <Dropdown.Divider className="m-0"></Dropdown.Divider>
              <Dropdown.Item className="pt-3 pb-3" onClick={this.removeSelected}>{_t('Delete')}</Dropdown.Item>
              {/*<Dropdown.Divider></Dropdown.Divider>*/}
            </Dropdown.Menu>
          </Dropdown>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Control type="text" size="sm" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
                  </Form.Group>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                       <tr>
                          <th>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          <th>{_t('Email')}</th>
                          <th>{_t('Username')}</th>
                          <th>{_t('Name')}</th>
                          <th>{_t('Sign Up Date')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => { 
                          return (
                            <tr key={item.requestId} className={this.state.selected.includes(item.requestId) ? 'table-primary' : ''}>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.requestId)} onChange={e => this.onSelectChange(e, item.requestId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              <td>{item.email}</td>
                              <td>{item.username}</td>
                              <td>{item.name}</td>
                              <td>{(() => {
                                let seconds = item.createdAt / 1000;
                                if (seconds) {
                                  /*if (`${item.updatedAt}`.length > 9) {
                                  seconds = Math.round(item.updatedAt / 1000);
                                  }*/
                                  let date = new Date(0);
                                  date.setUTCSeconds(seconds);
                                  let locale = date.toLocaleDateString();
                                  return (
                                    <span>{locale}</span>
                                  );
                                } else {
                                  return (
                                    <span>-</span>
                                  );
                                }
                              })()}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
        </div>
      </Fragment>
    )
  }
  
}

export {
  AccountRequests
};
