import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Navigation from '../../core/shared/NavigationV2';
import { InnerSidebar, InnerSidebarToggle, InnerSidebarExpanded } from '../../core/shared/InnerSidebar';
import { Screen, ScreenToggle } from '../../core/shared/Screen';
import DateSelect from '../../core/utils/DateSelect';
import { SearchableSelect } from '../../core/utils/SearchableSelect';
import { SearchableMultiSelect } from '../../core/utils/SearchableMultiSelect';
import { HelperOverlay, HelperBubble, HelperButton } from '../../core/utils/Helper';
import { ListGroup, Nav } from 'react-bootstrap';

class Dashboard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedItem: '',
      selectedItems: []
    };
  }
  
  render () {
    return (
      <Fragment>
        <Screen screenId="1">
          <Navigation routes={{
            'Dashboard': '/'
          }}>
            <DateSelect onChange={({from, to, utc}) => {}} />
            <HelperButton className="ml-2" helperId="tst001" />
            <HelperButton className="ml-2" helperId="tst002" icon="question-circle" color="orange"/>
            <HelperBubble helperId="tst002" message="This is a message that should be read very very loudly and with specific northern british accent. You shall become the son of legends if you do this."/>
          </Navigation>
          <InnerSidebar sidebarId="1">
            
          </InnerSidebar>
          <div className={`inner-container has-inner-sidebar has-toggle ${InnerSidebarExpanded('1') ? 'expanded' : ''}`}>
            <InnerSidebarToggle sidebarId="1">TOGGLE SIDEBAR</InnerSidebarToggle>
            <ScreenToggle screenId="2">OPEN SCREEN #2</ScreenToggle>
          </div>
          <HelperOverlay helperId="tst001" steps={[{
            selector: '.date-select',
            message: 'Hello worldy'
          }, {
            selector: '.inner-sidebar',
            message: 'You can use this filter to filter the data.'
          }, {
            selector: '.copyright',
            message: 'You can use this filter to filter the data.'
          }]}/>
        </Screen>
        <Screen screenId="2">
          <Navigation routes={{
            'Screen #2': '/screen'
          }}>
            <DateSelect onChange={({from, to, utc}) => {}} />
          </Navigation>
          <InnerSidebar sidebarId="2">
            
          </InnerSidebar>
          <div className="inner-container has-inner-sidebar has-toggle">
            <InnerSidebarToggle sidebarId="2">TOGGLE SIDEBAR</InnerSidebarToggle>
            <ScreenToggle screenId="2">CLOSE SCREEN #2</ScreenToggle>
          </div>
        </Screen>
      </Fragment>
    );
  }
}

export default Dashboard;