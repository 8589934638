import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, InputGroup } from 'react-bootstrap';
import User from '../stores/User';
import Preferences from '../stores/Preferences';
import { _t } from '../stores/Translator.js';
import HeaderV2 from '../../core/shared/HeaderV2';
import LoginInformation from '../../app/user/Login';
import Platform from '../stores/Platform';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      showPassword: false
    };
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  }
  
  onSubmit = async () => {
    this.setState({
      error: ''
    });
    let result = await (await fetch('/api/session/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.emailRef.current.value,
        password: this.passwordRef.current.value
      })
    })).json();
    if (result.status == 'success') {
      User.isLoggedIn = true;
      User.isAdmin = result.data.isAdmin;
      User.userId = result.data.userId;
      User.ownerId = result.data.organizationId || result.data.userId;
      User.email = result.data.email;
      User.username = result.data.username;
      User.name = result.data.name;
      // User.website = result.data.website;
      User.access = result.data.access;
      User.organization = result.data.organization;
      User.info = result.data.info;
      User.profileImage = result.data.profileImage;
      if (result.data.redirect && result.data.redirect !== '/login') {
        this.props.history.push(result.data.redirect);
      } else {
        this.props.history.push('/searches');
      }
    } else {
      this.setState({
        error: result.message
      });
    }
  }
  
  onKeyUp = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit();
    }
  }
  
  componentDidMount () {
    window.document.addEventListener('keyup', this.onKeyUp);
    if (this.props.location && this.props.location.state && this.props.location.state.email) {
      this.emailRef.current.value = this.props.location.state.email;
      this.passwordRef.current.focus();
    }
  }
  
  componentWillUnmount () {
    window.document.removeEventListener('keyup', this.onKeyUp);
  }
  
  render() {
    return (
      <div className="wy-login" tabIndex="0">
        {!Platform.embed &&
          <HeaderV2 />
        }
        <div className="bg-image d-flex vertical-center align-items-center">
          <div className="d-flex align-items-center auth px-2 py-5 mx-auto mt-7">
            <div className="row w-100 mx-0">
              <div className="col-lg-8 mx-auto flex-reset">
                <div className="auth-form-light text-left my-10 py-5 px-4 px-sm-5">
                  <h1 className="text-center">{_t(LoginInformation.title)}</h1>
                  <p className="error">{this.state.error}</p>
                  <Form>
                    <Form.Group className="d-flex search-field border-bottom position-relative">
                      <Form.Control test-id="input_email" type="email" placeholder={_t('Email')} size="lg" className="h-auto border-0" ref={this.emailRef} />
                    </Form.Group>
                    <Form.Group className="d-flex search-field border-bottom">  
                      <InputGroup >
                        <Form.Control type={this.state.showPassword ? 'text' : 'password'} test-id="input_password" placeholder={_t('Password')} size="lg" className="h-auto border-0" ref={this.passwordRef} />
                        <InputGroup.Append onClick={e => {this.setState({ showPassword: this.state.showPassword ? false : true}) }} >
                          <InputGroup.Text style={{"fontSize": '0.675rem'}} className="pl-2 pr-2 text-secondary bg-white border-0">
                            <span className={this.state.showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></span>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    {/*<Form.Group className="d-flex search-field border-bottom">
                      <Form.Control type="password" placeholder="Password" size="lg" className="h-auto border-0" ref={this.passwordRef} />
                    </Form.Group>
                    */}
                    <Form.Group className="mt-3 d-flex justify-content-center align-items-center">
                      <Form.Control test-id="button_sign-in" type="button" value={_t('SIGN IN')} className="btn btn-primary btn-lg font-weight-medium auth-form-bt blue-bg" onClick={this.onSubmit} />
                    </Form.Group>
                    {!Platform.embed &&
                      <div className="my-2 d-flex justify-content-center align-items-center forgot-password">
                        <Link test-id="link_reset-password" to="/reset_password" className="auth-link text-black mt-3">{_t('Forgot password?')}</Link>
                      </div>
                    }
                    <div className="d-none">
                      <div className="dividerline"></div>
                        <div className="text-center mt-4 font-weight-light">
                          <h4 className="text-muted">{_t('Create a Free Account')}</h4>
                          <p className="mt-3">{_t('Setting up an account is easy and only takes a few minutes!')}</p>
                          <div className="my-2 d-flex justify-content-center align-items-center">
                            <Link to="/signup" className="btn btn-block btn-outline-primary btn-lg font-weight-medium auth-form-btn account">CREATE FREE ACCOUNT</Link>
                          </div>
                        </div>
                      </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    );
  }
}

export default Login;
