import Env from './Env';
import Preferences from './Preferences';

let languages = window.LANG.languages;
let language = Env.DEFAULT_LANG;
if (Preferences['lang']) {
  language = Preferences['lang'];
}
let translations = window.LANG.translations[language] ? window.LANG.translations[language] : {};

const _t = (input) => translations[input] ? translations[input] : input;

export default {
  languages: () => languages,
  language: () => language,
  changeLanguage: async (lang) => {
    Preferences.update('lang', lang);
    language = lang;
    translations = window.LANG.translations[language] ? window.LANG.translations[language] : {};
  }
};
export {
  _t
};
