import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

class Releases extends Component {
  render() {
    return (
      <div>
        {/*
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-header">
                Oct. 16, 2020
              </div>
              <div className="card-body">
                <div>
                  - Added friendly release updates ;)
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
      </div>
    );
  }
  
}

export default Releases;