import { extendObservable } from 'mobx';

class Platform {
  constructor() {
    extendObservable(this, {
      app: false,
      embed: false,
      desktop: false,
      web: false,
      name: ''
    });
  }
}

export default new Platform();