import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';

import Preferences from '../stores/Preferences';
import Platform from '../stores/Platform';
import { _t } from '../stores/Translator.js';

class Navigation extends Component {
  constructor(props) {
    super(props);
  }
  
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  
  toggleSidebar() {
    Preferences.update('sidebar.collapsed', !Preferences['sidebar.collapsed']);
    document.body.classList.toggle('sidebar-icon-only');
  }

  addToggleSidebar() {
    Preferences.update('sidebar.collapsed', !Preferences['sidebar.collapsed']);
    document.body.classList.add('sidebar-icon-only');
  }
  
  removeToggleSidebar() {
    Preferences.update('sidebar.collapsed', !Preferences['sidebar.collapsed']);
    document.body.classList.remove('sidebar-icon-only');
  }
  
  render () {
    let routesLength = Object.entries(this.props.routes).length;
    if (!Platform.embed) {
      return (
        <div className="page-header justify-content-initial hide-toggler" >
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={this.toggleSidebar} >
            <i className="mdi mdi-menu"></i>
          </button>
          <div className="nav-routes">
            {Object.entries(this.props.routes).map(([pageTitle, route], i) => {
              if (routesLength - 1 === i) {
                return (
                  <Fragment>
                    <span className="text-muted text-decoration-none" style={{fontSize: '11px', cursor: 'pointer', whiteSpace: 'nowrap' }}>{_t(pageTitle)}</span>
                  </Fragment>
                );
              } else {
                return (
                  <Fragment>
                    <Link to={route} className="text-muted text-decoration-none" style={{fontSize: '11px', whiteSpace: 'nowrap' }}>{_t(pageTitle)}</Link>
                    {i < routesLength - 1 &&
                      <span className="fa fa-angle-right ml-1 mr-1"></span>
                    }
                  </Fragment>
                );
              }
            })}
          </div>
          <div className="nav-components">
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div></div>  
      );
    }
  }
}

export default Navigation;