export default {
  'searches.columns': {
    'Search': { title: 'Search', enabled: true },
    'Category': { title: 'Category', enabled: true },
    'Keywords': { title: 'Keywords', enabled: true },
    'State': { title: 'State', enabled: true },
    'Match Description': {title: 'Match Description', enabled: true },
    'Exchange Type': { title: 'Exchange Type', enabled: true },
    'Min. Price': { title: 'Min. Price', enabled: true },
    'Max. Price': { title: 'Max. Price', enabled: true },
    'Categories': { title: 'Categories', enabled: true },
    'Locations': { title: 'Locations', enabled: true },
    'Date Created': { title: 'Date Created', enabled: true }
  },
  'results.columns': {
    'Name': { title: 'Name', enabled: true },
    'Preview': { title: 'Preview', enabled: true },
    'Type': { title: 'Type', enabled: true },
    'Price':{ title: 'Price', enabled: true},
    'Platform': {title: 'Platform', enabled: true },
    'Location': { title: 'Location', enabled: true },
    'Categories': { title: 'Categories', enabled: true },
    'Description': { title: 'Description', enabled: true },
    'Date Posted': { title: 'Date Posted', enabled: true }
  },
  'wishes.columns': {
    'Name': { title: 'Name', enabled: true },
    'Preview': { title: 'Preview', enabled: true },
    'Type': { title: 'Type', enabled: true },
    'Price':{ title: 'Price', enabled: true},
    'Platform': {title: 'Platform', enabled: true },
    'Location': { title: 'Location', enabled: true },
    'Categories': { title: 'Categories', enabled: true },
    'Description': { title: 'Description', enabled: true },
    'Date Posted': { title: 'Date Posted', enabled: true }
  },
};