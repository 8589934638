import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../core/shared/Spinner';

import Start from './home/Start';
import Stop from './search/Stop';
import { LivePosts } from './live/LivePosts';

class PublicRoutes extends Component {
  
  state = {};
  
  render () {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          
          {/*<Route path="/live" component={ LivePosts } />*/}
          
          <Route exact path="/stop" component={ Stop } />
          
          <Route exact path="/start" component={ Start } />
          
          <Redirect to="/start" />
          
        </Switch>
      </Suspense>
    );
  }
  
}

export default PublicRoutes;