import React, { Component, Fragment } from 'react';
import { Form, Dropdown, Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import User from '../stores/User';
import { isMobile } from "react-device-detect";
import { Info, Warnings, Errors } from '../utils/Notices';
import Loader from '../stores/Loader';
import Navigation from '../../core/shared/NavigationV2';
import { _t } from '../stores/Translator.js';

class NewPageNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/notices/new',
      search: '',
      once: false,
      type: 'info',
      message: '',
      page: '',
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  save = async () => {
    try {
      if (!this.state.message.trim()) {
        throw Error('Message is required!');
      }
      if (window.confirm(_t('Add notice?'))) {
        let response = await (await fetch('/api/admin/notices/new', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            type: this.state.type,
            message: this.state.message,
            once: this.state.once,
            page: this.state.page
          })
        })).json();
        if (response.status !== 'success') {
          throw Error('Something went wrong');
        }
        this.setState({
          info: this.state.info.concat(`Successfully added a ${this.state.type} notice.`),
          once: false,
          type: '',
          message: '',
          page: ''
        });
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Notices': '/admin/notices',
          'New': ''
        }}>
          <Link className="mr-2" to="/admin/notices">
            <button type="button" className="btn btn-outline-primary">{_t('Back')}</button>
          </Link>
          <button type="button" className="btn btn-outline-primary" onClick={this.save}>{_t('Save')}</button>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <div className="row">
                      <div className="col-10">
                        <Form.Label>{_t('Type:')}</Form.Label>
                        <select className="form-control form-control-sm" id="type" onChange={(e => {this.setState({ type: e.target.value })})}>
                          <option value={'info'} selected>{_t('Info')}</option>
                          <option value={'warning'}>{_t('Warning')}</option>
                          <option value={'error'}>{_t('Error')}</option>
                        </select>
                      </div>
                      <div className="col-2">
                        <Form.Label></Form.Label>
                        <div className="form-check form-check-primary">
                          <label className="form-check-label">
                           <input type="checkbox" className="form-check-input" checked={this.state.once} onChange={e => {this.setState({ once: e.target.checked })}}/>
                            <i className="input-helper">{_t('Show only once?')}</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{_t('Message:')}</Form.Label>
                    <Form.Control as="textarea" rows="9" value={this.state.message} onChange={(e) => {this.setState({ message: e.target.value })}} placeholder={_t('Page is under maintenance until 16:00PM UTC.')}/>
                  </Form.Group>
                  <div className="w-100">
                    {_t('Preview:')}
                    <br />
                    <br />
                      <div className="row">
                        <div className="col-12">
                          {this.state.type === 'info' &&
                            <Info messages={[this.state.message]} onClose={removeMessage => {}}/>
                          }
                          {this.state.type === 'warning' &&
                            <Warnings messages={[this.state.message]} onClose={removeMessage => {}}/>
                          }
                          {this.state.type === 'error' &&
                            <Errors messages={[this.state.message]} onClose={removeMessage => {}}/>
                          }
                        </div>
                      </div>
                    <br />
                  </div>
                  <Form.Group>
                    <Form.Label>{_t('Page path:')}</Form.Label>
                    <Form.Control type="text" size="sm" value={this.state.page} onChange={(e) => {this.setState({ page: e.target.value })}} placeholder="/user/organizations"/>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } 
}

class ListPageNotices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '/admin/notices',
      list: [],
      selected: [],
      search: '',
      organizations: [],
      errors: [],
      warnings: [],
      info: []
    };
  }
  
  async componentDidMount() {
    this._ismounted = true;
    await this.updateList();
  }
    
  updateList = async () => {
    Loader.show();
    try {
      let response = await (await fetch('/api/admin/notices', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error(response.message);
      }
      this.setState({
        list: response.data.items
      }, this.onFilterChange);
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    Loader.hide();
  }
  
  componentWillUnmount() {
    this._ismounted = false;
  }
  
  onSelectChange = (e, noticeId) => {
    if (e.target.checked) {
      if (!this.state.selected.includes(noticeId)) {
        this.setState({
          selected: this.state.selected.concat([noticeId])
        });
      }
    } else {
      if (this.state.selected.includes(noticeId)) {
        this.setState({
          selected: this.state.selected.filter(item => {
            return item !== noticeId;
          })
        });
      }
    }
  }
  
  onGlobalSelectChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selected: this.applyFilters(this.state.list).map(item => {
          return item.noticeId;
        })
      });
    } else {
      this.setState({
        selected: []
      });
    }
  }
  
  onFilterChange = () => {
    let filteredList = this.applyFilters(this.state.list);
    let selected = filteredList.filter(item => this.state.selected.includes(item.noticeId)).map(item => item.noticeId);
    this.setState({
      selected: selected
    });
  }
  
  applyFilters = (list) => {
    return list.filter(item => {
      return item.message.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
    });
  }
  
  deleteSelected = async (e) => {
    try {
      if (window.confirm(_t('Are you sure?'))) {
        let selected = this.state.selected;
        for (let i in selected) {
          let response = await (await fetch('/api/admin/notices/delete', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              noticeId: selected[i]
            })
          })).json();
          if (response.status !== 'success') {
            throw Error('Something went wrong');
          }
          this.setState({
            list: this.state.list.filter(item => {
              return item.noticeId !== selected[i];
            }),
            selected: this.state.selected.filter(item => {
              return item !== selected[i];
            })
          });
        }
      }
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
    await this.updateList();
  }
  
  render () {
    return (
      <Fragment>
        <Navigation routes={{
          'Page Notices': ''
        }}>
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="btn btn-outline-primary" disabled={this.state.selected.length == 0}>{_t('Actions')}</Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {/*<Dropdown.Header>Disable</Dropdown.Header>*/}
              <Dropdown.Item className="pt-3 pb-3" onClick={this.deleteSelected}>{_t('Delete')}</Dropdown.Item>
              {/*<Dropdown.Divider></Dropdown.Divider>*/}
            </Dropdown.Menu>
          </Dropdown>
          <Link to="/admin/notices/new"><button type="button" className="btn btn-success">{_t('+ New')}</button></Link>
        </Navigation>
        <div className="inner-container">
          <div className="row">
            <div className="col-12">
              <Errors path={this.state.path} messages={this.state.errors} onClose={removeMessage => this.setState({ errors: this.state.errors.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Warnings path={this.state.path} messages={this.state.warnings} onClose={removeMessage => this.setState({ warnings: this.state.warnings.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Info path={this.state.path} messages={this.state.info} onClose={removeMessage => this.setState({ info: this.state.info.filter(message => message !== removeMessage) })}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <Form.Group>
                    <Form.Control type="text" size="sm" value={this.state.search} onChange={(e) => {this.setState({ search: e.target.value }, this.onFilterChange)}} placeholder={_t('Search')} autoFocus={isMobile ? false : true}/>
                  </Form.Group>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <Form.Group>
                              <div className="form-check form-check-primary">
                                <label className="form-check-label">
                                  <input type="checkbox" className="form-check-input" checked={this.state.selected.length > 0 && this.state.selected.length === this.applyFilters(this.state.list).length} onChange={this.onGlobalSelectChange}/>
                                  <i className="input-helper"></i>
                                </label>
                              </div>
                            </Form.Group>
                          </th>
                          <th>{_t('Type')}</th>
                          <th>{_t('Page')}</th>
                          <th>{_t('Message')}</th>
                          <th>{_t('Show only once?')}</th>
                          <th>{_t('Users (unread)')}</th>
                          <th>{_t('Date Created')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.applyFilters(this.state.list).map(item => { 
                          return (
                            <tr key={item.noticeId} className={this.state.selected.includes(item.noticeId) ? 'table-primary' : ''}>
                              <td>
                                <Form.Group>
                                  <div className="form-check form-check-primary">
                                    <label className="form-check-label">
                                      <input type="checkbox" className="form-check-input wy-db-item-checkbox" checked={this.state.selected.includes(item.noticeId)} onChange={e => this.onSelectChange(e, item.noticeId)}/>
                                      <i className="input-helper"></i>
                                    </label>
                                  </div>
                                </Form.Group>
                              </td>
                              <td>{item.type}</td>
                              <td>{item.page}</td>
                              <td>{item.message}</td>
                              <td>{item.once ? 'Yes' : 'No'}</td>
                              <td>{item.users.length}</td>
                              <td>{(() => {
                                let seconds = item.createdAt
                                /*if (`${item.createdAt}`.length > 9) {
                                  seconds = Math.round(item.createdAt / 1000);
                                }*/
                                let date = new Date(0);
                                date.setUTCSeconds(seconds);
                                let locale = date.toLocaleDateString();
                                return (
                                  <span>{locale}</span>
                                );
                              })()}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="fa fa-sync fa-spin"></i></div>
        </div>
      </Fragment>
    );
  } 
}

export {
  NewPageNotice,
  ListPageNotices
};