import React, { Component } from 'react';
import { Form, Dropdown, Pagination, FormControl  } from 'react-bootstrap';
import '../../assets/styles/core/utils/SearchableSelect.scss';

export class SearchableSelect extends Component {
  constructor(props) {
    super(props);
    let selected = null;
    React.Children.forEach(props.children, (element, idx) => {
      if (element.props.selected) {
        selected = element;
      }
    });
    this.state = {
      focused: false,
      overflowed: false,
      activeIndex: -1,
      visibleIndexes: Array.from(Array(React.Children.count(props.children)).keys()),
      selectedValue: selected ? selected.props.value : null,
      selectedElement: selected ? selected : null,
      search: selected ? selected.props.children : '',
      preventBlur: false
      // value: props.value
    };
    this.selectRef = React.createRef();
    this.searchRef = React.createRef();
    React.Children.forEach(props.children, (element, idx) => {
      this[`itemRef${idx}`] = React.createRef();
    });
    this.dropdownRef = React.createRef();
  }
  
  componentDidMount () {
    window.addEventListener('scroll', this.onScroll, true);
    this.onScroll();
    setTimeout(this.onScroll, 1000);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }
  
  onScroll = (e) => {
    if (this.dropdownRef.current) {
      let pos = this.dropdownRef.current.getBoundingClientRect();
      this.setState({
        overflowed: window.innerHeight - pos.top < 320
      });
    }
  }
  
  onFocus = (e) => {
    e.target.select();
    this.setState({
      focused: true
    }, () => {
      this.onScroll();
    });
  }
  
  targetTimeout = () => {
    this.setState({
      preventBlur: true
    }, () => {
      setTimeout(() => {
        this.setState({
          preventBlur: false
        });
      }, 200);
    });
  };

  onUlClick = (e) => {
    this.targetTimeout();
  }
  
  onBlur = (e) => {
    let input = e.target;
    setTimeout(() => {
      if ((!document.activeElement || !input.isSameNode(document.activeElement)) && !this.state.preventBlur) {
        this.setState({
          focused: false,
          activeIndex: -1,
          search: this.state.selectedElement ? this.state.selectedElement.textContent : '',
          visibleIndexes: Array.from(Array(React.Children.count(this.props.children)).keys()),
        });
      }
    });
  }
  
  onChange = (e) => {
    this.setState({
      search: e.target.value,
      selectedValue: null
    }, () => {
      let visibleIndexes = [0];
      React.Children.forEach(this.props.children, (element, idx) => {
        let children = element.props.children;
        if (children && typeof children !== 'string') {
          children = children.join(' ');
        }
        if (typeof this.state.selectedValue === 'string' || !this.state.search || (typeof element.props.value === 'string' && element.props.value.toLowerCase().includes(this.state.search.toLowerCase())) || (typeof children === 'string' && children.toLowerCase().includes(this.state.search.toLowerCase()))) {
          visibleIndexes.push(idx);
        }
      });
      this.setState({
        visibleIndexes: visibleIndexes
      });
    });
  }
  
  onClick = (e) => {
    console.log(e)
    this.targetTimeout();
    if (e.target.classList.contains('disabled')) {
      return;
    }
    // let value = e.target.value;
    let value = e.target.getAttribute('value');
    if (e.target.getAttribute('type') === 'number') {
      value = Number(value);
    }
    let text = e.target.textContent;
    this.setState({
      selectedElement: e.target,
      selectedValue: value,
      search: text,
      // value: value
    }, () => {
      this.selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
    });
  }
  
  onKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        if (this.state.activeIndex > -1) {
          let index = this.state.visibleIndexes[this.state.activeIndex];
          let value = this[`itemRef${index}`].current.getAttribute('value');
          if (this[`itemRef${index}`].current.getAttribute('type') === 'number') {
            value = Number(value);
          }
          let text = this[`itemRef${index}`].current.textContent;
          this.setState({
            selectedElement: this[`itemRef${index}`].current,
            selectedValue: value,
            search: text,
            // value: value
          }, () => {
            this.selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
            this.searchRef.current.blur();
          });
        }
      break;
      case 40:
        (() => {
          let index = this.state.activeIndex + 1;
          this.setState({
            activeIndex: index > (this.state.visibleIndexes.length - 1) ? (this.state.visibleIndexes.length - 1) : index
          });
        })();
      break;
      case 38:
        (() => {
          let index = this.state.activeIndex - 1;
          this.setState({
            activeIndex: index < 0 ? 0 : index
          });
        })();
      break;
      default:
        
      break;
    }
  }
  
  onMouseOver = (index) => {
    this.setState({
      activeIndex: this.state.visibleIndexes.indexOf(index)
    });
  }
  
  sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));
  
  async componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.state.selectedValue) {
      setTimeout(() => {
        let selected = null;
        React.Children.forEach(this.props.children, (element, idx) => {
          if (element.props.selected) {
            selected = element;
          }
        });
        this.setState({
          visibleIndexes: Array.from(Array(React.Children.count(this.props.children)).keys()),
          selectedValue: selected ? selected.props.value : null,
          selectedElement: selected ? selected : null,
          search: selected ? selected.props.children : '',
          // value: nextProps.value
        }, () => {
          // this.selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
        });
        React.Children.forEach(this.props.children, (element, idx) => {
          if (!this[`itemRef${idx}`]) {
            this[`itemRef${idx}`] = React.createRef();
          }
        });
      })
    }
    if (React.Children.toArray(nextProps.children).map(item => item.key).join(',') !== React.Children.toArray(this.props.children).map(item => item.key).join(',')) {
      let selected = null;
      React.Children.forEach(nextProps.children, (element, idx) => {
        if (element.props.selected) {
          selected = element;
        }
      });
      this.setState({
        visibleIndexes: Array.from(Array(React.Children.count(nextProps.children)).keys()),
        selectedValue: selected ? selected.props.value : null,
        selectedElement: selected ? selected : null,
        search: selected ? selected.props.children : '',
        // value: nextProps.value
      }, () => {
        // this.selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
      });
      React.Children.forEach(nextProps.children, (element, idx) => {
        if (!this[`itemRef${idx}`]) {
          this[`itemRef${idx}`] = React.createRef();
        }
      });
    }
  }
  
  render () {
    
    let dropdownStyle = {
      display: this.state.focused ? 'block' : 'none'
    };
    
    if (this.state.overflowed) {
      dropdownStyle.bottom = '100%';
      dropdownStyle.borderBottom = '0px';
    } else {
      dropdownStyle.top = '100%';
      dropdownStyle.borderTop = '0px';
    }
    
    return (
      <div ref={this.dropdownRef} className={this.props.className ? `${this.props.className} searchable-select ${this.props.disabled ? 'disabled' : ''}` : `searchable-select ${this.props.disabled ? 'disabled' : ''}`}>
        <input autocomplete="off" type="text" onFocus={this.onFocus} ref={this.searchRef} onBlur={this.onBlur} placeholder={this.props.placeholder} value={this.state.search} onChange={this.onChange} onKeyDown={this.onKeyDown} disabled={this.props.disabled ? 'disabled' : ''}/>
        <ul style={dropdownStyle} onClick={this.onUlClick}>
          {React.Children.map(this.props.children, (element, idx) => {
            // let children = element.props.children;
            // if (children && typeof children !== 'string') {
            //   children = children.join(' ');
            // }
            // if (typeof this.state.selected === 'string' || !this.state.search || (typeof element.props.value === 'string' && element.props.value.toLowerCase().includes(this.state.search.toLowerCase())) || (typeof children === 'string' && children.toLowerCase().includes(this.state.search.toLowerCase()))) {
            if (this.state.visibleIndexes.includes(idx)) {
              let selected = element.props.selected || this.state.selectedValue === element.props.value;
              let disabled = element.props.disabled;
              return (
                <li 
                  key={element.key} 
                  ref={this[`itemRef${idx}`]} 
                  onMouseDown={this.onClick} 
                  type={typeof element.props.value}
                  value={element.props.value} 
                  onMouseOver={(e) => { this.onMouseOver(idx) }} 
                  className={this.state.activeIndex > -1 && this.state.visibleIndexes[this.state.activeIndex] === idx ? `active ${selected ? 'selected' : ''} ${element.props.disabled ? 'disabled' : ''}` : `${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`} 
                  disabled={disabled ? 'true' : ''}
                >{element.props.children}</li>
              );
            // }
            }
          })}
        </ul>
        <span className="arrow-down"></span>
        <select className="d-none" onChange={this.props.onChange} ref={this.selectRef}>
          {React.Children.map(this.props.children, (element, idx) => {
            return (
              <option key={element.key} type={typeof element.props.value} value={element.props.value} selected={this.state.selectedValue === element.props.value}>{element.props.children}</option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default SearchableSelect;