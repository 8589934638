import { extendObservable } from 'mobx';
import appPreferences from '../../app/stores/Preferences';

class Preferences {
  constructor() {
    let preferences = {
      // 'preferences.fetched': false,
      'sidebar.collapsed': false
    };
    for (const [key, value] of Object.entries(appPreferences)) {
      preferences[key] = value;
    }
    for (const [key, value] of Object.entries(window.PREF)) {
      preferences[key] = value;
    }
    extendObservable(this, preferences);
  }
}

Preferences.prototype.update = function (key, value) {
  this[key] = value;
  fetch('/api/preferences/update', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      key: key,
      value: value
    })
  });
};

Preferences.prototype.save = function (key) {
  if (key) {
      fetch('/api/preferences/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key: key,
          value: this[key]
        })
      });
  } else {
    for (const [key, value] of Object.entries(this)) {
      fetch('/api/preferences/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key: key,
          value: value
        })
      });
    }
  }

};

Preferences.prototype.fetch = async function (key, value) {
  try {
    if (!this['preferences.fetched']) {
      let response = await (await fetch('/api/preferences', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status === 'success') {
        // let preferences = {};
        for (const [key, value] of Object.entries(response.data)) {
          this[key] = value;
          // preferences[key] = value;
        }
        // extendObservable(this, preferences);
        this['preferences.fetched'] = true;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const preferences = new Preferences();

export default preferences;