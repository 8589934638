import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { _t } from '../stores/Translator.js';

import LoginInformation from '../../app/user/Login';

import { LivePostsHeadless } from '../../app/live/LivePosts';

class HeaderV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      live: false
    };
  }
  
  render() {
    return (
      <div>
        <nav className="navbar v2 col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
          <div className="navbar-menu-wrapper d-flex align-items-center front-page-wrapper">
            <div className="navbar-nav navbar-nav-left d-flex align-items-center">
              <a className="logo" href="/searches">
                {isMobile &&
                  <img className="logo-img" src={require("../../assets/images/core/logo-small-w.png")} alt="logo"/>
                }
                {!isMobile &&
                  <img className="logo-img" src={require("../../assets/images/core/logo.png")} alt="logo"/>
                }
              </a>
              {/*
              <a className="nav-item" style={{
                cursor: 'pointer'
              }} onClick={e => { this.setState({ live: !this.state.live }); }}>Live Posts!</a>
              */}
            </div>
            <div className="navbar-nav navbar-nav-right d-flex align-items-center">
              <a href="/signup">{isMobile ? _t('SIGN UP') : _t('FREE SIGN UP')}</a>
              <a href="/login">
                <button className="btn btn-inverse-light">{_t('LOGIN')}</button>
              </a>
            </div>
          </div>
        </nav>
        <div className={this.state.live ? 'live-posts active' : 'live-posts'}>
          <div>
            <div>
              <LivePostsHeadless active={this.state.live} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderV2;