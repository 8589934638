import React, { Component } from 'react';
import Platform from '../../core/stores/Platform';
import { Link, withRouter } from 'react-router-dom';
class FooterExtenstion extends Component {
  render () {
    return (
      <div className="d-sm-flex justify-content-center justify-content-sm-between copyright">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {new Date().getFullYear()} Cloud9 Web Services, Novi Sad. All rights reserved.</span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Confidential</span>
      </div>
    );
  }
}

export default FooterExtenstion;