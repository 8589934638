import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import User from '../stores/User';
import Preferences from '../stores/Preferences';
import Platform from '../stores/Platform';

let triggers = {};
let toggles = {};

let cache = {};

let InnerSidebarExpanded = (id) => {
  if (cache[id]) {
    return cache[id] && cache[id].state && cache[id].state.expanded;
  }
  let stored = window.localStorage.getItem(id);
  if (!stored) {
    return false;
  }
  stored = JSON.parse(stored);
  cache[id] = stored;
  return stored && stored.state && stored.state.expanded;
};

class InnerSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.sidebarId || `${window.location.pathname}-InnerSidebar`,
      icon: 'caret-right',
      expanded: false
    };
    this.persistence = {
      id: props.sidebarId || `${window.location.pathname}-InnerSidebar`,
      state: [
        'expanded'
      ]
    };
    let stored = window.localStorage.getItem(this.persistence.id);
    if (stored) {
      stored = JSON.parse(stored);
      this.state.stored = stored;
      for (const [key, value] of Object.entries(this.state)) {
        if (this.persistence.state.includes(key)) {
          this.state[key] = stored.state[key];
        }
      }
    } else {
      let stored = {
        state: {}
      };
      for (const [key, value] of Object.entries(this.state)) {
        if (this.persistence.state.includes(key)) {
          stored.state[key] = this.state[key];
        }
      }
      this.state.stored = stored;
    }
    if (this.state.expanded) {
      (async () => {
        let innerSidebar = null;
        let attempts = 0;
        let maxAttempts = 10;
        while (!innerSidebar) {
          attempts++;
          if (attempts > maxAttempts) {
            break;
          }
          innerSidebar = this.containerRef && this.containerRef.current ? this.containerRef.current.parentElement.querySelector('.has-inner-sidebar') : null;
          await this.sleep(0.2);
        }
        if (this.containerRef.current) {
          this.containerRef.current.parentElement.querySelector('.has-inner-sidebar').classList.add('expanded');
        }
      })();
    }
    this.containerRef = React.createRef();
  }
  
  setState (state, callback) {
    (async (state) => {
      let stored = this.state.stored;
      for (const [key, value] of Object.entries(state)) {
        if (this.persistence.state.includes(key)) {
          stored.state[key] = state[key];
        }
      }
      super.setState({
        stored: stored
      });
      window.localStorage.setItem(this.persistence.id, JSON.stringify(stored));
      cache[this.persistence.id] = stored;
    })(state);
    super.setState(state, callback);
  }
  
  sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));
  
  componentDidMount () {
    if (toggles[this.state.id]) {
      triggers[this.state.id] = () => {
        this.toggle();
      };
    } else {
      this.setState({
        toggle: true
      });
    }
  }
  
  componentWillUnmount () {
    if (toggles[this.state.id]) {
      delete triggers[this.state.id];
    }
  }
  
  toggle = (e) => {
    this.setState({
      expanded: !this.state.expanded
    });
    if (this.containerRef.current) {
      this.containerRef.current.parentElement.querySelector('.has-inner-sidebar').classList.toggle('expanded');
    }
  };
  
  toggleSidebar = (e) => {
    if (!this.state.expanded) {
      return;
    }
    this.setState({
      expanded: !this.state.expanded
    });
    if (this.containerRef.current) {
      this.containerRef.current.parentElement.querySelector('.has-inner-sidebar').classList.toggle('expanded');
    }
  }
  
  containerToggleSidebar = (e) => {
    if (this.state.expanded) {
      return;
    }
    this.setState({
      expanded: !this.state.expanded
    });
    if (this.containerRef.current) {
      this.containerRef.current.parentElement.querySelector('.has-inner-sidebar').classList.toggle('expanded');
    }
  }
  
  render () {
    if (!Platform.embed) {
      return (
        <div ref={this.containerRef} className={this.state.expanded ? `inner-sidebar ${this.state.toggle ? 'include-toggle' : ''} expanded` : `inner-sidebar ${this.state.toggle ? 'include-toggle' : ''}`} style={{ zIndex: 1006 }} onClick={this.containerToggleSidebar}>
          <div test-id="button_inner-sidebar-toggle" className="toggle" onClick={this.toggleSidebar}>
            <i className={this.state.expanded ? 'fa fa-caret-left' : `fa fa-${this.props.icon || this.state.icon}`}></i>
          </div>
          <div className="elements">
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div></div>  
      );
    }
  }
}

class InnerSidebarToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.sidebarId || `${window.location.pathname}-InnerSidebar`,
    };
    toggles[this.state.id] = true;
    this.containerRef = React.createRef();
  }
  
  componentDidMount () {
    
  }
  
  onClickOpen = (e) => {
    if (!triggers[this.state.id]) {
      return;
    }
    triggers[this.state.id](e.target);
  }
  
  render () {
    return (
      <div ref={this.containerRef} className={this.props.className ? `${this.props.className} inner-sidebar-toggle` : 'inner-sidebar-toggle'} onClick={this.onClickOpen} style={{
        color: this.props.color ? this.props.color : '#1093c9'
      }}>
        {!this.props.children &&
          <i className={this.props.icon ? `fa fa-${this.props.icon}` : 'fa fa-info-circle'}></i>
        }
        {this.props.children &&
          <div>{this.props.children}</div>
        }
      </div>
    );
  }
}

export {
  InnerSidebar,
  InnerSidebarToggle,
  InnerSidebarExpanded
};