import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import User from '../stores/User';
import { isMobile } from "react-device-detect";
import NavbarItems from '../../app/shared/Navbar';
import Translator from '../stores/Translator.js';
import { _t } from '../stores/Translator.js';

class Navbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      notifications: [],
      unread: [],
      unreadCount: 0,
      notificationsInterval: null,
      platforms: [],
      closingNotifications: [],
      organizations: []
    };
  }
  
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  
  async componentDidMount() {
    this._ismounted = true;
    this.platforms();
    // await this.notifications();
    await this.notificationCount();
    this.state.notificationsInterval = setInterval(this.notificationCount, 1000 * 10);
  }
  
  componentWillUnmount() {
    this._ismounted = false;
    clearInterval(this.state.notificationsInterval);
  }
  
  platforms = async () => {
    try {
      let result = await (await fetch('/api/linked/platforms', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (result.status == 'success') {
        this.setState({
          platforms: result.data.platforms
        });
      } 
    } catch (err) {
      this.setState({
        error: err.message
      });
    } 
  }
  
  visit = async (platform) => {
    try {
      let result = await (await fetch('/api/linked/link', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          platformId: platform.platformId
        })
      })).json();
      if (result.status == 'success') {
        window.open(result.data.link);
      } 
    } catch (err) {
      this.setState({
        error: err.message
      });
    } 
  }
  
  notificationCount = async () => {
    try {
      let result = await (await fetch('/api/notifications/count', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (result.status == 'success') {
        this.setState({
          unreadCount: result.data.count
        });
      } 
    } catch (err) {
      this.setState({
        error: err.message
      });
    } 
  }
  
  notifications = async () => {
    try {
      let result = await (await fetch('/api/notifications', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (result.status == 'success') {
        let notifications = result.data.items.filter(notification => !notification.hidden).filter(notification => !this.state.closingNotifications.includes(notification.notificationId)).sort((a, b) => b.createdAt - a.createdAt);
        this.setState({
          notifications: notifications,
          unread: notifications.filter(notification => !notification.isRead)
        });
      } 
    } catch (err) {
      this.setState({
        error: err.message
      });
    } 
  }
  
  readNotifications = async () => {
    this.setState({
      updating: true,
      unreadCount: 0
    }, async () => {
      await this.notifications();
      this.setState({
        updating: false
      });
      let notifications = this.state.notifications.filter(notification => !notification.isRead);
      await (await fetch('/api/notifications/read/bulk', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          notificationIds: notifications.map(notification => notification.notificationId)
        })
      })).json();
    });
  }
  
  followNotification = async (e, notificationId) => {
    let notification = this.state.notifications.filter(notification => notification.notificationId === notificationId)[0];
    await (await fetch('/api/notifications/read', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        notificationId: notificationId
      })
    })).json();
    if (!notification.action.includes('http')) {
      this.props.history.push(notification.action);
    } else {
      window.open(notification.action);
    }
  }
  
  hideNotification = async (e, notificationId) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      this.setState({
        notifications: this.state.notifications.filter(notification => notification.notificationId !== notificationId),
        closingNotifications: this.state.closingNotifications.concat([notificationId])
      });
      await (await fetch('/api/notifications/read', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          notificationId: notificationId
        })
      })).json();
      await (await fetch('/api/notifications/hide', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          notificationId: notificationId
        })
      })).json();
    } catch (err) {
      console.log(err);
      this.setState({
        error: err.message
      });
    }
  }
  
  restoreAdminAccount = async () => {
    try {
      let response = await (await fetch('/api/admin/user/restore', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error('Something went wrong');
      }
      User.isAdmin = response.data.isAdmin;
      User.userId = response.data.userId;
      User.ownerId = response.data.organizationId || response.data.userId;
      User.username = response.data.username;
      User.email = response.data.email;
      User.name = response.data.name;
      User.access = response.data.access;
      User.organization = response.data.organization;
      User.info = response.data.info;
      User.impersonator = response.data.impersonator;
      User.viewer = response.data.viewer;
      window.location.href = '/admin/users';
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  restoreViewerAccount = async () => {
    try {
      let response = await (await fetch('/api/organization/member/restore', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (response.status !== 'success') {
        throw Error('Something went wrong');
      }
      User.isAdmin = response.data.isAdmin;
      User.userId = response.data.userId;
      User.ownerId = response.data.organizationId || response.data.userId;
      User.username = response.data.username;
      User.email = response.data.email;
      User.name = response.data.name;
      User.access = response.data.access;
      User.organization = response.data.organization;
      User.userInfo = response.data.userInfo;
      User.organizationInfo = response.data.organizationInfo;
      User.impersonator = response.data.impersonator;
      User.viewer = response.data.viewer;
      window.location.href = '/organization';
    } catch (err) {
      this.setState({
        errors: this.state.errors.concat(err.toString())
      });
    }
  }
  
  changeLanguage = async (language) => {
    await Translator.changeLanguage(language);
    window.location.reload();
  }
  
  render () {
    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center">
          {this.state.platforms.length > 0 &&
            <Dropdown className="nav-apps">
              <Dropdown.Toggle className="count-indicator p-0 toggle-arrow-hide bg-transparent">
                <svg width="22px" height="22px" viewBox="0 0 16 16" className="bi bi-grid-3x3-gap-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown p-0 position-relative">
                {this.state.platforms.map(platform => {
                  return (
                    <Dropdown.Item key={platform.title} className="dropdown-item py-3 d-flex align-items-center w-100" onClick={e => { this.visit(platform); }}>
                      <div className="w-100">
                        {platform.title}
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          }
          <Link className="navbar-brand brand-logo-mini align-self-center d-lg-none logo" to="/searches" >
            <img src={require("../../assets/images/core/logo-small.png")} alt="logo" />
          </Link>
          <div className="navbar-nav navbar-nav-left header-links">
            <Link className="nav-item d-none d-md-flex logo" to="/searches">
              <img className="logo" src={require("../../assets/images/core/logo.png")} alt="logo"/>
            </Link>
          </div>
          <div className="navbar-nav navbar-nav-right ml-lg-auto search w-0">
            <div className="search-form d-none" >
              <input type="text" name="search" placeholder="Search Query" />
            </div>
            <i className="fa fa-search"></i>
          </div>
          {/* Doesn't fit on mobile - todo : fix */}
          {/*
            {Translator.languages().length > 1 &&
              <div className="navbar-nav">
                <div className="nav-item nav-language">
                  <Dropdown>
                    <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                      <div style={{ cursor: 'pointer' }} className="">{Translator.language().toUpperCase()}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="navbar-dropdown preview-list">
                      {Translator.languages().map(language => {
                        return (
                          <Dropdown.Item key={language} className="dropdown-item preview-item d-flex align-items-center" onClick={e => this.changeLanguage(language)}>
                            <div className="w-100" style={{
                              minHeight: '18px',
                              lineHeight: '18px',
                              color: '#fff'
                            }}>{language.toUpperCase()}</div>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            }
          */}
          <ul className="navbar-nav navbar-nav-right">
            <li className={isMobile ? 'nav-item nav-profile border-0 pl-3 pr-3' : 'nav-item nav-profile border-0 pl-3 pr-0 pr-lg-3'}>
              <Dropdown>
                <Dropdown.Toggle test-id="dropdown_notifications" className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <i className="mdi mdi-bell-outline" style={{fontSize: '1.2rem', opacity: '0.9'}} onClick={this.readNotifications}></i>
                  {this.state.unreadCount > 0 &&
                    <span className="count bg-success">{this.state.unreadCount > 99 ? 99 : this.state.unreadCount}</span>
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list" style={{
                  position: 'relative'
                }}>
                <div style={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                  marginBottom: '43px'
                }}>
                  {this.state.notifications.length === 0 && 
                    <Dropdown.Item className="dropdown-item preview-item py-3 d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                      <span className="mb-1 preview-subject font-weight-normal float-left">{_t('No new notifications.')}</span>      
                    </Dropdown.Item>
                  }
                  {this.state.notifications.map(item => {
                    return (
                      <Dropdown.Item test-id="link_single-notification" key={item.notificationId} className="dropdown-item preview-item d-flex align-items-center" onClick={e => this.followNotification(e, item.notificationId)}>
                        <div className="w-100" style={{
                          minHeight: '26px'
                        }}>
                          <span className={!item.isRead ? 'preview-subject font-weight-bold float-left border-bottom-0' : 'preview-subject font-weight-light float-left border-bottom-0'}>{item.note.length > 62 ? item.note.slice(0, 62).concat('...') : item.note}</span>
                          <i className="fa fa-times float-right close-icon" onClick={e => this.hideNotification(e, item.notificationId)}></i>
                          <span className="date-created"><Moment fromNow>{item.createdAt * 1000}</Moment></span>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </div>
                  <Dropdown.Item key={0} className="dropdown-item preview-itemd-flex align-items-center position-absolute w-100" style={{
                    bottom: '0px',
                    left: '0px',
                    borderTop: '1px solid #ffffff5c',
                    padding: '14px'
                  }} test-id="link_all-notifications" as={Link} to="/user/notifications" >
                    <div className="w-100 text-center">
                      <span className="preview-subject font-weight-light">{_t('See all...')}</span>
                    </div>
                  </Dropdown.Item>
                  <div className={this.state.updating ? 'data-updating-v2 active' : 'data-updating-v2'}><i className="custom-icon icon-load fa-spin"></i></div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item nav-profile border-0 nav-user pl-3 pr-3">
              <Dropdown alignRight>
                <Dropdown.Toggle test-id="dropdown_profile" className="nav-link count-indicator bg-transparent">
                  <img style={{ width: '28px', height: '28px'}} className="img-xs rounded-circle" src={User.profileImage ? User.profileImage : require("../../assets/images/core/faces/placeholder.jpg")} alt="Profile" />
                  {!isMobile && User.organization && User.organization.organizationName &&
                    <div className="profile-text ml-3 mr-3 d-inline">
                      <div className="d-inline-flex" style={{ flexDirection: 'column', textAlign: 'left', verticalAlign: 'middle' }}>
                        <span className="mb-1">{User.username}</span>
                        <span className="" style={{ fontSize: '8px' }}>&bull; {User.organization.organizationName}</span>
                      </div>
                    </div>
                  }
                  {!isMobile && (!User.organization || !User.organization.organizationName) &&
                    <span className="profile-text mr-2">{User.username}</span>
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  {User.isAdmin && 
                    <Dropdown.Item test-id="link_manage-organizations" as={Link} to="/admin/organizations" className="dropdown-item preview-item p-3 d-flex align-items-center border-top-0 mt-2" >
                      <i className="fa fa-building text-center" style={{width: '20px'}}></i>
                      <span>{_t('Manage Organizations')}</span>
                    </Dropdown.Item>
                  }
                  {User.isAdmin &&
                    <Dropdown.Item test-id="link_manage-users" as={Link} to="/admin/users" className="dropdown-item preview-item p-3 d-flex align-items-center border-top-0 mt-2" >
                      <i className="fa fa-users text-center" style={{width: '20px'}}></i>
                      <span>{_t('Manage Users')}</span>
                    </Dropdown.Item>
                  }
                  {User.isAdmin &&
                    <Dropdown.Item test-id="link_account-requests" as={Link} to="/admin/accounts" className="dropdown-item preview-item p-3 d-flex align-items-center border-top-0 mt-2" >
                      <i className="fa fa-users text-center" style={{width: '20px'}}></i>
                      <span>{_t('Account Requests')}</span>
                    </Dropdown.Item>
                  }
                  {User.isAdmin &&
                    <Dropdown.Item test-id="link_manage-notifications" as={Link} to="/admin/notifications/new" className="dropdown-item preview-item p-3 d-flex align-items-center border-top-0 mt-2" >
                      <i className="fa fa-bell text-center" style={{width: '20px'}}></i>
                      <span>{_t('User Notifications')}</span>
                    </Dropdown.Item>
                  }
                  {User.isAdmin &&
                    <Dropdown.Item test-id="link_manage-notices" as={Link} to="/admin/notices" className="dropdown-item preview-item p-3 d-flex align-items-center border-top-0 mt-2" >
                      <i className="fa fa-exclamation-triangle text-center" style={{width: '20px'}}></i>
                      <span>{_t('Page Notices')}</span>
                    </Dropdown.Item>
                  }
                  {User.isAdmin &&
                    <Dropdown.Item test-id="link_manage-translations" as={Link} to="/admin/translations" className="dropdown-item preview-item p-3 d-flex align-items-center border-top-0 mt-2" >
                      <i className="fa fa-globe text-center" style={{width: '20px'}}></i>
                      <span>{_t('Manage Translations')}</span>
                    </Dropdown.Item>
                  }
                  {!User.viewer &&
                    <Dropdown.Item test-id="link_organization" as={Link} to="/organization" className="dropdown-item preview-item p-3 d-flex border-top-0 align-items-center mt-2" >
                      <i className="fa fa-building text-center" style={{width: '20px'}}></i>
                      <span>{_t('My Organization')}</span>
                    </Dropdown.Item>
                  }
                  {!User.viewer &&
                    <Dropdown.Item test-id="link_subscriptions" as={Link} to="/subscriptions" className="dropdown-item preview-item p-3 border-top-0 d-flex align-items-center mt-2" >
                      <i className="fa fa-envelope text-center" style={{width: '20px'}}></i>
                      <span>{_t('Subscriptions')}</span>
                    </Dropdown.Item>
                  }
                  {!User.viewer &&
                    <Dropdown.Item test-id="link_profile" as={Link} to="/user/profile" className="dropdown-item preview-item p-3 d-flex border-top-0 align-items-center mt-2" >
                      <i className="fa fa-user text-center" style={{width: '20px'}}></i>
                      <span>{_t('Profile')}</span>
                    </Dropdown.Item>
                  }
                  {NavbarItems.map((item, i) => {
                    if (!item.identity || User.access.includes(item.identity)) {
                      return (
                        <Dropdown.Item test-id={`link_${item.identity}`} as={Link} to={item.path} className="dropdown-item preview-item p-3 d-flex border-top-0 align-items-center mt-2" >
                          <i className={`fa fa-${item.icon} text-center`} style={{width: '20px'}}></i>
                          <span>{_t(item.name)}</span>
                        </Dropdown.Item>
                      );
                    }
                  })}
                  {/*!User.isAdmin && User.organization && 
                    <Dropdown.Item as={Link} to="/keys" className="dropdown-item preview-item p-3 d-flex align-items-center border-top-0 mt-2" >
                      <i className="fa fa-code text-center" style={{width: '20px'}}></i>
                      <span>{_t('API Access')}</span>
                    </Dropdown.Item>
                  */}
                  {(() => {
                    if (!User.isAdmin && User.organization && !User.viewer) {
                      if (User.organization.adminId === User.userId) {
                        return (
                          <Dropdown.Item as={Link} to="/keys" className="dropdown-item preview-item p-3 d-flex border-top-0 align-items-center mt-2" >
                            <i className="fa fa-code text-center" style={{width: '20px'}}></i>
                            <span>{_t('API Access')}</span>
                          </Dropdown.Item>
                        )
                      } else if (User.organization.admins && User.organization.admins.includes(User.userId)) {
                        return (
                          <Dropdown.Item as={Link} to="/keys" className="dropdown-item preview-item p-3 d-flex border-top-0 align-items-center mt-2" >
                            <i className="fa fa-code text-center" style={{width: '20px'}}></i>
                            <span>{_t('API Access')}</span>
                          </Dropdown.Item>
                        )
                      }
                    }
                  })()}
                  {User.impersonator && 
                    <Dropdown.Item onClick={this.restoreAdminAccount} className="dropdown-item preview-item border-top-0 p-3 d-flex align-items-center mt-2" >
                      <i className="fa fa-sign-out-alt text-center" style={{width: '20px'}}></i>
                      <span>{_t('Admin Account')}</span>
                    </Dropdown.Item>
                  }
                  {User.viewer && 
                    <Dropdown.Item onClick={this.restoreViewerAccount} className="dropdown-item preview-item border-top-0 p-3 d-flex align-items-center mt-2" >
                      <i className="fa fa-sign-out-alt text-center" style={{width: '20px'}}></i>
                      <span>{_t('Close Account View')}</span>
                    </Dropdown.Item>
                  }
                  {!User.impersonator && !User.viewer &&
                    <Dropdown.Item test-id="link_log-out" as={Link} to="/logout" className="dropdown-item border-top-0 preview-item p-3 d-flex align-items-center mt-2" >
                      <i className="fa fa-sign-out-alt text-center" style={{width: '20px'}}></i>
                      <span>{_t('Logout')}</span>
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);