import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Dashboard from './home/Dashboard';

import Spinner from '../core/shared/Spinner';

import Apps from './shared/Apps.js'
import Releases from './shared/Releases';

import { SearchList } from './search/SearchList';

import { ResultsList } from './search/ResultsList';

import { WishList } from './wishlist/WishList';

import { LivePosts } from './live/LivePosts';

import { Catalog } from './admin/Catalog';

import { Locations } from './admin/Locations';

class AppRoutes extends Component {
  
  state = {};
  
  render () {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          
          <Route exact path="/" component={ Dashboard } />
          
          <Route path="/searches" component={ SearchList } />
          
          <Route path="/results" component={ ResultsList } />
          
          <Route path="/wishlist" component={ WishList } />
          
          <Route path="/live/posts" component={ LivePosts } />
          
          <Route path="/releases" component={ Releases } />
          
          <Route path="/admin/catalog" component={ Catalog } />
          
          <Route path="/admin/locations" component={ Locations } />
          
          <Route exact path="/apps" component={ Apps } />
          
        </Switch>
      </Suspense>  
    );
  }
  
}

export default AppRoutes;