import { extendObservable } from 'mobx';

class User {
  constructor() {
    extendObservable(this, {
      isLoggedIn: false,
      isAdmin: false,
      userId: '',
      ownerId: '',
      username: '',
      email: '',
      // website: '',
      name: '',
      access: [],
      organization: {},
      profileImage: ''
    });
  }
}

export default new User();