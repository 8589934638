import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import Platform from '../stores/Platform';
import User from '../stores/User';

import Version from './Version';
import SidebarItems from '../../app/shared/Sidebar';
import { _t } from '../stores/Translator.js';

let dropdownPaths = [];

class SidebarDashboard extends Component {
  state = {};

  toggleMenuState(path) {
    const body = document.querySelector('body');
    if (body.classList.contains('sidebar-icon-only')) {
      return;
    }
    if (this.state[path]) {
      this.setState({
        [path] : false
      });
    } else if(Object.keys(this.state).length === 0) {
      this.setState({
        [path] : true
      });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({
          [i]: false
        });
      });
      this.setState({
        [path] : true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  
  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({
        [i]: false
      });
    });
    dropdownPaths.forEach((path => {
      if (this.isPathActive(path)) {
        this.setState({
          [path] : true
        });
      }
    }));
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
  
  isMenuOpen(path) {
    return this.state[path];
  }

  componentDidMount() {
    this.onRouteChanged();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
  
  newWindow = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(item.path);
  }
  
  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
        {SidebarItems.map((item, i) => {
          if (!item.sub) {
            if ((!item.identity || User.access.includes(item.identity)) && (!item.platforms || item.platforms.includes(Platform.name))) {
              if (item.type == 'link') {
                return (
                  <li key={`${i}`} className="nav-item">
                    <a test-id={item.testId} className="nav-link" href={item.href} rel="noopener noreferrer" target="_blank">
                      <i className={`fa fa-${item.icon} menu-icon`}></i>
                      <span className="menu-title">{_t(item.name)}</span>
                    </a>
                  </li>
                );
              } else {
                return (
                  <li key={`${i}`} className={ this.isPathActive(item.path) ? 'nav-item active' : 'nav-item' }>
                    <Link test-id={item.testId} className="nav-link" to={item.path}>
                      <i className={`fa fa-${item.icon} menu-icon`} ></i>
                      <span className="menu-title">{_t(item.name)}</span>
                      {/*
                      {Platform.desktop &&
                        <i className="desktop-app-link-out fa fa-external-link-square" onClick={e => { this.newWindow(e, item) }}></i>
                      }
                      */}
                    </Link>
                  </li>
                );
              }
            }
          } else {
            if (item.sub.filter(subitem => User.access.includes(subitem.identity)).length > 0) {
              if (!dropdownPaths.includes(item.path)){
                dropdownPaths.push(item.path);
              }
              return (
                <li  key={`${i}`} className={ this.isPathActive(item.path) ? 'nav-item active' : 'nav-item' }>
                  <div test-id={item.testId} className={ this.isMenuOpen(item.path) ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState(item.path) } data-toggle="collapse">
                    <i className={`fa fa-${item.icon} menu-icon`}></i>
                    <span className="menu-title">{item.name}</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={ this.isMenuOpen(item.path) }>
                    <ul className="nav flex-column sub-menu">
                      {item.sub.map((subitem, i) => {
                        if ((!subitem.identity || User.access.includes(subitem.identity)) && (!item.platforms || item.platforms.includes(Platform.name))) {
                          return (
                            <li key={`${i}-${i}`} className="nav-item">
                              <Link test-id={subitem.testId} className={ this.isPathActive(subitem.path) ? 'nav-link active' : 'nav-link' } to={subitem.path}>{_t(subitem.name)}</Link>
                              {/*
                              {Platform.desktop &&
                                <i className="desktop-app-link-out fa fa-external-link-square" onClick={e => { this.newWindow(e, item) }}></i>
                              }
                              */}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </Collapse>
                </li>
              );
            }
          }
        })}
        </ul>
        {!isMobile && <Version />}
      </nav>
    );
  }

}

export default withRouter(SidebarDashboard);