import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { _t } from '../stores/Translator.js';
import LoginInformation from '../../app/user/Login';

class Header extends Component {
  render() {
    return (
      <div >
        <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
          <div className="navbar-menu-wrapper d-flex align-items-center front-page-wrapper">
            <div className="navbar-nav navbar-nav-left d-flex align-items-center">
              <a href="tel:1-844-496-9969" className="phone">{_t('Call us:')} 844-496-9969</a>
              <a href="mailto:info@wowyow.com">
                <button className="btn btn-outline-light">{_t('Email Us')}</button>
              </a>
            </div>
            <div className="navbar-nav navbar-nav-right d-flex align-items-center">
              {/* <a href="#">{_t('REQUEST A DEMO')}</a>*/}
              <a test-id="link_sign-up" href="/signup">{_t('FREE SIGN UP')}</a>
              <a test-id="link_sign-in" href="/login">
                <button className="btn btn-inverse-light">{_t('CLIENT LOGIN')}</button>
              </a>
            </div>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center front-page-wrapper">
            <Link className="navbar-brand brand-logo-mini align-self-center d-lg-none" to="/">
              <img src={require("../../assets/images/core/logo-small.png")} alt="logo" />
            </Link>
            <div className="navbar-nav navbar-nav-left header-links">
              <Link className="nav-item d-none d-md-flex" to="/">
                <img className="logo" src={require("../../assets/images/core/logo.png")} alt="logo"/>
              </Link>
            </div>
            <div className="navbar-nav navbar-nav-right d-flex align-items-center">
              {LoginInformation.nav.links.map(link => {
                return (
                  <a href={link.href} target="_blank">{_t(link.name)}</a>
                );
              })}
            </div>
          </div>
        </nav>
      </div>
    );
  }
  
}

export default Header;