import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import './App.scss';
import CoreRoutes from './CoreRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import Loading from "./shared/Loading";
import User from './stores/User';
import Platform from './stores/Platform';
import Preferences from './stores/Preferences';
import Notices from './stores/Notices';
// import Widget from './integrations/zendesk/chat/Widget'
import './integrations/zendesk/Widget';
import { isMobile } from "react-device-detect";
import Env from './stores/Env';
import Loader from './stores/Loader';
import Settings from './stores/Settings';

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ready: false
    };
  }
  
  setParams () {
    if (this.props.history.location.search.includes('platform=app')) {
      Platform.app = true;
      Platform.name = 'app';
    } else if (this.props.history.location.search.includes('platform=desktop')) {
      Platform.desktop = true;
      Platform.name = 'desktop';
    } else if (this.props.history.location.search.includes('platform=embed')) {
      Platform.embed = true;
      Platform.name = 'embed';
    } else {
      Platform.web = true;
      Platform.name = 'web';
    }
  }
  
  // async setEnv () {
  //   try {
  //     let result = await (await fetch('/api/env', {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         location: this.props.history.location.pathname
  //       })
  //     })).json();
  //     if (result.status == 'success') {
  //       Object.entries(result.data.env).forEach(([key, value]) => {
  //         Env[key] = value;
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  
  async setSettings() {
    try {
      let result = await (await fetch('/api/settings', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (result.status == 'success') {
        Object.entries(result.data).forEach(([key, value]) => {
          // Settings[key] = value;
          Settings.update(key, value);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  async loggedIn () {
    try {
      let result = await (await fetch('/api/session/logged-in', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          location: this.props.history.location.pathname
        })
      })).json();
      if (result.status == 'success') {
        if (result.data.isLoggedIn) {
          if (!User.profileImage) {
            let result = await (await fetch('/api/user/profile-image', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                location: this.props.history.location.pathname
              })
            })).json();
            if (result.status == 'success') {
              User.profileImage = result.data.profileImage;
            }
          }
          User.isLoggedIn = true;
          User.isAdmin = result.data.isAdmin;
          User.userId = result.data.userId;
          User.ownerId = result.data.organizationId || result.data.userId;
          User.username = result.data.username;
          User.email = result.data.email;
          User.name = result.data.name;
          User.access = result.data.access;
          User.organization = result.data.organization;
          User.userInfo = result.data.userInfo;
          User.organizationInfo = result.data.organizationInfo;
          User.impersonator = result.data.impersonator;
          User.viewer = result.data.viewer;
          if (!this.state.ready) {
            this.setState({
              ready: true
            });
          }
          if (this.props.history.location.pathname === '/transit') {
            this.props.history.push('/');
          }
        } else {
          User.isLoggedIn = false;
          if (!this.state.ready) {
            this.setState({
              ready: true
            });
          }
        }
      } else {
        User.isLoggedIn = false;
        if (!this.state.ready) {
          this.setState({
            ready: true
          });
        }
      }
    } catch (err) {
      console.log(err)
      if (!this.state.ready) {
        this.setState({
          ready: true
        });
      }
    }
  }
  
  async componentDidMount () {
    this.setParams();
    await Promise.all([
      // this.setEnv(),
      this.setSettings()
    ]);
    await this.loggedIn();
    setInterval(async () => {
      await this.loggedIn();
    }, 60 * 1000);
  }
  
  render () {
    if (this.state.ready) {
      if (User.isLoggedIn) {
        Preferences.fetch();
        if (!isMobile && Preferences['sidebar.collapsed']) {
          document.body.classList.add('sidebar-icon-only');
        }
        Notices.fetch();
        if (Platform.embed) {
          return (
            <div className="container-scroller">
              <div className="content-wrapper" style={{
                background: 'transparent'
              }}>
                <Loading store={Loader} />
                <CoreRoutes />
              </div>
            </div>
          );
        } else {
          return (
            <div className="container-scroller">
              <Navbar />
              <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                  {/*<Navigation />*/}
                  <div className="content-wrapper">
                    <Loading store={Loader} />
                    <CoreRoutes />
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="container-scroller">
            <CoreRoutes />
          </div>
        );
      }
    } else {
      return (
        <div className="container-scroller">
          
        </div>
      );
    }

  }
  
}

export default observer(withRouter(App));
