import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import User from '../stores/User';

import Header from '../../core/shared/Header';

import Platform from '../stores/Platform';

class Transit extends Component {
  constructor(props) {
    super(props);
    let search = new URLSearchParams(window.location.search);
    this.state = {
      email: search.get('e'),
      token: search.get('t')
    };
  }
  
  async componentDidMount () {
    let result = await (await fetch('/api/linked/transit', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        token: this.state.token
      })
    })).json();
    if (result.status == 'success') {
      User.isLoggedIn = true;
      User.isAdmin = result.data.isAdmin;
      User.userId = result.data.userId;
      User.ownerId = result.data.organizationId || result.data.userId;
      User.email = result.data.email;
      User.username = result.data.username;
      User.name = result.data.name;
      // User.website = result.data.website;
      User.access = result.data.access;
      User.organization = result.data.organization;
      if (result.data.redirect && result.data.redirect !== '/transit') {
        this.props.history.push(result.data.redirect);
      } else {
        this.props.history.push('/');
      }
    } else {
      this.setState({
        error: result.message
      });
    }
  }
  
  render() {
    return (
      <div></div>
    );
  }
}

export default Transit;
