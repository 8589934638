import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import User from '../stores/User';

class Logout extends Component {
  constructor(props) {
    super(props);
    (async () => {
      let result = await (await fetch('/api/session/logout', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })).json();
      if (result.status == 'success') {
        User.isLoggedIn = false;
        User.isAdmin = null;
        User.userId = '';
        User.ownerId = '';
        User.email = '';
        User.username = '';
        User.name = '';
        // User.website = '';
        User.access = [];
        User.organization = {};
        this.props.history.push('/login');
      } else {
        this.setState({
          error: result.message
        });
      }
    })();
  }
  
  render() {
    return (
      <div className="inner-container"></div>
    );
  }
  
}

export default Logout;
