import React, { Component } from 'react';
import { observe } from 'mobx';
import User from '../../../core/stores/User';

const sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));

const script = document.createElement('script');
script.src = 'https://static.zdassets.com/ekr/snippet.js?key=c2b0010f-1efb-4bf9-b650-3a3a8f12dedd';
script.type = 'text/javascript';
script.id = 'ze-snippet';
script.async = true;
document.getElementsByTagName('head')[0].appendChild(script);
script.onload = async () => {
  
  window.zESettings = {
    webWidget: {
      // color: { theme: '#1093c9' },
      launcher: {
        // label: {
        //   '*': 'Help'
        // },
        mobile: {
          labelVisible: false
        }
      },
      contactOptions: {
        enabled: true,
        chatLabelOnline: { '*': 'Live Chat' },
      },
      chat: {
        suppress: false,
        notifications: {
          mobile: {
            disable: true
          }
        }
      },
      authenticate: {
        chat: {
          jwtFn: async (callback) => {
            let token = null;
            if (User.isLoggedIn) {
              token = await (await window.fetch('/api/integrations/zendesk/token')).text();
            }
            callback(token);
          }
        }
      }
    }
  };
  
  const login = async () => {
    if (User.username && User.email) {
      
      window.zESettings.webWidget.authenticate = {
        chat: {
          jwtFn: async (callback) => {
            let token = null;
            if (User.isLoggedIn) {
              token = await (await window.fetch('/api/integrations/zendesk/token')).text();
            }
            callback(token);
          }
        }
      }
      
      window.zE('webWidget', 'helpCenter:reauthenticate');
      
      // window.zE('webWidget', 'identify', {
      //   name: User.username,
      //   email: User.email,
      //   organization: 'wowyow'
      // });
      
      window.zE('webWidget', 'prefill', {
        name: {
          value: User.username,
          readOnly: true // optional
        },
        email: {
          value: User.email,
          readOnly: true // optional
        }
      });
      
    }
  };
  
  const logout = () => {
    window.zE('webWidget', 'logout');
  };
  
  observe(User, change => {
    switch (change.type) {
      case 'update':
        switch (change.name) {
          case 'isLoggedIn':
            
            if (User.isLoggedIn) {
              window.zE('webWidget', 'show');
            } else {
              window.zE('webWidget', 'hide');
            }
            
          break;
          case 'username':
            login();
          break;
          case 'email':
            login();
          break;
        }
      break;
    }
    
  });
  
  window.zE('webWidget', 'hide');
  
};

export default {};