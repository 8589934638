import React, { Component } from 'react';
import { Form, Dropdown, Pagination, FormControl  } from 'react-bootstrap';
import '../../assets/styles/core/utils/DateSelect.scss';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export class DateSelect extends Component {
  constructor(props) {
    super(props);
    
    let absoluteDateValue1 = new Date();
    let absoluteDateValue2 = new Date();
    
    let relativeValue = 'hours-1';
    
    if (!props.range || props.range === 'absolute') {
      if (props.from) {
        absoluteDateValue1 = moment(props.from);
      } else {
        absoluteDateValue1 = moment(absoluteDateValue1);
      }
      if (props.to) {
        absoluteDateValue2 = moment(props.to);
      } else {
        absoluteDateValue2 = moment(absoluteDateValue2);
      }
    } else {
      relativeValue = props.range;
      absoluteDateValue1 = moment(absoluteDateValue1);
      absoluteDateValue2 = moment(absoluteDateValue2);
    }
    
    let timezone = 'utc';
    if (props.timezone) {
      timezone = props.timezone;
    }
    
    if (timezone === 'utc') {
      absoluteDateValue1.utc().hours(0).minutes(0).seconds(0).millisecond(0);
      absoluteDateValue2.utc().hours(23).minutes(59).seconds(59).millisecond(0);
    } else {
      absoluteDateValue1.local().hours(0).minutes(0).seconds(0).millisecond(0).utc();
      absoluteDateValue2.local().hours(23).minutes(59).seconds(59).millisecond(0).utc();
    }
    
    window.moment = moment;
    
    this.state = {
      open: false,
      range: (!props.range || props.range === 'absolute') ? 'absolute' : 'relative',
      showTimezone: typeof props.showTimezone !== 'undefined' && !props.showTimezone ? false : true,
      showTime: typeof props.showTime !== 'undefined' && !props.showTime ? false : true,
      timezone: timezone,
      useTime: false,
      relativeValue: relativeValue,
      absoluteDateValue1: absoluteDateValue1,
      absoluteDateValue2: absoluteDateValue2,
      original: {
        range: (!props.range || props.range === 'absolute') ? 'absolute' : 'relative',
        useTime: false,
        timezone: props.timezone ? props.timezone : 'utc',
        relativeValue: relativeValue,
        absoluteDateValue1: moment(absoluteDateValue1.toISOString()).utc(),
        absoluteDateValue2: moment(absoluteDateValue2.toISOString()).utc(),
      },
      previewText: '',
    };
    this.dataViewRef = React.createRef();
    this.containerRef = React.createRef();
  }
  
  onClickOutside = (e) => {
    if (this.containerRef.current) {
      if (
        !this.containerRef.current.contains(e.target) &&
        (e.target.className && e.target.className.includes && !e.target.className.includes('rdt')) /* Patch */
      ) {
        this.setState({
          range: this.state.original.range,
          useTime: this.state.original.useTime,
          timezone: this.state.original.timezone,
          relativeValue: this.state.original.relativeValue,
          absoluteDateValue1: moment(this.state.original.absoluteDateValue1.toISOString()).utc(),
          absoluteDateValue2: moment(this.state.original.absoluteDateValue2.toISOString()).utc(),
          open: false
        });
      }
    }
  }
  
  componentDidMount () {
    this.setPreviewText();
    document.addEventListener('click', this.onClickOutside);
  }
  
  componentWillUnmount () {
    document.removeEventListener('click', this.onClickOutside);
  }
  
  setPreviewText = () => {
    let previewText = '';
    if (this.state.range === 'relative') {
      let parts = this.state.relativeValue.split('-');
      if (parts[1] === '1') {
        previewText = `Past ${parts[0].charAt(0).toUpperCase()}${parts[0].substring(0, parts[0].length - 1).slice(1)}`;
      } else {
        previewText = `Past ${parts[1]} ${parts[0].charAt(0).toUpperCase()}${parts[0].slice(1)}`;
      }
      previewText += ' (Inclusive)';
    } else {
      if (this.state.timezone === 'utc') {
        previewText += this.state.absoluteDateValue1.utc().format('YYYY-MM-DD');
      } else {
        previewText += this.state.absoluteDateValue1.local().format('MM/DD/YYYY');
      }
      if (this.state.useTime) {
        if (this.state.timezone === 'utc') {
          previewText += ` ${this.state.absoluteDateValue1.utc().format('HH:mm')}`;
        } else {
          previewText += ` ${this.state.absoluteDateValue1.local().format('hh:mm A')}`;
        }
      }
      previewText += ' - ';
      if (this.state.timezone === 'utc') {
        previewText += this.state.absoluteDateValue2.utc().format('YYYY-MM-DD');
      } else {
        previewText += this.state.absoluteDateValue2.local().format('MM/DD/YYYY');
      }
      if (this.state.useTime) {
        if (this.state.timezone === 'utc') {
          previewText += ` ${this.state.absoluteDateValue2.utc().format('HH:mm')}`;
        } else {
          previewText += ` ${this.state.absoluteDateValue2.local().format('hh:mm A')}`;
        }
      }
      if (this.state.timezone === 'utc') {
        previewText += ' (UTC)';
      } else {
        if (!this.state.useTime) {
          previewText += ' (Local)';
        }
      }
    }
    this.setState({
      previewText: previewText
    });
  }
  
  toggleOpen = (e) => {
    this.setState({
      open: !this.state.open
    });
  }
  
  onBlur = (e) => {
    // this.setState({
    //   open: false
    // });
  }
  
  componentWillReceiveProps (nextProps) {
    
  }
  
  onChangeTimezone = (timezone) => {
    this.setState({
      timezone: timezone
    });
  };
  
  onTimeToggle = (e) => {
    let absoluteDateValue1 = this.state.absoluteDateValue1;
    let absoluteDateValue2 = this.state.absoluteDateValue2;
    if (this.state.timezone === 'utc') {
      absoluteDateValue1.utc().hours(0).minutes(0).seconds(0).millisecond(0);
      absoluteDateValue2.utc().hours(23).minutes(59).seconds(59).millisecond(0);
    } else {
      absoluteDateValue1.local().hours(0).minutes(0).seconds(0).millisecond(0).utc();
      absoluteDateValue2.local().hours(23).minutes(59).seconds(59).millisecond(0).utc();
    }
    this.setState({
      useTime: !this.state.useTime,
      absoluteDateValue1: absoluteDateValue1.utc(),
      absoluteDateValue2: absoluteDateValue2.utc()
    });
  };
  
  submit = (e) => {
    this.setPreviewText();
    let event = {};
    if (this.state.range === 'relative') {
      event.range = this.state.relativeValue;
      let [metric, amount] = this.state.relativeValue.split('-');
      amount = Number(amount);
      event.from = moment(new Date()).subtract(amount, metric).utc().toISOString();
      event.to = moment(new Date()).utc().toISOString();
      event.timezone = this.state.timezone;
    } else {
      event.range = 'absolute';
      event.from = this.state.absoluteDateValue1.utc().toISOString();
      event.to = this.state.absoluteDateValue2.utc().toISOString();
      event.timezone = this.state.timezone;
    }
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    this.setState({
      original: {
        range: this.state.range,
        useTime: this.state.useTime,
        timezone: this.state.timezone,
        relativeValue: this.state.relativeValue,
        absoluteDateValue1: moment(this.state.absoluteDateValue1.toISOString()).utc(),
        absoluteDateValue2: moment(this.state.absoluteDateValue2.toISOString()).utc()
      },
      open: false
    });
  }
  minMaxDate = (currentDate) => {
    if (this.props.minDate && this.props.maxDate) {
      return moment(currentDate).isSameOrAfter(this.props.minDate, 'day')  && moment(currentDate).isSameOrBefore(this.props.maxDate, 'day');
    } else if (!this.props.minDate && this.props.maxDate) {
      return moment(currentDate).isSameOrBefore(this.props.maxDate, 'day');
    } else if (this.props.minDate && !this.props.maxDate) {
      return moment(currentDate).isSameOrAfter(this.props.minDate, 'day');
    }
   
  }
    
  render () {
    
    let dateFormat = '';
    let timeFormat = '';
    if (this.state.timezone === 'utc') {
      dateFormat = 'YYYY-MM-DD';
      if (this.state.useTime) {
        timeFormat = 'HH:mm';
      } else {
        timeFormat = '';
      }
    } else {
      dateFormat = 'MM/DD/YYYY'; /* Only valid for USA */
      if (this.state.useTime) {
        timeFormat = 'hh:mm A';
      } else {
        timeFormat = '';
      }
    }
    
    let absoluteDateValue1 = moment(this.state.absoluteDateValue1.toISOString()).utc();
    let absoluteDateValue2 = moment(this.state.absoluteDateValue2.toISOString()).utc();
    if (this.state.timezone === 'local') {
      absoluteDateValue1.local();
      absoluteDateValue2.local();
    }
    
    return (
      <div ref={this.containerRef} onBlur={this.onBlur} style={this.props.style ? this.props.style : {}} className={this.props.className ? `${this.props.className} date-select` : 'date-select'}>
        <div test-id="dropdown_date-select" className="preview" onClick={this.toggleOpen}>
          <span>{this.state.previewText}</span>
          <i className="fa fa-caret-down"></i>
        </div>
        <div ref={this.dataViewRef} className={this.state.open ? 'data-view open' : 'data-view'}>
          <div className="top-controls">
            {this.state.showTimezone && <div className={this.state.range === 'absolute' ? 'active' : ''} onClick={e => { this.setState({ range: 'absolute' }) }}>Absolute</div>}
            {this.state.showTimezone && <div className={this.state.range === 'relative' ? 'active' : ''} onClick={e => { this.setState({ range: 'relative' }) }}>Relative</div>}
            <div></div>
            {this.state.range === 'absolute' && this.state.showTimezone &&
              <div className={this.state.timezone === 'utc' ? 'active' : ''} onClick={e => { this.onChangeTimezone('utc') }}>UTC</div>
            }
            {this.state.range === 'absolute' && this.state.showTimezone &&
              <div className={this.state.timezone === 'local' ? 'active' : ''} onClick={e => { this.onChangeTimezone('local') }}>Local</div>
            }
          </div>
          <div className="central-controls">
            {this.state.range === 'relative' &&
              <div className="relative-controls">
                <div className={this.state.relativeValue.includes('hours') ? 'active' : ''}>Hours</div>
                <div className={this.state.relativeValue === 'hours-1' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'hours-1' }) }}>1</div>
                <div className={this.state.relativeValue === 'hours-2' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'hours-2' }) }}>2</div>
                <div className={this.state.relativeValue === 'hours-4' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'hours-4' }) }}>4</div>
                <div className={this.state.relativeValue === 'hours-6' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'hours-6' }) }}>6</div>
                <div className={this.state.relativeValue === 'hours-8' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'hours-8' }) }}>8</div>
                <div className={this.state.relativeValue === 'hours-12' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'hours-12' }) }}>12</div>
                <div className={this.state.relativeValue.includes('days') ? 'active' : ''}>Days</div>
                <div className={this.state.relativeValue === 'days-1' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'days-1' }) }}>1</div>
                <div className={this.state.relativeValue === 'days-2' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'days-2' }) }}>2</div>
                <div className={this.state.relativeValue === 'days-3' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'days-3' }) }}>3</div>
                <div className={this.state.relativeValue === 'days-4' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'days-4' }) }}>4</div>
                <div className={this.state.relativeValue === 'days-5' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'days-5' }) }}>5</div>
                <div className={this.state.relativeValue === 'days-6' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'days-6' }) }}>6</div>
                <div className={this.state.relativeValue.includes('weeks') ? 'active' : ''}>Weeks</div>
                <div className={this.state.relativeValue === 'weeks-1' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'weeks-1' }) }}>1</div>
                <div className={this.state.relativeValue === 'weeks-2' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'weeks-2' }) }}>2</div>
                <div className={this.state.relativeValue === 'weeks-4' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'weeks-4' }) }}>4</div>
                <div className={this.state.relativeValue === 'weeks-6' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'weeks-6' }) }}>6</div>
                <div></div>
                <div></div>
                <div className={this.state.relativeValue.includes('months') ? 'active' : ''}>Months</div>
                <div className={this.state.relativeValue === 'months-1' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'months-1' }) }}>1</div>
                <div className={this.state.relativeValue === 'months-2' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'months-2' }) }}>2</div>
                <div className={this.state.relativeValue === 'months-4' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'months-4' }) }}>4</div>
                <div className={this.state.relativeValue === 'months-6' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'months-6' }) }}>6</div>
                <div className={this.state.relativeValue === 'months-8' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'months-8' }) }}>8</div>
                <div className={this.state.relativeValue === 'months-12' ? 'active' : ''} onClick={e => { this.setState({ relativeValue: 'months-12' }) }}>12</div>
              </div>
            }
            {this.state.range === 'absolute' &&
              <div className="absolute-controls">
                <div>
                  <span>From:</span>
                </div>
                <Datetime test-id="input_date-select-start" isValidDate={this.minMaxDate} closeOnSelect={true} value={absoluteDateValue1} dateFormat={dateFormat} timeFormat={timeFormat} input={true} inputProps={{
                  onKeyDown: (e) => { e.preventDefault(); }
                }} onChange={value => { this.setState({ absoluteDateValue1: value.utc() })}} />
                <div>
                  <span>To:</span>
                </div>
                <Datetime test-id="input_date-select-end" isValidDate={this.minMaxDate}  closeOnSelect={true} value={absoluteDateValue2} dateFormat={dateFormat} timeFormat={timeFormat} input={true} inputProps={{
                  onKeyDown: (e) => { e.preventDefault(); }
                }} onChange={value => { this.setState({ absoluteDateValue2: value.utc() })}} />
              </div>
            }
          </div>
          <div className="bottom-controls">
            {this.state.range === 'absolute' && !this.props.hideTime && this.state.showTime &&
              <div className="" style={{ float: 'left', marginTop: '10px' }}>
                <input type="checkbox" onChange={this.onTimeToggle} checked={this.state.useTime}/>
                <label className="ml-2" style={{ verticalAlign: 'top' }}>Set Time</label>
              </div>
            }
            <input test-id="button_date-select-update" type="button" value="Update" onClick={this.submit}/>
          </div>
        </div>
      </div>
    );
  }
}

export default DateSelect;