import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import User from './stores/User';

import Spinner from './shared/Spinner';

import DemoUIElements from './demo/DemoUIElements';
import AdminSettings from './admin/AdminSettings';
import DomainSettings from './admin/DomainSettings';
import { EditUser, ListUsers } from './admin/Users';
import NewUser from './admin/Users';
import { AccountRequests } from './admin/AccountRequests';
import { ListOrganizations, EditOrganizations} from './admin/Organizations';
import { EditMember } from './organization/Members';
import { NewOrganization, EditOrganization } from './organization/Organizations';
import { NewUserNotification } from './admin/UserNotifications';
import { NewPageNotice, ListPageNotices } from './admin/PageNotices';
import Profile from './user/Profile';
import { ListNotifications } from './user/Notifications';
import { ListSubscriptions } from './user/Subscriptions';
import { ListKeys } from './organization/Keys';
import { Translations } from './admin/Translations';

import Login from './user/Login';
import Logout from './user/Logout';
import Signup from './user/Signup';
import { ResetPassword, ChangePassword } from './user/ResetPassword';
import Recover from './user/Recover';
import Transit from './user/Transit';

import AppRoutes from '../app/AppRoutes';
import PublicRoutes from '../app/PublicRoutes';

import Documents from './shared/Documents.js';

class CoreRoutes extends Component {
  
  state = {};
  
  render () {
    if (User.isLoggedIn) {
      if (User.isAdmin) {
        return (
          <Suspense fallback={<Spinner />}>
            <Switch>
              
              {User.access.includes('demos') && <Route exact path="/demo" component={ DemoUIElements } />}
              
              <Route path="/admin/domain_settings" component={ DomainSettings } />
              
              <Route exact path="/admin/users" component={ ListUsers } />
              <Route path="/admin/users/new" component={ NewUser } />
              <Route path="/admin/users/edit/:userId" component={ EditUser } />
              
              <Route exact path="/admin/accounts" component={ AccountRequests } />
              
              <Route exact path="/admin/organizations" component={ ListOrganizations } />
              
              <Route exact path="/organization" component={ EditOrganization } />
              
              <Route path="/organization/members/edit/:memberId" component={ EditMember } />
              
              <Route exact path="/admin/organizations/edit/:organizationId" component={ EditOrganizations } />
              
              <Route exact path="/admin/notifications/new" component={ NewUserNotification }/>
              
              <Route exact path="/admin/notices/new" component={ NewPageNotice }/>
              <Route exact path="/admin/notices" component={ ListPageNotices }/>
              
              <Route path="/admin/settings" component={ AdminSettings } />
              <Route path="/user/profile" component={ Profile } />
              
              <Route path="/user/notifications" component={ ListNotifications } />
              
              <Route path="/subscriptions" component={ ListSubscriptions } />
              
              <Route exact path="/documents" component={ Documents } />
              
              <Route path="/admin/translations" component={ Translations } />
              
              <Route path="/logout" component={ Logout } />
              
              <AppRoutes />
              
              <Redirect to="/" />
              
            </Switch>
          </Suspense>
        );
      } else {
        return (
          <Suspense fallback={<Spinner />}>
            <Switch>
              
              {User.access.includes('demos') && <Route exact path="/demo" component={ DemoUIElements } />}
        
              <Route path="/user/profile" component={ Profile } />
              
              <Route exact path="/organization" component={ EditOrganization } />
              
              <Route path="/organization/new" component={ NewOrganization } />
              
              <Route path="/organization/members/edit/:memberId" component={ EditMember } />
              
              <Route path="/user/notifications" component={ ListNotifications } />
              
              <Route path="/subscriptions" component={ ListSubscriptions } />
              
              <Route exact path="/documents" component={ Documents } />
              
              <Route path="/keys" component={ ListKeys } />
              
              <Route path="/logout" component={ Logout } />
              
              <AppRoutes />
              
              <Redirect to="/" />
              
            </Switch>
          </Suspense>
        );
      }
    } else {
      return (
        <Suspense fallback={<Spinner />}>
          <Switch>
          
            {/*<Route exact path="/demo" component={ DemoUIElements } />*/}
            
            <Route exact path="/login" component={ Login } />
            
            <Route path="/signup" component={ Signup } />
              
            <Route path="/reset_password" component={ ResetPassword } />
            
            <Route path="/recover/:userId/:token" component={ Recover } />
            
            <Route path="/transit" component={ Transit } />
            
            <PublicRoutes />
            
            <Redirect to="/start" />
            
          </Switch>
        </Suspense>
      );
    }
  }
  
}

export default CoreRoutes;