import React, { Component } from 'react';
import { Form, Dropdown, Pagination, FormControl  } from 'react-bootstrap';
import '../../assets/styles/core/utils/SearchableMultiSelect.scss';

export class SearchableMultiSelect extends Component {
  constructor(props) {
    super(props);
    let selectedIndexes = [];
    let selectedValues = [];
    let selectedChildren = [];
    React.Children.forEach(props.children, (element, idx) => {
      if (element.props.selected) {
        selectedIndexes.push(idx);
        selectedValues.push(element.props.value);
        selectedChildren.push(element.props.children);
      }
    });
    this.state = {
      active: false,
      focused: false,
      overflowed: false,
      activeIndex: -1,
      visibleIndexes: Array.from(Array(React.Children.count(props.children)).keys()),
      selectedIndexes: selectedIndexes,
      selectedValues: selectedValues,
      selectedChildren: selectedChildren,
      search: '',
      value: props.value,
      preventBlur: false
    };
    this.selectRef = React.createRef();
    this.searchRef = React.createRef();
    React.Children.forEach(props.children, (element, idx) => {
      this[`itemRef${idx}`] = React.createRef();
    });
    this.dropdownRef = React.createRef();
  }
  
  onClickOutside = (e) => {
    if (this.dropdownRef.current) {
      if (
        !this.dropdownRef.current.contains(e.target)
      ) {
        this.setState({
          active: false,
          focused: false,
          activeIndex: -1,
          search: this.state.selectedElement ? this.state.selectedElement.textContent : '',
          visibleIndexes: Array.from(Array(React.Children.count(this.props.children)).keys()),
        });
      }
    }
  }
  
  componentDidMount () {
    window.addEventListener('scroll', this.onScroll, true);
    document.addEventListener('click', this.onClickOutside);
    this.onScroll();
    setTimeout(this.onScroll, 1000);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
    document.removeEventListener('click', this.onClickOutside);
  }
  
  onScroll = (e) => {
    if (this.dropdownRef.current) {
      let pos = this.dropdownRef.current.getBoundingClientRect();
      this.setState({
        overflowed: window.innerHeight - pos.top < 320
      });
    }
  }
  
  showList = (e) => {
    this.searchRef.current.focus();
    this.setState({
      active: true,
      focused: true
    }, () => {
      this.onScroll();
    });
  }
  
  hideList = (e) => {
    this.setState({
      active: false,
      focused: false,
      activeIndex: -1,
      search: this.state.selectedElement ? this.state.selectedElement.textContent : '',
      visibleIndexes: Array.from(Array(React.Children.count(this.props.children)).keys()),
    });
  }
  
  onFocus = (e) => {
    this.setState({
      active: true,
      focused: true
    }, () => {
      this.onScroll();
    });
  }
  
  componentWillUnmount () {
    document.removeEventListener('click', this.onClickOutside);
  }
  
  onBlur = (e) => {
    return
    let input = e.target;
    setTimeout(() => {
      console.log(document.activeElement, input.isSameNode(document.activeElement), this.state.preventBlur)
      if ((!document.activeElement || !input.isSameNode(document.activeElement)) && !this.state.preventBlur) {
        this.setState({
          focused: false,
          activeIndex: -1,
          search: this.state.selectedElement ? this.state.selectedElement.textContent : '',
          visibleIndexes: Array.from(Array(React.Children.count(this.props.children)).keys()),
        });
      }
    }, 100);
  }
  
  onChange = (e) => {
    this.setState({
      search: e.target.value,
      // selectedValue: null
    }, () => {
      let visibleIndexes = [];
      React.Children.forEach(this.props.children, (element, idx) => {
        let children = element.props.children;
        if (children && typeof children !== 'string') {
          children = children.join(' ');
        }
        if (!this.state.search || (typeof element.props.value === 'string' && element.props.value.toLowerCase().includes(this.state.search.toLowerCase())) || (typeof children === 'string' && children.toLowerCase().includes(this.state.search.toLowerCase()))) {
          visibleIndexes.push(idx);
        }
      });
      this.setState({
        visibleIndexes: visibleIndexes
      });
    });
  }
  
  onClick = (e) => {
    if (e.target.classList.contains('disabled')) {
      return;
    }
    let value = e.target.getAttribute('value');
    if (e.target.getAttribute('type') === 'number') {
      value = Number(value);
    }
    let text = e.target.textContent;
    let selectedIndexes = [];
    let selectedValues = [];
    let selectedChildren = [];
    React.Children.forEach(this.props.children, (element, idx) => {
      if (this.state.selectedIndexes.includes(idx)) {
        if (element.props.value === value) {
          
        } else {
          selectedIndexes.push(idx);
          selectedValues.push(element.props.value);
          selectedChildren.push(element.props.children);
        }
      } else {
        if (element.props.value === value) {
          selectedIndexes.push(idx);
          selectedValues.push(element.props.value);
          selectedChildren.push(element.props.children);
        } else {
          
        }
      }
    });
    this.setState({
      selectedIndexes: selectedIndexes,
      selectedValues: selectedValues,
      selectedChildren: selectedChildren,
      value: selectedValues
    }, () => {
      if (this.props.onChange) {
        this.props.onChange({
          target: {
            value: selectedValues
          }
        });
      }
    });
  }
  
  onKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        if (this.state.activeIndex > -1) {
          let index = this.state.visibleIndexes[this.state.activeIndex];
          this.onClick({ target: this[`itemRef${index}`].current });
        }
      break;
      case 40:
        (() => {
          let index = this.state.activeIndex + 1;
          this.setState({
            activeIndex: index > (this.state.visibleIndexes.length - 1) ? (this.state.visibleIndexes.length - 1) : index
          });
        })();
      break;
      case 38:
        (() => {
          let index = this.state.activeIndex - 1;
          this.setState({
            activeIndex: index < 0 ? 0 : index
          });
        })();
      break;
      case 27:
        e.preventDefault();
        e.stopPropagation();
        this.searchRef.current.blur();
        return false;
      break;
      default:
        
      break;
    }
  }
  
  onKeyPress = (e) => {
    switch (e.keyCode) {
      case 27:
        e.preventDefault();
        e.stopPropagation();
        this.searchRef.current.blur();
        return false;
      break;
      default:
        
      break;
    }
  }
  
  onKeyUp = (e) => {
    switch (e.keyCode) {
      case 27:
        e.preventDefault();
        e.stopPropagation();
        this.searchRef.current.blur();
        return false;
      break;
      default:
        
      break;
    }
  }
  
  
  onMouseOver = (index) => {
    this.setState({
      activeIndex: this.state.visibleIndexes.indexOf(index)
    });
  }
  
  componentWillReceiveProps (nextProps) {
    if (JSON.stringify(nextProps.value) !== JSON.stringify(this.state.selectedValues)) {
      setTimeout(() => {
        let selectedIndexes = [];
        let selectedValues = [];
        let selectedChildren = [];
        React.Children.forEach(this.props.children, (element, idx) => {
          if (element.props.selected) {
            selectedIndexes.push(idx);
            selectedValues.push(element.props.value);
            selectedChildren.push(element.props.children);
          }
        });
        this.setState({
          visibleIndexes: Array.from(Array(React.Children.count(this.props.children)).keys()),
          selectedValues: selectedValues,
          selectedIndexes: selectedIndexes,
          selectedChildren: selectedChildren,
          // value: nextProps.value,
          value: selectedValues
        }, () => {
          // this.selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
        });
        React.Children.forEach(this.props.children, (element, idx) => {
          if (!this[`itemRef${idx}`]) {
            this[`itemRef${idx}`] = React.createRef();
          }
        });
      });
    }
    if (React.Children.toArray(nextProps.children).map(item => item.key).join(',') !== React.Children.toArray(this.props.children).map(item => item.key).join(',')) {
      let selectedIndexes = [];
      let selectedValues = [];
      let selectedChildren = [];
      React.Children.forEach(nextProps.children, (element, idx) => {
        if (element.props.selected) {
          selectedIndexes.push(idx);
          selectedValues.push(element.props.value);
          selectedChildren.push(element.props.children);
        }
      });
      this.setState({
        visibleIndexes: Array.from(Array(React.Children.count(nextProps.children)).keys()),
        selectedValues: selectedValues,
        selectedIndexes: selectedIndexes,
        selectedChildren: selectedChildren,
        // value: nextProps.value,
        value: selectedValues
      }, () => {
        // this.selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
      });
      React.Children.forEach(nextProps.children, (element, idx) => {
        if (!this[`itemRef${idx}`]) {
          this[`itemRef${idx}`] = React.createRef();
        }
      });
    }
  }
  
  render () {
    
    let visibleChildren = React.Children.map(this.props.children, (element, idx) => {
      // let children = element.props.children;
      // if (children && typeof children !== 'string') {
      //   children = children.join(' ');
      // }
      // if (typeof this.state.selected === 'string' || !this.state.search || (typeof element.props.value === 'string' && element.props.value.toLowerCase().includes(this.state.search.toLowerCase())) || (typeof children === 'string' && children.toLowerCase().includes(this.state.search.toLowerCase()))) {
      if (this.state.visibleIndexes.includes(idx)) {
        let selected = element.props.selected || this.state.selectedValues.includes(element.props.value);
        let disabled = element.props.disabled;
        return {
          element: element,
          idx: idx,
          selected: selected,
          disabled: disabled
        };
        // return (
        //   <li 
        //     key={element.key} 
        //     ref={this[`itemRef${idx}`]} 
        //     onMouseDown={this.onClick} 
        //     value={element.props.value} 
        //     onMouseOver={(e) => { this.onMouseOver(idx) }} 
        //     onClick={(e) => { this.searchRef.current.focus(); }}
        //     className={this.state.activeIndex > -1 && this.state.visibleIndexes[this.state.activeIndex] === idx ? `active ${selected ? 'selected' : ''} ${element.props.disabled ? 'disabled' : ''}` : `${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`} 
        //     disabled={disabled ? 'true' : ''}
        //   >{element.props.children}</li>
        // );
      // }
      }
    });
    
    let dropdownStyle = {
      // display: this.state.focused ? 'block' : 'none'
      display: this.state.active ? 'block' : 'none'
    };
    
    if (this.state.overflowed) {
      dropdownStyle.bottom = '100%';
      dropdownStyle.borderBottom = '0px';
    } else {
      dropdownStyle.top = '100%';
      dropdownStyle.borderTop = '0px';
    }
    
    return (
      <div ref={this.dropdownRef} className={this.props.className ? `${this.props.className} searchable-multi-select ${this.props.disabled ? 'disabled' : ''}` : `searchable-multi-select ${this.props.disabled ? 'disabled' : ''}`}>
        {/*<div className="list">
          {this.state.selectedIndexes.length === 0 &&
            <div className="list-note">No items selected...</div>
          }
          {this.state.selectedIndexes.map((index, i) => {
            return (
              <div className="list-item" key={this.state.selectedValues[i]} value={this.state.selectedValues[i]} onMouseDown={this.onClick}>{this.state.selectedChildren[i]}</div>
            );
          })}
        </div>*/}
        <div className="list">
          <div className="list-items" style={{
            padding: this.state.selectedValues.length > 0 ? '4px 0px 0px 4px' : '0px'
          }}>
            {this.state.selectedIndexes.map((index, i) => {
              return (
                <div className="list-item" key={this.state.selectedValues[i]} type={typeof this.state.selectedValues[i]} value={this.state.selectedValues[i]} onMouseDown={this.onClick}>
                  {this.state.selectedChildren[i]}
                  <i className="remove-item">&#x2715;</i>
                </div>
              );
            })}
          </div>
          <input autocomplete="off" style={{
            // width: this.state.focused || this.state.selectedValues.length > 0 ? 'auto' : '100%'
            borderTop: this.state.selectedValues.length > 0 ? '1px solid #bebebe' : '0px'
          }} type="text" onFocus={this.onFocus} ref={this.searchRef} onBlur={this.onBlur} placeholder={this.props.placeholder} value={this.state.search} onChange={this.onChange} onKeyDown={this.onKeyDown} onKeyPress={this.onKeyPress} onKeyUp={this.onKeyUp}/>
        </div>
        <ul style={dropdownStyle}>
          {/*
          {React.Children.map(this.props.children, (element, idx) => {
            // let children = element.props.children;
            // if (children && typeof children !== 'string') {
            //   children = children.join(' ');
            // }
            // if (typeof this.state.selected === 'string' || !this.state.search || (typeof element.props.value === 'string' && element.props.value.toLowerCase().includes(this.state.search.toLowerCase())) || (typeof children === 'string' && children.toLowerCase().includes(this.state.search.toLowerCase()))) {
            if (this.state.visibleIndexes.includes(idx)) {
              let selected = element.props.selected || this.state.selectedValues.includes(element.props.value);
              let disabled = element.props.disabled;
              return (
                <li 
                  key={element.key} 
                  ref={this[`itemRef${idx}`]} 
                  onMouseDown={this.onClick} 
                  value={element.props.value} 
                  onMouseOver={(e) => { this.onMouseOver(idx) }} 
                  onClick={(e) => { this.searchRef.current.focus(); }}
                  className={this.state.activeIndex > -1 && this.state.visibleIndexes[this.state.activeIndex] === idx ? `active ${selected ? 'selected' : ''} ${element.props.disabled ? 'disabled' : ''}` : `${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`} 
                  disabled={disabled ? 'true' : ''}
                >{element.props.children}</li>
              );
            // }
            }
          })}
          */}
          {visibleChildren.map(({element, idx, selected, disabled}) => {
            return (
              <li 
                key={element.key} 
                ref={this[`itemRef${idx}`]} 
                onMouseDown={this.onClick} 
                type={typeof element.props.value}
                value={element.props.value} 
                onMouseOver={(e) => { this.onMouseOver(idx) }} 
                onClick={(e) => { this.searchRef.current.focus(); }}
                className={this.state.activeIndex > -1 && this.state.visibleIndexes[this.state.activeIndex] === idx ? `active ${selected ? 'selected' : ''} ${element.props.disabled ? 'disabled' : ''}` : `${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`} 
                disabled={disabled ? 'true' : ''}
              >{element.props.children}</li>
            );
          })}
          {visibleChildren.length === 0 &&
            <li
              style={{
                color: '#b7b7b7'
              }}
              disabled={true}
              value={''}
              onClick={(e) => { this.searchRef.current.focus(); }}
            >No Results.</li>
          }
        </ul>
        <div className="arrow-trigger" onClick={e => {
          if (!this.state.active) {
            // this.searchRef.current.focus();
            this.showList();
          } else {
            // this.searchRef.current.blur();
            this.hideList();
          }
        }}>
          <span className="arrow arrow-down" style={{
            display: this.state.active ? 'none' : 'block'
          }}></span>
          <span className="arrow arrow-up" style={{
            display: this.state.active ? 'block' : 'none'
          }}></span>
        </div>
      </div>
    );
  }
}

export default SearchableMultiSelect;