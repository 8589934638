import React, { Component } from 'react';
import { Form, Dropdown, Pagination, FormControl  } from 'react-bootstrap';
import '../../assets/styles/core/utils/Helper.scss';

let triggers = {};

class HelperButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  onClickOpen = (e) => {
    if (!triggers[this.props.helperId]) {
      return;
    }
    triggers[this.props.helperId](e.target);
  }
  
  render () {
    return (
      <div className={this.props.className ? `${this.props.className} helper-button` : 'helper-button'} onClick={this.onClickOpen} style={{
        color: this.props.color ? this.props.color : '#1093c9'
      }}>
        <i className={this.props.icon ? `fa fa-${this.props.icon}` : 'fa fa-info-circle'}></i>
      </div>
    );
  }
  
}

class HelperOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: -1,
      active: false,
      steps: [],
      listeners: [],
      polyPoints: '0,0 0,0 0,0 0,0',
      messageTop: '0px',
      messageBottom: '0px',
      messageLeft: '0px',
      messageRight: '0px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
    };
  }
  
  addStep = ({action, selector, message}) => {
    return new Promise((resolve, reject) => {
      ((selector, message) => {
        let calculate = () => {
          let element = document.querySelector(selector);
          let margin = 10;
          let viewportOffset = element.getBoundingClientRect();
          let top = viewportOffset.top;
          let left = viewportOffset.left;
          let width = element.offsetWidth;
          let height = element.offsetHeight;
          let propY = 'top';
          let posY = 'out';

          if (top + height / 2 > window.innerHeight / 2) {
            propY = 'bottom';
            if (top < 75) {
              posY = 'in';
            }
          } else {
            if (window.innerHeight - top - height < 75) {
              posY = 'in';
            }
          }
          let propX = 'left';
          let posX = 'out';
          if (left + width / 2 > window.innerWidth / 2) {
            propX = 'right';
            if (left < 75) {
              posX = 'in';
            }
          } else {
            if (window.innerWidth - left - width < 75) {
              posX = 'in';
            }
          }
          return {
            action: action,
            pos: {
              x: left - margin,
              y: top - margin,
              w: width + margin * 2,
              h: height + margin * 2
            },
            message: {
              pos: {
                propY: propY,
                posY: posY,
                propX: propX,
                posX: posX
              },
              text: message
            }
          };
        };
        this.setState({
          steps: this.state.steps.concat([calculate()])
        }, () => {
          let index = this.state.steps.length - 1;
          ((index, calculate) => {
            let recalculate = (e) => {
              let steps = this.state.steps;
              steps[index] = calculate();
              this.setState({
                steps: steps
              }, async () => {
                if (this.state.steps[this.state.index].action) {
                  await this.doAction(this.state.steps[this.state.index].action.selector, this.state.steps[this.state.index].action.event);
                }
                this.moveTo(
                  this.state.steps[this.state.index].pos.x, 
                  this.state.steps[this.state.index].pos.y, 
                  this.state.steps[this.state.index].pos.w, 
                  this.state.steps[this.state.index].pos.h, 
                  this.state.steps[this.state.index].message.pos.propY, 
                  this.state.steps[this.state.index].message.pos.propX,
                  this.state.steps[this.state.index].message.pos.posY, 
                  this.state.steps[this.state.index].message.pos.posX
                );
              });
            }
            window.addEventListener('resize', recalculate);
            window.addEventListener('scroll', recalculate);
            this.setState({
              listeners: this.state.listeners.concat([recalculate])
            });
          })(index, calculate);
          resolve();
        });
      })(selector, message);
    });
  }
  
  startSteps = async () => {
    this.setState({
      index: -1,
      active: true,
      steps: [],
      polyPoints: '0,0 0,0 0,0 0,0',
      messageTop: '0px',
      messageBottom: '0px',
      messageLeft: '0px',
      messageRight: '0px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
    }, async () => {
      for (let i in this.props.steps) {
        if (!document.querySelector(this.props.steps[i].selector)) {
          continue;
        }
        await this.addStep(this.props.steps[i]);
      }
      this.onClickNext();
    });
  };
  
  onKeyUp = (e) => {
    if (e.key === 'Escape') {
      this.setState({
        active: false
      });
    }
  }
  
  componentDidMount () {
    triggers[this.props.helperId] = () => {
      this.startSteps();
    };
    window.document.addEventListener('keyup', this.onKeyUp);
  }
  
  componentWillUnmount () {
    for (let i in this.state.listeners) {
      window.removeEventListener('resize', this.state.listeners[i]);
      window.removeEventListener('scroll', this.state.listeners[i]);
    }
    window.document.removeEventListener('keyup', this.onKeyUp);
    this.setState({
      active: false,
      listeners: []
    });
    delete triggers[this.props.helperId];
  }
  
  onClickNext = (e) => {
    let index = this.state.index + 1;
    if (!this.state.steps[index]) {
      this.setState({
        active: false
      });
      return;
    }
    this.setState({
      index: index
    }, async () => {
      if (this.state.steps[this.state.index].action) {
        await this.doAction(this.state.steps[this.state.index].action.selector, this.state.steps[this.state.index].action.event);
      }
      this.moveTo(
        this.state.steps[this.state.index].pos.x, 
        this.state.steps[this.state.index].pos.y, 
        this.state.steps[this.state.index].pos.w, 
        this.state.steps[this.state.index].pos.h, 
        this.state.steps[this.state.index].message.pos.propY, 
        this.state.steps[this.state.index].message.pos.propX,
        this.state.steps[this.state.index].message.pos.posY, 
        this.state.steps[this.state.index].message.pos.posX
      );
    });
  }
  
  moveTo = (x, y, w, h, propY, propX, posY, posX) => {
    let offset = 10;
    
    this.setState({
      polyPoints: `${x},${y} ${x + w},${y} ${x + w},${y + h} ${x},${y + h}`
    });
    
    if (propY == 'top') {
      if (posY == 'in') {
        this.setState({
          messageBottom: `${(window.innerHeight - y - h) + offset}px`,
          messageTop: `initial`
        });
      } else {
        this.setState({
          messageTop: `${y + h + offset}px`,
          messageBottom: `initial`
        });
      }
    } else {
      if (posY == 'in') {
        this.setState({
          messageTop: `${y + offset}px`,
          messageBottom: `initial`
        });
      } else {
        this.setState({
          messageBottom: `${(window.innerHeight - y) + offset}px`,
          messageTop: `initial`
        });
      }
    }
    
    if (propX == 'left') {
      if (posX == 'in') {
        this.setState({
          messageRight: `${(window.innerWidth - (x + w)) + offset}px`,
          messageLeft: `initial`
        });
      } else {
        this.setState({
          messageLeft: `${x + w + offset}px`,
          messageRight: `initial`
        });
      }
    } else {
      if (posX == 'in') {
        this.setState({
          messageLeft: `${x + offset}px`,
          messageRight: `initial`
        });
      } else {
        this.setState({
          messageRight: `${(window.innerWidth - x) + offset}px`,
          messageLeft: `initial`
        });
      }
    }

    
    this.setState({
      messageBorderTLR: '8px',
      messageBorderTRR: '8px',
      messageBorderBRR: '8px',
      messageBorderBLR: '8px'
    });
    
    if (propY == 'top') {
      if (propX == 'left') {
        if (posX == 'in') {
          this.setState({
            messageBorderTRR: '0px'
          });
        } else {
          this.setState({
            messageBorderTLR: '0px'
          });
        }
      } else {
        if (posX == 'in') {
          this.setState({
            messageBorderTLR: '0px'
          });
        } else {
          this.setState({
            messageBorderTRR: '0px'
          });
        }
      }
    } else {
      if (propX == 'left') {
        if (posX == 'in') {
          this.setState({
            messageBorderBRR: '0px'
          });
        } else {
          this.setState({
            messageBorderBLR: '0px'
          });
        }
      } else {
         if (posX == 'in') {
          this.setState({
            messageBorderBLR: '0px'
          });
        } else {
          this.setState({
            messageBorderBRR: '0px'
          });
        }
      }
    }
    
  };
  
  sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));
  
  doAction = async (selector, event) => {
    switch (event) {
      case 'click':
        document.querySelector(selector).click();
      break;
      default:
        let evt = new window.Event(event);
        document.querySelector(selector).dispatchEvent(evt);
      break;
    }
    await this.sleep(0.7);
  };
  
  onClickSkip = (e) => {
    this.setState({
      active: false
    });
  };
  
  // componentWillReceiveProps (nextProps) {
  //   if (nextProps.value !== this.props.value) {
  //     if (nextProps.value !== this.state.selectedValue) {
  //       let selected = null;
  //       let index = -1;
  //       React.Children.forEach(this.props.children, (element, idx) => {
  //         if (element.props.selected) {
  //           selected = element;
  //           index = idx;
  //         }
  //       });
  //       this.setState({
  //         selectedIndex: selected ? index : -1,
  //         selectedValue: selected ? selected.props.value: ''
  //       }, () => {
  //         if (this.props.onChange) {
  //           this.props.onChange(this.state.selectedValue);
  //         }
  //       });
  //     }
  //   }
  // }
  
  render () {
    return (
      <div className={this.props.className ? `${this.props.className} helper-overlay` : `helper-overlay ${this.state.active ? 'active' : ''}`}>
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" mask="url(#hole)"/>
          <mask id="hole">
            <rect x="0" y="0" width="100%" height="100%" fill="white"/>
            <polygon points={this.state.polyPoints}/>
          </mask>
        </svg>
        <div className="message" style={{
          top: this.state.messageTop,
          bottom: this.state.messageBottom,
          left: this.state.messageLeft,
          right: this.state.messageRight,
          borderTopLeftRadius: this.state.messageBorderTLR,
          borderTopRightRadius: this.state.messageBorderTRR,
          borderBottomRightRadius: this.state.messageBorderBRR,
          borderBottomLeftRadius: this.state.messageBorderBLR
        }}>
          <div>{this.state.index > -1 && this.state.steps[this.state.index] ? this.state.steps[this.state.index].message.text : ''}</div>
        </div>
        <div className="control skip" onClick={this.onClickSkip}>Skip</div>
        <div className="control next" onClick={this.onClickNext}>{!this.state.steps[this.state.index + 1] ? 'Finish' : 'Next'}</div>
      </div>
    );
  }
}

class HelperBubble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      listeners: [],
      bubble: null,
      messageTop: '0px',
      messageBottom: '0px',
      messageLeft: '0px',
      messageRight: '0px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
    };
  }
  
  addMessage = (target) => {
    return new Promise((resolve, reject) => {
      ((target) => {
        let calculate = () => {
          let element = target;
          let margin = 10;
          let viewportOffset = element.getBoundingClientRect();
          let top = viewportOffset.top;
          let left = viewportOffset.left;
          let width = element.offsetWidth;
          let height = element.offsetHeight;
          let propY = 'top';
          if (top + height / 2 > window.innerHeight / 2) {
            propY = 'bottom';
          }
          let propX = 'left';
          if (left + width / 2 > window.innerWidth / 2) {
            propX = 'right';
          }
          return {
            pos: {
              x: left - margin,
              y: top - margin,
              w: width + margin * 2,
              h: height + margin * 2
            },
            message: {
              pos: {
                propY: propY,
                propX: propX,
              }
            }
          };
        };
        this.setState({
          bubble: calculate()
        }, () => {
          let recalculate = (e) => {
            let bubble = this.state.bubble;
            bubble = calculate();
            this.setState({
              bubble: bubble
            }, async () => {
              this.moveTo(
                this.state.bubble.pos.x, 
                this.state.bubble.pos.y, 
                this.state.bubble.pos.w, 
                this.state.bubble.pos.h, 
                this.state.bubble.message.pos.propY, 
                this.state.bubble.message.pos.propX
              );
            });
          };
          window.addEventListener('resize', recalculate);
          window.addEventListener('scroll', recalculate);
          this.setState({
            listeners: this.state.listeners.concat([recalculate])
          });
          resolve();
        });
      })(target);
    });
  }
  
  show = async (target) => {
    this.setState({
      active: true,
      bubble: null,
      messageTop: '0px',
      messageBottom: '0px',
      messageLeft: '0px',
      messageRight: '0px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
    }, async () => {
      await this.addMessage(target);
      this.moveTo(
        this.state.bubble.pos.x, 
        this.state.bubble.pos.y, 
        this.state.bubble.pos.w, 
        this.state.bubble.pos.h, 
        this.state.bubble.message.pos.propY, 
        this.state.bubble.message.pos.propX
      );
    });
  };
  
  hide = async () => {
    this.setState({
      active: false
    });
  }
  
  onKeyUp = (e) => {
    if (e.key === 'Escape') {
      this.setState({
        active: false
      });
    }
  }
  
  componentDidMount () {
    triggers[this.props.helperId] = (target) => {
      this.show(target);
    };
    window.document.addEventListener('keyup', this.onKeyUp);
  }
  
  componentWillUnmount () {
    for (let i in this.state.listeners) {
      window.removeEventListener('resize', this.state.listeners[i]);
      window.removeEventListener('scroll', this.state.listeners[i]);
    }
    window.removeEventListener('keyup', this.onKeyUp);
    this.setState({
      active: false,
      listeners: []
    });
    delete triggers[this.props.helperId];
  }
  
  moveTo = (x, y, w, h, propY, propX) => {
    let offset = 0;
    
    if (propY == 'top') {
      this.setState({
        messageTop: `${y + h + offset}px`,
        messageBottom: `initial`
      });
    } else {
      this.setState({
        messageBottom: `${(window.innerHeight - y) + offset}px`,
        messageTop: `initial`
      });
    }
    
    if (propX == 'left') {
      this.setState({
        messageLeft: `${x + w + offset}px`,
        messageRight: `initial`
      });
    } else {
      this.setState({
        messageRight: `${(window.innerWidth - x) - offset}px`,
        messageLeft: `initial`
      });
    }
    
    this.setState({
      messageBorderTLR: '4px',
      messageBorderTRR: '4px',
      messageBorderBRR: '4px',
      messageBorderBLR: '4px'
    });
    
    if (propY == 'top') {
      if (propX == 'left') {
        this.setState({
          messageBorderTLR: '0px'
        });
      } else {
        this.setState({
          messageBorderTRR: '0px'
        });
      }
    } else {
      if (propX == 'left') {
        this.setState({
          messageBorderBLR: '0px'
        });
      } else {
        this.setState({
          messageBorderBRR: '0px'
        });
      }
    }
    
  };
  
  sleep = (seconds) => new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));
  
  // componentWillReceiveProps (nextProps) {
  //   if (nextProps.value !== this.props.value) {
  //     if (nextProps.value !== this.state.selectedValue) {
  //       let selected = null;
  //       let index = -1;
  //       React.Children.forEach(this.props.children, (element, idx) => {
  //         if (element.props.selected) {
  //           selected = element;
  //           index = idx;
  //         }
  //       });
  //       this.setState({
  //         selectedIndex: selected ? index : -1,
  //         selectedValue: selected ? selected.props.value: ''
  //       }, () => {
  //         if (this.props.onChange) {
  //           this.props.onChange(this.state.selectedValue);
  //         }
  //       });
  //     }
  //   }
  // }
  
  render () {
    return (
      <div className={this.props.className ? `${this.props.className} helper-overlay` : `helper-overlay ${this.state.active ? 'active' : ''}`} onClick={this.hide}>
        <div className="message backdrop" style={{
          top: this.state.messageTop,
          bottom: this.state.messageBottom,
          left: this.state.messageLeft,
          right: this.state.messageRight,
          borderTopLeftRadius: this.state.messageBorderTLR,
          borderTopRightRadius: this.state.messageBorderTRR,
          borderBottomRightRadius: this.state.messageBorderBRR,
          borderBottomLeftRadius: this.state.messageBorderBLR
        }} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
          <div>{this.props.message ? this.props.message : ''}</div>
        </div>
      </div>
    );
  }
}

export {
  HelperOverlay,
  HelperButton,
  HelperBubble
};